import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { patchRejectManualVoucher } from '../../../../actions';
import withEndpointAuthorization from '../../../units/authorization/withEndPointAuthorization';
import { POST } from '../../../../config/permissions';
import { generateRejectManualVoucherEndpoint } from '../../../../config/endpoints';

const RejectManualVoucherCell = ({
  id,
  dispatchPatchRejectManualVoucher,
  loading,
}) => {
  const onReject = () => {
    if (loading) return;
    dispatchPatchRejectManualVoucher(id);
  };
  return (
    <>
      <i
        tabIndex="0"
        role="button"
        className="fa fa-times-circle text-danger"
        onKeyPress={onReject}
        onClick={onReject}
      />
    </>
  );
};
RejectManualVoucherCell.propTypes = {
  id: PropTypes.number.isRequired,
  dispatchPatchRejectManualVoucher: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ AccountingUnit }) => ({
  loading: !AccountingUnit.ManualVoucher.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToprops = {
  dispatchPatchRejectManualVoucher: patchRejectManualVoucher,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToprops,
)(RejectManualVoucherCell);

export default withEndpointAuthorization({
  url: generateRejectManualVoucherEndpoint(),
  mapUrlParamsToProps: { id: 'id' },
  permissionType: POST,
})(connectedComponent);
