import PropTypes from 'prop-types';
import React from 'react';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import LinkToModuleResource from '../../../common/resource/LinkToModuleResource';
import { DRIVER_PATH } from '../../../../config/paths';
import { tzNormalizeDate } from '../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../config/locale';

const DriverMovementHistoryBasicResource = ({
  id,
  driverFullName,
  startDate,
  endDate,
  driverMovementName,
  note,
  secondaryNote,
  driverId,
}) => {
  const missingDays = (startTimestamp, endTimestamp) => {
    const start = new Date(startTimestamp);
    const end = new Date(endTimestamp);
    const currentDate = new Date();
    if (currentDate > end) {
      return 0;
    }
    const diffTime = Math.abs(start - currentDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  return (
    <>
      <ResourceProperty label="Id:">{id}</ResourceProperty>
      <ResourceProperty label="Conductor:">
        <LinkToModuleResource
          text={driverFullName}
          href={`${DRIVER_PATH}/${driverId}`}
          isExternalPath
        />
      </ResourceProperty>
      <ResourceProperty label="Motivo:">{driverMovementName}</ResourceProperty>
      <ResourceProperty label="Fecha/Hora Inicio:">
        {tzNormalizeDate({ date: startDate, format: DATE_TIME_FORMAT })}
      </ResourceProperty>
      <ResourceProperty label="Fecha/Hora Final:">
        {tzNormalizeDate({ date: endDate, format: DATE_TIME_FORMAT })}
      </ResourceProperty>
      <ResourceProperty label="Días Faltantes:">
        {missingDays(startDate, endDate)}
      </ResourceProperty>
      <ResourceProperty label="Observación 1:">{note}</ResourceProperty>
      <ResourceProperty label="Observación 2:">
        {secondaryNote}
      </ResourceProperty>
    </>
  );
};

DriverMovementHistoryBasicResource.propTypes = {
  id: PropTypes.number.isRequired,
  driverFullName: PropTypes.string.isRequired,
  startDate: PropTypes.number.isRequired,
  endDate: PropTypes.number.isRequired,
  driverMovementName: PropTypes.string.isRequired,
  note: PropTypes.string,
  secondaryNote: PropTypes.string,
  driverId: PropTypes.number.isRequired,
};

DriverMovementHistoryBasicResource.defaultProps = {
  note: '',
  secondaryNote: '',
};

export default DriverMovementHistoryBasicResource;
