import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import Immutable from 'immutable';
import Content from '../../../layout/Content';
import {
  clearDriversMovementHistory,
  getDriverMovementHistory,
} from '../../../../actions';
import { DRIVER_MOVEMENT_HISTORY_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import DriverMovementHistoryBasicResource from './DriverMovementHistoryBasicResource';
import DriverMovementHistoryPrintButton from './DriverMovementHistoryPrintButton';
import EditButton from '../../../common/button/EditButton';

const DriverMovementHistory = ({
  match: {
    params: { id },
  },
  dispatchGetDriverMovementHistory,
  dispatchClearDriverMovementHistory,
  loading,
  driverMovementHistory,
  breadcrumbs,
}) => {
  useLayoutEffect(() => {
    dispatchGetDriverMovementHistory({ driverMovementHistoryId: id });
    return () => dispatchClearDriverMovementHistory();
  }, []);

  let content;

  let toolbar;

  if (loading) content = <Loader />;
  else if (driverMovementHistory.isEmpty())
    content = <NoDataResource returnPage={DRIVER_MOVEMENT_HISTORY_PATH} />;
  else {
    const driverMovementHistoryJson = driverMovementHistory.toJS();
    toolbar = (
      <ButtonToolbar className="pull-right">
        <EditButton
          path={`${DRIVER_MOVEMENT_HISTORY_PATH}/${driverMovementHistoryJson.id}/edit`}
        />
        <DriverMovementHistoryPrintButton {...driverMovementHistoryJson} />
      </ButtonToolbar>
    );
    content = (
      <DriverMovementHistoryBasicResource {...driverMovementHistoryJson} />
    );
  }
  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Historial de movimiento del conductor"
      subtitle="Historial de movimiento del conductor"
      content={content}
      toolbar={toolbar}
    />
  );
};

DriverMovementHistory.propTypes = {
  dispatchGetDriverMovementHistory: PropTypes.func.isRequired,
  dispatchClearDriverMovementHistory: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  driverMovementHistory: PropTypes.instanceOf(Immutable.Map).isRequired,
  match: matchPropTypes.isRequired,
  breadcrumbs: breadcrumbsPropTypes.isRequired,
};

DriverMovementHistory.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Historial de movimiento del conductor',
      href: DRIVER_MOVEMENT_HISTORY_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  driverMovementHistory: TrafficUnit.DriverMovementHistory.getIn([
    'current',
    'content',
  ]),
  loading: !TrafficUnit.DriverMovementHistory.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetDriverMovementHistory: getDriverMovementHistory,
  dispatchClearDriverMovementHistory: clearDriversMovementHistory,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DriverMovementHistory);
