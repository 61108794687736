import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Container, Row, Col, Input, Button } from 'reactstrap';
import Immutable from 'immutable';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import { postFixedTicketPrice, cancelCreditNote } from '../../../../actions';
import { tzNormalizeDate } from '../../../../utils/date';
import { numberFormatter } from '../../../../utils/number';
import { CONTINGENCY_MOTIVE_OPTION } from '../../../../config/constants';

const showNewTicketPricePrefix = 'showNewTicketPrice';
const newTicketPricePrefix = 'newTicketPrice';

let initialState;

class CostSummary extends Component {
  static propTypes = {
    costs: PropTypes.arrayOf(
      PropTypes.shape({
        fullName: PropTypes.string.isRequired,
        salePrice: PropTypes.number.isRequired,
        ticketId: PropTypes.number.isRequired,
      }),
    ).isRequired,
    total: PropTypes.number.isRequired,
    creditNote: PropTypes.instanceOf(Immutable.Map),
    cancelCreditNote: PropTypes.func.isRequired,
    showExtraColumns: PropTypes.bool,
    dispatchPostFixedTicketPrice: PropTypes.func.isRequired,
    onContingencyChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    creditNote: new Immutable.Map(),
    showExtraColumns: false,
  };

  constructor(props) {
    super(props);
    const { costs } = props;
    const stateObject = {
      contingencyData: [],
    };
    // create initial component state
    // acoording to the number of ticket prices
    costs.forEach(({ ticketId }) => {
      stateObject[`${showNewTicketPricePrefix}${ticketId}`] = false;
      stateObject[`${newTicketPricePrefix}${ticketId}`] = '';
    });
    initialState = stateObject;
    this.state = initialState;
  }

  componentDidUpdate(prevProps) {
    // reset component state after prices are updated
    if (prevProps.costs !== this.props.costs) {
      this.setNewTicketPriceState(initialState);
    }
  }

  setNewTicketPriceState = (newTicketPriceState) => {
    this.setState(newTicketPriceState);
  };

  handleCancelCreditNote = () => {
    this.props.cancelCreditNote();
  };

  handleEditPriceClick = ({ target }) => {
    const showNewTicketPriceState = {};
    showNewTicketPriceState[`${showNewTicketPricePrefix}${target.id}`] = true;
    this.setState(showNewTicketPriceState);
  };

  handleNewPriceChange = (event) => {
    const {
      value,
      dataset: { ticketid },
    } = event.target;
    const newTicketPriceState = {};
    newTicketPriceState[`${newTicketPricePrefix}${ticketid}`] = value;
    this.setState(newTicketPriceState);
  };

  applyNewPrices = () => {
    const fixedTicketPrices = [];

    Object.keys(this.state).forEach((key) => {
      const value = this.state[key];
      if (key.startsWith(newTicketPricePrefix) && value && value.length) {
        const ticketId = key.substring(newTicketPricePrefix.length);
        fixedTicketPrices.push({
          ticketId: parseInt(ticketId, 10),
          fixedPrice: parseFloat(value),
        });
      }
    });
    this.props.dispatchPostFixedTicketPrice({ fixedTicketPrices });
  };

  handleCancelPriceChange = (event) => {
    const {
      dataset: { ticketid },
    } = event.target;
    const ticketPriceState = {};
    ticketPriceState[`${showNewTicketPricePrefix}${ticketid}`] = false;
    ticketPriceState[`${newTicketPricePrefix}${ticketid}`] = '';
    this.setState(ticketPriceState);
  };

  shouldShowNewPricesButton = () => {
    let showButton = false;
    Object.keys(this.state).forEach((key) => {
      if (
        key.startsWith(showNewTicketPricePrefix) &&
        this.state[key] === true
      ) {
        showButton = true;
      }
    });
    return showButton;
  };

  handleInputChange = (event, ticketId, field) => {
    const { value } = event.target;

    this.setState((prevState) => {
      const existingIndex = prevState.contingencyData.findIndex(
        (data) => data.ticketId === ticketId,
      );

      let updatedContingencyData;
      if (existingIndex !== -1) {
        updatedContingencyData = [...prevState.contingencyData];
        updatedContingencyData[existingIndex] = {
          ...updatedContingencyData[existingIndex],
          [field]: value,
          ticketId,
        };
      } else {
        updatedContingencyData = [
          ...prevState.contingencyData,
          { ticketId, [field]: value },
        ];
      }

      this.props.onContingencyChange(updatedContingencyData);

      return { contingencyData: updatedContingencyData };
    });
  };

  render() {
    const { costs, total, creditNote, showExtraColumns } = this.props;
    let amount = <h3>Total: {numberFormatter({ value: total })}</h3>;
    if (!creditNote.isEmpty()) {
      const creditNoteAmount = creditNote.get('amount');
      const totalAmount =
        total - creditNoteAmount >= 0 ? total - creditNoteAmount : 0;
      const cancelStyles = {
        cursor: 'pointer',
      };
      amount = (
        <Fragment>
          <h3>
            <strike>Total: {numberFormatter({ value: total })}</strike>
          </h3>
          <Row>
            <Col xs={5}>
              <h3>
                Descuento por Anticipo:{' '}
                {numberFormatter({ value: creditNoteAmount })}
              </h3>
            </Col>
            <Col xs={1}>
              <i
                style={cancelStyles}
                onClick={this.handleCancelCreditNote}
                className="fa fa-times fa-2x text-danger"
                aria-hidden="true"
              />
            </Col>
            <Col xs={6} />
          </Row>
          <h2>
            <b>Total a pagar: {numberFormatter({ value: totalAmount })}</b>
          </h2>
        </Fragment>
      );
    }
    const showApplyNewPrices = this.shouldShowNewPricesButton();
    let applyNewPricesButton;
    if (showApplyNewPrices) {
      applyNewPricesButton = (
        <Button
          onClick={this.applyNewPrices}
          type="button"
          color="primary"
          outline
        >
          Aplicar Nuevos Precios
        </Button>
      );
    }
    return (
      <>
        <Row>
          <Col>
            <h3>Pasajeros</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped responsive>
              <thead>
                <tr>
                  <th>Pasajero</th>
                  <th>Datos del viaje</th>
                  <th>Asiento</th>
                  <th>Precio</th>
                  <th>Editar Precio</th>
                  {showExtraColumns && (
                    <>
                      <th>Serie de Contingencia</th>
                      <th>Código de Contingencia</th>
                      <th>Motivo de Contingencia</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {costs.map(
                  ({
                    id,
                    fullName,
                    idDocumentNumber,
                    salePrice,
                    ticketId,
                    trip: { source, destination, departureTime },
                    seatNumber,
                    identificationType,
                  }) => {
                    const showInput =
                      this.state[`${showNewTicketPricePrefix}${ticketId}`];

                    let priceTd;
                    if (showInput) {
                      priceTd = (
                        <Container>
                          <Row>
                            <Col xs={12} md={10}>
                              <Input
                                value={
                                  this.state[
                                    `${newTicketPricePrefix}${ticketId}`
                                  ]
                                }
                                onChange={this.handleNewPriceChange}
                                data-ticketid={ticketId}
                                placeholder="Nuevo precio"
                                type="text"
                              />
                            </Col>
                            <Col xs={12} md={2}>
                              <i
                                className="fa fa-times fa-2x"
                                aria-hidden="true"
                                data-ticketid={ticketId}
                                onClick={this.handleCancelPriceChange}
                              />
                            </Col>
                          </Row>
                        </Container>
                      );
                    } else {
                      priceTd = (
                        <i
                          id={ticketId}
                          onClick={this.handleEditPriceClick}
                          className="fa fa-pencil-square-o"
                          aria-hidden="true"
                        />
                      );
                    }
                    return (
                      <tr key={id}>
                        <td>
                          {fullName}
                          <br />
                          <small>
                            {identificationType}: {idDocumentNumber}
                          </small>
                        </td>
                        <td>
                          {source}
                          <span className="mr-3 ml-3"> &rarr; </span>
                          {destination}
                          <br />
                          <small>
                            Salida:{' '}
                            {tzNormalizeDate({
                              date: departureTime,
                              format: DATE_TIME_FORMAT,
                            })}
                          </small>
                        </td>
                        <td>{seatNumber}</td>
                        <td>{numberFormatter({ value: salePrice })}</td>
                        <td>{priceTd}</td>
                        {showExtraColumns && (
                          <>
                            <td>
                              <Input
                                type="text"
                                name={`contingencySeries${ticketId}`}
                                onChange={(e) =>
                                  this.handleInputChange(
                                    e,
                                    ticketId,
                                    'contingencySeries',
                                  )
                                }
                                placeholder="Serie de Contingencia"
                              />
                            </td>
                            <td>
                              <Input
                                type="text"
                                name={`contingencyCode${ticketId}`}
                                onChange={(e) =>
                                  this.handleInputChange(
                                    e,
                                    ticketId,
                                    'contingencyCode',
                                  )
                                }
                                placeholder="Código de Contingencia"
                              />
                            </td>
                            <td>
                              <select
                                className="form-control"
                                name={`contingencyMotive${ticketId}`}
                                onChange={(e) =>
                                  this.handleInputChange(
                                    e,
                                    ticketId,
                                    'contingencyMotive',
                                  )
                                }
                                defaultValue=""
                              >
                                <option value="" disabled>
                                  Selecciona un motivo
                                </option>
                                {CONTINGENCY_MOTIVE_OPTION.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  },
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>{applyNewPricesButton}</Col>
        </Row>
        <Row>
          <Col>{amount}</Col>
        </Row>
      </>
    );
  }
}

const mapDispatchToProps = {
  dispatchPostFixedTicketPrice: postFixedTicketPrice,
  cancelCreditNote,
};

export default connect(null, mapDispatchToProps)(CostSummary);
