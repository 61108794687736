import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  FormGroup,
  Button,
  Label,
  Input,
  FormFeedback,
  InputGroupText,
} from 'reactstrap';
import { Field, reduxForm, formValueSelector, Form, change } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import {
  isRequired,
  validateLength,
  validateNumber,
} from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import {
  clearBaggageConfiguration,
  getActiveSalesSession,
  getBaggageConfiguration,
  getPaymentMethods,
} from '../../../../actions';
import {
  CONTINGENCY_MOTIVE_OPTION,
  KILOGRAM_SYMBOL,
  SUNAT_INVOICE_CODE,
  TICKET_STATUS,
  TRANSACTION_TYPE_CREDIT_CARD,
} from '../../../../config/constants';
import Alert from '../../../common/informative/Alert';
import PaymentMethodSelect from '../../../common/forms/select/PaymentMethodSelect';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import DynamicForm from '../../../common/forms/DynamicForm';
import {
  generateBaggageItemFormColumns,
  generateBaggageTicketFormColumns,
} from '../../../../config/dynamicFormFields';
import Loader from '../../../common/Loader';
import {
  BAGGAGE_NO_CONFIGURED_ALERT_MESSAGE,
  MODAL_ACTION_CONFIRM_MESSAGE,
  SOME_TICKET_IS_NOT_ACTIVE_ALERT_MESSAGE,
  TICKETS_IN_DIFFERENT_GROUPS_ALERT_MESSAGE,
  TICKET_WITH_DIFFERENT_ITINERARIES_ALERT_MESSAGE,
} from '../../../../config/messages';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { CURRENCY } from '../../../../config/locale';
import { numberFormatter, currencyToNumber } from '../../../../utils/number';
import FormFooter from '../../../common/forms/FormFooter';
import VoucherTypeSelect from '../../../common/forms/select/VoucherTypeSelect';
import BusinessInputGroup from '../../../common/forms/BusinessInputGroup';
import { BAGGAGE_PATH } from '../../../../config/paths';
import Select from '../../../common/forms/select/Select';

const selector = formValueSelector('BaggageForm');

const validateLength4 = validateLength(4);

class BaggageForm extends Component {
  static validateDiscount(discount, { excessPriceBeforeDiscount }) {
    if (!discount) return undefined;

    return +discount > currencyToNumber(excessPriceBeforeDiscount)
      ? 'Descuento no debe ser mayor al Monto a Cobrar'
      : undefined;
  }

  static validateCustomer(customer, { excessPrice }) {
    return currencyToNumber(excessPrice) > 0 && !customer
      ? 'Campo requerido'
      : undefined;
  }

  constructor(props) {
    super(props);

    const {
      excessBaggageId,
      voucherCode,
      weight,
      ticketList,
      baggageItemList,
    } = props.initialValues;

    const newTicketList = ticketList || [];

    let totalWeight = 0;
    if (baggageItemList)
      baggageItemList.forEach((item) => {
        totalWeight += item.weight;
      });

    this.state = {
      showExcess: !!excessBaggageId,
      excessWeight: 0,
      excessPriceBeforeDiscount: 0,
      showExcessVoidButton: !!excessBaggageId,
      showWarningModal: false,
      voidDescription: null,
      pricePerKilogram: 0,
      maximumFreeWeightPerTicket: 0,
      totalWeight,
      showVoucherCode: !!voucherCode,
      ticketList: newTicketList,
      disableFields: !!newTicketList.length,
      showBaggage: !!weight,
      disableExcessCalculateButton: true,
      showExcessCalculateButton: !weight,
      differentGroup: false,
      differentItinerary: false,
      ticketIsNotActive: false,
      voucherCodeValue:
        props.initialValues.voucherTypeId &&
        props.initialValues.voucherTypeId.voucherCode,
    };
  }

  componentDidMount() {
    this.onMount();
  }

  componentDidUpdate() {
    this.onDidUpdate();
  }

  componentWillUnmount() {
    this.props.dispatchClearBaggageConfiguration();
  }

  onMount = () => {
    const {
      dispatchGetPaymentMethods,
      dispatchGetBaggageConfiguration,
      dispatchGetActiveSalesSession,
    } = this.props;

    dispatchGetActiveSalesSession({ source: BAGGAGE_PATH });
    dispatchGetPaymentMethods();
    dispatchGetBaggageConfiguration();
  };

  onDidUpdate = () => {
    const { pricePerKilogram, maximumFreeWeightPerTicket } = this.state;

    const {
      baggageConfiguration,
      initialValues: { weight },
    } = this.props;

    if (
      !baggageConfiguration.isEmpty() &&
      pricePerKilogram === 0 &&
      maximumFreeWeightPerTicket === 0
    )
      this.setState({
        pricePerKilogram: baggageConfiguration.get('pricePerKilogram'),
        maximumFreeWeightPerTicket: baggageConfiguration.get(
          'maximumFreeWeightPerTicket',
        ),
      });

    if (!weight) {
      this.calculateTotalWeight();
      this.getTicketList();

      this.disableExcessCalculateButton();
    }
  };

  onChangeDiscount = (e) => {
    const { dispatchChange } = this.props;

    const { excessPriceBeforeDiscount } = this.state;

    const price = excessPriceBeforeDiscount - e.target.value;

    dispatchChange(
      'BaggageForm',
      'excessPrice',
      numberFormatter({ value: price > 0 ? price : 0 }),
    );
  };

  onHandleVoidExcessSubmit = (formValues) => {
    const { voidDescription } = this.state;

    if (voidDescription && voidDescription.length)
      this.onHandleSubmit({ voidDescription, void: true, ...formValues });
  };

  onHandleSubmit = (formValues) => {
    const { onSubmit, ticketListValue, baggageItemListValue } = this.props;

    const { totalWeight, showExcess, excessWeight, excessPriceBeforeDiscount } =
      this.state;

    const newBaggage = {
      weight: totalWeight,
      comment: formValues.comment,
      excessBaggageRequest: null,
      void: formValues.void || null,
    };

    if (formValues.void)
      newBaggage.voidDescription = formValues.voidDescription;

    if (showExcess) {
      const discountAmount = formValues.discountAmount || 0;

      const excessPrice = excessPriceBeforeDiscount - discountAmount;

      newBaggage.excessBaggageRequest = {
        weight: excessWeight,
        priceBeforeDiscount: excessPriceBeforeDiscount,
        discountAmount,
        price: excessPrice,
        payerCustomerId: formValues.payerCustomerId.value,
        paymentMethodId: formValues.excessPaymentMethodId.value,
        comment: formValues.excessComment,
        voucherCode: formValues.voucherCode || null,
        businessId: formValues.businessId && formValues.businessId.value,
        voucherTypeId:
          formValues.voucherTypeId && formValues.voucherTypeId.value,
        contingencyDocumentSeries: formValues.contingencyDocumentSeries,
        contingencyDocumentCode: formValues.contingencyDocumentCode,
        contingencyMotive: formValues.contingencyMotive
          ? formValues.contingencyMotive.value
          : null,
      };
    }
    if (ticketListValue.length)
      newBaggage.tickets = ticketListValue.map(({ ticket }) => ({
        id: ticket.value,
        documentCode: ticket.label,
      }));

    if (baggageItemListValue.length) {
      newBaggage.items = baggageItemListValue.map(
        ({ itemCategoryId, itemSeries, itemCode, description, weight }) => ({
          itemCategoryId: itemCategoryId.value,
          itemSeries,
          itemCode,
          description,
          weight,
        }),
      );
    }

    onSubmit(newBaggage);
  };

  onChangePaymentMethod = (paymentMethod) => {
    this.setState({
      showVoucherCode:
        paymentMethod.transactionCode &&
        paymentMethod.transactionCode === TRANSACTION_TYPE_CREDIT_CARD,
    });

    if (this.state.showVoucherCode) {
      const { dispatchChange } = this.props;

      dispatchChange('BaggageForm', 'voucherCode', '');
    }
  };

  onChangeVoucherType = (option) =>
    this.setState({ voucherCodeValue: option.voucherCode });

  getTicketList = () => {
    const { ticketListValue } = this.props;

    const { ticketList } = this.state;

    const ticketsWithData = [];
    ticketListValue.forEach(({ ticket }) => {
      if (ticket) ticketsWithData.push(ticket);
    });

    if (ticketList.length !== ticketsWithData.length)
      this.setState({
        ticketList: ticketsWithData,
        disableExcessCalculateButton: true,
        showBaggage: false,
        showExcess: false,
      });
  };

  disableExcessCalculateButton = () => {
    const { ticketList, totalWeight, disableExcessCalculateButton } =
      this.state;

    if (
      disableExcessCalculateButton &&
      ticketList.length &&
      totalWeight > 0 &&
      this.props.initialValues
    )
      this.setState({ disableExcessCalculateButton: false });
  };

  calculateExcess = () => {
    const { dispatchChange, ticketListValue } = this.props;

    const {
      totalWeight,
      maximumFreeWeightPerTicket,
      pricePerKilogram,
      ticketList,
    } = this.state;

    dispatchChange('BaggageForm', 'comment', null);

    let showExcess = false;
    let excessWeight = 0;
    let excessPriceBeforeDiscount = 0;

    const hasPreviousBaggages = ticketList.some(
      (ticket) => ticket.baggages.length > 0,
    );

    const numberOfTickets = ticketListValue.filter(
      ({ ticket }) => ticket,
    ).length;

    let totalMaximumFreeWeightPerTicket =
      +numberOfTickets * +maximumFreeWeightPerTicket;

    let superTotalWeight = +totalWeight;

    if (hasPreviousBaggages) {
      const ticketListExcesses = [];
      ticketList.forEach(({ baggages }) => {
        if (baggages) {
          baggages.forEach((baggage) => {
            if (baggage) ticketListExcesses.push(baggage);
          });
        }
      });

      const ticketListWithExcess = ticketListExcesses.some(
        ({ excessBaggage }) => !!excessBaggage,
      );

      if (ticketListWithExcess) {
        // Case 1: tickets has one previous excess registered
        totalMaximumFreeWeightPerTicket = 0;
      } else {
        // Case 2: tickets has baggages without excesses
        let previousBaggageWeight = ticketListExcesses.reduce(
          (accumulator, baggage) => {
            const weight = baggage.status === 'ACTIVE' ? +baggage.weight : 0;
            return +accumulator + (weight || 0);
          },
          0,
        );

        previousBaggageWeight /= ticketListExcesses.length;

        const numberOfTicketsPrevious = ticketListExcesses[0].ticketDtos.length;

        totalMaximumFreeWeightPerTicket =
          +numberOfTicketsPrevious * +maximumFreeWeightPerTicket;

        superTotalWeight = +previousBaggageWeight + +totalWeight;

        dispatchChange(
          'BaggageForm',
          'comment',
          `Peso anterior: ${previousBaggageWeight}`,
        );
      }
    }

    if (totalMaximumFreeWeightPerTicket < superTotalWeight) {
      showExcess = true;
      excessWeight = superTotalWeight - totalMaximumFreeWeightPerTicket;
    }

    excessPriceBeforeDiscount = excessWeight * pricePerKilogram;

    this.setState({
      showExcess,
      excessWeight,
      excessPriceBeforeDiscount,
      showBaggage: true,
      differentGroup: false,
      differentItinerary: false,
      ticketIsNotActive: false,
    });

    this.checkDifferentBaggageGroup();
    this.checkDifferentItinerary();
    this.checkTicketIsNotActive();

    dispatchChange('BaggageForm', 'excessWeight', excessWeight);
    dispatchChange(
      'BaggageForm',
      'excessPriceBeforeDiscount',
      numberFormatter({ value: excessPriceBeforeDiscount }),
    );
    dispatchChange(
      'BaggageForm',
      'excessPrice',
      numberFormatter({ value: excessPriceBeforeDiscount }),
    );
  };

  calculateTotalWeight = () => {
    const { baggageItemListValue, dispatchChange } = this.props;

    let totalWeight = 0;
    baggageItemListValue.forEach((baggageItem) => {
      totalWeight += +baggageItem.weight || 0;
    });

    if (+totalWeight !== +this.state.totalWeight) {
      this.setState({
        totalWeight,
        disableExcessCalculateButton: true,
        showBaggage: false,
        showExcess: false,
        differentGroup: false,
        differentItinerary: false,
        ticketIsNotActive: false,
      });

      dispatchChange('BaggageForm', 'weight', totalWeight);
    }
  };

  handleVoidDescripcion = (e) => {
    this.setState({ voidDescription: e.target.value });
  };

  showModal = () => this.setState({ showWarningModal: true });

  closeModal = () =>
    this.setState({ showWarningModal: false, voidDescription: null });

  updateTicketList = (index, option) => {
    const newTicketList = [...this.state.ticketList];
    newTicketList[index] = option;

    this.setState({
      ticketList: newTicketList,
      showExcess: false,
      showBaggage: false,
      differentGroup: false,
      differentItinerary: false,
      ticketIsNotActive: false,
    });
  };

  checkDifferentBaggageGroup = () => {
    const { ticketList } = this.state;

    const startingTicket = ticketList[0].label;

    const ticketGroup = [];
    ticketList.forEach(({ baggages }) => {
      baggages.forEach((baggage) => {
        if (baggage) {
          ticketGroup.push(
            baggage.ticketDtos.some(
              (ticketDto) => ticketDto.documentCode === startingTicket,
            ),
          );
        }
      });
    });

    let sameGroup = ticketGroup.every((ticket) => ticket);

    sameGroup = !(
      sameGroup &&
      ticketGroup.length > 0 &&
      ticketGroup.length < ticketList.length
    );

    if (ticketGroup.length > 0) this.setState({ differentGroup: !sameGroup });
  };

  checkDifferentItinerary = () => {
    const { ticketList } = this.state;

    const itineraryIdList = ticketList.map(({ itineraryId }) => itineraryId);

    const sameItineraries = itineraryIdList.every(
      (id) => itineraryIdList[0] === id,
    );

    this.setState({ differentItinerary: !sameItineraries });
  };

  checkTicketIsNotActive = () => {
    const { ticketList } = this.state;

    const status = ticketList.every(
      ({ ticketStatus }) => ticketStatus === TICKET_STATUS.ACTIVE.value,
    );

    this.setState({ ticketIsNotActive: !status });
  };

  renderBaggageNoConfiguredAlert = () => {
    const { baggageConfiguration, baggageConfigurationActivity } = this.props;

    let alert = null;
    if (!baggageConfigurationActivity && baggageConfiguration.isEmpty())
      alert = <Alert message={BAGGAGE_NO_CONFIGURED_ALERT_MESSAGE} />;

    return alert;
  };

  renderDifferentItineraryAlert = () =>
    this.state.differentItinerary ? (
      <Alert message={TICKET_WITH_DIFFERENT_ITINERARIES_ALERT_MESSAGE} />
    ) : null;

  renderTicketIsNotActiveAlert = () =>
    this.state.ticketIsNotActive ? (
      <Alert message={SOME_TICKET_IS_NOT_ACTIVE_ALERT_MESSAGE} />
    ) : null;

  renderDifferentGroupAlert = () =>
    this.state.differentGroup ? (
      <Alert message={TICKETS_IN_DIFFERENT_GROUPS_ALERT_MESSAGE} />
    ) : null;

  renderBaggage = () => {
    const {
      showBaggage,
      showExcess,
      differentGroup,
      ticketIsNotActive,
      differentItinerary,
    } = this.state;

    if (
      showBaggage &&
      !differentGroup &&
      !ticketIsNotActive &&
      !differentItinerary
    ) {
      return (
        <div className="mb-3">
          <h5>Datos de Equipaje</h5>
          <FormGroup row>
            <FormItem label="Peso Total" required>
              <Field
                name="weight"
                type="text"
                component={TextInput}
                disabled
                placeholder="Peso"
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Comentario:">
              <Field name="comment" component={TextInput} type="textarea" />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem>
              <Label>
                <Field
                  name="hasExcess"
                  id="hasExcess"
                  component="input"
                  type="checkbox"
                  checked={showExcess}
                  disabled
                />{' '}
                Tiene Exceso
              </Label>
            </FormItem>
          </FormGroup>
        </div>
      );
    }
    return null;
  };

  renderButtons = () => {
    const {
      showBaggage,
      differentGroup,
      ticketIsNotActive,
      differentItinerary,
    } = this.state;

    return showBaggage &&
      !differentGroup &&
      !ticketIsNotActive &&
      !differentItinerary ? (
      <FormFooter saveButtonDisabled={!showBaggage}>
        {this.renderExcessVoidButton()}
      </FormFooter>
    ) : null;
  };

  renderContingencyFields = () => {
    const { activeSalesSession, isEdit } = this.props;

    let isContingencySalesSession = false;

    if (!activeSalesSession.isEmpty()) {
      isContingencySalesSession =
        activeSalesSession.get('workstation').contingency;
    }

    const contingencyField =
      isContingencySalesSession && !isEdit ? (
        <>
          <h5>Datos de Contingencia</h5>
          <FormGroup row>
            <FormItem label="Serie de Contingencia">
              <Field
                name="contingencyDocumentSeries"
                component={TextInput}
                type="text"
                placeholder="Serie de Contingencia"
                validate={
                  isContingencySalesSession
                    ? [isRequired, validateNumber, validateLength4]
                    : []
                }
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Código de Contingencia">
              <Field
                name="contingencyDocumentCode"
                component={TextInput}
                type="text"
                placeholder="Código de Contingencia"
                validate={
                  isContingencySalesSession ? [isRequired, validateNumber] : []
                }
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Motivo de Contingencia">
              <Field
                name="contingencyMotive"
                component={Select}
                placeholder="Motivo de Contingencia"
                options={CONTINGENCY_MOTIVE_OPTION}
                validate={isContingencySalesSession ? [isRequired] : []}
              />
            </FormItem>
          </FormGroup>
        </>
      ) : null;
    return contingencyField;
  };

  renderExcess = () => {
    const {
      disableFields,
      showExcess,
      differentGroup,
      ticketIsNotActive,
      differentItinerary,
      showVoucherCode,
      ticketList,
      voucherCodeValue,
    } = this.state;

    if (
      showExcess &&
      !differentGroup &&
      !ticketIsNotActive &&
      !differentItinerary
    ) {
      const customerOptions = ticketList.map(
        ({
          customerFullName,
          customerId,
          customerDocumentNumber,
          idCountryOfOrigin,
        }) => ({
          value: customerId,
          label: `${customerDocumentNumber} (${idCountryOfOrigin}) - ${customerFullName}`,
        }),
      );

      const voucherCode = showVoucherCode ? (
        <FormGroup row>
          <FormItem label="Nro Referencia (POS)" required>
            <Field
              name="voucherCode"
              component={TextInput}
              type="text"
              placeholder="Código de Voucher"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
      ) : null;

      const voucherTypeField = voucherCodeValue === SUNAT_INVOICE_CODE && (
        <BusinessInputGroup
          label="Empresa"
          labelRequired
          name="businessId"
          form="BaggageForm"
          validate={[isRequired]}
          disabled={disableFields}
          showDetails={!disableFields}
        />
      );

      return (
        <Fragment>
          <h5>Datos de Exceso de Equipaje</h5>
          <FormGroup row>
            <FormItem label="Peso" required>
              <Field
                name="excessWeight"
                component={TextInput}
                type="text"
                placeholder="Peso"
                append={<InputGroupText>{KILOGRAM_SYMBOL}</InputGroupText>}
                disabled
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Monto a Cobrar" required>
              <Field
                name="excessPriceBeforeDiscount"
                component={TextInput}
                type="text"
                placeholder="Monto a Cobrar"
                disabled
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Descuento">
              <Field
                name="discountAmount"
                component={TextInput}
                type="text"
                append={<InputGroupText>{CURRENCY}</InputGroupText>}
                placeholder="Descuento"
                onChange={this.onChangeDiscount}
                validate={[BaggageForm.validateDiscount, validateNumber]}
                disabled={disableFields}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row className="align-items-center">
            <FormItem label="Precio Final" required>
              <Field
                name="excessPrice"
                component={TextInput}
                type="text"
                placeholder="Precio Final"
                disabled
                size="lg"
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Tipo de Comprobante" required>
              <Field
                name="voucherTypeId"
                component={VoucherTypeSelect}
                onChange={this.onChangeVoucherType}
                isClearable={false}
                validate={[isRequired]}
                isDisabled={disableFields}
                displayInPaymentPage
              />
            </FormItem>
          </FormGroup>
          {voucherTypeField}
          <CustomerInputGroup
            label="Cliente"
            name="payerCustomerId"
            labelRequired
            validate={[BaggageForm.validateCustomer]}
            form="BaggageForm"
            options={customerOptions}
            disabled={disableFields}
          />
          <FormGroup row>
            <FormItem label="Método de Pago" required>
              <Field
                name="excessPaymentMethodId"
                component={PaymentMethodSelect}
                onChange={this.onChangePaymentMethod}
                validate={[isRequired]}
                isDisabled={disableFields}
                transactionCodesNotIncluded={[]}
              />
            </FormItem>
          </FormGroup>
          {this.renderContingencyFields()}
          {voucherCode}
          <FormGroup row>
            <FormItem label="Comentario:">
              <Field
                name="excessComment"
                component={TextInput}
                type="textarea"
              />
            </FormItem>
          </FormGroup>
        </Fragment>
      );
    }
    return null;
  };

  renderExcessCalculateButton = () => {
    const { loadingBaggages } = this.props;

    const { disableExcessCalculateButton, showExcessCalculateButton } =
      this.state;

    const icon = loadingBaggages ? (
      <i className="fa fa-spinner fa-spin" />
    ) : null;

    return showExcessCalculateButton ? (
      <Button
        type="button"
        color="primary"
        outline
        disabled={loadingBaggages || disableExcessCalculateButton}
        onClick={this.calculateExcess}
        className="mb-4"
      >
        {icon} Calcular exceso de equipaje
      </Button>
    ) : null;
  };

  renderExcessVoidButton = () =>
    this.state.showExcessVoidButton ? (
      <Button type="button" onClick={this.showModal} color="danger" outline>
        Anular exceso
      </Button>
    ) : null;

  renderModal = () => {
    const { handleSubmit } = this.props;

    const { voidDescription, showWarningModal } = this.state;

    const validJustification = !(voidDescription && voidDescription.length);

    const modalBody = (
      <>
        <p>{MODAL_ACTION_CONFIRM_MESSAGE}</p>
        <Input
          type="textarea"
          invalid={validJustification}
          name="voidDescription"
          placeholder="Ingrese un motivo"
          onChange={this.handleVoidDescripcion}
        />
        <FormFeedback>Debe ingresar el motivo de la acción.</FormFeedback>
      </>
    );

    return (
      <ConfirmationModal
        show={showWarningModal}
        body={modalBody}
        onClickConfirm={handleSubmit(this.onHandleVoidExcessSubmit)}
        onClickCancel={this.closeModal}
      />
    );
  };

  render() {
    const { handleSubmit, loading } = this.props;

    const { ticketList, disableFields } = this.state;

    if (loading) return <Loader />;

    const warningModal = this.renderModal();

    const baggageNoConfiguredAlert = this.renderBaggageNoConfiguredAlert();
    const differentItineraryAlert = this.renderDifferentItineraryAlert();
    const ticketIsNotActiveAlert = this.renderTicketIsNotActiveAlert();
    const differentGroupAlert = this.renderDifferentGroupAlert();

    const ticketListField = (
      <div className="mb-3">
        <h5>Boletos</h5>
        <DynamicForm
          name="ticketList"
          columns={generateBaggageTicketFormColumns(
            this.updateTicketList,
            disableFields,
          )}
          initialValues={ticketList}
          showRemoveButton={!disableFields}
          showAddButton={!disableFields}
        />
      </div>
    );

    const baggageItemListField = (
      <div className="mb-3">
        <h5>Items</h5>
        <DynamicForm
          name="baggageItemList"
          columns={generateBaggageItemFormColumns(disableFields)}
          showRemoveButton={!disableFields}
          showAddButton={!disableFields}
        />
      </div>
    );

    const baggageFields = this.renderBaggage();
    const excessFields = this.renderExcess();

    const excessCalculateButton = this.renderExcessCalculateButton();

    const buttons = this.renderButtons();

    return (
      <div>
        {warningModal}
        {baggageNoConfiguredAlert}
        <Form onSubmit={handleSubmit(this.onHandleSubmit)}>
          {ticketListField}
          {differentItineraryAlert}
          {ticketIsNotActiveAlert}
          {differentGroupAlert}
          {baggageItemListField}
          {excessCalculateButton}
          {baggageFields}
          {excessFields}
          {buttons}
        </Form>
      </div>
    );
  }
}

BaggageForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dispatchGetPaymentMethods: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    weight: PropTypes.number,
    comment: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    tickets: PropTypes.arrayOf(PropTypes.object),
    voucherCode: PropTypes.string,
    excessBaggageId: PropTypes.number,
    ticketList: PropTypes.arrayOf(
      PropTypes.shape({
        documentCode: PropTypes.string,
      }),
    ),
    baggageItemList: PropTypes.arrayOf(
      PropTypes.shape({
        weight: PropTypes.number,
      }),
    ),
    voucherTypeId: PropTypes.shape({
      voucherCode: PropTypes.string.isRequired,
    }),
  }),
  dispatchGetBaggageConfiguration: PropTypes.func.isRequired,
  dispatchClearBaggageConfiguration: PropTypes.func.isRequired,
  baggageConfiguration: PropTypes.instanceOf(Immutable.Map).isRequired,
  baggageConfigurationActivity: PropTypes.bool.isRequired,
  baggageItemListValue: PropTypes.arrayOf(
    PropTypes.shape({
      weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  ticketListValue: PropTypes.arrayOf(
    PropTypes.shape({
      ticket: PropTypes.shape({
        value: PropTypes.number,
      }),
    }),
  ),
  loading: PropTypes.bool.isRequired,
  loadingBaggages: PropTypes.bool,
  activeSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchGetActiveSalesSession: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

BaggageForm.defaultProps = {
  initialValues: {},
  baggageItemListValue: [],
  ticketListValue: [],
  loadingBaggages: false,
  isEdit: false,
};

const mapStateToProps = (state) => ({
  baggageConfiguration: state.BaggageUnit.BaggageConfiguration.getIn([
    'current',
    'content',
  ]),
  baggageConfigurationActivity: !state.BaggageUnit.BaggageConfiguration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  baggageItemListValue: selector(state, 'baggageItemList'),
  ticketListValue: selector(state, 'ticketList'),
  loading: !state.BaggageUnit.Baggage.getIn(['current', 'activity']).isEmpty(),
  loadingBaggages: state.BaggageUnit.Baggage.getIn(['all', 'loading']),
  activeSalesSession: state.SalesUnit.SalesSession.getIn(['active', 'content']),
});

const mapDispatchToProps = {
  dispatchGetPaymentMethods: getPaymentMethods,
  dispatchChange: change,
  dispatchGetBaggageConfiguration: getBaggageConfiguration,
  dispatchClearBaggageConfiguration: clearBaggageConfiguration,
  dispatchGetActiveSalesSession: getActiveSalesSession,
};

export default reduxForm({
  form: 'BaggageForm',
})(connect(mapStateToProps, mapDispatchToProps)(BaggageForm));
