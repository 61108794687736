import React, { useLayoutEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import ModuleList from '../../../layout/ModuleList';
import {
  EXPENSE_AUTHORIZER_USER_PATH,
  NEW_EXPENSE_AUTHORIZER_USER_PATH,
} from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { EXPENSE_AUTHORIZER_USERS_COLUMNS } from '../../../../config/columns';
import {
  clearExpenseAuthorizerUsers,
  getExpenseAuthorizerUsers,
} from '../../../../actions/accounting/ExpenseAuthorizerUser';

export const ExpenseAuthorizerUsers = ({
  breadcrumbs,
  expenseAuthorizerUsers,
  loading,
  dispatchGetExpenseAuthorizerUsers,
  dispatchClearExpenseAuthorizerUsers,
}) => {
  useLayoutEffect(
    () => () => {
      dispatchClearExpenseAuthorizerUsers();
    },
    [],
  );

  const data = expenseAuthorizerUsers.get('content') || [];
  const pages = expenseAuthorizerUsers.get('totalPages') || null;
  const defaultPageSize =
    expenseAuthorizerUsers.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Usuarios Autorizadores de Egresos"
      buttonPath={NEW_EXPENSE_AUTHORIZER_USER_PATH}
      breadcrumbs={breadcrumbs}
      tableStructure={{
        columns: EXPENSE_AUTHORIZER_USERS_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetExpenseAuthorizerUsers,
        loading,
        modelPath: EXPENSE_AUTHORIZER_USER_PATH,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetExpenseAuthorizerUsers: getExpenseAuthorizerUsers,
  dispatchClearExpenseAuthorizerUsers: clearExpenseAuthorizerUsers,
};

const mapStateToProps = ({ AccountingUnit }) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Usuarios Autorizadores de Egresos',
      href: EXPENSE_AUTHORIZER_USER_PATH,
    },
  ],
  expenseAuthorizerUsers: AccountingUnit.ExpenseAuthorizerUser.getIn([
    'all',
    'content',
  ]),
  loading: AccountingUnit.ExpenseAuthorizerUser.getIn(['all', 'loading']),
});

ExpenseAuthorizerUsers.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  expenseAuthorizerUsers: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetExpenseAuthorizerUsers: PropTypes.func.isRequired,
  dispatchClearExpenseAuthorizerUsers: PropTypes.func.isRequired,
};

ExpenseAuthorizerUsers.defaultProps = {
  expenseAuthorizerUsers: [],
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpenseAuthorizerUsers);
