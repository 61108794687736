import {
  CREDIT_NOTE_MANUAL_VOUCHER_REASONS,
  CURRENCY_CODE,
  MANUAL_VOUCHER_PAYMENT_TYPE,
  SUNAT_CREDIT_NOTE_CODE,
  SUNAT_DEBIT_NOTE_CODE,
  SUNAT_INVOICE_CODE,
} from '../../config/constants';
import { DATE_FORMAT } from '../../config/locale';
import { tzNormalizeDate } from '../date';
import { numberFormatter } from '../number';
import {
  x,
  y,
  titleSize,
  subtitleSize,
  paragraphSize,
  textSpacing,
  cellHeight,
  addText,
  isNewPageNeeded,
  addQrCode,
  createPdfDocument,
  setFont,
} from '../pdf';
import { padStart } from '../string';

const myInitialY = y;
let myY = myInitialY;
const qrWidth = 35;
const qrHeight = 35;
const documentWidth = 200;

const printDocumentHeader = (
  doc,
  { voucherType, documentSeries, documentCode },
) => {
  doc.setFontSize(titleSize);
  setFont(doc, 'bold');
  addText(
    doc,
    'TURISMO CIVA S.A.C.',
    x,
    (myY += textSpacing - 1),
    documentWidth,
    'left',
  );
  doc.setFontSize(paragraphSize);
  addText(
    doc,
    'DOMICILIO FISCAL',
    x,
    (myY += textSpacing),
    documentWidth,
    'left',
  );
  setFont(doc);
  addText(
    doc,
    'Jr. Sancho de Rivera N° 1184 Urb, Monserrate',
    x,
    (myY += textSpacing),
    documentWidth,
    'left',
  );
  addText(
    doc,
    'Lima, Lima, Lima',
    x,
    (myY += textSpacing),
    documentWidth,
    'left',
  );
  addText(
    doc,
    'Telf.: (511) 418-1111',
    x,
    (myY += textSpacing),
    documentWidth,
    'left',
  );
  addText(
    doc,
    'Agencia: CREDITOS Y COBRANZAS',
    x,
    (myY += textSpacing),
    documentWidth,
    'left',
  );

  doc.rect(130, (myY -= 25), 75, 20);

  setFont(doc, 'bold');
  if (
    voucherType.voucherCode === SUNAT_CREDIT_NOTE_CODE ||
    voucherType.voucherCode === SUNAT_DEBIT_NOTE_CODE
  ) {
    doc.setFontSize(subtitleSize);
    addText(doc, voucherType.name, x + 162.5, (myY += textSpacing + 2), 75);
    doc.setFontSize(paragraphSize);
    addText(
      doc,
      'R.U.C.: 20102427891',
      x + 162.5,
      (myY += textSpacing + 1),
      75,
    );
  } else {
    doc.setFontSize(paragraphSize);
    addText(
      doc,
      'R.U.C.: 20102427891',
      x + 162.5,
      (myY += textSpacing + 2),
      75,
    );
    doc.setFontSize(subtitleSize);
    addText(doc, voucherType.name, x + 162.5, (myY += textSpacing + 1), 75);
  }
  doc.setFontSize(paragraphSize);
  addText(
    doc,
    `${documentSeries}-${padStart(documentCode, 7)}`,
    x + 162.5,
    (myY += textSpacing + 1),
    75,
  );
  setFont(doc);
};

const printCustomerDetails = (
  doc,
  {
    business,
    customer,
    voucherType: { voucherCode },
    paymentType,
    currencyCode,
    issueDate,
    dueDate,
    description,
    reasonType,
    previousManualVoucher,
  },
) => {
  const hasCredit =
    voucherCode === SUNAT_CREDIT_NOTE_CODE ||
    voucherCode === SUNAT_DEBIT_NOTE_CODE;

  myY += 15;
  const initialY = myY;
  doc.line(x, initialY, documentWidth + 5, myY); // top line

  const businessNameLabel = business ? 'Razón Social' : 'Nombre';
  const businessTaxIdLabel = business ? 'RUC' : 'DNI';
  const businessNameValue = business ? business.name : customer.fullName;
  const businessTaxIdValue = business
    ? business.businessTaxId
    : customer.idDocumentNumber;
  const address = business
    ? business.address && business.address.address
    : customer.address && customer.address.address;

  doc.setFontSize(paragraphSize);
  setFont(doc, 'bold');
  addText(doc, businessNameLabel, x + 2, (myY += textSpacing + 1));
  setFont(doc);
  addText(doc, `: ${businessNameValue}`, x + 35, myY);

  setFont(doc, 'bold');
  addText(doc, businessTaxIdLabel, x + 2, (myY += textSpacing));
  setFont(doc);
  addText(doc, `: ${businessTaxIdValue}`, x + 35, myY);

  setFont(doc, 'bold');
  addText(doc, 'Dirección', x + 2, (myY += textSpacing));
  setFont(doc);
  const newAddress = doc.splitTextToSize(
    address ? address.toUpperCase() : '-',
    90,
  );
  addText(doc, ':', x + 35, myY);
  addText(doc, newAddress, x + 37, myY, 93, 'left');

  // Next line show only when the document will be credit note or debit note
  if (hasCredit && previousManualVoucher) {
    setFont(doc, 'bold');
    addText(doc, 'Documento de Referencia', x + 130, myY);
    setFont(doc);
    addText(
      doc,
      `: ${previousManualVoucher.documentSeries}-${padStart(
        previousManualVoucher.documentCode,
        7,
      )}`,
      x + 168,
      myY,
    );
  }

  setFont(doc, 'bold');
  addText(
    doc,
    'Forma de Pago',
    x + 2,
    (myY += textSpacing * newAddress.length),
  );
  setFont(doc);
  addText(
    doc,
    `: ${MANUAL_VOUCHER_PAYMENT_TYPE[paymentType].label}`,
    x + 35,
    myY,
  );

  setFont(doc, 'bold');
  addText(doc, 'Moneda', x + 130, myY);
  setFont(doc);
  addText(doc, `: ${CURRENCY_CODE[currencyCode].label}`, x + 168, myY);

  setFont(doc, 'bold');
  addText(doc, 'Fecha de Emisión', x + 2, (myY += textSpacing));
  setFont(doc);
  addText(
    doc,
    `: ${tzNormalizeDate({ date: issueDate, format: DATE_FORMAT })}`,
    x + 35,
    myY,
  );

  setFont(doc, 'bold');
  addText(doc, 'Tipo de Pago', x + 130, myY);
  setFont(doc);
  addText(
    doc,
    `: ${MANUAL_VOUCHER_PAYMENT_TYPE[paymentType].label}`,
    x + 168,
    myY,
  );

  setFont(doc, 'bold');
  addText(doc, 'Fecha de Vencimiento', x + 2, (myY += textSpacing));
  setFont(doc);
  addText(
    doc,
    `: ${tzNormalizeDate({ date: dueDate, format: DATE_FORMAT })}`,
    x + 35,
    myY,
  );

  const newDescription = doc.splitTextToSize(
    (CREDIT_NOTE_MANUAL_VOUCHER_REASONS[reasonType] &&
      CREDIT_NOTE_MANUAL_VOUCHER_REASONS[reasonType].label) ||
      description,
    158,
  );
  if (hasCredit) {
    setFont(doc, 'bold');
    addText(doc, 'Motivo o Sustento', x + 2, (myY += textSpacing));
    setFont(doc);
    addText(doc, ':', x + 35, myY, 10, 'left');
    addText(doc, newDescription, x + 37, myY, 160, 'left');
  }

  doc.line(x, initialY, x, (myY += 3 * newDescription.length)); // left line
  doc.line(x + 200, initialY, x + 200, myY); // right line of amount column
  doc.line(x, myY, documentWidth + 5, myY); // bottom line of first row
};

const printServiceDetailsAndAmounts = (
  doc,
  {
    items,
    currencyCode,
    taxes,
    totalAmountWithoutTaxes,
    totalAmountPayableText,
    totalAmount,
  },
) => {
  doc.line(x, (myY += 5), documentWidth + 5, myY); // top line
  doc.line(x, myY, x, myY + cellHeight); // left line
  doc.line(x + 20, myY, x + 20, myY + cellHeight); // right line of amount column
  doc.line(x + 135, myY, x + 135, myY + cellHeight); // right line of description column
  doc.line(x + 170, myY, x + 170, myY + cellHeight); // right line of unit price column
  doc.line(x + 200, myY, x + 200, myY + cellHeight); // right line of total price column

  doc.setFontSize(paragraphSize);
  setFont(doc, 'bold');
  addText(doc, 'Cantidad', x + 10, (myY += textSpacing), 20);
  addText(doc, 'Descripción', x + 78, myY, 110);
  addText(doc, 'P. Unitario', x + 152.5, myY, 40);
  addText(doc, 'Total', x + 185, myY, 30);
  setFont(doc);

  doc.line(x, (myY += 2), documentWidth + 5, myY); // bottom line of first row

  items.forEach(({ description, quantity, unitaryPrice, totalPrice }) => {
    myY = isNewPageNeeded(doc, x, myY, cellHeight);
    myY = myY > 5 ? myY : 5;

    const rowInitialY = myY;

    doc.setFontSize(paragraphSize);
    addText(doc, quantity.toString(), x + 10, (myY += cellHeight), 20);

    const newDescription = doc.splitTextToSize(description, 108);
    addText(doc, newDescription, x + 22, myY, 110, 'left');

    addText(
      doc,
      numberFormatter({
        style: 'currency',
        value: unitaryPrice,
        currency: currencyCode,
      }),
      x + 168,
      myY,
      33,
      'right',
    );
    addText(
      doc,
      numberFormatter({
        style: 'currency',
        value: totalPrice,
        currency: currencyCode,
      }),
      x + 198,
      myY,
      29,
      'right',
    );

    myY += 2 * newDescription.length;

    doc.line(x, rowInitialY, x, myY); // left line
    doc.line(x + 20, rowInitialY, x + 20, myY); // right line of amount column
    doc.line(x + 135, rowInitialY, x + 135, myY); // right line of description column
    doc.line(x + 170, rowInitialY, x + 170, myY); // right line of unit price column
    doc.line(x + 200, rowInitialY, x + 200, myY); // right line of total price column
  });

  doc.line(x, myY, x, myY + 4); // left line
  doc.line(x + 20, myY, x + 20, myY + 4); // right line of amount column
  doc.line(x + 135, myY, x + 135, myY + 4); // right line of description column
  doc.line(x + 170, myY, x + 170, myY + 4); // right line of unit price column
  doc.line(x + 200, myY, x + 200, myY + 4); // right line of total price column
  myY += 4;
  doc.line(x, myY, documentWidth + 5, myY); // bottom line of first row

  myY = isNewPageNeeded(doc, x, myY, cellHeight);
  myY = myY > 5 ? myY : 5;
  // Total's table
  doc.rect(x + 135, myY, 35, cellHeight);
  doc.rect(x + 170, myY, 30, cellHeight);
  addText(doc, 'Total Gravada:', x + 136, (myY += 4), 34, 'left');
  addText(
    doc,
    numberFormatter({
      style: 'currency',
      value: totalAmountWithoutTaxes,
      currency: currencyCode,
    }),
    x + 198,
    myY,
    29,
    'right',
  );

  myY = isNewPageNeeded(doc, x, myY, cellHeight);
  myY = myY > 5 ? myY + 2 : 5;
  doc.rect(x + 135, myY, 35, cellHeight);
  doc.rect(x + 170, myY, 30, cellHeight);
  addText(doc, 'Total Inafecto:', x + 136, (myY += 4), 34, 'left');
  addText(
    doc,
    numberFormatter({ style: 'currency', value: 0, currency: currencyCode }),
    x + 198,
    myY,
    29,
    'right',
  );

  myY = isNewPageNeeded(doc, x, myY, cellHeight);
  myY = myY > 5 ? myY + 2.1 : 5;
  doc.rect(x + 135, myY, 35, cellHeight);
  doc.rect(x + 170, myY, 30, cellHeight);
  addText(doc, 'Total Exonerado:', x + 136, (myY += 4), 34, 'left');
  addText(
    doc,
    numberFormatter({ style: 'currency', value: 0, currency: currencyCode }),
    x + 198,
    myY,
    29,
    'right',
  );

  myY = isNewPageNeeded(doc, x, myY, cellHeight);
  myY = myY > 5 ? myY + 2.1 : 5;
  doc.rect(x + 135, myY, 35, cellHeight);
  doc.rect(x + 170, myY, 30, cellHeight);
  addText(doc, 'Total IGV 18%:', x + 136, (myY += 4), 34, 'left');
  addText(
    doc,
    numberFormatter({
      style: 'currency',
      value: taxes,
      currency: currencyCode,
    }),
    x + 198,
    myY,
    29,
    'right',
  );

  myY = isNewPageNeeded(doc, x, myY, cellHeight);
  myY = myY > 5 ? myY + 2.1 : 5;
  doc.rect(x + 135, myY, 35, cellHeight);
  doc.rect(x + 170, myY, 30, cellHeight);
  addText(doc, 'Importe Total:', x + 136, (myY += 4), 34, 'left');
  addText(
    doc,
    numberFormatter({
      style: 'currency',
      value: totalAmount,
      currency: currencyCode,
    }),
    x + 198,
    myY,
    29,
    'right',
  );

  setFont(doc, 'bold');
  addText(doc, 'SON :', x, myY, 128, 'left');
  setFont(doc);

  const newTotalAmountPayableText =
    currencyCode === CURRENCY_CODE.USD.value
      ? totalAmountPayableText.replace('SOLES', 'DOLARES')
      : totalAmountPayableText;
  addText(doc, newTotalAmountPayableText, x + 9, myY, 123, 'left');
};

const printCreditDetails = (
  doc,
  {
    paymentType,
    installments,
    totalAmountPayable,
    totalAmount,
    dueDate,
    currencyCode,
    voucherType: { voucherCode },
  },
) => {
  if (paymentType === MANUAL_VOUCHER_PAYMENT_TYPE.CREDIT.value) {
    myY = isNewPageNeeded(doc, x, myY, 16 + 19);
    myY = myY > 5 ? myY + 7 : 5;
    doc.rect(x, myY, documentWidth, 16);
    doc.setFontSize(subtitleSize);
    addText(doc, 'Información del crédito', x + 2, (myY += textSpacing + 1));
    doc.setFontSize(paragraphSize);
    addText(doc, 'Monto neto pendiente de pago', x + 2, (myY += textSpacing));
    setFont(doc, 'bold');
    addText(
      doc,
      `: ${numberFormatter({
        style: 'currency',
        value:
          voucherCode === SUNAT_INVOICE_CODE ? totalAmountPayable : totalAmount,
        currency: currencyCode,
      })}`,
      x + 45,
      myY,
    );
    setFont(doc);
    addText(doc, 'Total de Cuotas', x + 2, (myY += textSpacing));
    setFont(doc, 'bold');
    addText(doc, `: ${installments}`, x + 45, myY);
    setFont(doc);

    myY = isNewPageNeeded(doc, x, myY, 13);
    myY = myY > 5 ? myY + 3 : 5;
    doc.rect(x, myY, documentWidth, 13);
    setFont(doc, 'bold');
    addText(doc, 'N° Cuota', x + 20, (myY += textSpacing + 1), 30);
    addText(doc, 'Fecha Vencimiento Cuota', x + 60, myY, 50);
    addText(doc, 'Monto Cuota', x + 105, myY, 30);
    setFont(doc);
    addText(doc, '1', x + 20, (myY += textSpacing), 30);
    addText(
      doc,
      tzNormalizeDate({ date: dueDate, format: DATE_FORMAT }),
      x + 60,
      myY,
      50,
    );
    addText(
      doc,
      numberFormatter({
        style: 'currency',
        value:
          voucherCode === SUNAT_INVOICE_CODE ? totalAmountPayable : totalAmount,
        currency: currencyCode,
      }),
      x + 105,
      myY,
      30,
    );

    myY = isNewPageNeeded(doc, x, myY, 10);
    myY = myY > 5 ? myY + 10 : 5;
    setFont(doc, 'bold');
    addText(doc, '* NOTA:', x, myY, documentWidth, 'left');
    addText(
      doc,
      'Operación sujeta al Sistema de Pago de Obligaciones Tributarias con el Gobierno Central.\nBanco de la Nación. Cta Cte: 0000-496103',
      x + 15,
      myY,
    );
    setFont(doc);
  }
};

const printQrCode = async (doc) => {
  myY = isNewPageNeeded(doc, x, myY, 10 + qrHeight + textSpacing);
  myY = myY > 5 ? myY + 10 : 5;
  await addQrCode(doc, 'F019-0004605', x + 83, myY, qrWidth, qrHeight);
  doc.setFontSize(paragraphSize - 2);
  setFont(doc, 'bold');
  addText(
    doc,
    'Representación impresa de la Factura Electrónica. Esta puede ser consultada en: www.civa.com.pe',
    x + 100,
    (myY += qrHeight),
    documentWidth,
  );
  setFont(doc);
  addText(
    doc,
    'Autorizado mediante Resolución de Intendencia N° 0180050002242/SUNAT',
    x + 100,
    (myY += textSpacing),
    documentWidth,
  );
};

const printManualVoucher = async (manualVoucher) => {
  const doc = createPdfDocument();

  myY = myInitialY;

  printDocumentHeader(doc, manualVoucher);
  printCustomerDetails(doc, manualVoucher);
  printServiceDetailsAndAmounts(doc, manualVoucher);
  printCreditDetails(doc, manualVoucher);
  await printQrCode(doc, manualVoucher);

  window.open(doc.output('bloburl'), '_blank');
};

export default printManualVoucher;
