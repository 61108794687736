import React, { useLayoutEffect } from 'react';
import { Form, FormGroup } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import Loader from '../../../common/Loader';
import Alert from '../../../common/informative/Alert';
import { THERE_ARE_NOT_DATA_TO_SHOW } from '../../../../config/messages';
import {
  clearUnclaimedCargoItems,
  putStatusToDepured,
} from '../../../../actions/cargo/LegalAbandonment';
import SelectionableTable from '../../../common/forms/table/SelectionableTable';
import FormFooter from '../../../common/forms/FormFooter';
import { LEGAL_ABANDONMENT_CARGO_ITEMS_COLUMNS } from '../../../../config/columns';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';

export const LegalAbandonmentResult = ({
  dispatchClearUnclaimedCargoItems,
  dispatchPutStatusToDepured,
  dispatchChange,
  handleSubmit,
  loading,
  items,
}) => {
  useLayoutEffect(() => () => dispatchClearUnclaimedCargoItems(), []);

  const onSubmitLegalAbandonment = (formValues) => {
    if (!formValues.items || formValues.items.length === 0) {
      toastr.error(
        'Error',
        'Debe seleccionar al menos un ítem para el abandono legal.',
      );
      return;
    }

    const legalAbandonmentDepuratedRequest = {
      legalAbandonmentRequestList: formValues.items,
      depuratedDocumentNumber: formValues.documentNumber,
    };

    const response = dispatchPutStatusToDepured(
      legalAbandonmentDepuratedRequest,
    );

    if (response) {
      dispatchClearUnclaimedCargoItems();
      dispatchChange('LegalAbandonmentResultForm', 'documentNumber', '');
    }
  };

  const data = items.get('content');
  let table = null;

  if (loading) table = <Loader />;

  if (data.length === 0) {
    table = <Alert message={THERE_ARE_NOT_DATA_TO_SHOW} />;
  } else {
    table = (
      <>
        <Form onSubmit={handleSubmit(onSubmitLegalAbandonment)}>
          <FormGroup row>
            <FormItem label="Número de Acta" required>
              <Field
                name="documentNumber"
                component={TextInput}
                type="text"
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <SelectionableTable
            columns={LEGAL_ABANDONMENT_CARGO_ITEMS_COLUMNS}
            data={data}
            defaultPageSize={10}
            returnOnlySelectedItems
            keyField="id"
            loading={loading}
            form="LegalAbandonmentResultForm"
          />
          <FormFooter />
        </Form>
      </>
    );
  }

  return table;
};
const mapStateToProps = ({ CargoUnit }) => ({
  loading: CargoUnit.LegalAbandonment.getIn(['all', 'loading']),

  items: CargoUnit.LegalAbandonment.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchClearUnclaimedCargoItems: clearUnclaimedCargoItems,
  dispatchPutStatusToDepured: putStatusToDepured,
  dispatchChange: change,
};

LegalAbandonmentResult.propTypes = {
  items: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  dispatchClearUnclaimedCargoItems: PropTypes.func.isRequired,
  dispatchPutStatusToDepured: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
};

LegalAbandonmentResult.defaultProps = {
  loading: false,
};

const formComponent = reduxForm({
  form: 'LegalAbandonmentResultForm',
})(LegalAbandonmentResult);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
