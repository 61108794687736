import React from 'react';
import PropTypes from 'prop-types';
import { GENERAL_STATUS } from '../../../../config/constants';
import '../ActionsCell.css';
import ApproveManualVoucherCell from './ApproveManualVoucherCell';
import RejectManualVoucherCell from './RejectManualVoucherCell';

const ManualVoucherActionsCell = ({ id, status }) => {
  if (status !== GENERAL_STATUS.PENDING.value) return null;

  return (
    <div className="actionsContainer">
      <ApproveManualVoucherCell id={id} /> <RejectManualVoucherCell id={id} />
    </div>
  );
};

ManualVoucherActionsCell.propTypes = {
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

export default ManualVoucherActionsCell;
