import React from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm } from 'redux-form';
import { PropTypes } from 'prop-types';
import { FormGroup, Form } from 'reactstrap';
import FormItem from '../../../common/forms/FormItem';
import FormFooter from '../../../common/forms/FormFooter';
import Loader from '../../../common/Loader';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';
import CompanyResourceSelect from '../../../common/forms/select/CompanyResourceSelect';

const CompanyResourceGroupForm = ({ handleSubmit, loading }) => {
  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <hr />
      <FormGroup row>
        <FormItem label="Nombre de Grupo" required>
          <Field
            name="name"
            component={TextInput}
            type="text"
            placeholder="Nombre de Grupo"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Descripción">
          <Field
            name="description"
            component={TextInput}
            type="textarea"
            placeholder="Descripción"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row className="mt-4">
        <FormItem label="Recurso de Compañia">
          <Field
            name="companyResourceId"
            component={CompanyResourceSelect}
            isClearable
            isMulti
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

const mapStateToProps = (state) => ({
  loading: !state.MechanicalMaintenanceUnit.CompanyResourceGroup.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

CompanyResourceGroupForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'CompanyResourceGroupForm',
})(CompanyResourceGroupForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
