import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import printPersonalMovementDocument from '../../../../utils/printers/DriverMovementHistoryVoucher';

const DriverMovementHistoryPrintButton = ({
  id,
  driverFullName,
  startDate,
  endDate,
  driverMovementName,
  driverId,
  companyBusId,
  documentNumber,
}) => {
  const print = () => {
    const driverMovementHistory = {
      id,
      driverFullName,
      startDate,
      endDate,
      driverMovementName,
      driverId,
      companyBusId,
      documentNumber,
    };

    printPersonalMovementDocument(driverMovementHistory);
  };

  return (
    <Button color="secondary" onClick={print} className="ml-2">
      <i className="fa fa-print" /> Imprimir
    </Button>
  );
};

DriverMovementHistoryPrintButton.propTypes = {
  id: PropTypes.number.isRequired,
  driverFullName: PropTypes.string.isRequired,
  startDate: PropTypes.number.isRequired,
  endDate: PropTypes.number.isRequired,
  driverMovementName: PropTypes.string.isRequired,
  driverId: PropTypes.number.isRequired,
  companyBusId: PropTypes.string,
  documentNumber: PropTypes.string,
};

DriverMovementHistoryPrintButton.defaultProps = {
  companyBusId: '-',
  documentNumber: '-',
};

export default DriverMovementHistoryPrintButton;
