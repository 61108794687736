import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectFilter from './SelectFilter';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';
import {
  clearAgencies,
  getAgencies,
  getVisibleAgencies,
} from '../../../actions';

const AgencyFilter = ({
  agencies,
  onChangeFilter,
  loading,
  visibilityRestricted,
  dispatchGetVisibleAgencies,
  dispatchClearAgencies,
  dispatchGetAgencies,
}) => {
  useLayoutEffect(() => () => dispatchClearAgencies(), []);

  const handleInputChange = (inputValue) => {
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      if (visibilityRestricted) {
        dispatchGetVisibleAgencies({ query: inputValue });
      } else {
        dispatchGetAgencies({ query: inputValue });
      }
    }
  };
  const agencyFilterOption = (options) => options;

  return (
    <SelectFilter
      isMulti
      isLoading={loading}
      onChangeFilter={onChangeFilter}
      onInputChange={handleInputChange}
      options={agencies}
      filterOption={agencyFilterOption}
      placeholder="Ingrese ubicación"
    />
  );
};

AgencyFilter.propTypes = {
  dispatchGetAgencies: PropTypes.func.isRequired,
  dispatchGetVisibleAgencies: PropTypes.func.isRequired,
  dispatchClearAgencies: PropTypes.func.isRequired,
  agencies: optionsPropTypes,
  onChangeFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  visibilityRestricted: PropTypes.bool,
};

AgencyFilter.defaultProps = {
  agencies: [],
  loading: false,
  visibilityRestricted: false,
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  agencies: HumanResourcesUnit.Agency.getIn(['all', 'content', 'content']).map(
    (identificationType) => ({
      value: identificationType.id,
      label: identificationType.name,
    }),
  ),
  loading: HumanResourcesUnit.Agency.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetAgencies: getAgencies,
  dispatchGetVisibleAgencies: getVisibleAgencies,
  dispatchClearAgencies: clearAgencies,
};

export default connect(mapStateToProps, mapDispatchToprops)(AgencyFilter);
