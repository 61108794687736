import React, { Fragment } from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { driverStatusInformationPropTypes } from '../proptypes/DriverPropTypes';
import EnumStatus from '../../../../common/EnumStatus';
import { DRIVER_STATUS_TYPE } from '../../../../../config/constants';

const DriverStatusInformationResource = ({
  driverType,
  homeBase,
  driverStatusType,
}) => (
  <Fragment>
    <ResourceProperty label="Tipo:">{driverType.name}</ResourceProperty>
    <ResourceProperty label="Base:">{homeBase.name}</ResourceProperty>
    <ResourceProperty label="Estado:">
      <EnumStatus
        enumObject={DRIVER_STATUS_TYPE}
        enumValue={driverStatusType}
      />
    </ResourceProperty>
  </Fragment>
);

DriverStatusInformationResource.propTypes = driverStatusInformationPropTypes;

export default DriverStatusInformationResource;
