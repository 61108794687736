import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { EXPENSE_AUTHORIZER_USER_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_EXPENSE_AUTHORIZER_USER,
  CLEAR_EXPENSE_AUTHORIZER_USERS,
  FLAG_EXPENSE_AUTHORIZER_USER_ACTIVITY,
  FLAG_GETTING_EXPENSE_AUTHORIZER_USERS,
  GET_EXPENSE_AUTHORIZER_USER,
  GET_EXPENSE_AUTHORIZER_USERS,
} from '../types';
import { EXPENSE_AUTHORIZER_USER_PATH } from '../../config/paths';

const flagGettingExpenseAuthorizerUsers = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_EXPENSE_AUTHORIZER_USERS,
    payload: flag,
  });

const getExpenseAuthorizerUsers =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingExpenseAuthorizerUsers(true));
      const query = tableFilters;
      const url = `${EXPENSE_AUTHORIZER_USER_ENDPOINT}?${QueryString.stringify(
        query,
      )}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const expenseAuthorizerUsers = await response.json();
      dispatch({
        type: GET_EXPENSE_AUTHORIZER_USERS,
        payload: expenseAuthorizerUsers,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingExpenseAuthorizerUsers(false));
    }
  };

const clearExpenseAuthorizerUsers = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXPENSE_AUTHORIZER_USERS,
  });

const flagExpenseAuthorizerUserActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_EXPENSE_AUTHORIZER_USER_ACTIVITY,
    payload: flag,
  });

const getExpenseAuthorizerUser =
  async ({ expenseAuthorizerUserId }) =>
  async (dispatch) => {
    try {
      dispatch(flagExpenseAuthorizerUserActivity(true));
      const url = `${EXPENSE_AUTHORIZER_USER_ENDPOINT}/${expenseAuthorizerUserId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const expenseAuthorizerUser = await response.json();
      dispatch({
        type: GET_EXPENSE_AUTHORIZER_USER,
        payload: expenseAuthorizerUser,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagExpenseAuthorizerUserActivity(false));
    }
  };

const clearExpenseAuthorizerUser = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXPENSE_AUTHORIZER_USER,
  });

const postExpenseAuthorizerUser =
  async ({ userId, costCenterIdList }) =>
  async (dispatch) => {
    try {
      dispatch(flagExpenseAuthorizerUserActivity(true));
      const payload = {
        userId,
        costCenterIdList,
      };
      const url = EXPENSE_AUTHORIZER_USER_ENDPOINT;

      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      dispatch(push(`${EXPENSE_AUTHORIZER_USER_PATH}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagExpenseAuthorizerUserActivity(false));
    }
  };

const putExpenseAuthorizerUser =
  async (expenseAuthorizerUserId, { userId, costCenterId }) =>
  async (dispatch) => {
    try {
      dispatch(flagExpenseAuthorizerUserActivity(true));
      const payload = {
        id: expenseAuthorizerUserId,
        userId,
        costCenterId,
      };
      const url = `${EXPENSE_AUTHORIZER_USER_ENDPOINT}/${expenseAuthorizerUserId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const user = await response.json();
      dispatch(push(`${EXPENSE_AUTHORIZER_USER_PATH}/${user.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagExpenseAuthorizerUserActivity(false));
    }
  };

const deleteExpenseAuthorizerUser =
  async ({ expenseAuthorizerUserId }) =>
  async (dispatch) => {
    try {
      dispatch(flagExpenseAuthorizerUserActivity(true));
      const url = `${EXPENSE_AUTHORIZER_USER_ENDPOINT}/${expenseAuthorizerUserId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response;
      dispatch(push(EXPENSE_AUTHORIZER_USER_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagExpenseAuthorizerUserActivity(false));
    }
  };

export {
  flagGettingExpenseAuthorizerUsers,
  getExpenseAuthorizerUsers,
  clearExpenseAuthorizerUsers,
  getExpenseAuthorizerUser,
  clearExpenseAuthorizerUser,
  putExpenseAuthorizerUser,
  postExpenseAuthorizerUser,
  deleteExpenseAuthorizerUser,
};
