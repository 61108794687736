import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import FormItem from '../../../../common/forms/FormItem';
import FormFooter from '../../../../common/forms/FormFooter';
import Select from '../../../../common/forms/select/Select';

const AddCargoLegalAbandonmentModalForm = ({ handleSubmit, options }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Destino">
        <Field
          name="destinationLocationId"
          component={Select}
          isClearable
          options={options}
        />
      </FormItem>
    </FormGroup>
    <FormFooter />
  </Form>
);

AddCargoLegalAbandonmentModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
};

AddCargoLegalAbandonmentModalForm.defaultProps = {};

const formComponent = reduxForm({
  form: 'AddCargoLegalAbandonmentModalForm',
})(AddCargoLegalAbandonmentModalForm);

export default connect()(formComponent);
