import React, { useEffect, useLayoutEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { Button, ButtonToolbar, Form } from 'reactstrap';
import {
  DEFAULT_PAGE_SIZE,
  EXPENSE_STATUS,
} from '../../../../config/constants';
import { EXPENSE_STATUS_COLUMNS } from '../../../../config/columns';
import {
  clearExpensePerUser,
  getExpensesPerUser,
} from '../../../../actions/sales/ExpenseStatus';
import SelectionableTable from '../../../common/forms/table/SelectionableTable';
import Modal from '../../../common/modal/Modal';
import RejectedExpenseForm from './RejectedExpenseForm';

const selector = formValueSelector('ExpensesUserSearchResultForm');

const ExpenseStatusSearchResult = ({
  loading,
  expenses,
  searchFormValues,
  dispatchGetExpensesPerUser,
  dispatchClearExpensePerUser,
  handleSubmit,
  onSubmit,
  isDisabled,
  expenseIdValue,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState('');

  useLayoutEffect(() => () => dispatchClearExpensePerUser(), []);

  const data = expenses.get('content') || [];
  const columns = EXPENSE_STATUS_COLUMNS;
  const pages = expenses.get('totalPages') || null;
  const defaultPageSize = expenses.get('size') || DEFAULT_PAGE_SIZE;

  const submitWithAction = (actionType, rejectedMotive = null) => {
    handleSubmit((formValues) =>
      onSubmit({ ...formValues, actionType, rejectedMotive }),
    )();
  };
  const openModal = () => {
    if (!expenseIdValue || expenseIdValue.length === 0) {
      toastr.error('Error', 'Debe seleccionar egresos');
    } else {
      setShowModal(true);
    }
  };
  const closeModal = () => setShowModal(false);

  const toolbar = (
    <ButtonToolbar className="pull-right">
      <div>
        <Button
          type="button"
          color="success"
          onClick={() => submitWithAction(EXPENSE_STATUS.APPROVED.value)}
          disabled={isDisabled}
        >
          Aprobar
        </Button>{' '}
        <Button
          type="button"
          color="danger"
          onClick={() => openModal()}
          disabled={isDisabled}
        >
          Rechazar
        </Button>
      </div>
    </ButtonToolbar>
  );

  const onModalSubmit = async ({ rejectedMotive }) => {
    submitWithAction(EXPENSE_STATUS.REJECTED.value, rejectedMotive);

    closeModal();
  };

  const buildModalBody = () => {
    const newModalBody = (
      <RejectedExpenseForm
        onCancel={closeModal}
        handleProcess={onModalSubmit}
      />
    );
    setModalBody(newModalBody);
  };

  useEffect(() => {
    if (showModal !== false) {
      buildModalBody();
    }
  }, [showModal]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-3">
          {toolbar}
          <SelectionableTable
            columns={columns}
            data={data}
            name="expenseIds"
            pages={pages}
            keyField="id"
            defaultPageSize={defaultPageSize}
            returnOnlySelectedItems
            shouldResetSelection
            loading={loading}
            fetchData={dispatchGetExpensesPerUser}
            params={searchFormValues}
            form="ExpensesUserSearchResultForm"
          />
        </div>
      </Form>
      <Modal
        show={showModal}
        title="Rechazar Egresos"
        body={modalBody}
        size="lg"
        onClickCancel={() => closeModal()}
        onClickClose={() => closeModal()}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.SalesUnit.ExpenseStatus.getIn(['all', 'loading']),
  expenses: state.SalesUnit.ExpenseStatus.getIn(['all', 'content']),
  expenseIdValue: selector(state, 'expenseIds'),
});

const mapDispatchToProps = {
  dispatchGetExpensesPerUser: getExpensesPerUser,
  dispatchClearExpensePerUser: clearExpensePerUser,
};

ExpenseStatusSearchResult.propTypes = {
  expenses: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  dispatchClearExpensePerUser: PropTypes.func.isRequired,
  searchFormValues: PropTypes.shape({
    expenseStatus: PropTypes.string,
  }).isRequired,
  dispatchGetExpensesPerUser: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  expenseIdValue: PropTypes.instanceOf(Array),
};

ExpenseStatusSearchResult.defaultProps = {
  loading: false,
  expenseIdValue: [],
};

const formComponent = reduxForm({
  form: 'ExpensesUserSearchResultForm',
})(ExpenseStatusSearchResult);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
