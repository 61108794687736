import PropTypes from 'prop-types';
import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';
import { clearDrivers, getDrivers } from '../../../actions';
import SelectFilter from './SelectFilter';

const DriverFilter = ({
  dispatchGetDrivers,
  dispatchClearDrivers,
  drivers,
  isMulti,
  loading,
  isClearable,
  ...rest
}) => {
  useLayoutEffect(() => () => dispatchClearDrivers(), []);

  const onInputChange = (inputValue) => {
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      dispatchGetDrivers({ query: inputValue });
    }
  };

  return (
    <SelectFilter
      isLoading={loading}
      onInputChange={onInputChange}
      options={drivers}
      isMulti={isMulti}
      isClearable={isClearable}
      {...rest}
    />
  );
};

DriverFilter.propTypes = {
  dispatchGetDrivers: PropTypes.func.isRequired,
  dispatchClearDrivers: PropTypes.func.isRequired,
  drivers: optionsPropTypes,
  placeholder: PropTypes.string,
  onChangeFilter: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  loading: PropTypes.bool,
  isClearable: PropTypes.bool,
};

DriverFilter.defaultProps = {
  placeholder: 'Buscar conductores...',
  isMulti: false,
  loading: false,
  isClearable: true,
  drivers: [],
};

const mapStateToProps = ({ TrafficUnit }) => ({
  drivers: TrafficUnit.Driver.getIn(['all', 'content', 'content']).map(
    (driver) => ({
      value: driver.id,
      label: driver.customer.fullName,
    }),
  ),
  loading: TrafficUnit.Driver.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetDrivers: getDrivers,
  dispatchClearDrivers: clearDrivers,
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverFilter);
