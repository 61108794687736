import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import QRCode from 'qrcode';

const CustomerQrModal = ({ user }) => {
  const [qrCode, setQrCode] = useState(null);

  useEffect(() => {
    const generateQrCode = async () => {
      if (user) {
        try {
          const userData = {
            firstName: user.firstName,
            lastName: user.lastName,
            identificationTypeId: user.identificationTypeId,
            idDocumentNumber: user.idDocumentNumber,
          };
          const userString = JSON.stringify(userData, null, 2);
          const qrString = await QRCode.toDataURL(userString, {
            errorCorrectionLevel: 'H',
            type: 'image/jpeg',
            scale: 4,
          });
          setQrCode(qrString);
        } catch (error) {
          console.error('Error generating QR Code:', error);
        }
      }
    };

    generateQrCode();
  }, [user]);

  return (
    <div>
      {qrCode && (
        <div className="text-center mt-4">
          <img src={qrCode} alt="User QR Code" className="img-fluid" />
        </div>
      )}
    </div>
  );
};

CustomerQrModal.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    identificationTypeId: PropTypes.number,
    idDocumentNumber: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = ({ UserUnit }) => ({
  user: UserUnit.Customer.getIn(['current', 'content']).toJS(),
});

export default connect(mapStateToProps, null)(CustomerQrModal);
