import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import Content from '../../../layout/Content';
import { DRIVER_MOVEMENT_HISTORY_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import {
  clearDriversMovementHistory,
  getDriverMovementHistory,
  putDriverMovementHistory,
} from '../../../../actions';
import NoDataResource from '../../../common/resource/NoDataResource';
import DriverMovementHistoryForm from './DriverMovementHistoryForm';
import Loader from '../../../common/Loader';
import { tzNormalizeDate } from '../../../../utils/date';

const EditDriverMovementHistory = ({
  breadcrumbs,
  match: {
    params: { id },
  },
  loading,
  driverMovementHistory,
  dispatchGetDriverMovementHistory,
  dispatchClearDriverMovementHistory,
  dispatchPutDriverMovementHistory,
}) => {
  useLayoutEffect(() => {
    dispatchGetDriverMovementHistory({ driverMovementHistoryId: id });
    return () => dispatchClearDriverMovementHistory();
  }, []);

  const onSubmit = (formValues) => {
    const startDate = tzNormalizeDate({
      date: formValues.startDate,
    });

    const endDate = tzNormalizeDate({
      date: formValues.endDate,
    });

    if (moment(startDate).isAfter(moment(endDate))) {
      toastr.error(
        'Error',
        'La fecha de inicio no puede ser mayor a la fecha de fin',
      );
      return;
    }

    const newFormValues = {
      ...formValues,
      startDate,
      endDate,
    };

    dispatchPutDriverMovementHistory(id, newFormValues);
  };

  const generateInitialValues = ({
    note,
    secondaryNote,
    driverMovementName,
    driverFullName,
  }) => ({
    note,
    secondaryNote,
    driver: {
      value: null,
      label: driverFullName,
    },
    driverMovement: {
      value: null,
      label: driverMovementName,
    },
  });

  let content;

  if (loading) content = <Loader />;
  else if (driverMovementHistory.isEmpty())
    content = <NoDataResource returnPage={DRIVER_MOVEMENT_HISTORY_PATH} />;
  else
    content = (
      <DriverMovementHistoryForm
        onSubmit={onSubmit}
        isEdit
        initialValues={generateInitialValues(driverMovementHistory.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Movimiento del Conductor"
      content={content}
    />
  );
};

EditDriverMovementHistory.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes.isRequired,
  dispatchGetDriverMovementHistory: PropTypes.func.isRequired,
  dispatchClearDriverMovementHistory: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  driverMovementHistory: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchPutDriverMovementHistory: PropTypes.func.isRequired,
};

const mapStateToProps = (
  { TrafficUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Historial de movimiento del conductor',
      href: DRIVER_MOVEMENT_HISTORY_PATH,
    },
    {
      text: 'Ver',
      href: `${DRIVER_MOVEMENT_HISTORY_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  loading: !TrafficUnit.DriverMovementHistory.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  driverMovementHistory: TrafficUnit.DriverMovementHistory.getIn([
    'current',
    'content',
  ]),
});

const mapDispatchToProps = {
  dispatchGetDriverMovementHistory: getDriverMovementHistory,
  dispatchClearDriverMovementHistory: clearDriversMovementHistory,
  dispatchPutDriverMovementHistory: putDriverMovementHistory,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditDriverMovementHistory);
