import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup } from 'reactstrap';
import { change, Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import FormFooter from '../../../common/forms/FormFooter';
import CargoPickupQrCorrelativeForm from './CargoPickupQrCorrelativeForm';
import {
  BAGGAGE_ITEM_STATUS,
  PARCEL_PAYMENT_STATUS_WAITING_FOR_PAYMENT,
  PARCEL_STATUS_UNCLAIMED,
  PAYMENT_METHOD_PAGO_EN_DESTINO,
  PEN_SYMBOL,
} from '../../../../config/constants';
import PaymentMethodInputGroup from '../../../common/forms/PaymentMethodInputGroup';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import {
  isRequired,
  validateIntegerNumber,
  validateMaxLength,
} from '../../../../utils/validators';
import Select from '../../../common/forms/select/Select';

const validateMaxLength10 = validateMaxLength(10);

const CargoPickupQrModal = ({
  parcel,
  handleSubmit,
  onCancel,
  handleProcess,
  cargoItemList,
  dispatchChange,
  options,
  items,
  setItems,
}) => {
  const onShowVoucherCode = () => {
    dispatchChange('CargoPickupModelForm', 'voucherCode', '');
  };

  const paymenthMethodInput =
    parcel.transactionCode === PAYMENT_METHOD_PAGO_EN_DESTINO &&
    parcel.parcelPaymentStatusType ===
      PARCEL_PAYMENT_STATUS_WAITING_FOR_PAYMENT ? (
      <PaymentMethodInputGroup onShowVoucherCode={onShowVoucherCode} />
    ) : null;

  const getDisplayPrice = () => {
    if (parcel.salePriceWithDiscount !== null) {
      return parcel.salePriceWithDiscount.toFixed(2);
    }
    if (parcel.salePriceWithDetraction !== null) {
      return parcel.salePriceWithDetraction.toFixed(2);
    }
    return parcel.salePrice.toFixed(2);
  };

  return (
    <>
      <ResourceProperty label="Serie:">
        {parcel.documentSeries}
      </ResourceProperty>
      <ResourceProperty label="Número de serie:">
        {parcel.documentCode}
      </ResourceProperty>
      <ResourceProperty label="Origen:">
        {parcel.sourceCityName}
      </ResourceProperty>
      <ResourceProperty label="Destino:">
        {parcel.destinationCityName}
      </ResourceProperty>
      {parcel.status.includes(PARCEL_STATUS_UNCLAIMED) && (
        <ResourceProperty label="Monto a Pagar:">
          {PEN_SYMBOL} {getDisplayPrice()}
        </ResourceProperty>
      )}
      <hr />

      <CargoPickupQrCorrelativeForm
        items={items}
        cargoItemList={cargoItemList}
        setItems={setItems}
      />

      <hr />

      {paymenthMethodInput}
      <FormGroup row className="mt-4">
        <FormItem label="Clave" required>
          <Field
            name="secretCode"
            component={TextInput}
            type="text"
            placeholder="Clave secreta"
            validate={[isRequired, validateIntegerNumber, validateMaxLength10]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row className="mt-4">
        <FormItem label="Se entrega a" required>
          <Field
            name="deliveredCustomer"
            component={Select}
            type="text"
            placeholder="Seleccione al Consignado a Entregar"
            validate={[isRequired]}
            options={options}
          />
        </FormItem>
      </FormGroup>

      {parcel.status.includes(PARCEL_STATUS_UNCLAIMED) && (
        <PaymentMethodInputGroup
          onShowVoucherCode={onShowVoucherCode}
          transactionCodesNotIncluded={[PAYMENT_METHOD_PAGO_EN_DESTINO]}
        />
      )}

      <Form onSubmit={handleSubmit(handleProcess)}>
        <FormFooter
          saveButtonColor="secondary"
          saveButtonIcon="fa fa-share"
          saveButtonText="Entregar"
        >
          <Button color="primary" onClick={onCancel}>
            Cancelar
          </Button>
        </FormFooter>
      </Form>
    </>
  );
};

CargoPickupQrModal.propTypes = {
  parcel: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleProcess: PropTypes.func.isRequired,
  cargoItemList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      correlation: PropTypes.string,
      description: PropTypes.string,
      weight: PropTypes.number,
    }),
  ).isRequired,
  dispatchChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      correlation: PropTypes.string,
      description: PropTypes.string,
      weight: PropTypes.number,
    }),
  ).isRequired,
  setItems: PropTypes.func.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  cargoItemList: CargoUnit.Cargo.getIn(['current', 'content', 'cargoItemList'])
    .map((item) => ({
      id: item.id,
      correlation: item.correlation,
      description: item.description,
      weight: item.weight,
      status: item.status,
    }))
    .filter((item) => item.status !== BAGGAGE_ITEM_STATUS.DELIVERED.value),
});

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'CargoPickupQrModal',
})(CargoPickupQrModal);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
