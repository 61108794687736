import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { CIRCUIT_PATH, NEW_CIRCUIT_PATH } from '../../../../config/paths';
import { CIRCUIT_COLUMNS } from '../../../../config/columns';
import { clearCircuits, getCircuits } from '../../../../actions';

export const Circuits = ({
  breadcrumbs,
  circuits,
  loading,
  dispatchGetCircuits,
  dispatchClearCircuits,
}) => {
  useLayoutEffect(() => () => dispatchClearCircuits(), []);

  const data = circuits.get('content') || [];
  const pages = circuits.get('totalPages') || null;
  const defaultPageSize = circuits.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Lista de Circuitos"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_CIRCUIT_PATH}
      tableStructure={{
        columns: CIRCUIT_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetCircuits,
        modelPath: CIRCUIT_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetCircuits: getCircuits,
  dispatchClearCircuits: clearCircuits,
};

const mapStateToProps = ({ RouteUnit }) => ({
  breadcrumbs: [
    ...RouteUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Circuitos',
      href: CIRCUIT_PATH,
    },
  ],
  circuits: RouteUnit.Circuit.getIn(['all', 'content']),
  loading: RouteUnit.Circuit.getIn(['all', 'loading']),
});

Circuits.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  circuits: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetCircuits: PropTypes.func.isRequired,
  dispatchClearCircuits: PropTypes.func.isRequired,
};

Circuits.defaultProps = {
  circuits: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Circuits);
