import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { MANUAL_VOUCHER_PATH } from '../../../../config/paths';
import {
  GENERAL_STATUS,
  SUNAT_CREDIT_NOTE_CODE,
} from '../../../../config/constants';

const ManualVoucherEditButton = ({
  manualVoucherId,
  manualVoucherStatus,
  manualVoucherCode,
  disabled,
}) => {
  if (disabled) {
    return (
      <Button color="primary" disabled>
        <i className="fa fa-pencil-square-o" /> Editar
      </Button>
    );
  }

  if (manualVoucherCode !== SUNAT_CREDIT_NOTE_CODE) {
    return (
      <Link
        className="btn btn-primary"
        to={`${MANUAL_VOUCHER_PATH}/${manualVoucherId}/edit`}
      >
        <i className="fa fa-pencil-square-o" /> Editar
      </Link>
    );
  }

  if (manualVoucherStatus === GENERAL_STATUS.PENDING.value) {
    return (
      <Link
        className="btn btn-primary"
        to={`${MANUAL_VOUCHER_PATH}/${manualVoucherId}/credit-note/edit`}
      >
        <i className="fa fa-pencil-square-o" /> Editar
      </Link>
    );
  }

  return null;
};

ManualVoucherEditButton.propTypes = {
  manualVoucherId: PropTypes.number.isRequired,
  manualVoucherStatus: PropTypes.string.isRequired,
  manualVoucherCode: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ManualVoucherEditButton.defaultProps = {
  disabled: false,
};

export default ManualVoucherEditButton;
