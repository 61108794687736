import React, { useEffect, useLayoutEffect, useState } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import Loader from '../../../common/Loader';
import Select from '../../../common/forms/select/Select';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import DatePicker from '../../../common/forms/input/DatePicker';
import FormFooter from '../../../common/forms/FormFooter';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import VoucherTypeSelect from '../../../common/forms/select/VoucherTypeSelect';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import BusinessInputGroup from '../../../common/forms/BusinessInputGroup';
import LinkToModuleResource from '../../../common/resource/LinkToModuleResource';
import { optionPropTypes } from '../../../common/forms/select/SelectPropTypes';
import { currencyToNumber, numberFormatter } from '../../../../utils/number';
import { enumToSelectOptions } from '../../../../utils/enum';
import {
  isRequired,
  validateEndDate,
  validateIntegerNumber,
  validateNumber,
} from '../../../../utils/validators';
import { clearOriginalVoucher, getOriginalVoucher } from '../../../../actions';
import {
  CREDIT_NOTE_MANUAL_VOUCHER_REASONS,
  CURRENCY_CODE,
  MANUAL_VOUCHER_PAYMENT_TYPE,
  MANUAL_VOUCHER_SERVICE_TYPE,
} from '../../../../config/constants';
import {
  BAGGAGE_PATH,
  CARGO_PATH,
  MANUAL_VOUCHER_PATH,
  TICKET_PATH,
} from '../../../../config/paths';

const selector = formValueSelector('CreditNoteManualVoucherForm');

const validateDate = (value, allValues) =>
  validateEndDate(value, allValues.issueDate);

export const CreditNoteManualVoucherForm = ({
  onSubmit,
  serviceType,
  handleSubmit,
  initialValues,
  originalVoucher,
  affectedByTaxes,
  creditNoteTotal,
  originalVoucherSeries,
  loadingOriginalVoucher,
  originalVoucherCorrelative,
  dispatchChange,
  dispatchGetOriginalVoucher,
  dispatchClearOriginalVoucher,
}) => {
  const [isPerson, setIsPerson] = useState(initialValues.isPerson);
  const [originalVoucherLabel, setOriginalVoucherLabel] = useState(null);
  const [originalVoucherPath, setOriginalVoucherPath] = useState(null);
  const [calculateAmount, setCalculateAmount] = useState(false);
  const [currencyCodeValue, setCurrencyCodeValue] = useState(
    initialValues.currencyCode.value,
  );
  const [descriptionDisabled, setDescriptionDisabled] = useState(false);

  useLayoutEffect(
    () => () => {
      dispatchClearOriginalVoucher();
    },
    [],
  );

  useEffect(() => {
    switch (serviceType.value) {
      case MANUAL_VOUCHER_SERVICE_TYPE.TICKET_SERVICE.value:
        setOriginalVoucherLabel('del Boleto');
        setOriginalVoucherPath(TICKET_PATH);
        break;
      case MANUAL_VOUCHER_SERVICE_TYPE.PARCEL.value:
        setOriginalVoucherLabel('de la Carga');
        setOriginalVoucherPath(CARGO_PATH);
        break;
      case MANUAL_VOUCHER_SERVICE_TYPE.EXCESS_BAGGAGE.value:
        setOriginalVoucherLabel('del Exceso de Equipaje');
        setOriginalVoucherPath(BAGGAGE_PATH);
        break;
      default:
        setOriginalVoucherLabel('del Comprobante Manual');
        setOriginalVoucherPath(MANUAL_VOUCHER_PATH);
        break;
    }
  }, [serviceType]);

  useEffect(() => {
    if (calculateAmount) {
      const totalAmountWithoutTaxes = creditNoteTotal;
      dispatchChange(
        'CreditNoteManualVoucherForm',
        'totalAmountWithoutTaxes',
        numberFormatter({
          style: 'currency',
          value: totalAmountWithoutTaxes,
          currency: currencyCodeValue,
        }),
      );

      let taxes = 0;
      if (affectedByTaxes) {
        taxes = totalAmountWithoutTaxes * 0.18;
      }
      const newTotalAmount = +totalAmountWithoutTaxes + +taxes;

      dispatchChange(
        'CreditNoteManualVoucherForm',
        'taxes',
        numberFormatter({
          style: 'currency',
          value: taxes,
          currency: currencyCodeValue,
        }),
      );
      dispatchChange(
        'CreditNoteManualVoucherForm',
        'totalAmount',
        numberFormatter({
          style: 'currency',
          value: newTotalAmount,
          currency: currencyCodeValue,
        }),
      );
      setCalculateAmount(false);
    }
  }, [calculateAmount]);

  const onCalculateAmount = () => {
    setCalculateAmount(true);
  };

  useEffect(() => {
    if (!originalVoucher.isEmpty()) {
      const originalVoucherJson = originalVoucher.toJS();
      dispatchChange(
        'CreditNoteManualVoucherForm',
        'affectedByTaxes',
        originalVoucherJson.affectedByTaxes,
      );
      dispatchChange(
        'CreditNoteManualVoucherForm',
        'creditNoteTotal',
        originalVoucherJson.amount,
      );
      onCalculateAmount();
    }
  }, [originalVoucher]);

  const onChangeIsPerson = ({ target: { checked } }) => {
    dispatchChange('CreditNoteManualVoucherForm', 'businessId', null);
    dispatchChange('CreditNoteManualVoucherForm', 'customerId', null);
    setIsPerson(checked);
  };

  const onSearchOriginalVoucher = () => {
    const convertedValue = Number(originalVoucherCorrelative);
    if (
      Number.isNaN(convertedValue) ||
      convertedValue < 1 ||
      originalVoucherSeries === ''
    ) {
      toastr.error('Error', 'Debe ingresar una serie y correlativo válidos');
      return;
    }
    dispatchGetOriginalVoucher({
      originalVoucherSeries,
      originalVoucherCorrelative,
      serviceType: serviceType.value,
    });
  };

  const onChangeOriginalVoucher = () => {
    dispatchClearOriginalVoucher();
  };

  const onChangeCurrencyCode = (option) => {
    setCurrencyCodeValue(option.value);
    onCalculateAmount();
  };

  const onChangeIssueDate = (e, value) => {
    dispatchChange('CreditNoteManualVoucherForm', 'dueDate', value);
  };

  const onChangeReason = (option) => {
    if (
      option.code === CREDIT_NOTE_MANUAL_VOUCHER_REASONS.OTHER_CONCEPTS.code
    ) {
      dispatchChange('CreditNoteManualVoucherForm', 'description', null);
      setDescriptionDisabled(false);
      return;
    }
    dispatchChange('CreditNoteManualVoucherForm', 'description', option.label);
    setDescriptionDisabled(true);
  };

  const onHandleSubmit = (formValues) => {
    if (originalVoucher.isEmpty()) {
      toastr.error('Error', 'Debe seleccionar un comprobante anterior');
      return;
    }
    const totalAmount = currencyToNumber(formValues.totalAmount);
    if (originalVoucher.get('maxAmount') < totalAmount) {
      toastr.error(
        'Error',
        'El monto total de la nota de crédito actual o en conjunto de notas de crédito anteriores no puede ser mayor al monto del comprobante anterior',
      );
      return;
    }
    let previousTicketId;
    let previousParcelId;
    let previousExcessBaggageId;
    let previousManualVoucherId;
    switch (serviceType.value) {
      case MANUAL_VOUCHER_SERVICE_TYPE.TICKET_SERVICE.value:
        previousTicketId = originalVoucher.get('id');
        break;
      case MANUAL_VOUCHER_SERVICE_TYPE.PARCEL.value:
        previousParcelId = originalVoucher.get('id');
        break;
      case MANUAL_VOUCHER_SERVICE_TYPE.EXCESS_BAGGAGE.value:
        previousExcessBaggageId = originalVoucher.get('id');
        break;
      default:
        previousManualVoucherId = originalVoucher.get('id');
        break;
    }
    const items = [
      {
        quantity: 1,
        unitaryPrice: creditNoteTotal,
        totalPrice: creditNoteTotal,
        description: formValues.description,
      },
    ];

    const newFormValues = {
      ...formValues,
      items,
      previousTicketId,
      previousParcelId,
      previousExcessBaggageId,
      previousManualVoucherId,
      voucherTypeId: formValues.voucherTypeId.value,
      serviceType: formValues.serviceType.value,
      currencyCode: formValues.currencyCode.value,
      businessId: formValues.businessId ? formValues.businessId.value : null,
      customerId: formValues.customerId ? formValues.customerId.value : null,
      reasonType: formValues.reason.value,
      taxes: currencyToNumber(formValues.taxes),
      totalAmount,
      totalAmountWithoutTaxes: currencyToNumber(
        formValues.totalAmountWithoutTaxes,
      ),
      installments: formValues.installments || 1,
      paymentType: formValues.paymentType.value,
    };

    onSubmit(newFormValues);
  };

  let customerField = (
    <BusinessInputGroup
      label="Empresa"
      labelRequired
      name="businessId"
      form="CreditNoteManualVoucherForm"
      validate={[isRequired]}
      showDetails
    />
  );

  if (isPerson)
    customerField = (
      <>
        <CustomerInputGroup
          label="Cliente"
          labelRequired
          name="customerId"
          form="CreditNoteManualVoucherForm"
          validate={[isRequired]}
          showDetails
        />
      </>
    );

  const contractorDataFields = (
    <>
      <h3>Datos del Contratante</h3>
      <FormGroup row>
        <FormItem label="">
          <Label>
            <Field
              name="isPerson"
              component="input"
              type="checkbox"
              onChange={onChangeIsPerson}
            />{' '}
            Es persona Natural
          </Label>
        </FormItem>
      </FormGroup>
      {customerField}
    </>
  );

  const voucherDataFields = (
    <>
      <h3>Datos del Comprobante</h3>
      <FormGroup row>
        <FormItem label="Tipo de Comprobante" required>
          <Field
            name="voucherTypeId"
            component={VoucherTypeSelect}
            isClearable={false}
            isDisabled
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipo de Servicio" required>
          <Field
            name="serviceType"
            component={Select}
            onChange={onChangeOriginalVoucher}
            options={enumToSelectOptions(MANUAL_VOUCHER_SERVICE_TYPE)}
            isClearable={false}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha de Expedición" required>
          <Field
            name="issueDate"
            component={DatePicker}
            onChange={onChangeIssueDate}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Motivo o Sustento" required>
          <Field
            name="reason"
            component={Select}
            onChange={onChangeReason}
            options={enumToSelectOptions(CREDIT_NOTE_MANUAL_VOUCHER_REASONS)}
            isClearable={false}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Descripción" required>
          <Field
            name="description"
            component={TextInput}
            validate={[isRequired]}
            disabled={descriptionDisabled}
          />
        </FormItem>
      </FormGroup>
    </>
  );

  const originalVoucherFields = (
    <>
      <h3>Datos {originalVoucherLabel}</h3>
      <FormGroup row>
        <FormItem label="Serie del Comprobante" required>
          <Row>
            <Field
              name="originalVoucherSeries"
              component={TextInput}
              onChange={onChangeOriginalVoucher}
            />
            <span className="mx-2">-</span>
            <Field
              name="originalVoucherCorrelative"
              component={TextInput}
              onChange={onChangeOriginalVoucher}
            />
            <Button
              type="button"
              color="primary"
              onClick={onSearchOriginalVoucher}
              className="ml-2 h-25"
            >
              Buscar
            </Button>
          </Row>
        </FormItem>
      </FormGroup>
    </>
  );

  const amountFields = (
    <>
      <FormGroup row>
        <FormItem label="">
          <Label>
            <Field
              name="affectedByTaxes"
              component="input"
              type="checkbox"
              disabled
            />{' '}
            Afecta a Impuestos
          </Label>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Moneda" required>
          <Field
            name="currencyCode"
            component={Select}
            options={enumToSelectOptions(CURRENCY_CODE)}
            onChange={onChangeCurrencyCode}
            isClearable={false}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Método de Pago" required>
          <Row>
            <Col lg={3} md={4} sm={12}>
              <Field
                name="paymentType"
                component={Select}
                options={enumToSelectOptions(MANUAL_VOUCHER_PAYMENT_TYPE)}
                isDisabled
                validate={[isRequired]}
              />
            </Col>
            <Col lg={2} md={3} sm={6}>
              <Field
                name="installments"
                id="installments"
                component={TextInput}
                showToolTip
                disabled
                type="text"
                placeholder="Cuotas"
                validate={[validateIntegerNumber]}
              />
            </Col>
            <Col lg={4} md={5} sm={6}>
              <Field
                name="dueDate"
                id="dueDate"
                component={DatePicker}
                disabled
                placeholder="Fecha de Expiración"
                showToolTip
                validate={[isRequired, validateDate]}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row className="align-items-center">
        <FormItem label="Total de nota de crédito" required>
          <Field
            name="creditNoteTotal"
            component={TextInput}
            onChange={onCalculateAmount}
            placeholder="Total de nota de crédito"
            type="text"
            validate={[isRequired, validateNumber]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row className="align-items-center">
        <FormItem label="Total Gravada" required>
          <Field
            name="totalAmountWithoutTaxes"
            component={TextInput}
            placeholder="Total Gravada"
            type="text"
            disabled
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row className="align-items-center">
        <FormItem label="Total IGV 18%">
          <Field
            name="taxes"
            component={TextInput}
            placeholder="Total IGV 18%"
            type="text"
            disabled
          />
        </FormItem>
      </FormGroup>
      <FormGroup row className="align-items-center">
        <FormItem label="Importe Total" required>
          <Field
            name="totalAmount"
            component={TextInput}
            placeholder="Importe Total"
            type="text"
            size="lg"
            disabled
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
    </>
  );

  let originalVoucherComponent = null;

  if (loadingOriginalVoucher) {
    originalVoucherComponent = <Loader />;
  }

  if (!originalVoucher.isEmpty()) {
    const originalVoucherJson = originalVoucher.toJS();
    originalVoucherComponent = (
      <>
        <ResourceProperty label="Comprobante: ">
          <LinkToModuleResource
            text={`${originalVoucherJson.documentSeries.toUpperCase()} - ${originalVoucherJson.documentCode
              .toString()
              .padStart(7, '0')}`}
            href={`${originalVoucherPath}/${originalVoucherJson.id}`}
            isExternalPath
          />
        </ResourceProperty>
        <ResourceProperty label="Monto: ">
          {numberFormatter({ value: originalVoucherJson.amount })}
        </ResourceProperty>
        <ResourceProperty label="Método de pago: ">
          {originalVoucherJson.paymentMethodName}
        </ResourceProperty>
        <ResourceProperty label="Monto máximo: ">
          {originalVoucherJson.maxAmount < 0
            ? numberFormatter({ value: 0 })
            : numberFormatter({ value: originalVoucherJson.maxAmount })}
        </ResourceProperty>
        {amountFields}
      </>
    );
  }

  return (
    <Form onSubmit={handleSubmit(onHandleSubmit)}>
      {contractorDataFields}
      <hr />
      {voucherDataFields}
      <hr />
      {originalVoucherFields}
      {originalVoucherComponent}
      <FormFooter />
    </Form>
  );
};

CreditNoteManualVoucherForm.propTypes = {
  serviceType: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  originalVoucher: PropTypes.instanceOf(Immutable.Map).isRequired,
  affectedByTaxes: PropTypes.bool,
  creditNoteTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  originalVoucherSeries: PropTypes.string,
  loadingOriginalVoucher: PropTypes.bool,
  originalVoucherCorrelative: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  dispatchChange: PropTypes.func.isRequired,
  dispatchGetOriginalVoucher: PropTypes.func.isRequired,
  dispatchClearOriginalVoucher: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    currencyCode: optionPropTypes,
    affectedByTaxes: PropTypes.bool,
    isPerson: PropTypes.bool,
    dueDate: PropTypes.string,
    paymentType: optionPropTypes,
  }),
};

CreditNoteManualVoucherForm.defaultProps = {
  serviceType: {},
  initialValues: null,
  affectedByTaxes: false,
  creditNoteTotal: null,
  originalVoucherSeries: '',
  loadingOriginalVoucher: false,
  originalVoucherCorrelative: '',
};

const mapStateToProps = (state) => ({
  serviceType: selector(state, 'serviceType'),
  creditNoteTotal: selector(state, 'creditNoteTotal'),
  originalVoucher: state.AccountingUnit.ManualVoucher.getIn([
    'current',
    'original',
  ]),
  affectedByTaxes: selector(state, 'affectedByTaxes'),
  originalVoucherSeries: selector(state, 'originalVoucherSeries'),
  originalVoucherCorrelative: selector(state, 'originalVoucherCorrelative'),
  loadingOriginalVoucher: !state.AccountingUnit.ManualVoucher.getIn([
    'current',
    'originalActivity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchGetOriginalVoucher: getOriginalVoucher,
  dispatchClearOriginalVoucher: clearOriginalVoucher,
};

const formComponent = reduxForm({
  form: 'CreditNoteManualVoucherForm',
})(CreditNoteManualVoucherForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
