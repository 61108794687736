import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import { deleteExpenseAuthorizerUser } from '../../../../actions/accounting/ExpenseAuthorizerUser';

const ExpenseAuthorizerUserDeleteButton = ({
  dispatchDeleteExpenseAuthorizerUser,
  expenseAuthorizerUserId,
  disabled,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onClickDelete = () =>
    dispatchDeleteExpenseAuthorizerUser({ expenseAuthorizerUserId });

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return (
    <>
      <ConfirmationModal
        show={showModal}
        onClickConfirm={onClickDelete}
        onClickCancel={closeModal}
      />
      <Button color="danger" onClick={openModal} disabled={disabled}>
        <i className="fa fa-trash-o" /> Eliminar
      </Button>
    </>
  );
};

ExpenseAuthorizerUserDeleteButton.propTypes = {
  dispatchDeleteExpenseAuthorizerUser: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  expenseAuthorizerUserId: PropTypes.number.isRequired,
};

ExpenseAuthorizerUserDeleteButton.defaultProps = {
  disabled: false,
};

const mapDispatchToProps = {
  dispatchDeleteExpenseAuthorizerUser: deleteExpenseAuthorizerUser,
};

export default connect(
  null,
  mapDispatchToProps,
)(ExpenseAuthorizerUserDeleteButton);
