import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../common/Table';
import { DRIVER_MOVEMENT_HISTORY_PATH } from '../../../../config/paths';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';
import { DRIVER_MOVEMENT_HISTORY_COLUMNS } from '../../../../config/columns';

const DriverMovementsHistoryBasicResource = ({ driverMovementHistoryList }) => (
  <>
    <hr />
    <h2>Historial de Movimientos</h2>
    <Table
      columns={DRIVER_MOVEMENT_HISTORY_COLUMNS}
      data={driverMovementHistoryList}
      modelPath={DRIVER_MOVEMENT_HISTORY_PATH}
      manual={false}
      filterable
      defaultFilterMethod={filterMethodCaseInsensitive}
      navigateToModelOnRowClick
      openPathInNewTab
    />
  </>
);

DriverMovementsHistoryBasicResource.propTypes = {
  driverMovementHistoryList: PropTypes.arrayOf(
    PropTypes.shape({
      driverMovementId: PropTypes.number.isRequired,
      driverMovementName: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default DriverMovementsHistoryBasicResource;
