import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clearDriversMovement, getDriversMovement } from '../../../actions';
import SelectFilter from './SelectFilter';
import { DEFAULT_QUERY_GET_ALL } from '../../../config/queries';
import { optionsPropTypes } from '../forms/select/SelectPropTypes';

const DriverMovementFilter = ({
  dispatchGetDriversMovement,
  dispatchClearDriversMovement,
  driversMovement,
  loading,
  isMulti,
  isClearable,
  onChangeFilter,
  ...rest
}) => {
  useLayoutEffect(() => {
    dispatchGetDriversMovement({ ...DEFAULT_QUERY_GET_ALL });
    return () => dispatchClearDriversMovement();
  }, []);

  return (
    <SelectFilter
      isMulti={isMulti}
      isClearable={isClearable}
      isLoading={loading}
      onChangeFilter={onChangeFilter}
      options={driversMovement}
      {...rest}
    />
  );
};

DriverMovementFilter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  driversMovement: optionsPropTypes,
  dispatchGetDriversMovement: PropTypes.func.isRequired,
  dispatchClearDriversMovement: PropTypes.func.isRequired,
};

DriverMovementFilter.defaultProps = {
  placeholder: 'Buscar movimiento...',
  isMulti: false,
  loading: false,
  isClearable: true,
  driversMovement: [],
};

const mapStateToProps = ({ TrafficUnit }) => ({
  driversMovement: TrafficUnit.DriverMovement.getIn([
    'all',
    'content',
    'content',
  ]).map((driverMovement) => ({
    value: driverMovement.id,
    label: driverMovement.name,
  })),
  loading: TrafficUnit.Driver.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetDriversMovement: getDriversMovement,
  dispatchClearDriversMovement: clearDriversMovement,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DriverMovementFilter);
