import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { CIRCUIT_PATH } from '../../../../config/paths';
import { clearCircuit, getCircuit, putCircuit } from '../../../../actions';
import CircuitForm from './CircuitForm';

const EditCircuit = ({
  breadcrumbs,
  dispatchGetCircuit,
  dispatchClearCircuit,
  dispatchPutCircuit,
  match: {
    params: { id: circuitId },
  },
  loading,
  circuit,
}) => {
  useLayoutEffect(() => {
    dispatchGetCircuit({ circuitId });

    return () => dispatchClearCircuit();
  }, []);

  const onSubmit = (formValues) => {
    const circuitRouteList = formValues.circuitRouteList.map((route) => ({
      routeId: route.value,
    }));
    dispatchPutCircuit(circuitId, { ...formValues, circuitRouteList });
  };

  const generateInitialValues = ({ name, description, circuitRouteList }) => ({
    name,
    description,
    circuitRouteList: circuitRouteList.map((circuitRoute) => ({
      value: circuitRoute.routeId,
      label: circuitRoute.route.name,
    })),
  });

  let content;

  if (loading) content = <Loader />;
  else if (circuit.isEmpty())
    content = <NoDataResource returnPage={CIRCUIT_PATH} />;
  else
    content = (
      <CircuitForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(circuit.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Circuito"
      subtitle="Editar circuito"
      content={content}
    />
  );
};

EditCircuit.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearCircuit: PropTypes.func.isRequired,
  dispatchGetCircuit: PropTypes.func.isRequired,
  dispatchPutCircuit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  circuit: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditCircuit.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearCircuit: clearCircuit,
  dispatchGetCircuit: getCircuit,
  dispatchPutCircuit: putCircuit,
};

const mapStateToProps = (
  { RouteUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...RouteUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Circuitos',
      href: CIRCUIT_PATH,
    },
    {
      text: 'Ver',
      href: `${CIRCUIT_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  circuit: RouteUnit.Circuit.getIn(['current', 'content']),
  loading: !RouteUnit.Circuit.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCircuit);
