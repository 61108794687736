import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getOrganizationCharts,
  clearOrganizationCharts,
  getOrganizationChartsUser,
} from '../../../../actions/index';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { optionsPropTypes } from './SelectPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import {
  ORGANIZATION_CHART_AREA_TYPES,
  ROBOT_FLIT_COMPANY_AREA,
} from '../../../../config/constants';
import SelectFilter from '../../filters/SelectFilter';

const OrganizationChartSelect = ({
  dispatchGetOrganizationCharts,
  dispatchGetOrganizationChartsUser,
  dispatchClearOrganizationCharts,
  options,
  loading,
  noOptionsMessage,
  input,
  meta,
  isDisabled,
  isClearable,
  hierarchy,
  parentId,
  isMulti,
  useForFilter,
  onChangeFilter,
  labelAsValue,
  placeholder,
  useByUser,
}) => {
  useLayoutEffect(() => {
    const params = { ...DEFAULT_QUERY_GET_ALL };

    params.query = [];

    if (hierarchy)
      params.query.push(
        `companyAreaTypeId:${ORGANIZATION_CHART_AREA_TYPES[hierarchy].id}`,
      );
    if (parentId) params.query.push(`parentCompanyAreaId:${parentId}`);

    if (useByUser) {
      dispatchGetOrganizationChartsUser({ ...params });
    } else {
      dispatchGetOrganizationCharts({ ...params }, hierarchy);
    }

    return () => dispatchClearOrganizationCharts(hierarchy);
  }, []);

  const props = {
    options,
    isLoading: loading,
    noOptionsMessage,
    isMulti,
    isClearable,
    isDisabled,
    onChangeFilter,
    placeholder,
  };

  if (!useForFilter) {
    props.input = input;
    props.meta = meta;
    return <Select {...props} />;
  }

  if (labelAsValue) {
    const newOptions = options.map((option) => ({
      ...option,
      value: option.name,
    }));
    props.options = newOptions;
  }

  return <SelectFilter {...props} />;
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }, { hierarchy }) => ({
  options: MechanicalMaintenanceUnit.OrganizationChart.getIn([
    'all',
    hierarchy ? `${hierarchy}Content` : 'content',
    'content',
  ])
    .filter(({ id }) => id !== ROBOT_FLIT_COMPANY_AREA)
    .map(({ id, name, companyAreaType }) => ({
      value: id,
      label: `${name} (${companyAreaType.name})`,
      name,
    })),
  loading: MechanicalMaintenanceUnit.OrganizationChart.getIn([
    'all',
    'loading',
  ]),
});

OrganizationChartSelect.propTypes = {
  options: optionsPropTypes,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.func,
  dispatchGetOrganizationCharts: PropTypes.func.isRequired,
  dispatchGetOrganizationChartsUser: PropTypes.func.isRequired,
  dispatchClearOrganizationCharts: PropTypes.func.isRequired,
  input: inputPropTypes,
  meta: metaPropTypes,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  hierarchy: PropTypes.string,
  parentId: PropTypes.number,
  isMulti: PropTypes.bool,
  useForFilter: PropTypes.bool,
  onChangeFilter: PropTypes.func,
  labelAsValue: PropTypes.bool,
  placeholder: PropTypes.string,
  useByUser: PropTypes.bool,
};

OrganizationChartSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  noOptionsMessage: undefined,
  isDisabled: false,
  isClearable: false,
  options: [],
  hierarchy: null,
  parentId: null,
  isMulti: false,
  useForFilter: false,
  onChangeFilter: () => {},
  labelAsValue: false,
  placeholder: 'Seleccione...',
  useByUser: false,
};

const mapDispatchToProps = {
  dispatchGetOrganizationCharts: getOrganizationCharts,
  dispatchGetOrganizationChartsUser: getOrganizationChartsUser,
  dispatchClearOrganizationCharts: clearOrganizationCharts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationChartSelect);
