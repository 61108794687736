import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Label } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import CompanyResourceTypeSelect from '../../../common/forms/select/CompanyResourceTypeSelect';
import {
  clearCompanyResourceTypes,
  getCompanyResourceTypes,
} from '../../../../actions/mechanical-maintenance/CompanyResource';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import { MAX_LENGTH_TEXTAREA_FOR_COMPANY_RESOURCE } from '../../../../config/constants';

export const CompanyResourceForm = ({
  handleSubmit,
  loading,
  edit,
  dispatchGetCompanyResourceTypes,
  dispatchClearCompanyResourceTypes,
}) => {
  useEffect(() => {
    dispatchGetCompanyResourceTypes(DEFAULT_QUERY_GET_ALL);

    return () => {
      dispatchClearCompanyResourceTypes();
    };
  }, []);

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Nombre" required>
          <Field
            name="name"
            component={TextInput}
            type="text"
            placeholder="Nombre"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipo" required>
          <Field
            name="companyResourceTypeId"
            component={CompanyResourceTypeSelect}
            isClearable={false}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Descripción">
          <Field
            name="description"
            component={TextInput}
            type="textarea"
            placeholder="Descripción"
            maxLength={MAX_LENGTH_TEXTAREA_FOR_COMPANY_RESOURCE}
          />
        </FormItem>
      </FormGroup>
      {edit && (
        <FormGroup row>
          <FormItem label="Estado">
            <Label>
              <Field name="active" component="input" type="checkbox" /> Activo
            </Label>
          </FormItem>
        </FormGroup>
      )}

      <FormFooter />
    </Form>
  );
};

CompanyResourceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  edit: PropTypes.bool,
  dispatchClearCompanyResourceTypes: PropTypes.func.isRequired,
  dispatchGetCompanyResourceTypes: PropTypes.func.isRequired,
};

CompanyResourceForm.defaultProps = {
  loading: false,
  edit: false,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  loading: !MechanicalMaintenanceUnit.CompanyResource.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetCompanyResourceTypes: getCompanyResourceTypes,
  dispatchClearCompanyResourceTypes: clearCompanyResourceTypes,
};

const formComponent = reduxForm({
  form: 'CompanyResourceForm',
})(CompanyResourceForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
