import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DRIVER_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import DriverForm from './DriverForm';
import { postDriver } from '../../../../actions';
import Content from '../../../layout/Content';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';
import { tzNormalizeDate } from '../../../../utils/date';

export class NewDriver extends Component {
  onSubmit = (formValues) => {
    const newFormValues = {
      file: formValues.file,
      customerId: formValues.customerId.value,
      driverTypeId: formValues.driverTypeId.value,
      homeBaseId: formValues.homeBaseId.value,
      crewStatusId: formValues.crewStatusId.value,
      driverDocumentList: formValues.driverDocumentList
        ? formValues.driverDocumentList.map(
            ({
              driverDocumentTypeId,
              documentNumber,
              effectiveDate,
              expirationDate,
              note,
            }) => ({
              driverDocumentTypeId: driverDocumentTypeId.value,
              documentNumber,
              effectiveDate: tzNormalizeDate({
                date: effectiveDate,
                format: TIMESTAMP_FORMAT,
              }),
              expirationDate: tzNormalizeDate({
                date: expirationDate,
                format: TIMESTAMP_FORMAT,
              }),
              note,
            }),
          )
        : [],
      driverContractList: formValues.driverContractList
        ? formValues.driverContractList.map(
            ({
              driverContractTypeId,
              effectiveDate,
              expirationDate,
              reason,
              note,
            }) => ({
              driverContractTypeId: driverContractTypeId.value,
              effectiveDate: tzNormalizeDate({
                date: effectiveDate,
                format: TIMESTAMP_FORMAT,
              }),
              expirationDate: expirationDate
                ? tzNormalizeDate({
                    date: expirationDate,
                    format: TIMESTAMP_FORMAT,
                  })
                : null,
              reason,
              note,
            }),
          )
        : [],
    };

    this.props.dispatchPostDriver(newFormValues);
  };

  render() {
    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nuevo Conductor"
        subtitle="Crear un nuevo conductor"
        content={<DriverForm onSubmit={this.onSubmit} />}
      />
    );
  }
}

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Conductores',
      href: DRIVER_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostDriver: postDriver,
};

NewDriver.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostDriver: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDriver);
