import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import Immutable from 'immutable';
import Content from '../../../layout/Content';
import DriverTripHistoryForm from './DriverTripHistoryForm';
import Table from '../../../common/Table';
import { DRIVER_TRIP_HISTORY_COLUMNS } from '../../../../config/columns';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import {
  clearDriverTripsByDateRange,
  getDriverTripsByDateRange,
} from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { tzNormalizeDate } from '../../../../utils/date';
import { INT_DATE_FORMAT } from '../../../../config/locale';
import { DEFAULT_QUERY } from '../../../../config/queries';

const DriversTripHistory = ({
  dispatchGetDriverTripsByDateRange,
  dispatchClearDriverTripsByDateRange,
  breadcrumbs,
  trips,
  loading,
}) => {
  const [driverId, setDriverId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useLayoutEffect(() => () => dispatchClearDriverTripsByDateRange(), []);

  const validateDates = (validateStartDate, validateEndDate) => {
    if (validateStartDate && validateEndDate) {
      if (moment(validateStartDate).isAfter(validateEndDate)) {
        toastr.error(
          'Error',
          'La fecha de inicio no puede ser mayor a la fecha de fin',
        );
        return false;
      }
    }

    return true;
  };

  const onSubmit = (formValues) => {
    setDriverId(formValues.driver.value);

    if (!validateDates(formValues.startDate, formValues.endDate)) {
      return;
    }

    const formattedStartDate = formValues.startDate
      ? tzNormalizeDate({ date: formValues.startDate, format: INT_DATE_FORMAT })
      : null;

    const formattedEndDate = formValues.endDate
      ? tzNormalizeDate({ date: formValues.endDate, format: INT_DATE_FORMAT })
      : null;

    setStartDate(formattedStartDate);

    setEndDate(formattedEndDate);

    const searchFormValues = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      ...DEFAULT_QUERY,
    };

    dispatchGetDriverTripsByDateRange(
      formValues.driver.value,
      searchFormValues,
    );
  };

  const onFetchData = (searchFormValues) => {
    if (!driverId) return;

    validateDates(searchFormValues.startDate, searchFormValues.endDate);

    dispatchGetDriverTripsByDateRange(driverId, {
      ...searchFormValues,
      startDate,
      endDate,
    });
  };

  const data = trips.get('content') || [];
  const columns = DRIVER_TRIP_HISTORY_COLUMNS;
  const pages = trips.get('totalPages') || null;
  const defaultPageSize = trips.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <Content
      title="Historial de viajes de conductores"
      breadcrumbs={breadcrumbs}
      content={
        <>
          <DriverTripHistoryForm onSubmit={onSubmit} />
          <Table
            columns={columns}
            data={data}
            loading={loading}
            pages={pages}
            defaultPageSize={defaultPageSize}
            fetchData={onFetchData}
          />
        </>
      }
    />
  );
};

DriversTripHistory.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetDriverTripsByDateRange: PropTypes.func.isRequired,
  dispatchClearDriverTripsByDateRange: PropTypes.func.isRequired,
  trips: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
};

DriversTripHistory.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Viajes Realizados',
      href: '',
    },
  ],
  trips: TrafficUnit.Driver.getIn(['current', 'trips']),
  loading: !TrafficUnit.Driver.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetDriverTripsByDateRange: getDriverTripsByDateRange,
  dispatchClearDriverTripsByDateRange: clearDriverTripsByDateRange,
};

export default connect(mapStateToProps, mapDispatchToProps)(DriversTripHistory);
