import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { EXPENSE_AUTHORIZER_USER_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import ExpenseAuthorizerUserForm from './ExpenseAuthorizerUserForm';
import {
  clearExpenseAuthorizerUser,
  getExpenseAuthorizerUser,
  putExpenseAuthorizerUser,
} from '../../../../actions/accounting/ExpenseAuthorizerUser';

const EditExpenseAuthorizerUser = ({
  breadcrumbs,
  dispatchGetExpenseAuthorizerUser,
  dispatchClearExpenseAuthorizerUser,
  dispatchPutExpenseAuthorizerUser,
  match: {
    params: { id: expenseAuthorizerUserId },
  },
  loading,
  expenseAuthorizerUser,
}) => {
  useLayoutEffect(() => {
    dispatchGetExpenseAuthorizerUser({ expenseAuthorizerUserId });

    return () => dispatchClearExpenseAuthorizerUser();
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = {
      userId: formValues.userId ? formValues.userId.value : null,
      costCenterId: formValues.costCenterId
        ? formValues.costCenterId.value
        : null,
    };

    dispatchPutExpenseAuthorizerUser(expenseAuthorizerUserId, newFormValues);
  };

  const generateInitialValues = ({ user, costCenter }) => ({
    userId: {
      value: user.id,
      label: user.customer.fullName,
    },
    costCenterId: costCenter
      ? {
          value: costCenter.id,
          label: costCenter.name,
        }
      : null,
  });

  let content;

  if (loading) content = <Loader />;
  else if (expenseAuthorizerUser.isEmpty())
    content = <NoDataResource returnPage={EXPENSE_AUTHORIZER_USER_PATH} />;
  else
    content = (
      <ExpenseAuthorizerUserForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(expenseAuthorizerUser.toJS())}
        saveMode={false}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Usuario Autorizador de Egresos"
      subtitle="Editar usuario autorizador de egresos"
      content={content}
    />
  );
};

EditExpenseAuthorizerUser.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearExpenseAuthorizerUser: PropTypes.func.isRequired,
  dispatchGetExpenseAuthorizerUser: PropTypes.func.isRequired,
  dispatchPutExpenseAuthorizerUser: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  expenseAuthorizerUser: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditExpenseAuthorizerUser.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchGetExpenseAuthorizerUser: getExpenseAuthorizerUser,
  dispatchClearExpenseAuthorizerUser: clearExpenseAuthorizerUser,
  dispatchPutExpenseAuthorizerUser: putExpenseAuthorizerUser,
};

const mapStateToProps = (
  { AccountingUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Usuarios Autorizadores de Egresos',
      href: EXPENSE_AUTHORIZER_USER_PATH,
    },
    {
      text: 'Ver',
      href: `${EXPENSE_AUTHORIZER_USER_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  expenseAuthorizerUser: AccountingUnit.ExpenseAuthorizerUser.getIn([
    'current',
    'content',
  ]),
  loading: !AccountingUnit.ExpenseAuthorizerUser.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditExpenseAuthorizerUser);
