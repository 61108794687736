import { addText, createPdfDocument, setFont } from '../pdf';

let myY;

const printDocumentHeader = (doc, response) => {
  setFont(doc);
  doc.setFontSize(8);

  addText(doc, response.departureDate, 40, myY, 50, 'right');
  doc.setFontSize(5);
  addText(doc, response.sourceAdress, 150, myY, 100, 'center');
  addText(doc, response.destinationAdress, 150, (myY += 5.2), 100, 'center');

  doc.setFontSize(8);

  addText(
    doc,
    `${response.destinatario.documentNumber} - ${response.destinatario.fullName} `,
    65,
    myY + 2,
    100,
    'center',
  );

  addText(doc, '20102427891', 47, (myY += 12), 80, 'right');

  addText(doc, response.bus.licensePlate, 130, (myY += 8), 80, 'right');

  let driverMy = myY - 8;

  if (response.driverList && response.driverList.length > 0) {
    addText(
      doc,
      `${response.driverList[0].name} ${response.driverList[0].lastName}`,
      45,
      (driverMy += 20),
      170,
      'left',
    );

    addText(
      doc,
      `${response.driverList[0].driverAuthorization}`,
      150,
      driverMy,
      90,
      'left',
    );

    if (response.driverList[1]) {
      addText(
        doc,
        `${response.driverList[1].name} ${response.driverList[1].lastName}`,
        50,
        (driverMy += 5.2),
        50,
        'left',
      );
      addText(
        doc,
        `${response.driverList[1].driverAuthorization}`,
        50,
        (driverMy += 7),
        90,
        'left',
      );
    }

    if (response.driverList[2]) {
      addText(
        doc,
        `${response.driverList[2].name} ${response.driverList[2].lastName}`,
        125,
        (driverMy += -7),
        80,
        'left',
      );
      addText(
        doc,
        `${response.driverList[2].driverAuthorization}`,
        145,
        (driverMy += 7),
        90,
        'left',
      );
    }
  }
};

const printDocumentBody = (doc, itemList) => {
  setFont(doc);
  doc.setFontSize(8);

  let startY = 170;
  const lineSpacing = 6.5;

  itemList.forEach((item, index) => {
    const description = item.descripcionItem || 'N/A';
    const weight = item.totalWeight || 0.0;
    const quantity = item.quantity || 0.0;

    addText(doc, (index + 1).toString(), 20, startY, 80, 'center');
    addText(doc, quantity.toString(), 40, startY, 15, 'center');
    addText(doc, 'UN', 50, startY, 30, 'center');
    addText(doc, description, 110, startY, 150, 'center');
    addText(doc, weight.toString(), 180, startY, 50, 'center');

    startY += lineSpacing;
  });
};

const printContingencySenderRemissionGuides = (contingencyguide) => {
  const doc = createPdfDocument();

  const rowsPerPage = 15;
  const totalPages = Math.ceil(contingencyguide.itemList.length / rowsPerPage);

  for (let i = 0; i < totalPages; i += 1) {
    if (i > 0) doc.addPage();

    myY = 68;

    printDocumentHeader(doc, contingencyguide);

    const currentPageItems = contingencyguide.itemList.slice(
      i * rowsPerPage,
      (i + 1) * rowsPerPage,
    );

    printDocumentBody(doc, currentPageItems);
  }

  window.open(doc.output('bloburl'), '_blank');
};

export default printContingencySenderRemissionGuides;
