import React, { useEffect, useLayoutEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Content from '../../../layout/Content';
import {
  clearManualVoucher,
  getManualVoucher,
  getOriginalVoucherById,
  putManualVoucher,
} from '../../../../actions';
import { MANUAL_VOUCHER_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import CreditNoteManualVoucherForm from './CreditNoteManualVoucherForm';
import { tzNormalizeDate } from '../../../../utils/date';
import {
  CREDIT_NOTE_MANUAL_VOUCHER_REASONS,
  CURRENCY_CODE,
  MANUAL_VOUCHER_PAYMENT_TYPE,
  MANUAL_VOUCHER_SERVICE_TYPE,
} from '../../../../config/constants';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { numberFormatter } from '../../../../utils/number';

export const EditCreditNoteManualVoucher = ({
  breadcrumbs,
  dispatchPutManualVoucher,
  dispatchGetManualVoucher,
  dispatchClearManualVoucher,
  dispatchGetOriginalVoucherById,
  loading,
  match: {
    params: { id: manualVoucherId },
  },
  voucher,
}) => {
  useLayoutEffect(() => {
    dispatchGetManualVoucher({ manualVoucherId });

    return () => {
      dispatchClearManualVoucher();
    };
  }, []);

  useEffect(async () => {
    if (!voucher.isEmpty() && !loading) {
      let id;
      const serviceType = voucher.get('serviceType');

      switch (serviceType) {
        case MANUAL_VOUCHER_SERVICE_TYPE.TICKET_SERVICE.value:
          id = voucher.get('previousTicketId');
          break;
        case MANUAL_VOUCHER_SERVICE_TYPE.PARCEL.value:
          id = voucher.get('previousParcelId');
          break;
        case MANUAL_VOUCHER_SERVICE_TYPE.EXCESS_BAGGAGE.value:
          id = voucher.get('previousExcessBaggageId');
          break;
        default:
          id = voucher.get('previousManualVoucherId');
      }

      await dispatchGetOriginalVoucherById({ id, serviceType });
    }
  }, [voucher]);

  const onSubmit = (formValues) => {
    dispatchPutManualVoucher(manualVoucherId, {
      ...formValues,
      userId: voucher.get('userId'),
      createDate: voucher.get('createDate'),
      status: voucher.get('status'),
    });
  };

  const generateInitialValues = ({
    voucherType,
    customer,
    business,
    issueDate,
    paymentType,
    affectedByTaxes,
    currencyCode,
    items,
    description,
    reasonType,
    serviceType,
    installments,
    dueDate,
    totalAmount,
    taxes,
    totalAmountWithoutTaxes,
  }) => {
    const currencyCodeObject = CURRENCY_CODE[currencyCode];

    return {
      businessId: business && {
        value: business.id,
        label: `${business.businessTaxId} - ${business.name}`,
        businessTaxId: business.businessTaxId,
        name: business.name,
        email: business.email,
        primaryContact: business.primaryContact,
        phone: business.phone,
        fax: business.fax,
        addressSummary: business.addressSummary,
        addressId: business.addressId,
        address: business.address,
      },
      customerId: customer && {
        value: customer.id,
        label: `${customer.idDocumentNumber} (${customer.idCountryOfOrigin}) - ${customer.fullName}`,
        id: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        fullName: customer.fullName,
        idDocumentNumber: customer.idDocumentNumber,
        gender: customer.gender,
        idCountryOfOrigin: customer.idCountryOfOrigin,
        identificationType: customer.identificationType,
        email: customer.email,
        mobilePhone: customer.mobilePhone,
        dob: customer.dob,
        discountCode: customer.discountCode,
      },
      isPerson: !!customer,
      issueDate: tzNormalizeDate({ date: issueDate }),
      totalAmount: numberFormatter({
        style: 'currency',
        value: totalAmount,
        currency: currencyCodeObject.value,
      }),
      taxes: numberFormatter({
        style: 'currency',
        value: taxes,
        currency: currencyCodeObject.value,
      }),
      totalAmountWithoutTaxes: numberFormatter({
        style: 'currency',
        value: totalAmountWithoutTaxes,
        currency: currencyCodeObject.value,
      }),
      installments,
      dueDate: tzNormalizeDate({ date: dueDate }),
      description,
      reason: CREDIT_NOTE_MANUAL_VOUCHER_REASONS[reasonType],
      affectedByTaxes,
      currencyCode: currencyCodeObject,
      serviceType: MANUAL_VOUCHER_SERVICE_TYPE[serviceType],
      paymentType: MANUAL_VOUCHER_PAYMENT_TYPE[paymentType],
      items: items.map(
        ({
          quantity,
          description: itemDescription,
          unitaryPrice,
          totalPrice,
        }) => ({
          quantity,
          description: itemDescription,
          unitaryPrice,
          totalPrice: numberFormatter({
            style: 'currency',
            value: totalPrice,
            currency: currencyCodeObject.value,
          }),
        }),
      ),
      voucherTypeId: {
        value: voucherType.id,
        label: voucherType.name,
        voucherCode: voucherType.voucherCode,
      },
    };
  };

  let content = null;

  if (loading) {
    content = <Loader />;
  } else if (voucher.isEmpty()) {
    content = <NoDataResource returnPage={MANUAL_VOUCHER_PATH} />;
  } else {
    content = (
      <CreditNoteManualVoucherForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(voucher.toJS())}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Nota de Crédito"
      subtitle="Crear un nuevo comprobante manual: Nota de crédito"
      content={content}
    />
  );
};

const mapStateToProps = (
  { AccountingUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Comprobantes Manuales',
      href: MANUAL_VOUCHER_PATH,
    },
    {
      text: 'Ver',
      href: `${MANUAL_VOUCHER_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  voucher: AccountingUnit.ManualVoucher.getIn(['current', 'content']),
  loading: !AccountingUnit.ManualVoucher.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  originalVoucher: AccountingUnit.ManualVoucher.getIn(['current', 'original']),
});

const mapDispatchToProps = {
  dispatchPutManualVoucher: putManualVoucher,
  dispatchGetManualVoucher: getManualVoucher,
  dispatchGetOriginalVoucherById: getOriginalVoucherById,
  dispatchClearManualVoucher: clearManualVoucher,
};

EditCreditNoteManualVoucher.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutManualVoucher: PropTypes.func.isRequired,
  dispatchGetManualVoucher: PropTypes.func.isRequired,
  dispatchClearManualVoucher: PropTypes.func.isRequired,
  dispatchGetOriginalVoucherById: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  voucher: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditCreditNoteManualVoucher.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditCreditNoteManualVoucher);
