import React from 'react';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import LegalAbandonmentForm from './LegalAbandonmentForm';

const LegalAbandonment = ({ breadcrumbs }) => (
  <Content
    breadcrumbs={breadcrumbs}
    title="Abandono Legal"
    subtitle="Items listo para Depurar"
    content={<LegalAbandonmentForm />}
  />
);

LegalAbandonment.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Abandono Legal',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, null)(LegalAbandonment);
