import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import circuitBasicInformationPropTypes from '../proptypes/CircuitPropTypes';
import { tzNormalizeDate } from '../../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';
import Table from '../../../../common/Table';
import { DEFAULT_PAGE_SIZE } from '../../../../../config/constants';
import { CIRCUIT_ROUTE_COLUMNS } from '../../../../../config/columns';
import { OLD_FRONT_ROUTE_PATH } from '../../../../../config/paths';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';

const CircuitBasicInformationResource = ({
  id,
  name,
  description,
  createDate,
  lastUpdate,
  circuitRouteList,
}) => (
  <>
    <ResourceProperty label="Nombre: ">{name || '-'}</ResourceProperty>
    <ResourceProperty label="Descripción: ">
      {description || '-'}
    </ResourceProperty>
    <h3 className="mt-3">Rutas</h3>
    <Table
      columns={CIRCUIT_ROUTE_COLUMNS}
      data={circuitRouteList}
      defaultPageSize={
        circuitRouteList.length <= 10
          ? circuitRouteList.length
          : DEFAULT_PAGE_SIZE
      }
      showPagination={circuitRouteList.length > 10}
      manual={false}
      modelPath={OLD_FRONT_ROUTE_PATH}
      filterable
      openPathInNewTab
      navigateToModelOnRowClick
      modelId="routeId"
      defaultFilterMethod={filterMethodCaseInsensitive}
    />
    <h3 className="mt-3">Información interna</h3>
    <ResourceProperty label="ID: ">{id || '-'}</ResourceProperty>
    <ResourceProperty label="Fecha de creación: ">
      {tzNormalizeDate({
        date: createDate,
        format: DATE_TIME_FORMAT,
      }) || '-'}
    </ResourceProperty>
    <ResourceProperty label="Fecha de última actualización: ">
      {tzNormalizeDate({
        date: lastUpdate,
        format: DATE_TIME_FORMAT,
      }) || '-'}
    </ResourceProperty>
  </>
);

CircuitBasicInformationResource.propTypes = circuitBasicInformationPropTypes;

export default CircuitBasicInformationResource;
