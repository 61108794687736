import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MechanicalMaintenanceHome from './MechanicalMaintenanceHome';
import {
  ACTIVITY_PATH,
  BUS_ACTIVITY_ASSIGNMENT_PATH,
  PREVENTIVE_EXECUTION_REGISTER_PATH,
  MECHANICAL_MAINTENANCE_UNIT_PATH,
  MECHANIC_PATH,
  NEW_ACTIVITY_PATH,
  NEW_BUS_ACTIVITY_ASSIGNMENT_PATH,
  NEW_MECHANIC_PATH,
  NEW_ORGANIZATION_CHART_PATH,
  ORGANIZATION_CHART_PATH,
  PREVENTIVE_MAINTENANCE_REPORT_PATH,
  BUS_ACTIVITY_HISTORY_PATH,
  MATERIAL_PATH,
  NEW_MATERIAL_PATH,
  ACTIVITY_PROJECTION_PATH,
  ADVANCED_EXECUTION_REGISTER_PATH,
  ODOMETER_BY_BUS_PATH,
  ACTIVITY_TASK_PATH,
  NEW_ACTIVITY_TASK_PATH,
  MAINTENANCE_WARNING_PATH,
  NEW_MAINTENANCE_WARNING_PATH,
  MAINTENANCE_SERVICE_ORDER_PATH,
  NEW_MAINTENANCE_SERVICE_ORDER_PATH,
  MY_MAINTENANCE_SERVICE_ORDER_PATH,
  MAINTENANCE_TASK_PATH,
  NEW_MAINTENANCE_TASK_PATH,
  MAINTENANCE_PLANNING_PATH,
  NEW_MAINTENANCE_PLANNING_PATH,
  MAINTENANCE_BUS_GROUP_PATH,
  NEW_MAINTENANCE_BUS_GROUP_PATH,
  COMPANY_RESOURCE_PATH,
  NEW_COMPANY_RESOURCE_PATH,
  NEW_COMPANY_RESOURCE_GROUP_PATH,
  COMPANY_RESOURCE_GROUP_PATH,
} from '../../../config/paths';
import OrganizationCharts from './organization-chart/OrganizationCharts';
import NewOrganizationChart from './organization-chart/NewOrganizationChart';
import OrganizationChart from './organization-chart/OrganizationChart';
import EditOrganizationChart from './organization-chart/EditOrganizationChart';
import Mechanics from './mechanic/Mechanics';
import NewMechanic from './mechanic/NewMechanic';
import Mechanic from './mechanic/Mechanic';
import EditMechanic from './mechanic/EditMechanic';
import Activities from './activity/Activities';
import NewActivity from './activity/NewActivity';
import Activity from './activity/Activity';
import EditActivity from './activity/EditActivity';
import BusActivityAssignments from './bus-activity-assigment/BusActivityAssignments';
import NewBusActivityAssignment from './bus-activity-assigment/NewBusActivityAssignment';
import BusActivityUnassignment from './bus-activity-assigment/BusActivityUnassignment';
import PreventiveMaintenanceReport from './preventive-maintenance-report/PreventiveMaintenanceReport';
import AdvancedExecutionRegistration from './register-of-execution/AdvancedExecutionRegister';
import BusActivityHistory from './bus-activity-history/BusActivityHistory';
import Materials from './material/Materials';
import NewMaterial from './material/NewMaterial';
import Material from './material/Material';
import EditMaterial from './material/EditMaterial';
import ActivityProjection from './activity-projection/ActivityProjection';
import PreventiveExecutionRegister from './register-of-execution/PreventiveExecutionRegister';
import OdometerByBus from './odometer-by-bus/OdometerByBus';
import NewActivityTask from './activity-task/NewActivityTask';
import ActivityTasks from './activity-task/ActivityTasks';
import MaintenanceWarnings from './maintenance-warning/MaintenanceWarnings';
import NewMaintenanceWarning from './maintenance-warning/NewMaintenanceWarning';
import MaintenanceWarning from './maintenance-warning/MaintenanceWarning';
import EditMaintenanceWarning from './maintenance-warning/EditMaintenanceWarning';
import NewMaintenanceServiceOrder from './service-order/NewMaintenanceServiceOrder';
import MaintenanceServiceOrders from './service-order/MaintenanceServiceOrders';
import MaintenanceServiceOrder from './service-order/MaintenanceServiceOrder';
import MyMaintenanceServiceOrders from './service-order/MyMaintenanceServiceOrders';
import EditMaintenanceServiceOrder from './service-order/EditMaintenanceServiceOrder';
import UpdateMaintenanceServiceOrder from './service-order/steps/UpdateMaintenanceServiceOrder';
import AssignActivitiesToMaintenanceServiceOrder from './service-order/steps/AssignActivitiesToMaintenanceServiceOrder';
import AssignEmployeesToMaintenanceServiceOrder from './service-order/steps/AssignEmployeesToMaintenanceServiceOrder';
import MyMaintenanceServiceOrdersForm from './service-order/MyMaintenanceServiceOrdersForm';
import AssignExecutionToMaintenceServiceOrder from './service-order/AssignExecutionToMaintenceServiceOrder';
import EditMaintenanceServiceOrderActivityBusExecution from './service-order/EditMaintenanceServiceOrderActivityBusExecution';
import EditMaintenanceServiceOrderActivityTaskExecution from './service-order/EditMaintenanceServiceOrderActivityTaskExecution';
import MaintenanceTasks from './maintenance-task/MaintenanceTasks';
import MaintenanceTask from './maintenance-task/MaintenanceTask';
import NewMaintenanceTask from './maintenance-task/NewMaintenanceTask';
import EditMaintenanceTask from './maintenance-task/EditMaintenanceTask';
import MaintenancePlannings from './maintenance-planning/MaintenancePlannings';
import NewMaintenancePlanning from './maintenance-planning/NewMaintenancePlanning';
import MaintenancePlanning from './maintenance-planning/MaintenancePlanning';
import EditMaintenanceServiceOrderMaintenancePlanningActivityExecution from './service-order/execution/EditMaintenanceServiceOrderMaintenancePlanningActivityExecution';
import MaintenanceBusGroups from './maintenance-bus-group/MaintenanceBusGroups';
import NewMaintenanceBusGroup from './maintenance-bus-group/NewMaintenanceBusGroup';
import MaintenanceBusGroup from './maintenance-bus-group/MaintenanceBusGroup';
import EditMaintenanceBusGroup from './maintenance-bus-group/EditMaintenanceBusGroup';
import CloneActivity from './activity/CloneActivity';
import EditMaintenancePlanning from './maintenance-planning/EditMaintenancePlanning';
import CompanyResources from './company-resource/CompanyResources';
import NewCompanyResource from './company-resource/NewCompanyResource';
import CompanyResource from './company-resource/CompanyResource';
import EditCompanyResource from './company-resource/EditCompanyResource';
import CompanyResourceGroup from './company-resource-group/CompanyResourceGroup';
import CompanyResourceGroups from './company-resource-group/CompanyResourceGroups';
import NewCompanyResourceGroup from './company-resource-group/NewCompanyResourceGroup';
import EditCompanyResourceGroup from './company-resource-group/EditCompanyResourceGroup';

const MechanicalMaintenanceUnit = () => (
  <Switch>
    <Route
      exact
      path={MECHANICAL_MAINTENANCE_UNIT_PATH}
      component={MechanicalMaintenanceHome}
    />
    <Route
      exact
      path={ORGANIZATION_CHART_PATH}
      component={OrganizationCharts}
    />
    <Route
      exact
      path={NEW_ORGANIZATION_CHART_PATH}
      component={NewOrganizationChart}
    />
    <Route
      exact
      path={`${ORGANIZATION_CHART_PATH}/:id`}
      component={OrganizationChart}
    />
    <Route
      exact
      path={`${ORGANIZATION_CHART_PATH}/:id/edit`}
      component={EditOrganizationChart}
    />
    <Route exact path={MECHANIC_PATH} component={Mechanics} />
    <Route exact path={NEW_MECHANIC_PATH} component={NewMechanic} />
    <Route exact path={`${MECHANIC_PATH}/:id`} component={Mechanic} />
    <Route exact path={`${MECHANIC_PATH}/:id/edit`} component={EditMechanic} />
    <Route exact path={ACTIVITY_PATH} component={Activities} />
    <Route exact path={NEW_ACTIVITY_PATH} component={NewActivity} />
    <Route exact path={`${ACTIVITY_PATH}/:id`} component={Activity} />
    <Route exact path={`${ACTIVITY_PATH}/:id/edit`} component={EditActivity} />
    <Route
      exact
      path={`${ACTIVITY_PATH}/:id/clone`}
      component={CloneActivity}
    />

    <Route
      exact
      path={BUS_ACTIVITY_ASSIGNMENT_PATH}
      component={BusActivityAssignments}
    />
    <Route
      exact
      path={NEW_BUS_ACTIVITY_ASSIGNMENT_PATH}
      component={NewBusActivityAssignment}
    />
    <Route
      exact
      path={`${BUS_ACTIVITY_ASSIGNMENT_PATH}/:id/unassign`}
      component={BusActivityUnassignment}
    />
    <Route
      exact
      path={PREVENTIVE_MAINTENANCE_REPORT_PATH}
      component={PreventiveMaintenanceReport}
    />
    <Route
      exact
      path={PREVENTIVE_EXECUTION_REGISTER_PATH}
      component={PreventiveExecutionRegister}
    />
    <Route
      exact
      path={ADVANCED_EXECUTION_REGISTER_PATH}
      component={AdvancedExecutionRegistration}
    />
    <Route
      exact
      path={BUS_ACTIVITY_HISTORY_PATH}
      component={BusActivityHistory}
    />
    <Route exact path={MATERIAL_PATH} component={Materials} />
    <Route exact path={NEW_MATERIAL_PATH} component={NewMaterial} />
    <Route exact path={`${MATERIAL_PATH}/:id`} component={Material} />
    <Route exact path={`${MATERIAL_PATH}/:id/edit`} component={EditMaterial} />
    <Route
      exact
      path={ACTIVITY_PROJECTION_PATH}
      component={ActivityProjection}
    />
    <Route exact path={ODOMETER_BY_BUS_PATH} component={OdometerByBus} />
    <Route exact path={ACTIVITY_TASK_PATH} component={ActivityTasks} />
    <Route exact path={NEW_ACTIVITY_TASK_PATH} component={NewActivityTask} />
    <Route
      exact
      path={MAINTENANCE_WARNING_PATH}
      component={MaintenanceWarnings}
    />
    <Route
      exact
      path={NEW_MAINTENANCE_WARNING_PATH}
      component={NewMaintenanceWarning}
    />
    <Route
      exact
      path={`${MAINTENANCE_WARNING_PATH}/:id`}
      component={MaintenanceWarning}
    />
    <Route
      exact
      path={`${MAINTENANCE_WARNING_PATH}/:id/edit`}
      component={EditMaintenanceWarning}
    />
    <Route
      exact
      path={MAINTENANCE_SERVICE_ORDER_PATH}
      component={MaintenanceServiceOrders}
    />
    <Route
      exact
      path={NEW_MAINTENANCE_SERVICE_ORDER_PATH}
      component={NewMaintenanceServiceOrder}
    />
    <Route
      exact
      path={`${MAINTENANCE_SERVICE_ORDER_PATH}/:id`}
      component={MaintenanceServiceOrder}
    />
    <Route
      exact
      path={`${MAINTENANCE_SERVICE_ORDER_PATH}/:id/edit`}
      component={EditMaintenanceServiceOrder}
    />
    <Route
      exact
      path={`${MAINTENANCE_SERVICE_ORDER_PATH}/:id/update-information`}
      component={UpdateMaintenanceServiceOrder}
    />
    <Route
      exact
      path={`${MAINTENANCE_SERVICE_ORDER_PATH}/:id/assign-activities`}
      component={AssignActivitiesToMaintenanceServiceOrder}
    />
    <Route
      exact
      path={`${MAINTENANCE_SERVICE_ORDER_PATH}/:id/assign-employees`}
      component={AssignEmployeesToMaintenanceServiceOrder}
    />
    <Route
      exact
      path={`${MAINTENANCE_SERVICE_ORDER_PATH}/:id/execution`}
      component={AssignExecutionToMaintenceServiceOrder}
    />
    <Route
      exact
      path={`${MAINTENANCE_SERVICE_ORDER_PATH}/:id/execution/:abeId/edit-activity-bus`}
      component={EditMaintenanceServiceOrderActivityBusExecution}
    />
    <Route
      exact
      path={`${MAINTENANCE_SERVICE_ORDER_PATH}/:id/execution/:ateId/edit-activity-task`}
      component={EditMaintenanceServiceOrderActivityTaskExecution}
    />
    <Route
      exact
      path={`${MAINTENANCE_SERVICE_ORDER_PATH}/:id/execution/:abeId/edit-planning-activity-execution`}
      component={
        EditMaintenanceServiceOrderMaintenancePlanningActivityExecution
      }
    />
    <Route
      exact
      path={MY_MAINTENANCE_SERVICE_ORDER_PATH}
      component={MyMaintenanceServiceOrders}
    />
    <Route
      exact
      path={`${MY_MAINTENANCE_SERVICE_ORDER_PATH}/:id`}
      component={MyMaintenanceServiceOrdersForm}
    />
    <Route exact path={MAINTENANCE_TASK_PATH} component={MaintenanceTasks} />
    <Route
      exact
      path={NEW_MAINTENANCE_TASK_PATH}
      component={NewMaintenanceTask}
    />
    <Route
      exact
      path={`${MAINTENANCE_TASK_PATH}/:id`}
      component={MaintenanceTask}
    />
    <Route
      exact
      path={`${MAINTENANCE_TASK_PATH}/:id/edit`}
      component={EditMaintenanceTask}
    />
    <Route
      exact
      path={MAINTENANCE_PLANNING_PATH}
      component={MaintenancePlannings}
    />
    <Route
      exact
      path={NEW_MAINTENANCE_PLANNING_PATH}
      component={NewMaintenancePlanning}
    />
    <Route
      exact
      path={`${MAINTENANCE_PLANNING_PATH}/:id`}
      component={MaintenancePlanning}
    />
    <Route
      exact
      path={`${MAINTENANCE_PLANNING_PATH}/:id/edit`}
      component={EditMaintenancePlanning}
    />
    <Route
      exact
      path={MAINTENANCE_BUS_GROUP_PATH}
      component={MaintenanceBusGroups}
    />

    <Route
      exact
      path={NEW_MAINTENANCE_BUS_GROUP_PATH}
      component={NewMaintenanceBusGroup}
    />
    <Route
      exact
      path={`${MAINTENANCE_BUS_GROUP_PATH}/:id`}
      component={MaintenanceBusGroup}
    />
    <Route
      exact
      path={`${MAINTENANCE_BUS_GROUP_PATH}/:id/edit`}
      component={EditMaintenanceBusGroup}
    />
    <Route exact path={COMPANY_RESOURCE_PATH} component={CompanyResources} />

    <Route
      exact
      path={NEW_COMPANY_RESOURCE_PATH}
      component={NewCompanyResource}
    />

    <Route
      exact
      path={`${COMPANY_RESOURCE_PATH}/:id`}
      component={CompanyResource}
    />
    <Route
      exact
      path={`${COMPANY_RESOURCE_PATH}/:id/edit`}
      component={EditCompanyResource}
    />

    <Route
      exact
      path={COMPANY_RESOURCE_GROUP_PATH}
      component={CompanyResourceGroups}
    />

    <Route
      exact
      path={NEW_COMPANY_RESOURCE_GROUP_PATH}
      component={NewCompanyResourceGroup}
    />

    <Route
      exact
      path={`${COMPANY_RESOURCE_GROUP_PATH}/:id`}
      component={CompanyResourceGroup}
    />
    <Route
      exact
      path={`${COMPANY_RESOURCE_GROUP_PATH}/:id/edit`}
      component={EditCompanyResourceGroup}
    />
  </Switch>
);

export default MechanicalMaintenanceUnit;
