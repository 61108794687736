import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import Modal from '../../../common/modal/Modal';
import {
  getMaintenancePlanning,
  putAddMaintenancePlanningResources,
} from '../../../../actions';
import AddResourceMaintenancePlanningForm from './AddResourceMaintenancePlanningForm';

export const AddResourceMaintenancePlanningButton = ({
  maintenancePlanning,
  dispatchPutAddMaintenancePlanningResources,
  dispatchGetMaintenancePlanning,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(null);

  const isCompanyResource = () => {
    if (
      !maintenancePlanning ||
      !maintenancePlanning.maintenancePlanningResourceList
    ) {
      return null;
    }

    return maintenancePlanning.maintenancePlanningResourceList.every(
      (resource) => resource.companyResourceId !== null,
    );
  };

  const onModalSubmit = async (formValues) => {
    const newFormValues = {
      maintenanceBusGroupIdList: [],
      registeredBusIdList: [],
      companyResourceIdList: [],
    };

    if (
      formValues.maintenanceBusGroupId &&
      formValues.maintenanceBusGroupId.length > 0
    ) {
      newFormValues.maintenanceBusGroupIdList =
        formValues.maintenanceBusGroupId.map((option) => option.value);
    }

    if (formValues.registeredBusId && formValues.registeredBusId.length > 0) {
      newFormValues.registeredBusIdList = formValues.registeredBusId.map(
        (option) => option.value,
      );
    }
    if (
      formValues.companyResourceId &&
      formValues.companyResourceId.length > 0
    ) {
      newFormValues.companyResourceIdList = formValues.companyResourceId.map(
        (option) => option.value,
      );
    }
    if (
      formValues.companyResourceGroupId &&
      formValues.companyResourceGroupId.length > 0
    ) {
      newFormValues.companyResourceGroupIdList =
        formValues.companyResourceGroupId.map((option) => option.value);
    }

    await dispatchPutAddMaintenancePlanningResources(
      maintenancePlanning.id,
      newFormValues,
    );

    dispatchGetMaintenancePlanning({
      maintenancePlanningId: maintenancePlanning.id,
    });

    setShowModal(false);
  };

  const onCancel = () => {
    setShowModal(false);
  };

  const onShowModal = () => {
    const isCompany = isCompanyResource();
    const newModalBody = (
      <AddResourceMaintenancePlanningForm
        onCancel={onCancel}
        handleProcess={onModalSubmit}
        isCompanyResource={isCompany}
      />
    );
    setModalBody(newModalBody);
    setShowModal(true);
  };

  return (
    <>
      <Button color="info" onClick={onShowModal}>
        <i className="fa fa-plus-circle" /> Agregar Recursos al Plan
      </Button>
      <Modal
        show={showModal}
        title="Agregar Recursos al Plan de Mantenimiento"
        body={modalBody}
        size="lg"
      />
    </>
  );
};

AddResourceMaintenancePlanningButton.propTypes = {
  maintenancePlanning: PropTypes.instanceOf(Object).isRequired,
  dispatchGetMaintenancePlanning: PropTypes.func.isRequired,
  dispatchPutAddMaintenancePlanningResources: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchGetMaintenancePlanning: getMaintenancePlanning,
  dispatchPutAddMaintenancePlanningResources:
    putAddMaintenancePlanningResources,
};

export default connect(
  null,
  mapDispatchToProps,
)(AddResourceMaintenancePlanningButton);
