import LogoCiva from '../../resources/images/logo-civa.png';
import { tzNormalizeDate } from '../date';
import { DATE_FORMAT, TIME_FORMAT } from '../../config/locale';
import { createPdfDocument, setFont } from '../pdf';

const marginLeft = 10;
const startY = 15;
const pageWidth = 297;
const pageHeight = 210;
const columnWidth = pageWidth / 2 - 20;
const rectWidth = columnWidth - 10;

const drawMovementTicket = (doc, movement, offsetX) => {
  let currentY = startY;

  doc.addImage(LogoCiva, 'PNG', marginLeft + offsetX, currentY, 30, 10);
  currentY += 20;

  setFont(doc, 'bold');
  doc.setFontSize(10);
  doc.text(
    'PAPELETA DE MOVIMIENTO DE PERSONAL',
    offsetX + columnWidth / 2,
    currentY,
    { align: 'center' },
  );
  currentY += 8;

  doc.setFontSize(8);

  const rectX = marginLeft + offsetX;
  const rectY = currentY;
  let rectHeight = 30;
  let fieldY = rectY + 7;

  setFont(doc, 'bold');
  doc.text('Conductor:', rectX + 2, fieldY);
  setFont(doc, 'normal');
  doc.text(movement.driverFullName, rectX + 30, fieldY);
  fieldY += 7;

  setFont(doc, 'bold');
  doc.text('Bus:', rectX + 2, fieldY);
  setFont(doc, 'normal');
  doc.text(`${movement.companyBusId || '-'}`, rectX + 30, fieldY);
  fieldY += 7;

  setFont(doc, 'bold');
  doc.text('DNI:', rectX + 2, fieldY);
  setFont(doc, 'normal');
  doc.text(`${movement.documentNumber || '-'}`, rectX + 30, fieldY);
  fieldY += 7;

  setFont(doc, 'bold');
  doc.text('Desde:', rectX + 2, fieldY);
  setFont(doc, 'normal');
  const desdeText = `${tzNormalizeDate({
    date: movement.startDate,
    format: DATE_FORMAT,
  })} ${tzNormalizeDate({ date: movement.startDate, format: TIME_FORMAT })}`;
  doc.text(desdeText, rectX + 30, fieldY);
  fieldY += 7;

  setFont(doc, 'bold');
  doc.text('Hasta:', rectX + 2, fieldY);
  setFont(doc, 'normal');
  const hastaText = `${tzNormalizeDate({
    date: movement.endDate,
    format: DATE_FORMAT,
  })} ${tzNormalizeDate({ date: movement.endDate, format: TIME_FORMAT })}`;
  const hastaLines = doc.splitTextToSize(hastaText, rectWidth - 30);
  doc.text(hastaLines, rectX + 30, fieldY);
  fieldY += hastaLines.length * 5;

  rectHeight = fieldY - rectY + 5;
  doc.rect(rectX, rectY, rectWidth, rectHeight);

  fieldY += 15;

  setFont(doc, 'bold');

  const year = new Date().getFullYear();
  doc.text(
    `LIMA _______ DE _____________ DEL ${year}`,
    offsetX + columnWidth / 4 + 30,
    fieldY,
  );
  fieldY += 15;

  const column1X = marginLeft + offsetX + 14;
  const column2X = offsetX + columnWidth / 2 + 12;
  const signatureY = fieldY + 15;

  doc.text('______________________', column1X, signatureY);
  doc.text('______________________', column2X, signatureY);
  doc.text('JEFE INMEDIATO', column1X + 8, signatureY + 5);
  doc.text('COLABORADOR', column2X + 10, signatureY + 5);
};

const printPersonalMovementDocument = (movement) => {
  const doc = createPdfDocument('landscape', 'mm', [pageWidth, pageHeight]);
  drawMovementTicket(doc, movement, 0);
  drawMovementTicket(doc, movement, columnWidth + 20);
  window.open(doc.output('bloburl'), '_blank');
};

export default printPersonalMovementDocument;
