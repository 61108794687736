import React, { useState } from 'react';
import { Button } from 'reactstrap';
import Modal from '../../../common/modal/Modal';
import CustomerQrModal from './CustomerQrModal';

const CustomerQrButton = () => {
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const body = <CustomerQrModal />;

  return (
    <>
      <Button color="success" onClick={openModal}>
        <i className="fa fa-qrcode" /> Generar QR
      </Button>
      <Modal
        title="Cliente QR"
        size="md"
        show={showModal}
        body={body}
        onClickCancel={closeModal}
        onClickClose={closeModal}
      />
    </>
  );
};

export default CustomerQrButton;
