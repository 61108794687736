import Immutable from 'immutable';
import {
  POSTPAID_BOOKING_FLAG_SEARCHING_FOR_ITINERARIES,
  POSTPAID_BOOKING_CHANGE_STEP,
  POSTPAID_BOOKING_SEARCH_FOR_ITINERARIES,
  POSTPAID_BOOKING_SELECT_ITINERARY,
  POSTPAID_BOOKING_SELECT_SEAT,
  POSTPAID_BOOKING_FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS,
  POSTPAID_BOOKING_DESELECT_SEAT,
  POSTPAID_BOOKING_FLAG_POSTING_RESERVATION,
  POSTPAID_BOOKING_POST_RESERVATION_REQUESTS,
  POSTPAID_BOOKING_GET_ITINERARY_FOOD_GROUP,
  POSTPAID_BOOKING_FLAG_POSTING_PRETRANSACTION,
  POSTPAID_BOOKING_POST_PRETRANSACTION,
  POSTPAID_BOOKING_RESET_STEP,
  POSTPAID_BOOKING_FLAG_GETTING_ALL,
  POSTPAID_BOOKING_GET_ALL,
  POSTPAID_BOOKING_CLEAR_ALL,
  POSTPAID_BOOKING_FLAG_APPROVING,
  POSTPAID_BOOKING_FLAG_REJECTING,
  POSTPAID_BOOKINGS_REPLACE_ONE,
  FLAG_ADDING_VOUCHER_TO_POSTPAID_BOOKING,
  FLAG_GETTING_PRETRANSACTION_FOR_POSTPAID_BOOKING,
  POSTPAID_BOOKING_GET_PRETRANSACTION,
  FLAG_POSTPAID_BOOKING_RESERVATION_CREATED,
  SET_FIXED_PRICE_FOR_CURRENT_POSTPAID_BOOKING,
  POSTPAID_BOOKING_CLEAR_ITINERARY,
  POSTPAID_BOOKING_CLEAR_SEATS,
  POSTPAID_BOOKING_CLEAR_SELECT_ITINERARY,
} from '../../../actions/types/booking/PostpaidBooking';
import { COLLECTION_SHAPE } from '../../../config/shapes';
import { POSTPAID_BOOKING_SEARCH_STEP_INDEX } from '../../../config/constants';
import {
  DELETE_KIDS_ON_POSTPAID_ITINERARY,
  POST_KIDS_ON_POSTPAID_ITINERARY,
} from '../../../actions/types';
import { tzNormalizeDate } from '../../../utils/date';

const INITIAL_STATE = new Immutable.Map({
  currentStep: POSTPAID_BOOKING_SEARCH_STEP_INDEX,
  search: new Immutable.Map({
    query: new Immutable.Map({
      source: { value: 4, label: 'Lima' },
      destination: null,
      // number of passengers is just a number not an object
      numPassengers: null,
      time: tzNormalizeDate(),
    }),
  }),
  itineraries: new Immutable.Map({
    results: new Immutable.Map(),
    searchingForItineraries: false,
    selectedItinerarySearchResults: new Immutable.List(),
  }),
  seats: new Immutable.Map({
    seatSelectionChangesInProgress: new Immutable.Set(),
    seatReservations: new Immutable.Set(),
  }),
  passengers: new Immutable.Map({
    itineraryFoodGroup: null,
    postingReservation: false,
    kidsOnPostpaidItinerary: new Immutable.Set(),
  }),
  receiver: new Immutable.Map({
    customerOrder: new Immutable.Map(),
    postingPretransaction: false,
    pretransactions: new Immutable.List(),
  }),
  summary: new Immutable.Map({
    pretransactions: new Immutable.List(),
    loading: false,
  }),
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    activity: false,
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case POSTPAID_BOOKING_RESET_STEP:
      return state.set(
        payload.statePropertyName,
        INITIAL_STATE.get(payload.statePropertyName),
      );
    case POSTPAID_BOOKING_CHANGE_STEP:
      return state.set('currentStep', payload);
    case POSTPAID_BOOKING_FLAG_SEARCHING_FOR_ITINERARIES:
      return state.setIn(['itineraries', 'searchingForItineraries'], payload);
    case POSTPAID_BOOKING_SEARCH_FOR_ITINERARIES:
      return (
        state
          // set the query
          .setIn(
            ['search', 'query'],
            new Immutable.Map({
              source: payload.source,
              destination: payload.destination,
              numPassengers: payload.numPassengers,
              time: payload.time,
            }),
          )
          // set the results
          .setIn(['itineraries', 'results'], new Immutable.Map(payload.results))
      );
    case POSTPAID_BOOKING_SELECT_ITINERARY:
      return state.setIn(
        ['itineraries', 'selectedItinerarySearchResults', payload.index],
        payload.itinerarySearchResult,
      );
    case POSTPAID_BOOKING_CLEAR_ITINERARY:
      return state.setIn(['itineraries'], INITIAL_STATE.getIn(['itineraries']));
    case POSTPAID_BOOKING_CLEAR_SELECT_ITINERARY:
      return state.setIn(
        ['itineraries', 'selectedItinerarySearchResults'],
        INITIAL_STATE.getIn(['itineraries', 'selectedItinerarySearchResults']),
      );
    case POSTPAID_BOOKING_CLEAR_SEATS:
      return state.setIn(['seats'], INITIAL_STATE.getIn(['seats']));
    case POSTPAID_BOOKING_FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS:
      // start seat selection change
      if (payload.status === 'start') {
        return state.setIn(
          ['seats', 'seatSelectionChangesInProgress'],
          state
            .getIn(['seats', 'seatSelectionChangesInProgress'])
            .add(payload.seatId),
        );
      }
      // stop seat selection change
      return state.setIn(
        ['seats', 'seatSelectionChangesInProgress'],
        state
          .getIn(['seats', 'seatSelectionChangesInProgress'])
          .remove(payload.seatId),
      );
    case POSTPAID_BOOKING_SELECT_SEAT:
      return state.setIn(
        ['seats', 'seatReservations'],
        state.getIn(['seats', 'seatReservations']).add(payload),
      );
    case POSTPAID_BOOKING_DESELECT_SEAT:
      return state.setIn(
        ['seats', 'seatReservations'],
        state
          .getIn(['seats', 'seatReservations'])
          .delete(
            state
              .getIn(['seats', 'seatReservations'])
              .findKey((seatReservation) => seatReservation.id === payload),
          ),
      );
    case POSTPAID_BOOKING_GET_ITINERARY_FOOD_GROUP:
      return state.setIn(['passengers', 'itineraryFoodGroup'], payload);
    case POSTPAID_BOOKING_FLAG_POSTING_RESERVATION:
      return state.setIn(['passengers', 'postingReservation'], payload);
    case POST_KIDS_ON_POSTPAID_ITINERARY:
      return state.setIn(
        ['passengers', 'kidsOnPostpaidItinerary'],
        state.getIn(['passengers', 'kidsOnPostpaidItinerary']).add(payload),
      );
    case DELETE_KIDS_ON_POSTPAID_ITINERARY:
      return state.setIn(
        ['passengers', 'kidsOnPostpaidItinerary'],
        state
          .getIn(['passengers', 'kidsOnPostpaidItinerary'])
          .delete(
            state
              .getIn(['passengers', 'kidsOnPostpaidItinerary'])
              .findKey((kidOnItinerary) => kidOnItinerary === payload),
          ),
      );
    case POSTPAID_BOOKING_POST_RESERVATION_REQUESTS:
      return state.setIn(['receiver', 'customerOrder'], Immutable.Map(payload));
    case POSTPAID_BOOKING_FLAG_POSTING_PRETRANSACTION:
      return state.setIn(['receiver', 'postingPretransaction'], payload);
    case POSTPAID_BOOKING_POST_PRETRANSACTION:
      return state.setIn(
        ['receiver', 'pretransactions'],
        Immutable.List(payload),
      );
    case POSTPAID_BOOKING_FLAG_GETTING_ALL:
      return state.setIn(['all', 'activity'], payload);
    case POSTPAID_BOOKING_GET_ALL:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case POSTPAID_BOOKING_CLEAR_ALL:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case POSTPAID_BOOKING_FLAG_APPROVING:
      return state.setIn(['all', 'activity'], payload);
    case POSTPAID_BOOKING_FLAG_REJECTING:
      return state.setIn(['all', 'activity'], payload);
    case POSTPAID_BOOKINGS_REPLACE_ONE: {
      const postpaidBookings = [...state.getIn(['all', 'content', 'content'])];
      const postpaidBooking = postpaidBookings.find(
        (element) => element.id === payload.id,
      );
      const index = postpaidBookings.indexOf(postpaidBooking);
      postpaidBookings[index] = payload;
      return state.setIn(
        ['all', 'content'],
        Immutable.Map({ content: postpaidBookings }),
      );
    }
    case FLAG_ADDING_VOUCHER_TO_POSTPAID_BOOKING:
      return state.setIn(['all', 'activity'], payload);
    case FLAG_GETTING_PRETRANSACTION_FOR_POSTPAID_BOOKING:
      return state.setIn(['summary', 'loading'], payload);
    case POSTPAID_BOOKING_GET_PRETRANSACTION:
      return state.setIn(
        ['summary', 'pretransactions'],
        Immutable.List(payload),
      );
    case FLAG_POSTPAID_BOOKING_RESERVATION_CREATED:
      return state.set('reservationCreated', payload);

    case SET_FIXED_PRICE_FOR_CURRENT_POSTPAID_BOOKING: {
      const oldPassengers = [
        ...state.getIn(['receiver', 'customerOrder', 'passengers']),
      ];
      const newPassengers = payload;
      oldPassengers.forEach((oldPassenger, index) => {
        newPassengers.forEach((newPassenger) => {
          if (oldPassenger.ticketId === newPassenger.ticketId) {
            oldPassengers[index].salePrice = newPassenger.fixedPrice;
          }
        });
      });
      return state.setIn(
        ['receiver', 'customerOrder', 'passengers'],
        oldPassengers,
      );
    }
    default:
      return state;
  }
};
