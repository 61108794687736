import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import { DEFAULT_SELECT_PLACEHOLDER } from '../../../../config/constants';
import { clearDriversMovement, getDriversMovement } from '../../../../actions';
import { optionsPropTypes } from './SelectPropTypes';

const DriverMovementSelect = ({
  value,
  loading,
  options,
  isGridVariant,
  isMulti,
  placeholder,
  isClearable,
  onValueChange,
  dispatchClearDriversMovement,
  dispatchGetDriversMovement,
  ...rest
}) => {
  useLayoutEffect(() => {
    dispatchGetDriversMovement();
    return () => dispatchClearDriversMovement();
  }, []);

  return (
    <Select
      isLoading={loading}
      options={options}
      isMulti={isMulti}
      input={{ value }}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      placeholder={placeholder}
      {...rest}
    />
  );
};

DriverMovementSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetDriversMovement: PropTypes.func.isRequired,
  dispatchClearDriversMovement: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
};

DriverMovementSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  value: {},
  options: [],
  onValueChange: undefined,
  isClearable: false,
  placeholder: DEFAULT_SELECT_PLACEHOLDER,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  options: TrafficUnit.DriverMovement.getIn(['all', 'content', 'content']).map(
    ({ id, name }) => ({
      value: id,
      label: name,
    }),
  ),
  loading: TrafficUnit.DriverMovement.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchClearDriversMovement: clearDriversMovement,
  dispatchGetDriversMovement: getDriversMovement,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DriverMovementSelect);
