import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { EXPENSE_AUTHORIZER_USER_PATH } from '../../../../config/paths';
import ExpenseAuthorizerUserDeleteButton from './ExpenseAuthorizerUserDeleteButton';

const ExpenseAuthorizerUserToolbar = ({ expenseAuthorizerUserId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton
        path={`${EXPENSE_AUTHORIZER_USER_PATH}/${expenseAuthorizerUserId}/edit`}
      />{' '}
      <ExpenseAuthorizerUserDeleteButton
        expenseAuthorizerUserId={expenseAuthorizerUserId}
      />
    </div>
  </ButtonToolbar>
);

ExpenseAuthorizerUserToolbar.propTypes = {
  expenseAuthorizerUserId: PropTypes.number.isRequired,
};

export default ExpenseAuthorizerUserToolbar;
