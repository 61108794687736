import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { postManualVoucher } from '../../../../actions';
import Content from '../../../layout/Content';
import ManualVoucherForm from './ManualVoucherForm';
import {
  CURRENCY_CODE,
  MANUAL_VOUCHER_PAYMENT_TYPE,
  MANUAL_VOUCHER_SERVICE_TYPE,
} from '../../../../config/constants';
import { MANUAL_VOUCHER_PATH } from '../../../../config/paths';
import { currencyToNumber } from '../../../../utils/number';
import { getSubstring } from '../../../../utils/string';
import { tzNormalizeDate } from '../../../../utils/date';

export class NewManualVoucher extends Component {
  onSubmit = (formValues) => {
    const newFormValues = { ...formValues };

    if (formValues.businessId)
      newFormValues.businessId = formValues.businessId.value;

    if (formValues.customerId)
      newFormValues.customerId = formValues.customerId.value;

    newFormValues.installments = formValues.installments || 1;
    newFormValues.voucherTypeId = formValues.voucherTypeId.value;
    newFormValues.serviceType = formValues.serviceType.value;
    newFormValues.currencyCode = formValues.currencyCode.value;
    newFormValues.paymentType = formValues.paymentType.value;

    newFormValues.items = formValues.items.map((item) => ({
      ...item,
      totalPrice: currencyToNumber(item.totalPrice),
    }));

    newFormValues.totalAmountWithoutTaxes = currencyToNumber(
      formValues.totalAmountWithoutTaxes,
    );
    newFormValues.taxes = currencyToNumber(formValues.taxes);
    newFormValues.totalAmount = currencyToNumber(formValues.totalAmount);
    newFormValues.detractionAmount =
      formValues.detractionAmount &&
      currencyToNumber(formValues.detractionAmount);
    newFormValues.totalAmountPayable =
      formValues.totalAmountPayable &&
      currencyToNumber(formValues.totalAmountPayable);
    newFormValues.previousManualVoucherId = getSubstring(
      formValues.previousManualVoucherId,
    );

    this.props.dispatchPostManualVoucher(newFormValues);
  };

  render() {
    const today = tzNormalizeDate();

    const initialValues = {
      issueDate: today,
      dueDate: today,
      currencyCode: CURRENCY_CODE.PEN,
      serviceType: MANUAL_VOUCHER_SERVICE_TYPE.TICKET_SERVICE,
      paymentType: MANUAL_VOUCHER_PAYMENT_TYPE.CASH,
    };

    return (
      <Content
        breadcrumbs={this.props.breadcrumbs}
        title="Nuevo Comprobante Manual"
        subtitle="Crear un nuevo comprobante manual: Boleta y/o Factura"
        content={
          <ManualVoucherForm
            onSubmit={this.onSubmit}
            initialValues={initialValues}
          />
        }
      />
    );
  }
}

const mapStateToProps = ({ AccountingUnit }) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Comprobantes Manuales',
      href: MANUAL_VOUCHER_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostManualVoucher: postManualVoucher,
};

NewManualVoucher.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostManualVoucher: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewManualVoucher);
