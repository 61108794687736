import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { COMPANY_RESOURCE_GROUP_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import CompanyResourceGroupBasicInformationResource from './resource/CompanyResourceGroupBasicInformationResource';
import {
  clearCompanyResourceGroup,
  getCompanyResourceGroup,
} from '../../../../actions';
import CompanyResourceGroupToolbar from './CompanyResourceGroupToolbar';

export const CompanyResourceGroup = ({
  breadcrumbs,
  dispatchClearCompanyResourceGroup,
  dispatchGetCompanyResourceGroup,
  match: {
    params: { id: companyResourceGroupId },
  },
  loading,
  companyResourceGroup,
}) => {
  useLayoutEffect(() => {
    dispatchGetCompanyResourceGroup({ companyResourceGroupId });

    return () => dispatchClearCompanyResourceGroup();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (companyResourceGroup.isEmpty())
    content = <NoDataResource returnPage={COMPANY_RESOURCE_GROUP_PATH} />;
  else {
    const companyResourceGroupJson = companyResourceGroup.toJS();

    content = (
      <>
        <h3>Información Básica</h3>
        <CompanyResourceGroupBasicInformationResource
          {...companyResourceGroupJson}
        />
      </>
    );

    toolbar = (
      <CompanyResourceGroupToolbar
        companyResourceGroupId={companyResourceGroupJson.id}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Grupo de Recursos de Compañia"
      content={content}
    />
  );
};

CompanyResourceGroup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearCompanyResourceGroup: PropTypes.func.isRequired,
  dispatchGetCompanyResourceGroup: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  companyResourceGroup: PropTypes.instanceOf(Immutable.Map).isRequired,
};

CompanyResourceGroup.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearCompanyResourceGroup: clearCompanyResourceGroup,
  dispatchGetCompanyResourceGroup: getCompanyResourceGroup,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupos de Recursos de Compañia',
      href: COMPANY_RESOURCE_GROUP_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  companyResourceGroup: MechanicalMaintenanceUnit.CompanyResourceGroup.getIn([
    'current',
    'content',
  ]),
  loading: !MechanicalMaintenanceUnit.CompanyResourceGroup.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyResourceGroup);
