import { shape, number, string } from 'prop-types';
import { userBasicInformationPropTypes } from '../../../user/user/proptypes/UserPropTypes';

const costCenterBasicInformationPropTypes = {
  id: number,
  name: string,
  description: string,
  costCenterCode: string,
};

const expenseAuthorizerUserBasicInformationPropTypes = {
  userId: number.isRequired,
  user: shape(userBasicInformationPropTypes),
  costCenterId: number,
  costCenter: shape(costCenterBasicInformationPropTypes),
};

export {
  costCenterBasicInformationPropTypes,
  expenseAuthorizerUserBasicInformationPropTypes,
};
