import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import { CIRCUIT_PATH } from '../../../../config/paths';
import { clearCircuit, getCircuit } from '../../../../actions';
import CircuitToolbar from './CircuitToolbar';
import CircuitBasicInformationResource from './resource/CircuitBasicInformationResorce';

export const Circuit = ({
  breadcrumbs,
  dispatchGetCircuit,
  dispatchClearCircuit,
  match: {
    params: { id: circuitId },
  },
  loading,
  circuit,
}) => {
  useLayoutEffect(() => {
    dispatchGetCircuit({ circuitId });

    return () => dispatchClearCircuit();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (circuit.isEmpty())
    content = <NoDataResource returnPage={CIRCUIT_PATH} />;
  else {
    const circuitJson = circuit.toJS();

    content = <CircuitBasicInformationResource {...circuitJson} />;

    toolbar = <CircuitToolbar circuitId={circuitJson.id} />;
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
      title="Circuito"
      content={content}
    />
  );
};

Circuit.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearCircuit: PropTypes.func.isRequired,
  dispatchGetCircuit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  circuit: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Circuit.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearCircuit: clearCircuit,
  dispatchGetCircuit: getCircuit,
};

const mapStateToProps = ({ RouteUnit }) => ({
  breadcrumbs: [
    ...RouteUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Circuitos',
      href: CIRCUIT_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  circuit: RouteUnit.Circuit.getIn(['current', 'content']),
  loading: !RouteUnit.Circuit.getIn(['current', 'activity']).isEmpty(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Circuit);
