import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import * as PostpaidSummaryPropTypes from './PostpaidSummaryPropTypes';
import { tzNormalizeDate } from '../../../../utils/date';
import { DATE_TIME_FORMAT } from '../../../../config/locale';

const PretransactionDetails = ({ pretransactions }) => {
  let deliveryAddress = null;
  const firstPreTransaction = pretransactions[0];
  if (firstPreTransaction.customerDeliveryAddressId) {
    deliveryAddress = (
      <Row>
        <Col>
          <ResourceProperty label="Dirección de Delivery:">
            {firstPreTransaction.customerDeliveryAddress.deliveryAddress}
          </ResourceProperty>
        </Col>
      </Row>
    );
  }

  // we are using voucherCode to store the pago efectivo code
  let voucherCode = null;
  if (firstPreTransaction.voucherCode) {
    voucherCode = (
      <Row>
        <Col>
          <ResourceProperty label="Código PagoEfectivo:">
            {firstPreTransaction.voucherCode}
          </ResourceProperty>
        </Col>
      </Row>
    );
  }

  // we are using pagoEfectivoExpirationDate to store the pago efectivo code
  let pagoEfectivoExpirationDate = null;
  if (firstPreTransaction.pagoEfectivoExpirationDate) {
    pagoEfectivoExpirationDate = (
      <Row>
        <Col>
          <ResourceProperty label="Fecha de Expiración CIP:">
            {tzNormalizeDate({
              date: firstPreTransaction.pagoEfectivoExpirationDate,
              format: DATE_TIME_FORMAT,
            })}
          </ResourceProperty>
        </Col>
      </Row>
    );
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <ResourceProperty label="Correo de Destino:">
            {firstPreTransaction.customerEmailAddress.email}
          </ResourceProperty>
        </Col>
      </Row>
      {pretransactions.map((pretransaction) => (
        <Row key={pretransaction.id}>
          <Col>
            <ResourceProperty label="Método de Pago:">
              {pretransaction.paymentMethod.name}
            </ResourceProperty>
          </Col>
        </Row>
      ))}
      {deliveryAddress}
      {voucherCode}
      {pagoEfectivoExpirationDate}
    </Fragment>
  );
};

PretransactionDetails.propTypes = {
  pretransactions: PostpaidSummaryPropTypes.pretransactionPropType.isRequired,
};

export default PretransactionDetails;
