import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EXPENSE_AUTHORIZER_USER_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import ExpenseAuthorizerUserForm from './ExpenseAuthorizerUserForm';
import { postExpenseAuthorizerUser } from '../../../../actions/accounting/ExpenseAuthorizerUser';

const NewExpenseAuthorizerUser = ({
  breadcrumbs,
  dispatchPostExpenseAuthorizerUser,
}) => {
  const onSubmit = (formValues) => {
    const newFormValues = {
      userId: formValues.userId ? formValues.userId.value : null,
      costCenterIdList: formValues.costCenterId
        ? formValues.costCenterId.map((item) => item.value)
        : [],
    };
    dispatchPostExpenseAuthorizerUser(newFormValues);
  };

  const content = <ExpenseAuthorizerUserForm onSubmit={onSubmit} />;
  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Usuario Autorizador de Egresos"
      subtitle="Crear un nuevo usuario autorizador"
      content={content}
    />
  );
};

const mapDispatchToProps = {
  dispatchPostExpenseAuthorizerUser: postExpenseAuthorizerUser,
};

const mapStateToProps = ({ AccountingUnit }) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Usuarios Autorizadores de Egresos',
      href: EXPENSE_AUTHORIZER_USER_PATH,
    },
    {
      text: 'Nuevo Usuario Autorizador de Egresos',
      href: '',
    },
  ],
});

NewExpenseAuthorizerUser.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostExpenseAuthorizerUser: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewExpenseAuthorizerUser);
