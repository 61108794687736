import Immutable from 'immutable';
import {
  HOME_PATH,
  EXPENSE_TYPE_PATH,
  TICKET_TAXABLE_INFORMATION_PATH,
  MANUAL_VOUCHER_PATH,
  BILLING_PATH,
  DETRACTION_PATH,
  EXPENSE_AUTHORIZER_USER_PATH,
} from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    {
      id: 'trip-expenses',
      title: 'Gastos de Viaje',
      path: '/accounting/trip-expenses',
      icon: 'usd',
      disabled: true,
    },
    {
      id: 'expense-types',
      title: 'Tipos de Egreso',
      path: EXPENSE_TYPE_PATH,
      icon: 'list-ol',
    },
    {
      id: 'voucher-types',
      title: 'Tipos de comprobante',
      path: '/accounting/voucher-types',
      icon: 'envelope-o',
      disabled: true,
    },
    {
      id: 'payment-methods',
      title: 'Métodos de Pago',
      path: '/accounting/payment-methods',
      icon: 'credit-card-alt',
      disabled: true,
    },
    {
      id: 'sales-taxes',
      title: 'Impuestos a la Venta',
      path: '/accounting/sales-taxes',
      icon: 'percent',
      disabled: true,
    },
    {
      id: 'exchange-rates',
      title: 'Tasas de Cambio',
      path: '/accounting/exchange-rates',
      icon: 'gg',
      disabled: true,
    },
    {
      id: 'liquidation-difference-reasons',
      title: 'Discrepancias de Liquidación',
      path: '/accounting/liquidation-difference-reasons',
      icon: 'question-circle-o',
      disabled: true,
    },
    {
      id: 'general-income-types',
      title: 'Tipos de Ingreso',
      path: '/accounting/general-income-types',
      icon: 'list-ol',
      disabled: true,
    },
    {
      id: 'transactions',
      title: 'Transacción',
      path: '/accounting/transactions',
      icon: 'money',
      disabled: true,
    },
    {
      id: 'ticket-taxable-information',
      title: 'Control de Ingresos - Boletos',
      path: TICKET_TAXABLE_INFORMATION_PATH,
      icon: 'edit',
    },
    {
      id: 'manual-voucher',
      title: 'Comprobantes Manuales',
      path: MANUAL_VOUCHER_PATH,
      icon: 'print',
    },
    {
      id: 'accounting-reports',
      title: 'Reporte de Contabilidad',
      path: '/accounting/accounting-reports',
      icon: 'file-excel-o',
      disabled: true,
    },
    {
      id: 'billing',
      title: 'Datos de Facturación',
      path: BILLING_PATH,
      icon: 'dollar',
    },
    {
      id: 'detraction',
      title: 'Detracción',
      path: DETRACTION_PATH,
      icon: 'bank',
    },
    {
      id: 'expense-authorizer-user',
      title: 'Usuario Autorizador de gastos',
      path: EXPENSE_AUTHORIZER_USER_PATH,
      icon: 'user',
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Contabilidad',
      tag: 'span',
      href: '/accounting',
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
