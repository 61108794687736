import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import FormFooter from '../../../common/forms/FormFooter';
import Loader from '../../../common/Loader';
import CostCenterSelect from '../../../common/forms/select/CostCenterSelect';
import UserSelect from '../../../common/forms/select/UserSelect';

export const ExpenseAuthorizerUserForm = ({
  handleSubmit,
  loading,
  saveMode,
}) =>
  loading ? (
    <Loader />
  ) : (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Usuario" required>
          <Field
            name="userId"
            component={UserSelect}
            type="text"
            placeholder="Usuario"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Centro de Costos" required>
          <Field
            name="costCenterId"
            component={CostCenterSelect}
            isMulti={saveMode}
            type="text"
            placeholder="Centro de Costos"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>

      <FormFooter />
    </Form>
  );

const mapStateToProps = ({ AccountingUnit }) => ({
  loading: !AccountingUnit.Detraction.getIn(['current', 'activity']).isEmpty(),
});

ExpenseAuthorizerUserForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  saveMode: PropTypes.bool,
};

ExpenseAuthorizerUserForm.defaultProps = {
  loading: false,
  saveMode: true,
};

const formComponent = reduxForm({
  form: 'ExpenseAuthorizerUserForm',
})(ExpenseAuthorizerUserForm);

export default connect(mapStateToProps)(formComponent);
