import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditButton from '../../../common/button/EditButton';
import { COMPANY_RESOURCE_GROUP_PATH } from '../../../../config/paths';
import CompanyResourceGroupDeleteButton from './CompanyResourceGroupDeleteButton';

const CompanyResourceGroupToolbar = ({ companyResourceGroupId }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <EditButton
        path={`${COMPANY_RESOURCE_GROUP_PATH}/${companyResourceGroupId}/edit`}
      />{' '}
      <CompanyResourceGroupDeleteButton
        companyResourceGroupId={companyResourceGroupId}
      />
    </div>
  </ButtonToolbar>
);

CompanyResourceGroupToolbar.propTypes = {
  companyResourceGroupId: PropTypes.number.isRequired,
};

export default CompanyResourceGroupToolbar;
