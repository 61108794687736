import React, { Fragment } from 'react';
import moment from 'moment';
import { GENDERS } from '../../../../../config/constants';
import { tzNormalizeDate } from '../../../../../utils/date';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import {
  customerBasicInformationDefaultProps,
  customerBasicInformationPropTypes,
} from '../proptypes/CustomerPropTypes';
import { DATE_FORMAT } from '../../../../../config/locale';

const CustomerBasicInformationResource = ({
  firstName,
  lastName,
  idDocumentNumber,
  idCountryOfOrigin,
  gender,
  identificationType,
  dob,
  address,
}) => {
  const customerGender = GENDERS.find(({ value }) => value === gender);

  return (
    <Fragment>
      <ResourceProperty label="Nombres:">{firstName || '-'}</ResourceProperty>
      <ResourceProperty label="Apellidos:">{lastName || '-'}</ResourceProperty>
      <ResourceProperty label="Género:">
        {customerGender ? customerGender.label : '-'}
      </ResourceProperty>
      <ResourceProperty label="Tipo de Documento:">
        {identificationType ? identificationType.name : '-'}
      </ResourceProperty>
      <ResourceProperty label="Número de Documento:">
        {idDocumentNumber || '-'}
      </ResourceProperty>
      <ResourceProperty label="País de Origen:">
        {idCountryOfOrigin || '-'}
      </ResourceProperty>
      <ResourceProperty label="Fecha de Nacimiento:">
        {dob ? tzNormalizeDate({ date: dob, format: DATE_FORMAT }) : '-'}
      </ResourceProperty>
      <ResourceProperty label="Edad:">
        {dob ? moment().diff(tzNormalizeDate({ date: dob }), 'years') : '-'}
      </ResourceProperty>
      <ResourceProperty label="Domicilio:">
        {address ? address.address : '-'}
      </ResourceProperty>
      <ResourceProperty label="Distrito:">
        {address ? address.district : '-'}
      </ResourceProperty>
      <ResourceProperty label="Provincia:">
        {address ? address.city.name : '-'}
      </ResourceProperty>
      <ResourceProperty label="Departamento:">
        {address ? address.city.region.name : '-'}
      </ResourceProperty>
    </Fragment>
  );
};

CustomerBasicInformationResource.propTypes = customerBasicInformationPropTypes;

CustomerBasicInformationResource.defaultProps =
  customerBasicInformationDefaultProps;

export default CustomerBasicInformationResource;
