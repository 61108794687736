import React, { Fragment } from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import {
  customerContactInformationDefaultProps,
  customerContactInformationPropTypes,
} from '../proptypes/CustomerPropTypes';

const CustomerContactInformationResource = ({
  email,
  mobilePhone,
  homePhone,
  workPhone,
}) => {
  const mobilePhoneResource = mobilePhone ? (
    <a href={`tel:${mobilePhone}`}>{mobilePhone}</a>
  ) : (
    '-'
  );
  const homePhoneResource = homePhone ? (
    <a href={`tel:${homePhone}`}>{homePhone}</a>
  ) : (
    '-'
  );
  const workPhoneResource = workPhone ? (
    <a href={`tel:${workPhone}`}>{workPhone}</a>
  ) : (
    '-'
  );
  const emailResource = email ? <a href={`mailto:${email}`}>{email}</a> : '-';
  return (
    <Fragment>
      <ResourceProperty label="Email:">{emailResource}</ResourceProperty>
      <ResourceProperty label="Celular:">
        {mobilePhoneResource}
      </ResourceProperty>
      <ResourceProperty label="Teléfono Fijo:">
        {homePhoneResource}
      </ResourceProperty>
      <ResourceProperty label="Teléfono de Trabajo:">
        {workPhoneResource}
      </ResourceProperty>
    </Fragment>
  );
};

CustomerContactInformationResource.propTypes =
  customerContactInformationPropTypes;

CustomerContactInformationResource.defaultProps =
  customerContactInformationDefaultProps;

export default CustomerContactInformationResource;
