import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import {
  Button,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { inputPropTypes, metaPropTypes } from './InputPropTypes';
import Modal from '../../modal/Modal';
import ItinerarySearch from '../../../units/itinerary/itinerary/ItinerarySearch';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import { tzNormalizeDate } from '../../../../utils/date';

export const ItinerarySearchInput = ({
  input: { value, name, onChange },
  placeholder,
  meta: { touched, error, warning },
  disabled,
  dispatchChange,
  form,
  handleClear,
  handleSelectItinerary,
  handleShowItinerarySearch,
  isMulti,
}) => {
  const [showItinerarySearchModal, setShowItinerarySearchModal] =
    useState(false);
  const [itineraries, setItineraries] = useState([]);

  const closeModal = () => setShowItinerarySearchModal(false);

  const formatItineraryName = (data) => {
    const { id: itineraryId, routeName, departuretime } = data;
    return `${itineraryId} - ${routeName} ${tzNormalizeDate({
      date: departuretime,
      format: DATE_TIME_FORMAT,
    })}`;
  };

  const getRowData = (data) => {
    const itineraryName = formatItineraryName(data);
    const { id: itineraryId } = data;

    if (isMulti) {
      if (!itineraries.some((it) => it.id === itineraryId)) {
        const newItineraries = [
          ...itineraries,
          { id: itineraryId, name: itineraryName },
        ];
        setItineraries(newItineraries);
        dispatchChange(
          form,
          name,
          newItineraries.map((it) => it.name).join(', '),
        );

        if (handleSelectItinerary) {
          handleSelectItinerary({
            itineraryIds: newItineraries.map((it) => it.id),
          });
        }
      }
    } else {
      setItineraries([{ id: itineraryId, name: itineraryName }]);
      dispatchChange(form, name, itineraryName);

      if (handleSelectItinerary) {
        handleSelectItinerary({ itineraryId });
      }
    }

    closeModal();
  };

  const showModal = () => {
    if (handleShowItinerarySearch) handleShowItinerarySearch();
    setShowItinerarySearchModal(true);
  };

  const clearField = () => {
    if (handleClear) {
      handleClear();
    }

    dispatchChange(form, name, null);
    setItineraries([]);
  };

  const removeItinerary = (itineraryId) => {
    const newItineraries = itineraries.filter((it) => it.id !== itineraryId);
    setItineraries(newItineraries);
    dispatchChange(form, name, newItineraries.map((it) => it.name).join(', '));

    if (handleSelectItinerary) {
      handleSelectItinerary({
        itineraryIds: newItineraries.map((it) => it.id),
      });
    }
  };

  const buttons = (
    <InputGroupAddon addonType="append">
      <Button
        type="button"
        outline
        title={`Buscar ${placeholder}`}
        onClick={showModal}
        style={{ zIndex: 0 }}
      >
        <i className="fa fa-search" /> Buscar
      </Button>
      <Button
        type="button"
        title="Limpiar"
        outline
        onClick={clearField}
        style={{ zIndex: 0 }}
        disabled={itineraries.length === 0}
      >
        Eliminar todo
      </Button>
    </InputGroupAddon>
  );

  const modal = (
    <Modal
      show={showItinerarySearchModal}
      title={`Búsqueda ${placeholder}`}
      body={<ItinerarySearch getRowData={getRowData} onModal />}
      onClickClose={closeModal}
      size="xl"
    />
  );

  const renderSelectedItineraries = () => {
    if (!isMulti || itineraries.length === 0) return null;

    return (
      <div className="mt-2">
        {itineraries.map((itinerary) => (
          <div
            key={itinerary.id}
            className="d-flex align-items-center mb-1 p-2 border rounded"
          >
            <span className="flex-grow-1">{itinerary.name}</span>
            <Button
              type="button"
              color="link"
              className="p-0 ml-2"
              onClick={() => removeItinerary(itinerary.id)}
            >
              <i className="fa fa-times text-danger" />
            </Button>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      {modal}
      <InputGroup>
        <Input
          disabled={disabled}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          type="text"
          readOnly
        />
        {buttons}
      </InputGroup>
      {renderSelectedItineraries()}
      <FormText color="danger">
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </FormText>
    </div>
  );
};

ItinerarySearchInput.propTypes = {
  input: inputPropTypes.isRequired,
  placeholder: PropTypes.string,
  meta: metaPropTypes.isRequired,
  disabled: PropTypes.bool,
  dispatchChange: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  handleClear: PropTypes.func,
  handleSelectItinerary: PropTypes.func,
  handleShowItinerarySearch: PropTypes.func,
  isMulti: PropTypes.bool,
};

ItinerarySearchInput.defaultProps = {
  placeholder: 'ID de Itinerario',
  disabled: false,
  handleSelectItinerary: null,
  handleShowItinerarySearch: null,
  handleClear: null,
  isMulti: false,
};

const mapDispatchToProps = {
  dispatchChange: change,
};

export default connect(null, mapDispatchToProps)(ItinerarySearchInput);
