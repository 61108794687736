import React, { useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { clearCreditNotes, getCreditNotes } from '../../../../actions';
import { DEFAULT_SELECT_PLACEHOLDER } from '../../../../config/constants';

export const CreditNoteSelect = ({
  loading,
  options,
  isGridVariant,
  isMulti,
  isClearable,
  onValueChange,
  dispatchClearCreditNotes,
  dispatchGetCreditNotes,
  customerId,
  isCustomerIdRequired,
  optionsRegistered,
  ...rest
}) => {
  useLayoutEffect(
    () => () => {
      if (!optionsRegistered) {
        dispatchClearCreditNotes();
      }
    },
    [],
  );

  useEffect(() => {
    if (isCustomerIdRequired && !optionsRegistered) {
      const query = [`customerId:${customerId}`, 'isValid:true'];
      dispatchGetCreditNotes({ query });
    }
  }, [customerId]);

  const onInputChange = (inputValue) => {
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      const query = [`reference:${inputValue}`];

      dispatchGetCreditNotes({ query });
    }
  };

  return (
    <Select
      isLoading={loading}
      onInputChange={!isCustomerIdRequired ? onInputChange : undefined}
      options={options}
      isMulti={isMulti}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      {...rest}
    />
  );
};

CreditNoteSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetCreditNotes: PropTypes.func.isRequired,
  dispatchClearCreditNotes: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
  optionsRegistered: PropTypes.bool,
  placeholder: PropTypes.string,
  customerId: PropTypes.number,
  isCustomerIdRequired: PropTypes.bool,
};

CreditNoteSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  options: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: DEFAULT_SELECT_PLACEHOLDER,
  customerId: 0,
  isCustomerIdRequired: false,
  optionsRegistered: false,
};

const mapStateToProps = ({ SalesUnit }) => ({
  options: SalesUnit.CreditNote.getIn(['all', 'content', 'content']).map(
    ({ id, documentSeries, documentCode, customerFullName, amount }) => ({
      value: id,
      label: `${
        documentSeries && documentCode
          ? `${documentSeries}-${documentCode.toString().padStart(7, '0')} - `
          : ''
      }S/.${amount} - ${customerFullName}`,
      amount,
    }),
  ),
  loading: SalesUnit.CreditNote.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetCreditNotes: getCreditNotes,
  dispatchClearCreditNotes: clearCreditNotes,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditNoteSelect);
