import { combineReducers } from 'redux';
import Driver from './Driver';
import DriverType from './DriverType';
import CabinCrew from './CabinCrew';
import UnitHome from './UnitHome';
import ExpectedFuelConsumption from './ExpectedFuelConsumption';
import BusFuelGroup from './BusFuelGroup';
import ChassisBodywork from './ChassisBodywork';
import FuelProfile from './FuelProfile';
import PurchaseOrder from './PurchaseOrder';
import ExtraordinaryMovementControl from './ExtraordinaryMovementControl';
import FuelVoucher from './FuelVoucher';
import BaseFuelRefill from './BaseFuelRefill';
import DriverBusAssignment from './DriverBusAssignment';
import FuelConsumptionReportPerBus from './FuelConsumptionReportPerBus';
import FuelConsumptionReportPerDriver from './FuelConsumptionReportPerDriver';
import FuelReportPreview from './FuelReportPreview';
import ExtraFuelConsumption from './ExtraFuelConsumption';
import CrewStatus from './CrewStatus';
import DriverDocumentType from './DriverDocumentType';
import DriverContractType from './DriverContractType';
import DriverMovementHistory from './DriverMovementHistory';
import DriverMovement from './DriverMovement';

export default combineReducers({
  Driver,
  DriverType,
  CabinCrew,
  UnitHome,
  ExpectedFuelConsumption,
  BusFuelGroup,
  ChassisBodywork,
  FuelProfile,
  PurchaseOrder,
  ExtraordinaryMovementControl,
  FuelVoucher,
  BaseFuelRefill,
  DriverBusAssignment,
  FuelConsumptionReportPerBus,
  FuelConsumptionReportPerDriver,
  FuelReportPreview,
  ExtraFuelConsumption,
  CrewStatus,
  DriverDocumentType,
  DriverContractType,
  DriverMovementHistory,
  DriverMovement,
});
