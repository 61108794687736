import JsPdf from 'jspdf';
import QRCode from 'qrcode';
import tahoma from './printers/fonts/tahoma';
import tahomaBold from './printers/fonts/tahoma-bold';

const x = 5;
const y = 5;
const titleSize = 16;
const subtitleSize = 11;
const paragraphSize = 8;
const textSpacing = 4;
const cellHeight = 6;
const tableHeaderSize = 11;

const createPdfDocument = (orientation = 'p', unit = 'mm', format = 'a4') => {
  const doc = new JsPdf(orientation, unit, format);

  doc.addFileToVFS('tahoma.ttf', tahoma);
  doc.addFont('tahoma.ttf', 'Tahoma', 'normal', 'WinAnsiEncoding');
  doc.addFileToVFS('tahoma-bold.ttf', tahomaBold);
  doc.addFont('tahoma-bold.ttf', 'Tahoma', 'bold', 'WinAnsiEncoding');
  doc.setFont('Tahoma');

  return doc;
};

const reduceText = (doc, text, maxWidth) => {
  let cuttedText = text;
  let textWidth = doc.getTextWidth(cuttedText);

  while (textWidth > maxWidth && cuttedText.length > 0) {
    cuttedText = cuttedText.substring(0, cuttedText.length - 1);
    textWidth = doc.getTextWidth(cuttedText);
  }

  return cuttedText;
};

const addText = (doc, text, xPosition, yPosition, maxWidth, align = 'center') =>
  doc.text(
    text || '-',
    xPosition,
    yPosition,
    maxWidth && {
      align,
      lineHeightFactor: 1.1,
      maxWidth,
    },
  );

const setFont = (doc, style = 'normal', font = 'Tahoma') => {
  doc.setFont(font, style);
};

const addImage = async (doc, image, xPosition, yPosition, width, height) => {
  const img = new Image();
  img.src = image;
  doc.addImage(img, 'jpeg', xPosition, yPosition, width, height);
};

const addQrCode = async (doc, value, xPosition, yPosition, width, height) => {
  const qrString = await QRCode.toDataURL(value, {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    scale: 4,
  });
  doc.addImage(qrString, 'jpeg', xPosition, yPosition, width, height);
};

const isNewPageNeeded = (doc, xPosition, yPosition, height = 0) => {
  if (
    yPosition + height >=
    Math.round(doc.internal.pageSize.getHeight() - xPosition)
  ) {
    doc.addPage();
    return y;
  }

  return yPosition;
};

const addWaterMark = (doc, text) => {
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  doc.saveGraphicsState();

  doc.setGState(new doc.GState({ opacity: 0.2 }));
  doc.setFontSize(30);
  doc.setTextColor(150, 150, 150);
  doc.setFont('helvetica', 'bold');

  const positionX = pageWidth / 2;
  const positionY = pageHeight / 3.3;

  doc.text(
    text,
    positionX,
    positionY,
    {
      align: 'center',
      angle: 45,
    },
    45,
  );

  doc.restoreGraphicsState();
};

export {
  x,
  y,
  titleSize,
  subtitleSize,
  paragraphSize,
  textSpacing,
  cellHeight,
  tableHeaderSize,
  createPdfDocument,
  addText,
  isNewPageNeeded,
  addQrCode,
  addImage,
  setFont,
  reduceText,
  addWaterMark,
};
