import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DRIVER_PATH, generateDriverTripsPath } from '../../../../config/paths';
import { getDriver, clearDriver } from '../../../../actions';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import DriverToolbar from './DriverToolbar';
import CustomerBasicInformationResource from '../../user/customer/resource/CustomerBasicInformationResource';
import CustomerContactInformationResource from '../../user/customer/resource/CustomerContactInformationResource';
import DriverStatusInformationResource from './resource/DriverStatusInformationResource';
import DriverDocumentInformationResource from './resource/DriverDocumentInformationResource';
import DriverContractInformationResource from './resource/DriverContractInformationResource';
import DriverMovementsHistoryBasicResource from './DriverMovementsHistoryBasicResource';

const Driver = ({
  breadcrumbs,
  dispatchGetDriver,
  dispatchClearDriver,
  loading,
  match: {
    params: { id },
  },
  driver,
}) => {
  useLayoutEffect(() => {
    dispatchGetDriver({ id });
    return () => dispatchClearDriver();
  }, []);

  let content;
  let toolbar;

  if (loading) content = <Loader />;
  else if (driver.isEmpty())
    content = <NoDataResource returnPage={DRIVER_PATH} />;
  else {
    const driverJson = driver.toJS();

    content = (
      <>
        <Row>
          <Col md={4}>
            <h3>Información Básica</h3>
            <CustomerBasicInformationResource {...driverJson.customer} />
          </Col>
          <Col md={4}>
            <h3>Información de Contacto</h3>
            <CustomerContactInformationResource {...driverJson.customer} />
          </Col>
          <Col md={4}>
            <h3>Foto</h3>
            <img
              src={driverJson.imageUrl}
              className="img-fluid"
              aria-hidden="true"
              width={300}
              alt=""
            />
          </Col>
        </Row>
        <DriverDocumentInformationResource
          driverDocumentList={driverJson.driverDocumentList}
        />
        <h3>Estado</h3>
        <DriverStatusInformationResource {...driverJson} />
        <DriverContractInformationResource {...driverJson} />
        <Link
          className="btn btn-outline-primary"
          to={generateDriverTripsPath(driverJson.id)}
        >
          <i className="fa fa-bus" /> Ver viajes realizados
        </Link>
        <DriverMovementsHistoryBasicResource
          driverMovementHistoryList={driverJson.driverMovementHistoryList}
        />
      </>
    );

    toolbar = <DriverToolbar driverId={driverJson.id} />;
  }

  return (
    <Content
      title="Conductor"
      subtitle="Ver un conductor"
      content={content}
      breadcrumbs={breadcrumbs}
      toolbar={toolbar}
    />
  );
};

Driver.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetDriver: PropTypes.func.isRequired,
  dispatchClearDriver: PropTypes.func.isRequired,
  dispatchGetDriverTrips: PropTypes.func.isRequired,
  dispatchClearDriverTrips: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  driver: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Driver.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Conductores',
      href: DRIVER_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  driver: TrafficUnit.Driver.getIn(['current', 'content']),
  loading: !TrafficUnit.Driver.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetDriver: getDriver,
  dispatchClearDriver: clearDriver,
};

export default connect(mapStateToProps, mapDispatchToProps)(Driver);
