import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import ResourceProperty from '../../../common/resource/ResourceProperty';

// TODO test
const CreditNote = ({ creditNote, description }) => {
  let noteDescription = creditNote.valid
    ? description
    : 'Nota de crédito ya fue UTILIZADA';
  if (!creditNote.valid && creditNote.amount !== 0)
    noteDescription = 'Nota de crédito ya fue ANULADA';
  const color = creditNote.valid ? 'primary' : 'danger';
  return (
    <Alert color={color}>
      <h3>Nota de crédito</h3>
      <p>{noteDescription}</p>
      {creditNote.documentSeries && creditNote.documentCode && (
        <ResourceProperty label="Serie - Correlativo:">
          {creditNote.documentSeries} -{' '}
          {creditNote.documentCode.toString().padStart(7, '0')}
        </ResourceProperty>
      )}
      <ResourceProperty label="Monto:">
        {!creditNote.valid && creditNote.amount !== 0
          ? '0.00'
          : creditNote.amount.toFixed(2)}
      </ResourceProperty>
    </Alert>
  );
};

CreditNote.propTypes = {
  creditNote: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    valid: PropTypes.bool.isRequired,
    documentSeries: PropTypes.string.isRequired,
    documentCode: PropTypes.number.isRequired,
  }).isRequired,
  description: PropTypes.string,
};

CreditNote.defaultProps = {
  description:
    'Este boleto fue postergado y tiene una nota de crédito disponible',
};

export default CreditNote;
