import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { postContingencySenderRemissionGuide } from '../../../../actions/cargo/ManualSenderRemissionGuide';
import printContingencySenderRemissionGuides from '../../../../utils/printers/ContingencySenderRemissionGuide';

const PrintContingencySenderRemmisionGuiderButton = ({
  dispatchPostContingencySenderRemissionGuide,
  contingencyRequest,
  loading,
}) => {
  const onClick = async () => {
    const contingencyGuide = await dispatchPostContingencySenderRemissionGuide(
      contingencyRequest,
    );

    if (contingencyGuide) {
      printContingencySenderRemissionGuides(contingencyGuide);
    }
  };

  return (
    <>
      <Button color="warning" onClick={onClick} disabled={loading}>
        <i className="fa fa-print" /> Imprimir Contingencia
      </Button>
    </>
  );
};

PrintContingencySenderRemmisionGuiderButton.propTypes = {
  dispatchPostContingencySenderRemissionGuide: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  contingencyRequest: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  dispatchPostContingencySenderRemissionGuide:
    postContingencySenderRemissionGuide,
};

const mapStateToProps = (state) => ({
  loading: !state.CargoUnit.ManualSenderRemissionGuide.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});
const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrintContingencySenderRemmisionGuiderButton);

export default connectedComponent;
