import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import { TICKET_PATH } from '../../../../config/paths';
import { numberFormatter } from '../../../../utils/number';

const History = ({ nextTickets, previousTickets }) => {
  let nextTicketComponent;
  let previousTikcetComponent;

  if (previousTickets.length > 0) {
    previousTikcetComponent = previousTickets.map((ticket) => (
      <Row key={ticket.id}>
        <Col>
          <ResourceProperty label="Boleto Anterior:">
            <div className="d-inline-flex">
              <a href={`${TICKET_PATH}/${ticket.id}`}>{ticket.code}</a>
              <p className="ml-1">
                - Utilizado {numberFormatter({ value: ticket.amount })}
              </p>
            </div>
          </ResourceProperty>
        </Col>
      </Row>
    ));
  }

  if (nextTickets.length > 0) {
    nextTicketComponent = nextTickets.map((ticket) => (
      <Row key={ticket.id}>
        <Col>
          <ResourceProperty label="Siguiente Boleto:">
            <div className="d-inline-flex">
              <a href={`${TICKET_PATH}/${ticket.id}`}>{ticket.code}</a>
              <p className="ml-1">
                - Utilizado {numberFormatter({ value: ticket.amount })}
              </p>
            </div>
          </ResourceProperty>
        </Col>
      </Row>
    ));
  }
  return (
    <>
      <h3>Historial</h3>
      {nextTicketComponent}
      {previousTikcetComponent}
    </>
  );
};

History.propTypes = {
  nextTickets: PropTypes.instanceOf(Array),
  previousTickets: PropTypes.instanceOf(Array),
};

History.defaultProps = {
  nextTickets: [],
  previousTickets: [],
};

export default History;
