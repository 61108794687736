import PropTypes from 'prop-types';

const pretransactionPropType = PropTypes.arrayOf({
  customerDeliveryAddress: PropTypes.shape({
    deliveryAddress: PropTypes.string.isRequired,
  }),
  customerEmailAddress: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }),
  paymentMethod: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  voucherCode: PropTypes.string,
});

const passengersPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    idDocumentNumber: PropTypes.string.isRequired,
    seatNumber: PropTypes.number.isRequired,
  }),
);

export { pretransactionPropType, passengersPropType };
