import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  CLEAR_DRIVERS_MOVEMENT,
  FLAG_DRIVER_MOVEMENT_ACTIVITY,
  FLAG_GETTING_DRIVERS_MOVEMENT,
  GET_DRIVERS_MOVEMENT,
} from '../types';
import { DRIVER_MOVEMENT_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';

const flagGettingDriversMovement = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DRIVERS_MOVEMENT,
    payload: flag,
  });

const getDriversMovement = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingDriversMovement(true));
    const query = tableFilters;
    const url = `${DRIVER_MOVEMENT_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const drivers = await response.json();
    dispatch({
      type: GET_DRIVERS_MOVEMENT,
      payload: drivers,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingDriversMovement(false));
  }
};

const clearDriversMovement = () => (dispatch) =>
  dispatch({
    type: CLEAR_DRIVERS_MOVEMENT,
  });

const flagDriverMovementActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_DRIVER_MOVEMENT_ACTIVITY,
    payload: flag,
  });

export { getDriversMovement, clearDriversMovement, flagDriverMovementActivity };
