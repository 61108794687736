import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { COMPANY_RESOURCE_GROUP_PATH } from '../../../../config/paths';
import { postCompanyResourceGroup } from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import CompanyResourceGroupForm from './CompanyResourceGroupForm';

const NewCompanyResourceGroup = ({
  breadcrumbs,
  dispatchPostCompanyResourceGroup,
}) => {
  const onSubmit = (formValues) => {
    const companyResourceList = [];

    const newFormValues = { ...formValues };

    if (
      Array.isArray(formValues.companyResourceId) &&
      formValues.companyResourceId.length > 0
    ) {
      formValues.companyResourceId.forEach((resource) => {
        companyResourceList.push({
          id: resource.value,
        });
      });
    }

    newFormValues.companyResourceList = companyResourceList;

    dispatchPostCompanyResourceGroup(newFormValues);
  };

  const content = <CompanyResourceGroupForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Grupo de Recurso de Compañia"
      subtitle="nuevo grupo de recurso de compañia"
      content={content}
    />
  );
};

NewCompanyResourceGroup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostCompanyResourceGroup: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostCompanyResourceGroup: postCompanyResourceGroup,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupos de Recursos de Compañia',
      href: COMPANY_RESOURCE_GROUP_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCompanyResourceGroup);
