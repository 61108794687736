import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  generateChangeExpenseStatusEndpoint,
  generateExpenseStatusEndpoint,
  generateExpenseStatusRemarkEndpoint,
  generateRejectedExpenseByAgencyEnpoint,
  MASSIVE_EXPENSE_STATUS_ENDPOINT,
} from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import {
  FLAG_GETTING_EXPENSE_STATUS,
  GET_EXPENSE_STATUS,
  CLEAR_EXPENSE_STATUS,
  GET_EXPENSE_STATUS_REMARK,
  FLAG_EXPENSE_STATUS_REMARK_ACTIVITY,
  CLEAR_EXPENSE_STATUS_REMARK,
  GET_EXPENSE_REJECTED,
  CLEAR_EXPENSE_REJECTED,
} from '../types/sales';
import { EXPENSE_STATUS_PATH } from '../../config/paths';
import { flagExpenseActivity } from './Expense';

const flagGettingExpenseStatus = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_EXPENSE_STATUS,
    payload: flag,
  });

const flagExpenseStatusRemarkActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_EXPENSE_STATUS_REMARK_ACTIVITY,
    payload: flag,
  });

const getExpensesPerUser = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingExpenseStatus(true));
    const { expenseStatus } = tableFilters;
    const query = tableFilters;
    const url = `${generateExpenseStatusEndpoint(
      expenseStatus,
    )}?${QueryString.stringify(query)}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response, null, dispatch);
    const expenses = await response.json();
    dispatch({ type: GET_EXPENSE_STATUS, payload: expenses });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingExpenseStatus(false));
  }
};

const clearExpensePerUser = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXPENSE_STATUS,
  });

const getExpenseStatusRemark = async (expenseId) => async (dispatch) => {
  try {
    dispatch(flagExpenseStatusRemarkActivity(true));
    const url = generateExpenseStatusRemarkEndpoint(expenseId);
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response, null, dispatch);
    const remarks = await response.json();
    dispatch({ type: GET_EXPENSE_STATUS_REMARK, payload: remarks });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagExpenseStatusRemarkActivity(false));
  }
};

const clearExpenseStatusRemark = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXPENSE_STATUS_REMARK,
  });

const postExpenseStatusRemark =
  async ({ expenseId, remarks }) =>
  async (dispatch) => {
    try {
      dispatch(flagExpenseStatusRemarkActivity(true));
      const payload = {
        expenseId,
        remarks,
      };
      const url = generateExpenseStatusRemarkEndpoint(expenseId);
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(`${EXPENSE_STATUS_PATH}/${expenseId}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagExpenseStatusRemarkActivity(false));
    }
  };

const putExpenseStatus =
  async ({ expenseId, expenseStatus }) =>
  async (dispatch) => {
    try {
      dispatch(flagExpenseActivity(true));
      const payload = {
        expenseId,
        expenseStatus,
      };
      const url = generateChangeExpenseStatusEndpoint(expenseId, expenseStatus);
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(EXPENSE_STATUS_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagExpenseActivity(false));
    }
  };

const putMassiveExpenseStatus =
  async ({ expenseStatus, expenseIds, rejectedMotive }) =>
  async (dispatch) => {
    try {
      dispatch(flagExpenseActivity(true));

      const payload = {
        expenseStatus,
        expenseIds,
        rejectedMotive,
      };

      const url = MASSIVE_EXPENSE_STATUS_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);
      const responseJson = await response.json();
      return responseJson;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagExpenseActivity(false));
    }
  };

const getExpenseRejectedByAgency =
  async ({ agencyId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingExpenseStatus(true));

      const url = generateRejectedExpenseByAgencyEnpoint(agencyId);
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const expenseList = await response.json();
      dispatch({ type: GET_EXPENSE_REJECTED, payload: expenseList });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingExpenseStatus(false));
    }
  };

const clearExpenseRejectedByAgency = () => (dispatch) =>
  dispatch({
    type: CLEAR_EXPENSE_REJECTED,
  });

export {
  flagGettingExpenseStatus,
  getExpensesPerUser,
  clearExpensePerUser,
  getExpenseStatusRemark,
  clearExpenseStatusRemark,
  postExpenseStatusRemark,
  putExpenseStatus,
  putMassiveExpenseStatus,
  getExpenseRejectedByAgency,
  clearExpenseRejectedByAgency,
};
