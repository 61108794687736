import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { MAINTENANCE_PLANNING_PATH } from '../../../../config/paths';
import {
  clearMaintenancePlanning,
  getMaintenancePlanning,
  putMaintenancePlanning,
} from '../../../../actions/mechanical-maintenance';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import {
  MAINTENANCE_FACTOR_TYPE,
  MAINTENANCE_PLANNING_TYPE,
} from '../../../../config/constants';
import MaintenancePlanningForm from './MaintenancePlanningForm';

const EditMaintenancePlanning = ({
  breadcrumbs,
  dispatchGetMaintenancePlanning,
  dispatchClearMaintenancePlanning,
  dispatchPutMaintenancePlanning,
  match: {
    params: { id: maintenancePlanningId },
  },
  loading,
  maintenancePlanning,
}) => {
  useLayoutEffect(() => {
    dispatchGetMaintenancePlanning({ maintenancePlanningId });

    return () => dispatchClearMaintenancePlanning();
  }, []);

  const onSubmit = (formValues) => {
    const maintenancePlanningResourceList = [];

    if (
      Array.isArray(formValues.registeredBusId) &&
      formValues.registeredBusId.length > 0
    ) {
      formValues.registeredBusId.forEach((bus) => {
        maintenancePlanningResourceList.push({
          registeredBusId: bus.value,
          companyResourceId: null,
          maintenanceBusGroupId: null,
        });
      });
    } else if (
      Array.isArray(formValues.companyResourceId) &&
      formValues.companyResourceId.length > 0
    ) {
      formValues.companyResourceId.forEach((resource) => {
        maintenancePlanningResourceList.push({
          registeredBusId: null,
          companyResourceId: resource.value,
          maintenanceBusGroupId: null,
        });
      });
    } else if (
      Array.isArray(formValues.maintenanceBusGroupId) &&
      formValues.maintenanceBusGroupId.length > 0
    ) {
      formValues.maintenanceBusGroupId.forEach((maintenanceGroup) => {
        maintenancePlanningResourceList.push({
          registeredBusId: null,
          companyResourceId: null,
          maintenanceBusGroupId: maintenanceGroup.value,
        });
      });
    }

    const newFormValues = { ...formValues };
    newFormValues.planningType = formValues.planningType.value;
    newFormValues.factorType = formValues.factorType.value;
    newFormValues.companyAreaId = formValues.companyArea.value;
    newFormValues.maintenancePlanningActivityOrderList = Array.isArray(
      formValues.maintenancePlanningActivityOrderList,
    )
      ? formValues.maintenancePlanningActivityOrderList.map((activity) => ({
          activityId: activity.activity.value,
          factor: activity.factor,
          activityOrder: activity.activityOrder,
        }))
      : [];
    newFormValues.maintenancePlanningResourceList =
      maintenancePlanningResourceList;

    dispatchPutMaintenancePlanning(maintenancePlanningId, newFormValues);
  };

  const generateInitialValues = ({
    description = '',
    planningType = null,
    resetFactor = 0,
    percentageDeviation = 0,
    factorType = null,
    companyArea = null,
    maintenancePlanningActivityOrderList = [],
    maintenancePlanningResourceList = [],
  }) => {
    const createOption = (id, label) => ({ value: id, label });

    let maintenanceBusGroupId = null;
    let registeredBusId = null;
    let companyResourceId = null;

    if (Array.isArray(maintenancePlanningResourceList)) {
      const busGroup = maintenancePlanningResourceList.find(
        (resource) => resource.maintenanceBusGroup,
      );
      if (busGroup) {
        maintenanceBusGroupId = createOption(
          busGroup.maintenanceBusGroup.id,
          busGroup.maintenanceBusGroup.name,
        );
      }

      if (!maintenanceBusGroupId) {
        const bus = maintenancePlanningResourceList.find(
          (resource) => resource.registeredBus,
        );
        if (bus) {
          registeredBusId = createOption(
            bus.registeredBus.id,
            `${bus.registeredBus.companyBusId} - ${bus.registeredBus.licensePlate}`,
          );
        }
      }

      if (!maintenanceBusGroupId && !registeredBusId) {
        const companyResources = maintenancePlanningResourceList
          .filter((resource) => resource.companyResource)
          .map((resource) =>
            createOption(
              resource.companyResource.id,
              resource.companyResource.name,
            ),
          );

        companyResourceId =
          companyResources.length > 0 ? companyResources : null;
      }
    }

    return {
      description,
      planningType: planningType && MAINTENANCE_PLANNING_TYPE[planningType],
      resetFactor,
      percentageDeviation,
      factorType: factorType && MAINTENANCE_FACTOR_TYPE[factorType],
      companyArea:
        companyArea && createOption(companyArea.id, companyArea.name),
      maintenancePlanningActivityOrderList:
        maintenancePlanningActivityOrderList.map((activity) => ({
          id: activity.id,
          activity: createOption(
            activity.activity.id,
            `${activity.activity.companyActivityId} - ${activity.activity.name}`,
          ),
          factor: activity.factor,
          activityOrder: activity.activityOrder,
        })),
      maintenanceBusGroupId,
      registeredBusId,
      companyResourceId,
    };
  };

  let content;

  if (loading) content = <Loader />;
  else if (maintenancePlanning.isEmpty())
    content = <NoDataResource returnPage={MAINTENANCE_PLANNING_PATH} />;
  else
    content = (
      <MaintenancePlanningForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(maintenancePlanning.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Plan de Mantenimiento"
      subtitle="Editar plan de mantenimiento"
      content={content}
    />
  );
};

EditMaintenancePlanning.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearMaintenancePlanning: PropTypes.func.isRequired,
  dispatchGetMaintenancePlanning: PropTypes.func.isRequired,
  dispatchPutMaintenancePlanning: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  maintenancePlanning: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditMaintenancePlanning.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearMaintenancePlanning: clearMaintenancePlanning,
  dispatchGetMaintenancePlanning: getMaintenancePlanning,
  dispatchPutMaintenancePlanning: putMaintenancePlanning,
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Planes de Mantenimiento',
      href: MAINTENANCE_PLANNING_PATH,
    },
    {
      text: 'Ver',
      href: `${MAINTENANCE_PLANNING_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  maintenancePlanning: MechanicalMaintenanceUnit.MaintenancePlanning.getIn([
    'current',
    'content',
  ]),
  loading: !MechanicalMaintenanceUnit.MaintenancePlanning.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditMaintenancePlanning);
