import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import {
  clearCompanyResourceGroups,
  getCompanyResourceGroups,
} from '../../../../actions';

export const CompanyResourceGroupSelect = ({
  loading,
  options,
  isGridVariant,
  isMulti,
  isClearable,
  onValueChange,
  dispatchClearCompanyResourceGroups,
  dispatchGetCompanyResourceGroups,
  listAll,
  ...rest
}) => {
  useLayoutEffect(() => () => dispatchClearCompanyResourceGroups(), []);

  const onInputChange = (inputValue) => {
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      dispatchGetCompanyResourceGroups({ query: `name:${inputValue}` });
    }
  };

  const filterOption = (users) => users;

  return (
    <Select
      isLoading={loading}
      onInputChange={onInputChange}
      options={options}
      isMulti={isMulti}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      filterOption={filterOption}
      {...rest}
    />
  );
};

CompanyResourceGroupSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  dispatchGetCompanyResourceGroups: PropTypes.func.isRequired,
  dispatchClearCompanyResourceGroups: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  options: optionsPropTypes,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  listAll: PropTypes.bool,
};

CompanyResourceGroupSelect.defaultProps = {
  isGridVariant: false,
  isMulti: false,
  options: [],
  isClearable: false,
  onValueChange: undefined,
  placeholder: 'Busca por nombre de grupo',
  listAll: false,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  options: MechanicalMaintenanceUnit.CompanyResourceGroup.getIn([
    'all',
    'content',
    'content',
  ]).map(({ id, name }) => ({
    value: id,
    label: name,
  })),
  loading: MechanicalMaintenanceUnit.CompanyResourceGroup.getIn([
    'all',
    'loading',
  ]),
});

const mapDispatchToProps = {
  dispatchGetCompanyResourceGroups: getCompanyResourceGroups,
  dispatchClearCompanyResourceGroups: clearCompanyResourceGroups,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyResourceGroupSelect);
