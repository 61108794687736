import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Row,
  InputGroup,
  InputGroupAddon,
  Input,
} from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { Field } from 'redux-form';
import QRCode from 'qrcode';
import Loader from '../../../common/Loader';
import LogoCiva from '../../../../resources/images/logo-civa.png';

const NiubizLinkModal = ({ niubizResponse, isLoading }) => {
  const [qrCode, setQrCode] = useState(null);

  useEffect(() => {
    const generateQrCode = async () => {
      if (niubizResponse && niubizResponse.link) {
        try {
          const qrString = await QRCode.toDataURL(niubizResponse.link, {
            errorCorrectionLevel: 'H',
            type: 'image/jpeg',
            scale: 4,
          });
          setQrCode(qrString);
        } catch (error) {
          console.error('Error generating QR Code:', error);
        }
      }
    };
    generateQrCode();
  }, [niubizResponse]);

  const handleCopyToClipboard = () => {
    if (niubizResponse && niubizResponse.link) {
      navigator.clipboard.writeText(niubizResponse.link).then(() => {
        toastr.success('Enlace copiado al portapapeles');
      });
    }
  };

  return (
    <div>
      <Row className="text-center mb-4">
        <Col>
          <img src={LogoCiva} alt="QR Code" width={200} />
        </Col>
      </Row>
      {isLoading && <Loader />}
      {niubizResponse && (
        <div>
          <FormGroup>
            <InputGroup>
              <Field
                name="link"
                component={({ input }) => (
                  <Input
                    {...input}
                    type="text"
                    placeholder="link"
                    value={niubizResponse.link}
                    disabled
                  />
                )}
              />
              <InputGroupAddon addonType="append">
                <Button
                  type="button"
                  color="secondary"
                  onClick={handleCopyToClipboard}
                >
                  <i className="fa fa-clipboard" aria-hidden="true" /> Copiar
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          {qrCode && (
            <Row className="text-center mt-4">
              <Col>
                <img src={qrCode} alt="QR Code" className="img-fluid" />
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  );
};

NiubizLinkModal.propTypes = {
  niubizResponse: PropTypes.shape({
    link: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool,
};

NiubizLinkModal.defaultProps = {
  isLoading: true,
};

export default NiubizLinkModal;
