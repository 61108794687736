import React from 'react';
import { Form, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import FormFooter from '../../../common/forms/FormFooter';
import DriverSelect from '../../../common/forms/select/DriverSelect';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import DatePicker from '../../../common/forms/input/DatePicker';

const DriverTripHistoryForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Conductor" required>
        <Field
          name="driver"
          component={DriverSelect}
          label="Conductor"
          placeholder="Conductor"
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Fecha Inicio">
        <Field name="startDate" component={DatePicker} />
      </FormItem>
    </FormGroup>
    <FormGroup row>
      <FormItem label="Fecha Final">
        <Field name="endDate" component={DatePicker} />
      </FormItem>
    </FormGroup>
    <FormFooter saveButtonText="Buscar" saveButtonIcon="fa fa-search" />
  </Form>
);

DriverTripHistoryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const formComponent = reduxForm({
  form: 'DriverTripHistoryForm',
})(DriverTripHistoryForm);

export default connect(null, null)(formComponent);
