import { combineReducers } from 'redux';
import UnitHome from './UnitHome';
import ExpenseType from './ExpenseType';
import LiquidationDifferenceReason from './LiquidationDifferenceReason';
import PaymentMethod from './PaymentMethod';
import VoucherType from './VoucherType';
import CostCenter from './CostCenter';
import GeneralIncomeType from './GeneralIncomeType';
import ManualVoucher from './ManualVoucher';
import Billing from './Billing';
import Detraction from './Detraction';
import ExpenseAuthorizerUser from './ExpenseAuthorizerUser';

export default combineReducers({
  UnitHome,
  ExpenseType,
  LiquidationDifferenceReason,
  PaymentMethod,
  VoucherType,
  CostCenter,
  GeneralIncomeType,
  ManualVoucher,
  Billing,
  Detraction,
  ExpenseAuthorizerUser,
});
