import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Content from '../../../layout/Content';
import { ACTIVITY_PROJECTION_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { postPreventiveMaintenanceReport } from '../../../../actions';
import PreventiveMaintenanceReportSearchForm from '../preventive-maintenance-report/PreventiveMaintenanceReportSearchForm';
import ActivityProjectionSearchResult from '../activity-projection/ActivityProjectionSearchResult';
import { tzNormalizeDate } from '../../../../utils/date';
import { INT_DATE_FORMAT } from '../../../../config/locale';
import Alert from '../../../common/informative/Alert';
import { ENTER_DATA_TO_SHOW_DATA } from '../../../../config/messages';

const ActivityProjection = ({
  breadcrumbs,
  dispatchPostPreventiveMaintenanceReport,
  loading,
}) => {
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async (formValues) => {
    const newSearchValues = {
      registeredBusIdList:
        formValues.registeredBusIdList &&
        formValues.registeredBusIdList.length > 0
          ? formValues.registeredBusIdList.map((bus) => bus.value)
          : null,
      companyResourceIdList:
        formValues.companyResourceIdList &&
        formValues.companyResourceIdList.length > 0
          ? formValues.companyResourceIdList.map((resource) => resource.value)
          : null,
      homeBaseLocationId: formValues.homeBaseLocationId
        ? formValues.homeBaseLocationId.value
        : null,
      companyAreaId: formValues.companyAreaId && formValues.companyAreaId.value,

      daily: false,
      dateFrom: tzNormalizeDate({ date: formValues.dateFrom, time: 'start' }),
      dateTo: tzNormalizeDate({ date: formValues.dateTo, time: 'end' }),
      simulationDays: formValues.simulationDays || 0,
      simulationKilometers: formValues.simulationKilometers || 0,
      activityId: formValues.activityId && formValues.activityId.value,
      size: 100000,
    };
    setSubmitted(true);
    dispatchPostPreventiveMaintenanceReport(newSearchValues);
  };

  const today = tzNormalizeDate({ time: 'start', format: INT_DATE_FORMAT });
  const tomorrow = tzNormalizeDate({ time: 'end', format: INT_DATE_FORMAT });

  let table = <Alert type="info" message={ENTER_DATA_TO_SHOW_DATA} />;
  if (submitted) table = <ActivityProjectionSearchResult />;

  const content = (
    <>
      <PreventiveMaintenanceReportSearchForm
        onSubmit={onSubmit}
        initialValues={{
          dateFrom: today,
          dateTo: tomorrow,
        }}
        showBase
        showSimulation
        registeredBusIsMulti
        searchButtonDisabled={loading}
        disableDateTo
        showActivityField
        showCompanyResource
        showOrganizationChart
        isProjection
      />
      <div className="mt-3">{table}</div>
    </>
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Proyección de Actividades"
      content={content}
    />
  );
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Proyección de Actividades',
      href: ACTIVITY_PROJECTION_PATH,
    },
  ],
  loading: !MechanicalMaintenanceUnit.ExecutionRegistration.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

ActivityProjection.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostPreventiveMaintenanceReport: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  dispatchPostPreventiveMaintenanceReport: postPreventiveMaintenanceReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityProjection);
