import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import ManualVoucherEditButton from './ManualVoucherEditButton';
import PrintManualVoucherButton from './PrintManualVoucherButton';
import { manualVoucherDataPropTypes } from './proptypes/ManualVoucherPropTypes';
import { GENERAL_STATUS } from '../../../../config/constants';

const ManualVoucherToolbar = ({ manualVoucher }) => (
  <ButtonToolbar className="pull-right">
    <div>
      {manualVoucher.status === GENERAL_STATUS.APPROVED.value && (
        <PrintManualVoucherButton manualVoucher={manualVoucher} />
      )}{' '}
      {manualVoucher.status !== GENERAL_STATUS.REJECTED.value && (
        <ManualVoucherEditButton
          manualVoucherCode={manualVoucher.voucherType.voucherCode}
          manualVoucherStatus={manualVoucher.status}
          manualVoucherId={manualVoucher.id}
        />
      )}
    </div>
  </ButtonToolbar>
);

ManualVoucherToolbar.propTypes = {
  manualVoucher: PropTypes.shape(manualVoucherDataPropTypes).isRequired,
};

export default ManualVoucherToolbar;
