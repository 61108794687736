import React from 'react';
import PropTypes from 'prop-types';
import { change, Field, reduxForm } from 'redux-form';
import { Button, Form } from 'reactstrap';
import { connect } from 'react-redux';
import FormFooter from '../../../common/forms/FormFooter';
import { REJECTED_EXPENSE_OPTIONS } from '../../../../config/constants';
import Select from '../../../common/forms/select/Select';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';

const RejectedExpenseForm = ({ handleSubmit, handleProcess, onCancel }) => (
  <>
    <Form onSubmit={handleSubmit(handleProcess)}>
      <h3>Seleccione el Motivo del Rechazo de los Egresos</h3>
      <hr />
      <FormItem label="Motivo de Rechazo" required>
        <Field
          name="rejectedMotive"
          id="action"
          component={Select}
          options={REJECTED_EXPENSE_OPTIONS}
          validate={[isRequired]}
        />
      </FormItem>

      <FormFooter
        saveButtonColor="danger"
        saveButtonIcon="fa fa-exclamation-circle"
        saveButtonText="Rechazar"
      >
        <Button color="primary" onClick={onCancel}>
          Cancelar
        </Button>
      </FormFooter>
    </Form>
  </>
);

RejectedExpenseForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  onCancel: PropTypes.func.isRequired,
  handleProcess: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'RejectedExpenseForm',
})(RejectedExpenseForm);

export default connect(null, mapDispatchToProps)(formComponent);
