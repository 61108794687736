import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_DRIVERS,
  GET_DRIVERS,
  CLEAR_DRIVERS,
  FLAG_DRIVER_ACTIVITY,
  GET_DRIVER,
  CLEAR_DRIVER,
  GET_DRIVER_TRIPS,
  CLEAR_DRIVER_TRIPS,
} from '../types/traffic';
import {
  DRIVER_ENDPOINT,
  generateDriverTripsByDateRangeEndpoint,
  generateDriverTripsEndpoint,
  generateFreeDriversEndpoint,
} from '../../config/endpoints';
import {
  BASIC_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { DRIVER_PATH } from '../../config/paths';
import { DEFAULT_QUERY_GET_ALL } from '../../config/queries';

const flagGettingDrivers = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DRIVERS,
    payload: flag,
  });

const getDrivers = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingDrivers(true));
    const query = tableFilters;
    const url = `${DRIVER_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const drivers = await response.json();
    dispatch({
      type: GET_DRIVERS,
      payload: drivers,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingDrivers(false));
  }
};

const clearDrivers = () => (dispatch) =>
  dispatch({
    type: CLEAR_DRIVERS,
  });

const flagDriverActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_DRIVER_ACTIVITY,
    payload: flag,
  });

const getDriver =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagDriverActivity(true));
      const url = `${DRIVER_ENDPOINT}/${id}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const driver = await response.json();
      dispatch({
        type: GET_DRIVER,
        payload: driver,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDriverActivity(false));
    }
  };

const clearDriver = () => (dispatch) =>
  dispatch({
    type: CLEAR_DRIVER,
  });

const getFreeDrivers =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingDrivers(true));
      const url = `${generateFreeDriversEndpoint(
        itineraryId,
      )}?${QueryString.stringify(DEFAULT_QUERY_GET_ALL)}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response, null, dispatch);
      const drivers = await response.json();
      dispatch({
        type: GET_DRIVERS,
        payload: drivers,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingDrivers(false));
    }
  };

const postUploadDriverImage = async (driverId, file) => {
  try {
    const url = `${DRIVER_ENDPOINT}/${driverId}/image`;
    const formData = new FormData();
    formData.append('file', file);
    await fetch(url, {
      ...BASIC_POST_CONFIG,
      body: formData,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  }
};

const postDriver =
  async ({
    customerId,
    driverTypeId,
    homeBaseId,
    crewStatusId,
    driverDocumentList,
    driverContractList,
    file,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagDriverActivity(true));
      const payload = {
        customerId,
        driverTypeId,
        homeBaseId,
        crewStatusId,
        driverDocumentList,
        driverContractList,
      };
      const url = DRIVER_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const driver = await response.json();
      if (file) {
        await postUploadDriverImage(driver.id, file);
      }
      dispatch(push(`${DRIVER_PATH}/${driver.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDriverActivity(false));
    }
  };

const putDriver =
  async (
    driverId,
    {
      customerId,
      driverTypeId,
      homeBaseId,
      crewStatusId,
      driverDocumentList,
      driverContractList,
      file,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagDriverActivity(true));
      const payload = {
        customerId,
        driverTypeId,
        homeBaseId,
        crewStatusId,
        driverDocumentList,
        driverContractList,
        id: driverId,
      };
      const url = `${DRIVER_ENDPOINT}/${driverId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const driver = await response.json();
      if (file) {
        await postUploadDriverImage(driverId, file);
      }
      dispatch(push(`${DRIVER_PATH}/${driver.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDriverActivity(false));
    }
  };

const deleteDriver =
  async ({ driverId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDriverActivity(true));
      const url = `${DRIVER_ENDPOINT}/${driverId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      await response.json();
      dispatch(push(DRIVER_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDriverActivity(false));
    }
  };

const getDriverTrips =
  async ({ id }, tableFilters) =>
  async (dispatch) => {
    try {
      dispatch(flagDriverActivity(true));

      const query = tableFilters;
      const url = `${generateDriverTripsEndpoint(id)}?${QueryString.stringify(
        query,
      )}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const trips = await response.json();
      dispatch({
        type: GET_DRIVER_TRIPS,
        payload: trips,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDriverActivity(false));
    }
  };

const clearDriverTrips = () => (dispatch) =>
  dispatch({
    type: CLEAR_DRIVER_TRIPS,
  });

const getDriverTripsByDateRange =
  async (driverId, { startDate, endDate, ...params }) =>
  async (dispatch) => {
    try {
      dispatch(flagDriverActivity(true));
      let url = generateDriverTripsByDateRangeEndpoint(driverId);
      if (startDate) {
        url += `?startDate=${startDate}`;
      }
      if (endDate) {
        url += `${startDate ? '&' : '?'}endDate=${endDate}`;
      }

      if (params) {
        url += `${startDate || endDate ? '&' : '?'}${QueryString.stringify(
          params,
        )}`;
      }

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const trips = await response.json();
      dispatch({
        type: GET_DRIVER_TRIPS,
        payload: trips,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDriverActivity(false));
    }
  };

const clearDriverTripsByDateRange = () => (dispatch) =>
  dispatch({
    type: CLEAR_DRIVER_TRIPS,
  });

export {
  flagGettingDrivers,
  getDrivers,
  clearDrivers,
  getDriver,
  clearDriver,
  getFreeDrivers,
  postDriver,
  deleteDriver,
  putDriver,
  getDriverTrips,
  clearDriverTrips,
  getDriverTripsByDateRange,
  clearDriverTripsByDateRange,
};
