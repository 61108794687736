import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import PrintContingencySenderRemmisionGuiderButton from './PrintContingencySenderRemmisionGuiderButton';

const ManualSenderRemissionGuideToolbar = ({
  isFirstSubmit,
  contingencyRequest,
}) => (
  <ButtonToolbar className="pull-right">
    <div>
      {isFirstSubmit && (
        <>
          <PrintContingencySenderRemmisionGuiderButton
            contingencyRequest={contingencyRequest}
          />
        </>
      )}{' '}
    </div>
  </ButtonToolbar>
);

ManualSenderRemissionGuideToolbar.propTypes = {
  isFirstSubmit: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  contingencyRequest: PropTypes.object.isRequired,
};

export default ManualSenderRemissionGuideToolbar;
