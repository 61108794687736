import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import EditExpenseButton from './EditExpenseButton';
import { EXPENSE_STATUS } from '../../../../config/constants';

const ExpenseToolbar = ({ expenseId, isTripExpense, status }) => (
  <ButtonToolbar className="pull-right">
    <div>
      {status !== EXPENSE_STATUS.APPROVED.value && (
        <EditExpenseButton
          expenseId={expenseId}
          isTripExpense={isTripExpense}
        />
      )}{' '}
    </div>
  </ButtonToolbar>
);

ExpenseToolbar.propTypes = {
  expenseId: PropTypes.number.isRequired,
  isTripExpense: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default ExpenseToolbar;
