import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import CompanyResourceBasicInformationResourceProptypes from './CompanyResourceBasicInformationResourceProptypes';
import Checkbox from '../../../../common/forms/Checkbox';

const CompanyResourceBasicInformationResource = ({
  id,
  name,
  companyResourceType,
  active,
  description,
}) => (
  <>
    <ResourceProperty label="ID:">{id}</ResourceProperty>
    <ResourceProperty label="Nombre:">{name}</ResourceProperty>
    <ResourceProperty label="Descripción:">{description}</ResourceProperty>
    <ResourceProperty label="Tipo:">
      {companyResourceType.description}
    </ResourceProperty>
    <div>
      <Checkbox checked={active} /> Activo
    </div>
  </>
);

CompanyResourceBasicInformationResource.propTypes =
  CompanyResourceBasicInformationResourceProptypes;

export default CompanyResourceBasicInformationResource;
