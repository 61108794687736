import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { ITINERARY_SCHEDULE_PATH } from '../../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../../common/resource/proptypes/CommonPropTypes';
import ItineraryScheduleForm from './ItineraryScheduleForm';
import { INT_DATE_FORMAT, TIME_FORMAT } from '../../../../../config/locale';
import {
  ITINERARY_REPEAT_TYPE,
  ITINERARY_TYPE,
} from '../../../../../config/constants';
import {
  clearItinerarySchedule,
  getItinerarySchedule,
  putItinerarySchedule,
} from '../../../../../actions';
import Loader from '../../../../common/Loader';
import Alert from '../../../../common/informative/Alert';
import { TEMPLATE_WITHOUT_ITINERARY_ERROR_MESSAGE } from '../../../../../config/messages';
import NoDataResource from '../../../../common/resource/NoDataResource';
import Content from '../../../../layout/Content';
import { tzNormalizeDate } from '../../../../../utils/date';

export const EditItinerarySchedule = ({
  breadcrumbs,
  dispatchPutItinerarySchedule,
  dispatchGetItinerarySchedule,
  dispatchClearItinerarySchedule,
  match: {
    params: { id: itineraryScheduleId },
  },
  itinerary,
  loading,
}) => {
  const [itineraryScheduleVersion, setItineraryScheduleVersion] = useState(0);
  const [itineraryCode, setItineraryCode] = useState(null);
  const [stateStartDate, setStateStartDate] = useState(null);
  const [stateEndDate, setStateEndDate] = useState(null);
  const [departureTime, setDepartureTime] = useState(null);

  useLayoutEffect(() => {
    dispatchGetItinerarySchedule({ itineraryScheduleId });

    return () => {
      dispatchClearItinerarySchedule();
    };
  }, []);

  useEffect(() => {
    if (!itinerary.isEmpty() && itineraryScheduleVersion === 0) {
      setItineraryScheduleVersion(itinerary.get('itineraryScheduleVersion'));
      setItineraryCode(
        itinerary.get('itinerary')
          ? itinerary.get('itinerary').itineraryCode
          : null,
      );
      setStateStartDate(itinerary.get('startDate'));
      setStateEndDate(itinerary.get('endDate'));
      setDepartureTime(
        itinerary.get('itinerary')
          ? itinerary.get('itinerary').departureTime
          : null,
      );
    }
  }, [itinerary, itineraryScheduleVersion]);

  const onSubmit = (formValues) => {
    const { name, description, isMaciva, isActive } = formValues;

    const itineraryId = itinerary.get('itinerary').id;

    const currentDepartureTime = departureTime;
    const currentStartDate = stateStartDate;
    const currentEndDate = stateEndDate;

    let formattedDepartureTime = formValues.departureTime
      ? tzNormalizeDate({ date: formValues.departureTime, format: TIME_FORMAT })
      : tzNormalizeDate({ format: TIME_FORMAT });

    let formattedStartDate = formValues.startDate
      ? tzNormalizeDate({ date: formValues.startDate, format: INT_DATE_FORMAT })
      : tzNormalizeDate({ format: INT_DATE_FORMAT });

    let formattedEndDate = formValues.endDate
      ? tzNormalizeDate({ date: formValues.endDate, format: INT_DATE_FORMAT })
      : tzNormalizeDate({ format: INT_DATE_FORMAT });

    if (isMaciva) {
      formattedDepartureTime = tzNormalizeDate({
        date: currentDepartureTime,
        format: TIME_FORMAT,
      });
      formattedStartDate = tzNormalizeDate({
        date: currentStartDate,
        format: INT_DATE_FORMAT,
      });
      formattedEndDate = tzNormalizeDate({
        date: currentEndDate,
        format: INT_DATE_FORMAT,
      });
    }

    const startDate = tzNormalizeDate({
      date: `${formattedStartDate} ${formattedDepartureTime}`,
    });

    const endDate = tzNormalizeDate({
      date: `${formattedEndDate} ${formattedDepartureTime}`,
      addTime: { amount: 30, unit: 'seconds' },
    });

    let itinerarySchedulePricingProfiles = [];

    let itineraryPricingProfiles = [];

    if (formValues.pricingProfileId) {
      itinerarySchedulePricingProfiles = formValues.pricingProfileId.map(
        (pricingProfile) => ({
          pricingProfileId: pricingProfile.value,
          itineraryScheduleId,
        }),
      );

      itineraryPricingProfiles = formValues.pricingProfileId.map(
        (pricingProfile) => ({
          pricingProfileId: pricingProfile.value,
          itineraryId,
        }),
      );
    }

    const driverCommissionId = formValues.driverCommissionId
      ? formValues.driverCommissionId.value
      : null;

    const circuitId = formValues.circuitId ? formValues.circuitId.value : null;

    const serviceTypeId = formValues.serviceTypeId
      ? formValues.serviceTypeId.value
      : null;

    const cabinCrewCommissionId = formValues.cabinCrewCommissionId
      ? formValues.cabinCrewCommissionId.value
      : null;

    const createdFromApi = false;

    const newFormValues = {
      itineraryCode,
      itineraryScheduleVersion,
      name,
      description,
      itinerary: {
        routeId: formValues.routeId ? formValues.routeId.value : null,
        seatMapId: formValues.seatMapId ? formValues.seatMapId.value : null,
        type: formValues.type ? formValues.type.value : null,
        itineraryPricingProfiles,
        isMaciva,
        driverCommissionId,
        circuitId,
        serviceTypeId,
        createdFromApi,
        isActive,
        cabinCrewCommissionId,
        departureTime: startDate,
        departureDate: startDate,
        id: itineraryId,
      },
      repeatEvery: 1,
      repeatType: ITINERARY_REPEAT_TYPE.NO_REPEAT.value,
      driverCommissionId,
      circuitId,
      serviceTypeId,
      createdFromApi,
      isMaciva,
      itinerarySchedulePricingProfiles,
      cabinCrewCommissionId,
      startDate,
      departureTime: formattedDepartureTime,
      endDate,
      companyId: formValues.companyId ? formValues.companyId.value : null,
    };

    if (!isMaciva) {
      newFormValues.repeatEvery = formValues.repeatEvery || 1;
      newFormValues.repeatType = formValues.repeatType.value;
      newFormValues.dailyRepeat = null;
      newFormValues.weeklyRepeat = null;
      newFormValues.monthlyRepeat = null;
      newFormValues.yearlyRepeat = null;
      newFormValues.noRepeat = null;
      newFormValues.itineraryScheduleSegmentList =
        formValues.itineraryScheduleSegmentList;

      if (formValues.repeatType.value === ITINERARY_REPEAT_TYPE.NO_REPEAT.value)
        newFormValues.endDate = startDate;

      if (
        formValues.repeatType.value === ITINERARY_REPEAT_TYPE.DAILY_REPEAT.value
      )
        newFormValues.dailyRepeat = {};

      if (
        formValues.repeatType.value ===
        ITINERARY_REPEAT_TYPE.WEEKLY_REPEAT.value
      )
        newFormValues.weeklyRepeat = {
          monday: formValues.monday,
          tuesday: formValues.tuesday,
          wednesday: formValues.wednesday,
          thursday: formValues.thursday,
          friday: formValues.friday,
          saturday: formValues.saturday,
          sunday: formValues.sunday,
        };

      if (
        formValues.repeatType.value ===
        ITINERARY_REPEAT_TYPE.MONTHLY_REPEAT.value
      )
        newFormValues.monthlyRepeat = {
          repeatByDayOfMonth: !!formValues.repeatByDayOfMonth,
        };
    }

    dispatchPutItinerarySchedule(itineraryScheduleId, newFormValues);
  };

  const generateInitialValues = (itinerarySchedule) => {
    const routeId = {
      value: itinerarySchedule.itinerary.route.id,
      label: `${itinerarySchedule.itinerary.route.id} - ${itinerarySchedule.itinerary.route.name}`,
      routeSegmentList: itinerarySchedule.itinerary.route.routeSegmentList,
    };

    const serviceTypeId = {
      value: itinerarySchedule.itinerary.serviceType.id,
      label: itinerarySchedule.itinerary.serviceType.name,
    };

    const circuitId = {
      value: itinerarySchedule.itinerary.circuit.id,
      label: `${itinerarySchedule.itinerary.circuit.id} - ${itinerarySchedule.itinerary.circuit.name}`,
    };

    const type = {
      value: itinerarySchedule.itinerary.type,
      label:
        ITINERARY_TYPE[itinerarySchedule.itinerary.type.toLowerCase()].label,
    };

    const initialValues = {
      itineraryCode: itinerarySchedule.itineraryCode,
      itineraryScheduleVersion: itinerarySchedule.itineraryScheduleVersion,
      name: itinerarySchedule.name,
      description: itinerarySchedule.description,
      routeId,
      serviceTypeId,
      circuitId,
      type,
      isMaciva: itinerarySchedule.itinerary.isMaciva,
      active: itinerarySchedule.itinerary.active,
    };

    if (itinerarySchedule.itinerary.seatMap) {
      initialValues.seatMapId = {
        value: itinerarySchedule.itinerary.seatMap.id,
        label: itinerarySchedule.itinerary.seatMap.name,
      };
    }

    if (itinerarySchedule.itinerary.driverCommission) {
      initialValues.driverCommissionId = {
        value: itinerarySchedule.itinerary.driverCommission.id,
        label: `${itinerarySchedule.itinerary.driverCommission.id} - ${itinerarySchedule.itinerary.driverCommission.name}`,
      };
    }

    if (itinerarySchedule.itinerary.cabinCrewCommission) {
      initialValues.cabinCrewCommissionId = {
        value: itinerarySchedule.itinerary.cabinCrewCommission.id,
        label: itinerarySchedule.itinerary.cabinCrewCommission.name,
      };
    }

    if (!itinerarySchedule.itinerary.isMaciva) {
      initialValues.pricingProfileId =
        itinerarySchedule.itinerarySchedulePricingProfiles.map(
          ({ pricingProfile, pricingProfileId }) => ({
            value: pricingProfileId,
            label: `${pricingProfileId} - ${pricingProfile.name}`,
          }),
        );

      if (itinerarySchedule.repeatType) {
        initialValues.repeatType =
          ITINERARY_REPEAT_TYPE[itinerarySchedule.repeatType];
      }

      if (itinerarySchedule.company) {
        initialValues.companyId = {
          value: itinerarySchedule.company.id,
          label: itinerarySchedule.company.name,
        };
      }

      initialValues.departureTime = tzNormalizeDate({
        date: itinerarySchedule.itinerary.departureTime,
      });

      initialValues.startDate = tzNormalizeDate({
        date: itinerarySchedule.startDate,
      });

      const { endDate } = itinerarySchedule;

      initialValues.endDate = tzNormalizeDate({ date: endDate });
      initialValues.repeatEvery = itinerarySchedule.repeatEvery;

      initialValues.routeSegmentList = routeId.routeSegmentList;

      const durationAdjustmentList = [];

      const layoverList = [];

      itinerarySchedule.itineraryScheduleSegmentList.forEach(
        (
          { segmentId, segmentAdjustment, segment: { duration }, startOffset },
          index,
          array,
        ) => {
          durationAdjustmentList[segmentId] = segmentAdjustment;

          if (array[index + 1]) {
            const { startOffset: nextStartOffset } = array[index + 1];

            const currentLayover =
              nextStartOffset - segmentAdjustment - duration - startOffset;

            layoverList[segmentId] = currentLayover;
          }
        },
      );

      initialValues.durationAdjustmentList = durationAdjustmentList;

      initialValues.layoverList = layoverList;
    }

    return initialValues;
  };

  let content = null;

  if (loading) {
    content = <Loader />;
  } else if (itinerary.isEmpty()) {
    content = <NoDataResource returnPage={ITINERARY_SCHEDULE_PATH} />;
  } else {
    content = (
      <Alert message={TEMPLATE_WITHOUT_ITINERARY_ERROR_MESSAGE} type="danger" />
    );

    if (itinerary.get('itinerary')) {
      content = (
        <ItineraryScheduleForm
          onSubmit={onSubmit}
          initialValues={generateInitialValues(itinerary.toJS())}
        />
      );
    }
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Plantilla de Itinerario"
      subtitle="Editar plantilla de itinerario"
      content={content}
    />
  );
};

const mapStateToProps = (
  { ItineraryUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Plantillas de Itinerario',
      href: ITINERARY_SCHEDULE_PATH,
    },
    {
      text: 'Ver',
      href: `${ITINERARY_SCHEDULE_PATH}/${id}`,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
  itinerary: ItineraryUnit.ItinerarySchedule.getIn(['current', 'content']),
  loading: !ItineraryUnit.ItinerarySchedule.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutItinerarySchedule: putItinerarySchedule,
  dispatchGetItinerarySchedule: getItinerarySchedule,
  dispatchClearItinerarySchedule: clearItinerarySchedule,
};

EditItinerarySchedule.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutItinerarySchedule: PropTypes.func.isRequired,
  dispatchGetItinerarySchedule: PropTypes.func.isRequired,
  dispatchClearItinerarySchedule: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  itinerary: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditItinerarySchedule);
