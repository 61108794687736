import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import FormItem from '../../../common/forms/FormItem';
import Select from '../../../common/forms/select/Select';
import { getLocationsForItinerarySearchOmission } from '../../../../actions/itinerary/LocationForItinerarySearchOmission';
import { isRequired } from '../../../../utils/validators';
import Loader from '../../../common/Loader';
import FormFooter from '../../../common/forms/FormFooter';
import ItinerarySearchInput from '../../../common/forms/input/ItinerarySearchInput';

const ItinerarySearchOmissionForm = ({
  onSubmit,
  handleSubmit,
  dispatchGetLocationsForItinerarySearchOmission,
  locationForItinerarySearchOmissionOptions,
  loading,
}) => {
  useLayoutEffect(() => {
    dispatchGetLocationsForItinerarySearchOmission();
  }, []);

  const [itineraryIdList, setItineraryIdList] = useState([]);

  const handleSelectItinerary = (itineraryIds) => {
    setItineraryIdList(itineraryIds);
  };

  const onHandleSubmit = (formValues) => {
    const newFormValues = { ...formValues, itineraryIdList };
    onSubmit(newFormValues);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Form onSubmit={handleSubmit(onHandleSubmit)}>
      <FormGroup row>
        <FormItem label="Ubicación Origen: " required>
          <Field
            name="sourceLocationId"
            component={Select}
            type="text"
            placeholder="Ubicación Origen"
            options={locationForItinerarySearchOmissionOptions}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Ubicación Destino: " required>
          <Field
            name="destinationLocationId"
            component={Select}
            type="text"
            placeholder="Ubicación Destino"
            options={locationForItinerarySearchOmissionOptions}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Itinerarios: ">
          <Field
            name="itineraries"
            handleSelectItinerary={({ itineraryIds }) =>
              handleSelectItinerary(itineraryIds)
            }
            component={ItinerarySearchInput}
            placeholder="Itinerarios"
            props={{
              form: 'ItinerarySearchOmissionForm',
            }}
            isMulti
          />
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

ItinerarySearchOmissionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatchGetLocationsForItinerarySearchOmission: PropTypes.func.isRequired,
  locationForItinerarySearchOmissionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool,
};

ItinerarySearchOmissionForm.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ ItineraryUnit }) => {
  const locationForItinerarySearchOmissionOptions =
    ItineraryUnit.LocationForItinerarySearchOmission.getIn(['all', 'content']);

  const options = [];
  locationForItinerarySearchOmissionOptions.forEach((location) => {
    options.push({
      value: location.id,
      label: `${location.id} - ${location.name}`,
    });
  });

  return {
    locationForItinerarySearchOmissionOptions: options,
    loading: !ItineraryUnit.ItinerarySearchOmission.getIn([
      'current',
      'activity',
    ]).isEmpty(),
  };
};

const mapDispatchToProps = {
  dispatchGetLocationsForItinerarySearchOmission:
    getLocationsForItinerarySearchOmission,
};

const formComponent = reduxForm({ form: 'ItinerarySearchOmissionForm' })(
  ItinerarySearchOmissionForm,
);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
