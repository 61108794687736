import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import { getExpenseTypes, clearExpenseTypes } from '../../../../actions';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';

const ExpenseTypeSelect = ({
  dispatchGetExpenseTypes,
  dispatchClearExpenseTypes,
  options,
  isLoading,
  isMulti,
  input,
  meta,
  ...rest
}) => {
  useLayoutEffect(() => {
    dispatchGetExpenseTypes({ ...DEFAULT_QUERY_GET_ALL });

    return () => dispatchClearExpenseTypes();
  }, []);

  return (
    <Select
      options={options}
      isLoading={isLoading}
      isMulti={isMulti}
      input={input}
      meta={meta}
      {...rest}
    />
  );
};

ExpenseTypeSelect.propTypes = {
  dispatchGetExpenseTypes: PropTypes.func.isRequired,
  dispatchClearExpenseTypes: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
};

ExpenseTypeSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isLoading: false,
  isMulti: false,
  options: [],
};

const mapStateToProps = ({ AccountingUnit }) => ({
  options: AccountingUnit.ExpenseType.getIn(['all', 'content', 'content']).map(
    (expenseType) => {
      let costCenter = null;

      if (expenseType.costCenter) {
        costCenter = {
          value: expenseType.costCenter.id,
          label: expenseType.costCenter.description,
        };
      }

      return {
        value: expenseType.id,
        label: expenseType.name,
        createDate: expenseType.createDate,
        description: expenseType.description,
        expenseCode: expenseType.expenseCode,
        expenseTypeLabel: expenseType.expenseTypeLabel,
        lastUpdate: expenseType.lastUpdate,
        isBasicService: expenseType.basicService,
        costCenter,
      };
    },
  ),
  loading: AccountingUnit.ExpenseType.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetExpenseTypes: getExpenseTypes,
  dispatchClearExpenseTypes: clearExpenseTypes,
};

export default connect(mapStateToProps, mapDispatchToprops)(ExpenseTypeSelect);
