import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { COMPANY_RESOURCE_GROUP_PATH } from '../../../../config/paths';
import {
  clearCompanyResourceGroup,
  getCompanyResourceGroup,
  putCompanyResourceGroup,
} from '../../../../actions/mechanical-maintenance';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Loader from '../../../common/Loader';
import NoDataResource from '../../../common/resource/NoDataResource';
import CompanyResourceGroupForm from './CompanyResourceGroupForm';

const EditCompanyResourceGroup = ({
  breadcrumbs,
  dispatchGetCompanyResourceGroup,
  dispatchClearCompanyResourceGroup,
  dispatchPutCompanyResourceGroup,
  match: {
    params: { id: companyResourceGroupId },
  },
  loading,
  companyResourceGroup,
}) => {
  useLayoutEffect(() => {
    dispatchGetCompanyResourceGroup({ companyResourceGroupId });

    return () => dispatchClearCompanyResourceGroup();
  }, []);

  const onSubmit = (formValues) => {
    const companyResourceList = [];

    const newFormValues = { ...formValues };

    if (
      Array.isArray(formValues.companyResourceId) &&
      formValues.companyResourceId.length > 0
    ) {
      formValues.companyResourceId.forEach((resource) => {
        companyResourceList.push({
          id: resource.value,
        });
      });
    }

    newFormValues.companyResourceList = companyResourceList;

    dispatchPutCompanyResourceGroup(companyResourceGroupId, newFormValues);
  };

  const generateInitialValues = ({
    name,
    description,
    companyResourceList,
  }) => ({
    name,
    description,
    companyResourceId:
      companyResourceList.length !== 0
        ? companyResourceList.map((resource) => ({
            value: resource.id,
            label: resource.name,
          }))
        : null,
  });

  let content;

  if (loading) content = <Loader />;
  else if (companyResourceGroup.isEmpty())
    content = <NoDataResource returnPage={COMPANY_RESOURCE_GROUP_PATH} />;
  else
    content = (
      <CompanyResourceGroupForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(companyResourceGroup.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Grupo de Recursos de Compañia"
      subtitle="Editar grupo de recursos de compañia"
      content={content}
    />
  );
};

EditCompanyResourceGroup.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearCompanyResourceGroup: PropTypes.func.isRequired,
  dispatchGetCompanyResourceGroup: PropTypes.func.isRequired,
  dispatchPutCompanyResourceGroup: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  companyResourceGroup: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditCompanyResourceGroup.defaultProps = {
  loading: false,
};

const mapDispatchToProps = {
  dispatchClearCompanyResourceGroup: clearCompanyResourceGroup,
  dispatchGetCompanyResourceGroup: getCompanyResourceGroup,
  dispatchPutCompanyResourceGroup: putCompanyResourceGroup,
};

const mapStateToProps = (
  { MechanicalMaintenanceUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupos de Recursos de Compañia',
      href: COMPANY_RESOURCE_GROUP_PATH,
    },
    {
      text: 'Ver',
      href: `${COMPANY_RESOURCE_GROUP_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  companyResourceGroup: MechanicalMaintenanceUnit.CompanyResourceGroup.getIn([
    'current',
    'content',
  ]),
  loading: !MechanicalMaintenanceUnit.CompanyResourceGroup.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditCompanyResourceGroup);
