import React from 'react';
import PropTypes, { object } from 'prop-types';
import Table from '../../../common/Table';
import { REJECTED_EXPENSE_COLUMNS } from '../../../../config/columns';
import { filterMethodCaseInsensitive } from '../../../../utils/filter-methods';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';

const RejectedExpenseSummary = ({ rejectedExpenses }) => {
  const totalRejected = rejectedExpenses.length;

  return (
    <div>
      <h2>{`Número de Egresos Rechazados: ${totalRejected}`}</h2>
      <hr />
      <Table
        columns={REJECTED_EXPENSE_COLUMNS}
        data={rejectedExpenses}
        filterable
        defaultPageSize={
          totalRejected < DEFAULT_PAGE_SIZE ? totalRejected : DEFAULT_PAGE_SIZE
        }
        defaultFilterMethod={filterMethodCaseInsensitive}
      />
    </div>
  );
};

RejectedExpenseSummary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rejectedExpenses: PropTypes.arrayOf(object).isRequired,
};

export default RejectedExpenseSummary;
