import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DRIVER_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import DriverForm from './DriverForm';
import Loader from '../../../common/Loader';
import { clearDriver, getDriver, putDriver } from '../../../../actions';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';
import { tzNormalizeDate } from '../../../../utils/date';

export class EditDriver extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      dispatchGetDriver,
    } = this.props;
    dispatchGetDriver({ id });
  }

  componentWillUnmount() {
    this.props.dispatchClearDriver();
  }

  onSubmit = (formValues) => {
    const newFormValues = {
      file: formValues.file || null,
      customerId: formValues.customerId.value,
      driverTypeId: formValues.driverTypeId.value,
      homeBaseId: formValues.homeBaseId.value,
      crewStatusId: formValues.crewStatusId.value,
      driverDocumentList: formValues.driverDocumentList
        ? formValues.driverDocumentList.map(
            ({
              driverDocumentTypeId,
              documentNumber,
              effectiveDate,
              expirationDate,
              note,
            }) => ({
              driverDocumentTypeId: driverDocumentTypeId.value,
              documentNumber,
              effectiveDate: tzNormalizeDate({
                date: effectiveDate,
                format: TIMESTAMP_FORMAT,
              }),
              expirationDate: tzNormalizeDate({
                date: expirationDate,
                format: TIMESTAMP_FORMAT,
              }),
              note,
            }),
          )
        : [],
      driverContractList: formValues.driverContractList
        ? formValues.driverContractList.map(
            ({
              driverContractTypeId,
              effectiveDate,
              expirationDate,
              reason,
              note,
            }) => ({
              driverContractTypeId: driverContractTypeId.value,
              effectiveDate: tzNormalizeDate({
                date: effectiveDate,
                format: TIMESTAMP_FORMAT,
              }),
              expirationDate: expirationDate
                ? tzNormalizeDate({
                    date: expirationDate,
                    format: TIMESTAMP_FORMAT,
                  })
                : null,
              reason,
              note,
            }),
          )
        : [],
    };

    const {
      dispatchPutDriver,
      match: {
        params: { id },
      },
    } = this.props;
    dispatchPutDriver(id, newFormValues);
  };

  generateInitialValues = (driver) => ({
    customerId: {
      value: driver.customerId,
      label: driver.customer.fullName,
    },
    driverDocumentList: driver.driverDocumentList.map(
      ({
        driverDocumentTypeId,
        driverDocumentType,
        effectiveDate,
        expirationDate,
        documentNumber,
        note,
      }) => ({
        driverDocumentTypeId: {
          value: driverDocumentTypeId,
          label: driverDocumentType.name,
        },
        documentNumber,
        effectiveDate,
        expirationDate,
        note,
      }),
    ),
    driverTypeId: {
      value: driver.driverTypeId,
      label: driver.driverType.name,
    },
    homeBaseId: {
      value: driver.homeBaseId,
      label: driver.homeBase.name,
    },
    crewStatusId: {
      value: driver.crewStatusId,
      label: driver.crewStatus.name,
    },
    driverContractList: driver.driverContractList.map(
      ({
        driverContractTypeId,
        driverContractType,
        effectiveDate,
        expirationDate,
        reason,
        note,
      }) => ({
        driverContractTypeId: {
          value: driverContractTypeId,
          label: driverContractType.name,
        },
        reason,
        effectiveDate,
        expirationDate,
        note,
      }),
    ),
  });

  render() {
    const { breadcrumbs, driver, loading } = this.props;

    let content;

    if (loading) {
      content = <Loader />;
    } else if (driver.isEmpty()) {
      content = <NoDataResource returnPage={DRIVER_PATH} />;
    } else {
      content = (
        <DriverForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(driver.toJS())}
          imageUrl={driver.get('imageUrl')}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Conductor"
        subtitle="Editar conductor"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { TrafficUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Conductores',
      href: DRIVER_PATH,
    },
    {
      text: 'Ver',
      href: `${DRIVER_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  driver: TrafficUnit.Driver.getIn(['current', 'content']),
  loading: !TrafficUnit.Driver.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutDriver: putDriver,
  dispatchGetDriver: getDriver,
  dispatchClearDriver: clearDriver,
};

EditDriver.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutDriver: PropTypes.func.isRequired,
  dispatchGetDriver: PropTypes.func.isRequired,
  dispatchClearDriver: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  driver: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditDriver.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDriver);
