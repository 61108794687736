import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { clearManualVouchers, getManualVouchers } from '../../../../actions';
import {
  MANUAL_VOUCHER_PATH,
  NEW_MANUAL_VOUCHER_CREDIT_NOTE_PATH,
  NEW_MANUAL_VOUCHER_PATH,
} from '../../../../config/paths';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import {
  generateCreditNoteManualVoucherColumns,
  generateManualVoucherColumns,
} from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Table from '../../../common/Table';

export const ManualVouchers = ({
  breadcrumbs,
  vouchers,
  loading,
  dispatchGetManualVouchers,
  dispatchClearManualVouchers,
  showOnModal,
  getRowData,
  byBusiness,
}) => {
  const [showCreditNotes, setShowCreditNotes] = useState(false);
  const [query, setQuery] = useState('voucherTypeId!:83');

  useLayoutEffect(
    () => () => {
      dispatchClearManualVouchers();
    },
    [],
  );

  const onClickShowCreditNotes = () => {
    let newQuery;
    if (query !== 'voucherTypeId!:83') {
      newQuery = 'voucherTypeId!:83';
      setQuery(newQuery);
    } else {
      newQuery = 'voucherTypeId:83';
      setQuery(newQuery);
    }
    setShowCreditNotes(!showCreditNotes);
    dispatchGetManualVouchers({ query: newQuery, sort: 'id,desc' });
  };

  const onFetchData = (data) => {
    const newData = { ...data };

    // TODO remove this hack when ModuleList implements multiple filter on a same column
    const businessTaxIdFound =
      newData.query &&
      newData.query.length &&
      newData.query.filter(
        (column) =>
          column.substring(0, column.indexOf(':')) === 'business.businessTaxId',
      );

    if (businessTaxIdFound && businessTaxIdFound[0]) {
      const businessTaxIdValue = businessTaxIdFound[0].substring(
        businessTaxIdFound[0].indexOf(':') + 1,
      );
      const businessTaxIdIndex = newData.query.indexOf(businessTaxIdFound[0]);
      const column =
        businessTaxIdValue.length === 8
          ? 'customer.idDocumentNumber'
          : 'business.businessTaxId';
      newData.query[businessTaxIdIndex] = `${column}:${businessTaxIdValue}`;
    }

    if (byBusiness)
      newData.query = [
        ...newData.query,
        `business.businessTaxId:${byBusiness}`,
        `paid:false`,
      ];

    dispatchGetManualVouchers(newData);
  };

  const data = vouchers.get('content') || [];
  const pages = vouchers.get('totalPages') || null;
  const defaultPageSize = vouchers.get('size') || DEFAULT_PAGE_SIZE;

  const tableProps = {
    data,
    pages,
    defaultPageSize,
    filterable: true,
    fetchData: onFetchData,
    loading,
    navigateToModelOnRowClick: true,
  };

  if (showOnModal)
    return (
      <Table
        {...tableProps}
        columns={generateManualVoucherColumns(false)}
        getRowData={getRowData}
        highlightSelectedRow
      />
    );

  return (
    <ModuleList
      title={showCreditNotes ? 'Notas de Créditos' : 'Comprobantes Manuales'}
      breadcrumbs={breadcrumbs}
      buttonPath={
        showCreditNotes
          ? NEW_MANUAL_VOUCHER_CREDIT_NOTE_PATH
          : NEW_MANUAL_VOUCHER_PATH
      }
      buttonText={
        showCreditNotes ? 'Crear nota de crédito' : 'Crear comprobante manual'
      }
      extraButtons={
        <Button
          type="button"
          color="success"
          className="mr-2"
          onClick={onClickShowCreditNotes}
        >
          {showCreditNotes
            ? 'Mostrar boletas/facturas'
            : 'Mostrar notas de crédito'}
        </Button>
      }
      tableStructure={{
        ...tableProps,
        modelPath: MANUAL_VOUCHER_PATH,
        columns: showCreditNotes
          ? generateCreditNoteManualVoucherColumns()
          : generateManualVoucherColumns(true),
        queryProps: query,
      }}
    />
  );
};

ManualVouchers.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  vouchers: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetManualVouchers: PropTypes.func.isRequired,
  dispatchClearManualVouchers: PropTypes.func.isRequired,
  showOnModal: PropTypes.bool,
  getRowData: PropTypes.func,
  byBusiness: PropTypes.string,
};

ManualVouchers.defaultProps = {
  vouchers: null,
  loading: false,
  showOnModal: false,
  getRowData: null,
  byBusiness: null,
};

const mapStateToProps = ({ AccountingUnit }) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Comprobantes Manuales',
      href: MANUAL_VOUCHER_PATH,
    },
  ],
  vouchers: AccountingUnit.ManualVoucher.getIn(['all', 'content']),
  loading: AccountingUnit.ManualVoucher.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetManualVouchers: getManualVouchers,
  dispatchClearManualVouchers: clearManualVouchers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualVouchers);
