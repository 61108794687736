import React, { useEffect, useLayoutEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form, Button, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import FormItem from '../../../common/forms/FormItem';
import Select from '../../../common/forms/select/Select';
import {
  getCustomerEmails,
  clearCustomerEmails,
  getCustomerDeliveryAddresses,
  clearCustomerDeliveryAddresses,
  getPaymentMethods,
  getVoucherTypes,
  postCustomerEmail,
  postCustomerDeliveryAddress,
  clearCreditNotes,
  postPostpaidBookingPretransactionV2,
} from '../../../../actions/';
import { isRequired, validateEmail } from '../../../../utils/validators';
import CreatableSelect from '../../../common/forms/select/CreatableSelect';
import {
  TRANSACTION_TYPE_PAGO_EFECTIVO,
  TRANSACTION_TYPE_DELIVERY_CREDIT_CARD,
  TRANSACTION_TYPE_DELIVERY_CASH,
  EMAIL_PATTERN,
  TRANSACTION_TYPE_NIUBIZ,
  NIUBIZ_DOCUMENT_TYPE,
  CIP_DOCUMENT_TYPE,
  CREDIT_NOTE,
} from '../../../../config/constants';
import VoucherTypeInputGroup from '../../../common/forms/VoucherTypeInputGroup';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import PagoEfectivoFields from './PagoEfectivoFields';
import NiubizFields from './NiubizFields';
import TextInput from '../../../common/forms/input/TextInput';
import PaymentMethodSelect from '../../../common/forms/select/PaymentMethodSelect';
import CreditNoteSelect from '../../../common/forms/select/CreditNoteSelect';
import { passengersPropType } from './ReceiverPropTypes';
import { numberFormatter } from '../../../../utils/number';

const formatCreateLabel = (inputValue) => <span>Registrar {inputValue}</span>;

const isValidEmail = (optionValue) => {
  const email = optionValue.label;
  return validateEmail(email);
};

const isValidNiubizLink = (value) =>
  value || value === '0' || value === 0
    ? undefined
    : 'Generar el link es requerido';

const selector = formValueSelector('ReceiverForm');

const ReceiverForm = ({
  change,
  emails,
  totalPrice,
  passengers,
  submitting,
  creditNotes,
  handleSubmit,
  isEmailLoading,
  customerOrderId,
  selectedCustomer,
  deliveryAddresses,
  isPaymentMethodLoading,
  dispatchGetVoucherTypes,
  dispatchClearCreditNotes,
  isDeliveryAddressLoading,
  dispatchGetPaymentMethods,
  dispatchGetCustomerEmails,
  dispatchClearCustomerEmails,
  dispatchGetCustomerDeliveryAddresses,
  dispatchClearCustomerDeliveryAddresses,
  dispatchPostPostpaidBookingPretransactionV2,
}) => {
  useLayoutEffect(() => {
    dispatchGetPaymentMethods();
    dispatchGetVoucherTypes();

    return () => {
      dispatchClearCreditNotes();
    };
  }, []);

  const [showVoucherCode, setShowVoucherCode] = useState(false);
  const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [showCreditNoteSelect, setShowCreditNoteSelect] = useState(false);
  const [remainingPrice, setRemainingPrice] = useState(totalPrice);

  useEffect(() => {
    if (creditNotes.length > 0) {
      setRemainingPrice(
        totalPrice -
          creditNotes.reduce(
            (accumulated, creditNote) => accumulated + creditNote.amount,
            0,
          ),
      );
    }
  }, [creditNotes]);

  const handleChangeEmail = (option) =>
    change('userEmail', option ? option.label : null);

  const handleCustomerChange = ({
    value: customerId,
    firstName,
    lastName,
    email,
    idDocumentNumber,
    mobilePhone,
  }) => {
    dispatchClearCustomerEmails();
    dispatchClearCustomerDeliveryAddresses();

    change('userName', null);
    change('userLastName', null);
    change('userEmail', null);
    change('userDocumentNumber', null);
    change('userPhone', null);

    if (customerId) {
      dispatchGetCustomerEmails({ customerId });
      dispatchGetCustomerDeliveryAddresses({ customerId });
      change('userName', firstName);
      change('userLastName', lastName);
      change('userEmail', email);
      change('userDocumentNumber', idDocumentNumber);
      change('userPhone', mobilePhone);
    }
  };

  const isValidNewEmail = (inputValue, selectValue, selectOptions) => {
    const existsInOptions = selectOptions.find(
      (option) => option.label === inputValue,
    );

    const isInputEmpty = inputValue.trim().length === 0;

    const validEmail = EMAIL_PATTERN.test(inputValue);

    return !isInputEmpty && !existsInOptions && validEmail && selectedCustomer;
  };

  const handleNoEmails = () => {
    if (!selectedCustomer)
      return <p className="text-danger">Debe seleccionar un destinatario</p>;

    return <p>Ingrese un email válido para ser registrado</p>;
  };

  const handleCreateEmail = async (inputValue) => {
    const newCustomerEmail = await postCustomerEmail({
      customerId: selectedCustomer.value,
      email: inputValue,
    });

    dispatchGetCustomerEmails({
      customerId: selectedCustomer.value,
    });

    change('email', {
      value: newCustomerEmail.id,
      label: newCustomerEmail.email,
    });
    change('userEmail', newCustomerEmail.email);
  };

  const handlePaymentMethodChange = (selectedPaymentMethod) => {
    setShowVoucherCode(
      selectedPaymentMethod.value &&
        selectedPaymentMethod.transactionCode ===
          TRANSACTION_TYPE_PAGO_EFECTIVO,
    );

    setPaymentMethod(selectedPaymentMethod.transactionCode);

    if (
      selectedPaymentMethod.transactionCode === TRANSACTION_TYPE_PAGO_EFECTIVO
    ) {
      change('userDocumentType', CIP_DOCUMENT_TYPE.DNI);
    }

    if (selectedPaymentMethod.transactionCode === TRANSACTION_TYPE_NIUBIZ) {
      change('userDocumentType', NIUBIZ_DOCUMENT_TYPE.DNI);
    }

    setShowDeliveryAddress(
      selectedPaymentMethod.value &&
        [
          TRANSACTION_TYPE_DELIVERY_CASH,
          TRANSACTION_TYPE_DELIVERY_CREDIT_CARD,
        ].includes(selectedPaymentMethod.transactionCode),
    );
    setShowCreditNoteSelect(
      selectedPaymentMethod.value &&
        selectedPaymentMethod.transactionCode === CREDIT_NOTE,
    );

    if (showVoucherCode) change('voucherCode', '');

    if (showDeliveryAddress) change('deliveryAddress', null);
  };

  const handleNextPaymentMethodChange = (selectedPaymentMethod) => {
    setShowVoucherCode(
      selectedPaymentMethod.value &&
        selectedPaymentMethod.transactionCode ===
          TRANSACTION_TYPE_PAGO_EFECTIVO,
    );
    setShowDeliveryAddress(
      selectedPaymentMethod.value &&
        [
          TRANSACTION_TYPE_DELIVERY_CASH,
          TRANSACTION_TYPE_DELIVERY_CREDIT_CARD,
        ].includes(selectedPaymentMethod.transactionCode),
    );

    if (showVoucherCode) change('voucherCode', '');

    if (showDeliveryAddress) change('deliveryAddress', null);
  };

  const isValidNewDeliveryAddress = (inputValue, selectOptions) => {
    const existsInOptions = selectOptions.find(
      (option) => option.label === inputValue,
    );

    const isInputEmpty = inputValue.trim().length === 0;

    return !isInputEmpty && !existsInOptions && selectedCustomer;
  };

  const handleNoDeliveryAddress = () => {
    if (!selectedCustomer)
      return <p className="text-danger">Debe seleccionar un destinatario</p>;

    return (
      <p>No hay direcciones registradas, ingrese una para ser registrada</p>
    );
  };

  const handleCreateDeliveryAddress = async (inputValue) => {
    const newCustomerDeliveryAddress = await postCustomerDeliveryAddress({
      customerId: selectedCustomer.value,
      deliveryAddress: inputValue,
    });

    dispatchGetCustomerDeliveryAddresses({
      customerId: selectedCustomer.value,
    });

    change('deliveryAddress', {
      value: newCustomerDeliveryAddress.id,
      label: newCustomerDeliveryAddress.deliveryAddress,
    });
  };

  const handleReceiverSubmit = (formValues) => {
    if (showCreditNoteSelect && creditNotes.length === 0) {
      toastr.error('Error', 'No se ha aplicado ninguna nota de crédito');
      return;
    }
    const postpaidPretransactionList = [];
    let currentRemainingPrice = totalPrice;
    creditNotes
      .sort((a, b) => a.amount - b.amount)
      .forEach((creditNote) => {
        let creditNoteAmount = 0;
        if (currentRemainingPrice === 0) return;
        if (currentRemainingPrice > creditNote.amount) {
          currentRemainingPrice -= creditNote.amount;
          creditNoteAmount = creditNote.amount;
        } else {
          creditNoteAmount = currentRemainingPrice;
          currentRemainingPrice = 0;
        }
        const postpaidPreTransaction = {
          customerOrderId,
          customerEmailAddressId: formValues.email.value,
          customerDeliveryAddressId: formValues.deliveryAddress
            ? formValues.deliveryAddress.value
            : null,
          paymentMethodId: formValues.paymentMethod.value,
          voucherTypeId: formValues.voucherType.value,
          businessId: formValues.business ? formValues.business.value : null,
          voucherCode: formValues.voucherCode || null,
          creditNoteId: creditNote.value,
          amount: creditNoteAmount,
          businessTaxId: formValues.businessTaxId,
          businessName: formValues.businessName,
          addressSummary: formValues.addressSummary,
        };
        postpaidPretransactionList.push(postpaidPreTransaction);
      });

    if (currentRemainingPrice !== 0 || !showCreditNoteSelect) {
      const postpaidPreTransaction = {
        customerOrderId,
        customerEmailAddressId: formValues.email.value,
        customerDeliveryAddressId: formValues.deliveryAddress
          ? formValues.deliveryAddress.value
          : null,
        paymentMethodId: formValues.nextPaymentMethod
          ? formValues.nextPaymentMethod.value
          : formValues.paymentMethod.value,
        voucherTypeId: formValues.voucherType.value,
        businessId: formValues.business ? formValues.business.value : null,
        voucherCode: formValues.voucherCode || null,
        amount: currentRemainingPrice,
        businessTaxId: formValues.businessTaxId,
        businessName: formValues.businessName,
        addressSummary: formValues.addressSummary,
      };
      postpaidPretransactionList.push(postpaidPreTransaction);
    }

    dispatchPostPostpaidBookingPretransactionV2(postpaidPretransactionList);
  };

  const voucherCodeField = showVoucherCode && (
    <>
      <hr />
      <FormGroup row>
        <FormItem label="Código">
          <Field
            name="voucherCode"
            component={TextInput}
            validate={[isRequired]}
            disabled
          />
        </FormItem>
      </FormGroup>
    </>
  );

  const voucherCode = (
    <>
      <PagoEfectivoFields
        selector={selector}
        show={showVoucherCode}
        form="ReceiverForm"
      />
      {voucherCodeField}
    </>
  );

  const niubizForm = paymentMethod === TRANSACTION_TYPE_NIUBIZ && (
    <>
      <NiubizFields selector={selector} form="ReceiverForm" />
      <FormGroup row>
        <FormItem>
          <Field
            name="niubizLink"
            validate={[isValidNiubizLink]}
            component={TextInput}
            type="hidden"
            disabled
          />
        </FormItem>
      </FormGroup>
    </>
  );

  const deliveryAddress = showDeliveryAddress ? (
    <>
      <h2>Delivery</h2>
      <FormGroup row>
        <FormItem label="Dirección">
          <Field
            name="deliveryAddress"
            component={CreatableSelect}
            options={deliveryAddresses}
            isLoading={isDeliveryAddressLoading}
            placeholder="Calle de ejemplo 123 - Distrito ejemplo"
            isValidNewOption={isValidNewDeliveryAddress}
            noOptionsMessage={handleNoDeliveryAddress}
            onCreateOption={handleCreateDeliveryAddress}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
    </>
  ) : null;

  return (
    <Form onSubmit={handleSubmit(handleReceiverSubmit)}>
      <CustomerInputGroup
        label="Destinatario"
        labelRequired
        name="customer"
        form="ReceiverForm"
        mobilePhoneRequired
        onChange={handleCustomerChange}
        showDetails
        showCreditNoteMessage
        onlyCustomer={passengers.length > 1 ? undefined : passengers[0].id}
        autoFocus
      />
      <FormGroup row>
        <FormItem label="Correo">
          <Field
            invalid
            name="email"
            component={CreatableSelect}
            options={emails}
            isLoading={isEmailLoading}
            formatCreateLabel={formatCreateLabel}
            validate={[isRequired, isValidEmail]}
            onCreateOption={handleCreateEmail}
            isValidNewOption={isValidNewEmail}
            noOptionsMessage={handleNoEmails}
            onChange={handleChangeEmail}
          />
        </FormItem>
      </FormGroup>
      <VoucherTypeInputGroup
        voucherTypeFieldName="voucherType"
        businessFieldName="business"
        businessTaxIdFieldName="businessTaxId"
        businessNameFieldName="businessName"
        addressSummaryFieldName="addressSummary"
        change={change}
      />
      <FormGroup row>
        <FormItem label="Método de Pago">
          <Field
            name="paymentMethod"
            component={PaymentMethodSelect}
            onChange={handlePaymentMethodChange}
            validate={[isRequired]}
            isLoading={isPaymentMethodLoading}
            transactionCodesNotIncluded={[]}
          />
          {showCreditNoteSelect && passengers.length > 1 && (
            <p className="text-danger">
              Este método de pago sólo aplica para venta un solo pasaje
            </p>
          )}
        </FormItem>
      </FormGroup>
      {passengers.length === 1 &&
        selectedCustomer &&
        selectedCustomer.value &&
        showCreditNoteSelect && (
          <FormGroup row>
            <FormItem label="Nota de crédito">
              <Field
                name="creditNotes"
                component={CreditNoteSelect}
                isMulti
                validate={[isRequired]}
                isCustomerIdRequired
                optionsRegistered
              />
            </FormItem>
          </FormGroup>
        )}
      {creditNotes.length > 0 && remainingPrice > 0 && (
        <>
          <h3>
            <b>{`Restante: ${numberFormatter({
              style: 'currency',
              value: remainingPrice,
            })}`}</b>
          </h3>
          <FormGroup row>
            <FormItem label="Siguiente método de Pago">
              <Field
                name="nextPaymentMethod"
                component={PaymentMethodSelect}
                onChange={handleNextPaymentMethodChange}
                validate={[isRequired]}
                isLoading={isPaymentMethodLoading}
                transactionCodesNotIncluded={[CREDIT_NOTE]}
              />
            </FormItem>
          </FormGroup>
        </>
      )}
      {voucherCode}
      {niubizForm}
      {deliveryAddress}
      <div className="FormButtonGroup">
        <Button type="submit" disabled={submitting} color="primary" size="lg">
          Reservar <i className="fa fa-arrow-circle-right" />
        </Button>
      </div>
    </Form>
  );
};

ReceiverForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  emails: Select.propTypes.options.isRequired,
  isEmailLoading: PropTypes.bool.isRequired,
  deliveryAddresses: Select.propTypes.options.isRequired,
  isDeliveryAddressLoading: PropTypes.bool.isRequired,
  dispatchGetCustomerEmails: PropTypes.func.isRequired,
  dispatchClearCustomerEmails: PropTypes.func.isRequired,
  dispatchGetCustomerDeliveryAddresses: PropTypes.func.isRequired,
  dispatchClearCustomerDeliveryAddresses: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
  isPaymentMethodLoading: PropTypes.bool.isRequired,
  dispatchGetPaymentMethods: PropTypes.func.isRequired,
  dispatchClearCreditNotes: PropTypes.func.isRequired,
  customerOrderId: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatchGetVoucherTypes: PropTypes.func.isRequired,
  dispatchPostPostpaidBookingPretransactionV2: PropTypes.func.isRequired,
  passengers: passengersPropType.isRequired,
  creditNotes: PropTypes.instanceOf(Array),
  totalPrice: PropTypes.number.isRequired,
};

ReceiverForm.defaultProps = {
  selectedCustomer: null,
  creditNotes: [],
};

const mapStateToProps = (state) => ({
  emails: state.UserUnit.Customer.getIn(['emails', 'content', 'content']).map(
    (customerEmail) => ({
      value: customerEmail.id,
      label: customerEmail.email,
    }),
  ),
  isEmailLoading: state.UserUnit.Customer.getIn(['emails', 'loading']),
  deliveryAddresses: state.UserUnit.Customer.getIn([
    'deliveryAddresses',
    'content',
    'content',
  ]).map((deliveryAddress) => ({
    value: deliveryAddress.id,
    label: deliveryAddress.deliveryAddress,
  })),
  isDeliveryAddressLoading: state.UserUnit.Customer.getIn([
    'deliveryAddresses',
    'loading',
  ]),
  selectedCustomer: selector(state, 'customer'),
  isPaymentMethodLoading: state.AccountingUnit.PaymentMethod.getIn([
    'all',
    'loading',
  ]),
  voucherTypes: state.AccountingUnit.VoucherType.getIn([
    'all',
    'content',
    'content',
  ]).reduce((options, voucherType) => {
    if (voucherType.displayInPaymentsPage) {
      options.push({
        value: voucherType.id,
        label: voucherType.name,
      });
    }
    return options;
  }, []),
  isVoucherTypeLoading: state.AccountingUnit.VoucherType.getIn([
    'all',
    'loading',
  ]),
  customerOrderId: state.PostpaidBookingUnit.PostpaidBooking.getIn([
    'receiver',
    'customerOrder',
    'id',
  ]),
  businesses: state.UserUnit.Business.getIn(['all', 'content', 'content']).map(
    (business) => ({
      value: business.id,
      label: `${business.businessTaxId} - ${business.name}`,
    }),
  ),
  isBusinessLoading: state.UserUnit.Business.getIn(['all', 'loading']),
  creditNotes: selector(state, 'creditNotes'),
});

const mapDispatchToProps = {
  dispatchGetCustomerEmails: getCustomerEmails,
  dispatchClearCustomerEmails: clearCustomerEmails,
  dispatchGetCustomerDeliveryAddresses: getCustomerDeliveryAddresses,
  dispatchClearCustomerDeliveryAddresses: clearCustomerDeliveryAddresses,
  dispatchGetPaymentMethods: getPaymentMethods,
  dispatchGetVoucherTypes: getVoucherTypes,
  dispatchClearCreditNotes: clearCreditNotes,
  dispatchPostPostpaidBookingPretransactionV2:
    postPostpaidBookingPretransactionV2,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'ReceiverForm',
  })(ReceiverForm),
);
