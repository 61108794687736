import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { padStart } from 'lodash';
import { Col, Row } from 'reactstrap';
import {
  PARCEL_FINANCIAL_STATUS,
  PARCEL_PAYMENT_STATUS,
  PEN_SYMBOL,
} from '../../../../../config/constants';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';
import { tzNormalizeDate } from '../../../../../utils/date';
import {
  CARGO_ITEM_PATH,
  CARGO_PATH,
  SALES_SESSION_PATH,
} from '../../../../../config/paths';
import {
  CARGO_ITEMS_COLUMNS,
  PARCEL_TRANSACTIONS_COLUMNS,
} from '../../../../../config/columns';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import Table from '../../../../common/Table';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import parcelBasicInformationPropTypes from '../proptypes/ParcelPropTypes';
import EnumStatus from '../../../../common/EnumStatus';
import LinkToModuleResource from '../../../../common/resource/LinkToModuleResource';
import ParcelCommentForm from '../ParcelCommentForm';
import { postParcelComment } from '../../../../../actions';
import CreditNote from '../../../reservation/ticket/CreditNote';

const ParcelBasicInformationResource = ({
  id,
  cargoItemList,
  cargoDelivery,
  detractionAmount,
  salePriceWithDetraction,
  customer,
  business,
  voucherTypeName,
  documentSeries,
  documentCode,
  sourceCityName,
  destinationCityName,
  createDate,
  lastUpdate,
  senderCustomerFullName,
  senderCustomer,
  consigneeCustomerFullName,
  consigneeCustomer,
  paymentMethodName,
  salePrice,
  attachedDocument,
  transactionList,
  paymentStatus,
  salesSessionId,
  comments,
  commentsList,
  dispatchPostParcelComment,
  financialStatus,
  user,
  lastUpdateUser,
  secondaryConsigneeCustomer,
  creditNoteList,
  discountCodeString,
  salePriceWithDiscount,
  corporateCreditAmount,
  contingencyMotive,
  previousParcelId,
  previousParcelDocumentCode,
  attachedDocumentParcelList,
}) => {
  const postComment = (formValues) => {
    const newFormValues = {
      parcelId: id,
      description: formValues.parcelComment,
    };

    dispatchPostParcelComment(newFormValues);
  };

  const transactions = (
    <div>
      <h5>Transacciones</h5>
      <Table
        columns={PARCEL_TRANSACTIONS_COLUMNS}
        data={transactionList}
        defaultPageSize={transactionList.length}
        showPagination={false}
      />
    </div>
  );

  const items = (
    <div>
      <h5>Items</h5>
      <Table
        columns={CARGO_ITEMS_COLUMNS}
        data={cargoItemList}
        manual={false}
        filterable
        defaultPageSize={10}
        defaultFilterMethod={filterMethodCaseInsensitive}
        modelPath={CARGO_ITEM_PATH}
        navigateToModelOnRowClick
        openPathInNewTab
      />
    </div>
  );

  const delivery = cargoDelivery ? (
    <>
      <h5>Delivery</h5>
      <ResourceProperty label="Distrito:">
        {cargoDelivery.district.name}
      </ResourceProperty>
      <ResourceProperty label="Dirección:">
        {cargoDelivery.address}
      </ResourceProperty>
    </>
  ) : null;

  const detraction =
    detractionAmount !== null ? (
      <>
        <ResourceProperty label="Detracción:">
          {PEN_SYMBOL} {detractionAmount.toFixed(2)}
        </ResourceProperty>
        <ResourceProperty label="Monto Total con Detracción:">
          {PEN_SYMBOL} {salePriceWithDetraction.toFixed(2)}
        </ResourceProperty>
      </>
    ) : null;

  const discount =
    salePriceWithDiscount !== null ? (
      <>
        {discountCodeString && (
          <ResourceProperty label="Código de Descuento:">
            {discountCodeString}
          </ResourceProperty>
        )}
        {corporateCreditAmount && (
          <ResourceProperty label="Descuento Corporativo:">
            {PEN_SYMBOL} {corporateCreditAmount.toFixed(2)}
          </ResourceProperty>
        )}
        <ResourceProperty label="Monto Total con Descuento:">
          {PEN_SYMBOL} {salePriceWithDiscount.toFixed(2)}
        </ResourceProperty>
      </>
    ) : null;

  let client = null;
  if (customer !== null) {
    client = (
      <>
        <h3>Información del Cliente</h3>
        <ResourceProperty label="Cliente:">
          {customer.fullName}
          {' - '}
          {customer.identificationType.name} {customer.idDocumentNumber}
        </ResourceProperty>
        <ResourceProperty label="Correo del Cliente:">
          {customer.email || '-'}
        </ResourceProperty>
      </>
    );
  } else {
    client = (
      <>
        <h3>Información de la Empresa</h3>
        <ResourceProperty label="Empresa:">
          {business.name}
          {' - RUC '}
          {business.businessTaxId}
        </ResourceProperty>
      </>
    );
  }

  let contigency = null;

  if (contingencyMotive !== null) {
    contigency = (
      <>
        <h3>Información del Contigencia</h3>
        <ResourceProperty label="Motivo de Contingencia:">
          {contingencyMotive}
        </ResourceProperty>
      </>
    );
  }

  let attachedDocuments = '-';

  if (attachedDocumentParcelList && attachedDocumentParcelList.length > 0) {
    attachedDocuments = attachedDocumentParcelList
      .map((doc) => doc.attachedDocumentCorrelation)
      .join(', ');
  } else if (attachedDocument) {
    attachedDocuments = attachedDocument;
  }

  const parcelInformationResource = (
    <>
      <ResourceProperty label="Número de Comprobante:">
        {documentSeries}-{padStart(documentCode, 7)}
      </ResourceProperty>
      <ResourceProperty label="Estado del Comprobante:">
        <EnumStatus
          enumObject={PARCEL_FINANCIAL_STATUS}
          enumValue={financialStatus}
        />
      </ResourceProperty>
      <ResourceProperty label="Sesión de ventas:">
        <LinkToModuleResource
          text={salesSessionId}
          href={`${SALES_SESSION_PATH}/${salesSessionId}`}
          isExternalPath
        />
      </ResourceProperty>
      <ResourceProperty label="Documento adjunto:">
        {attachedDocuments}
      </ResourceProperty>
      <ResourceProperty label="Comentarios: ">
        {comments || '-'}
      </ResourceProperty>

      <h3>Detalles del Envío</h3>
      <ResourceProperty label="Origen:">{sourceCityName}</ResourceProperty>
      <ResourceProperty label="Destino:">
        {destinationCityName}
      </ResourceProperty>
      <ResourceProperty label="Remitente:">
        {senderCustomerFullName}
        {' - '}
        {senderCustomer.identificationType.name}{' '}
        {senderCustomer.idDocumentNumber}
      </ResourceProperty>
      <ResourceProperty label="Consignado:">
        {consigneeCustomerFullName}
        {' - '}
        {consigneeCustomer.identificationType.name}{' '}
        {consigneeCustomer.idDocumentNumber}
      </ResourceProperty>
      {secondaryConsigneeCustomer !== null && (
        <ResourceProperty label="Consignado Secundario:">
          {secondaryConsigneeCustomer.fullName}
          {' - '}
          {secondaryConsigneeCustomer.identificationType.name}{' '}
          {secondaryConsigneeCustomer.idDocumentNumber}
        </ResourceProperty>
      )}
      {client}

      <h3>Información Financiera</h3>
      <ResourceProperty label="Tipo de Comprobante:">
        {voucherTypeName}
      </ResourceProperty>
      <ResourceProperty label="Método de Pago:">
        {paymentMethodName}
      </ResourceProperty>
      <ResourceProperty label="Estado de Pago:">
        <EnumStatus
          enumObject={PARCEL_PAYMENT_STATUS}
          enumValue={paymentStatus}
        />
      </ResourceProperty>
      <ResourceProperty label="Monto Total:">
        {PEN_SYMBOL} {salePrice.toFixed(2)}
      </ResourceProperty>
      {discount}
      {previousParcelId && (
        <>
          <h3>Otros Datos</h3>
          <ResourceProperty label="Comprobante Anterior:">
            <a href={`${CARGO_PATH}/${previousParcelId}`}>
              {previousParcelDocumentCode}
            </a>
          </ResourceProperty>
        </>
      )}
      {contigency}
    </>
  );

  const parcelInternalResource = (
    <>
      <h3>Información Interna</h3>
      <ResourceProperty label="Fecha de Creación:">
        {tzNormalizeDate({
          date: createDate,
          format: DATE_TIME_FORMAT,
        })}
      </ResourceProperty>
      <ResourceProperty label="Última Actualización:">
        {tzNormalizeDate({
          date: lastUpdate,
          format: DATE_TIME_FORMAT,
        })}
      </ResourceProperty>
      <ResourceProperty label="Creado por:">
        {user ? user.customer.fullName : '-'}
      </ResourceProperty>
      <ResourceProperty label="Actualizado por:">
        {lastUpdateUser ? lastUpdateUser.customer.fullName : '-'}
      </ResourceProperty>
    </>
  );

  const parcelCreditNoteList = creditNoteList.map((creditNote) => (
    <CreditNote creditNote={creditNote} description="Anticipo de Carga" />
  ));

  return (
    <>
      <Row>
        <Col md={creditNoteList.length === 0 ? 6 : 4}>
          {parcelInformationResource}
        </Col>
        <Col md={creditNoteList.length === 0 ? 6 : 4}>
          {parcelInternalResource}
        </Col>
        {creditNoteList.length > 0 && <Col md={4}>{parcelCreditNoteList}</Col>}
      </Row>
      {detraction}
      {delivery}
      {transactions}
      {items}
      <div>
        <h5>Comentarios</h5>
        <ParcelCommentForm comments={commentsList} onSubmit={postComment} />
      </div>
    </>
  );
};

const mapDispatchToProps = {
  dispatchPostParcelComment: postParcelComment,
};

ParcelBasicInformationResource.propTypes = {
  ...parcelBasicInformationPropTypes,
  dispatchPostParcelComment: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps,
)(ParcelBasicInformationResource);
