import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { COMPANY_RESOURCE_GROUP_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_DELETE_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { COMPANY_RESOURCE_GROUP_PATH } from '../../config/paths';
import {
  CLEAR_COMPANY_RESOURCE_GROUP,
  CLEAR_COMPANY_RESOURCE_GROUPS,
  FLAG_COMPANY_RESOURCE_GROUP_ACTIVITY,
  FLAG_GETTING_COMPANY_RESOURCE_GROUPS,
  GET_COMPANY_RESOURCE_GROUP,
  GET_COMPANY_RESOURCE_GROUPS,
} from '../types';

const flagCompanyResourceGroup = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_COMPANY_RESOURCE_GROUPS,
    payload: flag,
  });

const getCompanyResourceGroups = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagCompanyResourceGroup(true));
    const query = tableFilters;
    const url = `${COMPANY_RESOURCE_GROUP_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise, null, dispatch);
    const response = await promise.json();

    dispatch({
      type: GET_COMPANY_RESOURCE_GROUPS,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagCompanyResourceGroup(false));
  }
};

const clearCompanyResourceGroups = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPANY_RESOURCE_GROUPS,
  });
};

const flagCompanyResourceGroupActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_COMPANY_RESOURCE_GROUP_ACTIVITY,
    payload: flag,
  });

const getCompanyResourceGroup =
  async ({ companyResourceGroupId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCompanyResourceGroupActivity(true));
      const url = `${COMPANY_RESOURCE_GROUP_ENDPOINT}/${companyResourceGroupId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise, null, dispatch);
      const response = await promise.json();
      dispatch({
        type: GET_COMPANY_RESOURCE_GROUP,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCompanyResourceGroupActivity(false));
    }
  };

const clearCompanyResourceGroup = () => (dispatch) =>
  dispatch({
    type: CLEAR_COMPANY_RESOURCE_GROUP,
  });

const postCompanyResourceGroup =
  async ({ name, description, companyResourceList }) =>
  async (dispatch) => {
    try {
      dispatch(flagCompanyResourceGroupActivity(true));

      const payload = {
        name,
        description,
        companyResourceList,
      };
      const url = COMPANY_RESOURCE_GROUP_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const companyResourceGroup = await response.json();
      if (companyResourceGroup) {
        dispatch(
          push(`${COMPANY_RESOURCE_GROUP_PATH}/${companyResourceGroup.id}`),
        );
      }
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCompanyResourceGroupActivity(false));
    }
  };

const deleteCompanyResourceGroup =
  async ({ companyResourceGroupId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCompanyResourceGroupActivity(true));
      const url = `${COMPANY_RESOURCE_GROUP_ENDPOINT}/${companyResourceGroupId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(COMPANY_RESOURCE_GROUP_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCompanyResourceGroupActivity(false));
    }
  };

const putCompanyResourceGroup =
  async (companyResourceGroupId, { name, description, companyResourceList }) =>
  async (dispatch) => {
    try {
      dispatch(flagCompanyResourceGroupActivity(true));
      const payload = {
        id: companyResourceGroupId,
        name,
        description,
        companyResourceList,
      };
      const url = `${COMPANY_RESOURCE_GROUP_ENDPOINT}/${companyResourceGroupId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(
        push(`${COMPANY_RESOURCE_GROUP_PATH}/${companyResourceGroupId}`),
      );
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCompanyResourceGroupActivity(false));
    }
  };

export {
  getCompanyResourceGroups,
  clearCompanyResourceGroups,
  getCompanyResourceGroup,
  clearCompanyResourceGroup,
  postCompanyResourceGroup,
  putCompanyResourceGroup,
  deleteCompanyResourceGroup,
};
