import React, { useState, useLayoutEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { Row, Col, FormGroup, Label, InputGroupText } from 'reactstrap';
import { Field, reduxForm, Form, change, formValueSelector } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import {
  isRequired,
  validateEndDate,
  validateNumber,
} from '../../../../utils/validators';
import { tzNormalizeDate } from '../../../../utils/date';
import TextInput from '../../../common/forms/input/TextInput';
import DynamicForm from '../../../common/forms/DynamicForm';
import {
  generatePriceVariationByZonesColumns,
  PRICE_PER_RANGE_DATES_FORM_COLUMNS,
} from '../../../../config/dynamicFormFields';
import SeatMapSelect from '../../../common/forms/select/SeatMapSelect';
import SeatMap from '../../../modules/booking/seats/SeatMap';
import { CURRENCY, TIMESTAMP_FORMAT } from '../../../../config/locale';
import {
  COORDINATE_BUTTON_NOT_CLICKED_ERROR_MESSAGE,
  generateErrorMessageForIncompleteDataInPricingByZone,
  generateErrorMessageForZoneFloorNumberNotSelected,
} from '../../../../config/messages';
import AgencyGroupSelect from '../../../common/forms/select/AgencyGroupSelect';
import FormFooter from '../../../common/forms/FormFooter';
import { range } from '../../../../utils/array';
import RouteSelect from '../../../common/forms/select/RouteSelect';
import ServiceTypeSelect from '../../../common/forms/select/ServiceTypeSelect';

const selector = formValueSelector('PricingProfileForm');

const validatePricePerDateRange = ({ priceDatesList }, { initialValues }) => {
  const errors = {};

  const priceDatesListErrors = [];

  if (priceDatesList) {
    const newPriceDatesList = priceDatesList.map((date, index) => {
      const newDate = { ...date };

      if (!date.id) newDate.id = `newId${index}`;

      return newDate;
    });

    const allPriceDatesList = [
      ...(initialValues && initialValues.initialPriceDates
        ? initialValues.initialPriceDates
        : []),
      ...newPriceDatesList,
    ];

    newPriceDatesList.forEach(
      ({ startDate, endDate, absoluteChange, id }, index) => {
        const priceDateRangeError = {};

        const startDateTimestamp = tzNormalizeDate({
          date: startDate,
          format: TIMESTAMP_FORMAT,
          time: startDate,
        });

        const endDateTimestamp = tzNormalizeDate({
          date: endDate,
          format: TIMESTAMP_FORMAT,
          time: 'start',
        });

        // Validate start date
        let startDateError;

        if (!startDate) startDateError = isRequired(startDate);
        else {
          const startDateIncluding = allPriceDatesList.some((priceRange) => {
            if (priceRange.id === id) return false;

            return (
              tzNormalizeDate({
                date: priceRange.startDate,
                format: TIMESTAMP_FORMAT,
                time: 'start',
              }) <= startDateTimestamp &&
              startDateTimestamp <=
                tzNormalizeDate({
                  date: priceRange.endDate,
                  format: TIMESTAMP_FORMAT,
                  time: 'start',
                })
            );
          });

          startDateError = !startDateIncluding
            ? undefined
            : 'Fecha incluida en otro rango';
        }

        priceDateRangeError.startDate = startDateError;

        // Validate end date
        let endDateError;

        if (!endDate) endDateError = isRequired(endDate);
        else {
          const endDateIncluding = allPriceDatesList.some((priceRange) => {
            if (priceRange.id === id) return false;

            return (
              tzNormalizeDate({
                date: priceRange.startDate,
                format: TIMESTAMP_FORMAT,
                time: 'start',
              }) <= endDateTimestamp &&
              endDateTimestamp <=
                tzNormalizeDate({
                  date: priceRange.endDate,
                  format: TIMESTAMP_FORMAT,
                  time: 'start',
                })
            );
          });

          endDateError = !endDateIncluding
            ? validateEndDate(endDate, startDate)
            : 'Fecha incluida en otro rango';
        }

        priceDateRangeError.endDate = endDateError;

        // Validate difference
        priceDateRangeError.absoluteChange = !absoluteChange
          ? isRequired(absoluteChange)
          : validateNumber(absoluteChange);

        priceDatesListErrors[index] = priceDateRangeError;
      },
    );
  }

  if (priceDatesListErrors.length) errors.priceDatesList = priceDatesListErrors;

  return errors;
};

const PricingProfileForm = ({
  initialValues,
  dispatchChange,
  priceZoneList,
  handleSubmit,
}) => {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [stops, setStops] = useState('');
  const [isMaciva, setIsMaciva] = useState(false);
  const [isPriceByDay, setIsPriceByDay] = useState(false);
  const [floors, setFloors] = useState(null);
  const [positionType, setPositionType] = useState(null);
  const [positionIndex, setPositionIndex] = useState(null);
  const [seatReservations, setSeatReservations] = useState(null);
  const [enabledFloor, setEnabledFloor] = useState(null);

  const onMount = () => {
    if (initialValues) {
      setIsPriceByDay(initialValues.isPriceByDay || null);
      setFloors(
        initialValues.seatMapId ? initialValues.seatMapId.floors : null,
      );
      setIsMaciva(initialValues.isMaciva);
    }
  };

  useLayoutEffect(() => onMount(), []);

  const onChangeSeatMap = (option) => {
    setFloors(option ? option.floors : null);
    setPositionType(null);
    setPositionIndex(null);
    setSeatReservations(null);
    setEnabledFloor(null);

    dispatchChange('PricingProfileForm', 'priceZoneList', []);
  };

  const onClickHighlightSeats = (index) => {
    const {
      startXPosition,
      endXPosition,
      startYPosition,
      endYPosition,
      itemfloorNumber,
    } = priceZoneList[index];

    if (
      startXPosition !== undefined &&
      endXPosition !== undefined &&
      startYPosition !== undefined &&
      endYPosition !== undefined &&
      itemfloorNumber !== undefined
    ) {
      const coordinates = [];

      const { seats } = floors[itemfloorNumber.value - 1];

      const { xPosition: lastXPosition, yPosition: lastYPosition } =
        seats[seats.length - 1];

      const firstXPosition = 0;

      const firstYPosition = 0;

      let newStartXPosition = parseInt(startXPosition, 10);

      let newEndXPosition = parseInt(endXPosition, 10);

      if (newStartXPosition < firstXPosition)
        newStartXPosition = firstXPosition;

      if (newEndXPosition > lastXPosition) newEndXPosition = lastXPosition;

      let newStartYPosition = parseInt(startYPosition, 10);

      let newEndYPosition = parseInt(endYPosition, 10);

      if (newStartYPosition < firstYPosition)
        newStartYPosition = firstYPosition;

      if (newEndYPosition > lastYPosition) newEndYPosition = lastYPosition;

      for (
        let startIndex = newStartXPosition;
        startIndex <= newEndXPosition;
        startIndex += 1
      ) {
        for (
          let endIndex = newStartYPosition;
          endIndex <= newEndYPosition;
          endIndex += 1
        ) {
          coordinates.push({
            xPosition: startIndex,
            yPosition: endIndex,
            floorNumber: itemfloorNumber.value,
          });
        }
      }

      setSeatReservations(coordinates);
    } else
      toastr.error(
        'Error',
        generateErrorMessageForIncompleteDataInPricingByZone(index + 1),
      );
  };

  const onClickRemove = (index, fields) => {
    fields.remove(index);
    setSeatReservations(null);
    setEnabledFloor(null);
  };

  const setZonePosition = (index, type) => {
    if (priceZoneList[index].itemfloorNumber) {
      setPositionIndex(index);
      setPositionType(type);
      setEnabledFloor(priceZoneList[index].itemfloorNumber.value);
    } else
      toastr.error(
        'Error',
        generateErrorMessageForZoneFloorNumberNotSelected(index + 1),
      );
  };

  const setPricingProfileName = (source, destination, points) => {
    setFrom(source);
    setTo(destination);
    setStops(points);

    dispatchChange(
      'PricingProfileForm',
      'name',
      `${source} - ${destination} - ${points}`,
    );
  };

  const handleSeatSelection = ({ xPosition, yPosition }) => {
    if (!positionType && !positionIndex)
      toastr.error('Error', COORDINATE_BUTTON_NOT_CLICKED_ERROR_MESSAGE);
    else {
      dispatchChange(
        'PricingProfileForm',
        `priceZoneList[${positionIndex}].${positionType}XPosition`,
        xPosition,
      );

      dispatchChange(
        'PricingProfileForm',
        `priceZoneList[${positionIndex}].${positionType}YPosition`,
        yPosition,
      );
    }
  };

  const priceByDayFields = !isPriceByDay ? null : (
    <Fragment>
      <h3>Cambios de Precio por Día de Semana</h3>
      <p>
        Cambio porcentual del precio base en un día determinado de la semana.
      </p>
      <FormGroup row>
        <FormItem label="Lunes">
          <Field
            name="monday"
            component={TextInput}
            placeholder="Lunes"
            append={<InputGroupText>{CURRENCY}</InputGroupText>}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Martes">
          <Field
            name="tuesday"
            component={TextInput}
            placeholder="Martes"
            append={<InputGroupText>{CURRENCY}</InputGroupText>}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Miércoles">
          <Field
            name="wednesday"
            component={TextInput}
            placeholder="Miércoles"
            append={<InputGroupText>{CURRENCY}</InputGroupText>}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Jueves">
          <Field
            name="thursday"
            component={TextInput}
            placeholder="Jueves"
            append={<InputGroupText>{CURRENCY}</InputGroupText>}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Viernes">
          <Field
            name="friday"
            component={TextInput}
            placeholder="Viernes"
            append={<InputGroupText>{CURRENCY}</InputGroupText>}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Sábado">
          <Field
            name="saturday"
            component={TextInput}
            placeholder="Sábado"
            append={<InputGroupText>{CURRENCY}</InputGroupText>}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Domingo">
          <Field
            name="sunday"
            component={TextInput}
            placeholder="Domingo"
            append={<InputGroupText>{CURRENCY}</InputGroupText>}
          />
        </FormItem>
      </FormGroup>
    </Fragment>
  );

  let seatMap = null;

  if (floors) {
    const floorOptions = range(1, floors.length + 1).map((floor) => ({
      value: floor,
      label: floor,
    }));

    seatMap = (
      <Row>
        <Col sm={12} md={6} lg={6}>
          <SeatMap
            floors={floors}
            handleSeatSelection={handleSeatSelection}
            seatReservations={seatReservations}
            renderFor="pricingProfile"
            enabledFloor={enabledFloor}
          />
        </Col>
        <Col sm={12} md={6} lg={6}>
          <DynamicForm
            title="Zona"
            nestedTittle="Precio Por Segmento"
            name="priceZoneList"
            columns={generatePriceVariationByZonesColumns(
              floorOptions,
              (index) => setZonePosition(index, 'start'),
              (index) => setZonePosition(index, 'end'),
            )}
            onClickRemove={onClickRemove}
            otherButtonsPerRow={[
              {
                label: 'Resaltar',
                color: 'success',
                onClick: (index) => onClickHighlightSeats(index),
              },
            ]}
          />
        </Col>
      </Row>
    );
  }

  const fieldsNoMaciva = isMaciva ? null : (
    <Fragment>
      <FormGroup row>
        <FormItem>
          <Label>
            <Field
              name="isPriceByDay"
              id="isPriceByDay"
              component="input"
              type="checkbox"
              onChange={(e) => setIsPriceByDay(e.target.checked)}
            />{' '}
            Cambios de precio por día de semana
          </Label>
        </FormItem>
      </FormGroup>
      {priceByDayFields}
      <hr />
      <h3>Variación de Precio por Rango de Fechas</h3>
      <p>
        Especifique los rangos de fechas para los que desea aplicar una
        variación de precio.
      </p>
      <DynamicForm
        name="priceDatesList"
        columns={PRICE_PER_RANGE_DATES_FORM_COLUMNS}
      />
      <h3>Variación de Precio por Zonas</h3>
      <p>
        Seleccione un mapa de asientos para el que desea aplicar una variación
        de precio.
      </p>
      <FormGroup row>
        <FormItem label="Mapa de Asientos">
          <Field
            name="seatMapId"
            component={SeatMapSelect}
            onChange={onChangeSeatMap}
            isClearable
          />
        </FormItem>
      </FormGroup>
      {seatMap}
      <hr />
      <FormGroup row>
        <FormItem label="Grupos de Agencias">
          <Field
            name="agencyGroups"
            component={AgencyGroupSelect}
            isMulti
            isClearable
          />
        </FormItem>
      </FormGroup>
    </Fragment>
  );

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="">
          <Row>
            <Col>
              <Field
                name="from"
                component={TextInput}
                placeholder="De"
                onChange={(e) =>
                  setPricingProfileName(e.target.value, to, stops)
                }
              />
            </Col>
            <Col>
              <Field
                name="to"
                component={TextInput}
                placeholder="A"
                onChange={(e) =>
                  setPricingProfileName(from, e.target.value, stops)
                }
              />
            </Col>
            <Col>
              <Field
                name="stops"
                component={TextInput}
                placeholder="Paradas"
                onChange={(e) =>
                  setPricingProfileName(from, to, e.target.value)
                }
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Nombre" required>
          <Field
            name="name"
            component={TextInput}
            placeholder="Nombre"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Rutas" required>
          <Field
            name="pricingProfileRouteList"
            component={RouteSelect}
            isMulti
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipo de Servicio" required>
          <Field
            name="serviceType"
            component={ServiceTypeSelect}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Precio Base" required>
          <Field
            name="basePrice"
            component={TextInput}
            type="text"
            placeholder="0.00"
            validate={[isRequired, validateNumber]}
            append={<InputGroupText>{CURRENCY}</InputGroupText>}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem>
          <Label>
            <Field
              name="isMaciva"
              id="isMaciva"
              component="input"
              type="checkbox"
              onChange={(e) => setIsMaciva(e.target.checked)}
            />{' '}
            Maciva
          </Label>
        </FormItem>
      </FormGroup>
      {fieldsNoMaciva}
      <FormFooter />
    </Form>
  );
};

PricingProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  priceZoneList: PropTypes.arrayOf(
    PropTypes.shape({
      itemfloorNumber: PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.number,
      }),
      basePriceOverride: PropTypes.number,
      startXPosition: PropTypes.number,
      endXPosition: PropTypes.number,
      startYPosition: PropTypes.number,
      endYPosition: PropTypes.number,
      priceSegmentList: PropTypes.arrayOf(
        PropTypes.shape({
          promotionBasePrice: PropTypes.number,
          sourceCity: PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string,
          }),
          destinationCity: PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string,
          }),
        }),
      ),
    }),
  ),
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    basePrice: PropTypes.number,
    isMaciva: PropTypes.bool,
    isPriceByDay: PropTypes.bool,
    seatMapId: PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
      floors: PropTypes.arrayOf(
        PropTypes.shape({
          floorNumber: PropTypes.number.isRequired,
          width: PropTypes.number.isRequired,
          length: PropTypes.number.isRequired,
          seats: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              price: PropTypes.number,
              floorNumber: PropTypes.number.isRequired,
              seatItineraryId: PropTypes.string,
              seatMapElement: PropTypes.shape({
                id: PropTypes.number.isRequired,
                elementIcon: PropTypes.string.isRequired,
                isSeat: PropTypes.bool.isRequired,
              }),
              seatMapElementId: PropTypes.number.isRequired,
              seatMapId: PropTypes.number.isRequired,
              seatNumber: PropTypes.number.isRequired,
              seatStatus: PropTypes.string.isRequired,
              xPosition: PropTypes.number.isRequired,
              yPosition: PropTypes.number.isRequired,
            }),
          ).isRequired,
        }),
      ),
    }),
  }),
};

PricingProfileForm.defaultProps = {
  priceZoneList: [],
  initialValues: null,
};

const mapStateToProps = (state) => ({
  priceZoneList: selector(state, 'priceZoneList'),
});

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'PricingProfileForm',
  validate: validatePricePerDateRange,
})(PricingProfileForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
