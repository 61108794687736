import React from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormItem from '../../../common/forms/FormItem';
import {
  isRequired,
  validateNumber,
  validateYear,
} from '../../../../utils/validators';
import TextInput from '../../../common/forms/input/TextInput';
import FormFooter from '../../../common/forms/FormFooter';
import Select from '../../../common/forms/select/Select';
import { MONTH_OPTIONS } from '../../../../config/constants';

const PrintDriverMovementHistoryForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <FormItem label="Imprimir Tareo">
        <Row>
          <Col md={6}>
            <Field
              name="month"
              component={Select}
              options={MONTH_OPTIONS}
              placeholder="Mes"
              validate={[isRequired]}
            />
          </Col>
          <Col md={6}>
            <Field
              name="year"
              component={TextInput}
              placeholder="Año"
              defaultValue={new Date().getFullYear()}
              props={{
                defaultValue: new Date().getFullYear(),
              }}
              validate={[isRequired, validateNumber, validateYear]}
            />
          </Col>
        </Row>
      </FormItem>
    </FormGroup>
    <FormFooter
      saveButtonColor="secondary"
      saveButtonText="Exportar"
      saveButtonIcon="fa fa-file-text"
    />
  </Form>
);
PrintDriverMovementHistoryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const formComponent = reduxForm({
  form: 'PrintDriverMovementHistoryForm',
  initialValues: {
    month: {
      value: new Date().getMonth() + 1,
      label: MONTH_OPTIONS.find(
        (option) => option.value === new Date().getMonth() + 1,
      ).label,
    },
    year: new Date().getFullYear(),
  },
})(PrintDriverMovementHistoryForm);

export default connect(null, null)(formComponent);
