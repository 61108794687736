import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  CLEAR_DRIVERS_MOVEMENT_HISTORY,
  FLAG_DRIVER_MOVEMENT_HISTORY_ACTIVITY,
  FLAG_GETTING_DRIVERS_MOVEMENT_HISTORY,
  GET_DRIVER_MOVEMENT_HISTORY,
  GET_DRIVERS_MOVEMENT_HISTORY,
  POST_DRIVER_MOVEMENT_HISTORY,
  PUT_DRIVER_MOVEMENT_HISTORY,
} from '../types';
import { DRIVER_MOVEMENT_HISTORY_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { DRIVER_MOVEMENT_HISTORY_PATH } from '../../config/paths';
import { tzNormalizeDate } from '../../utils/date';
import { DATE_TIME_FORMAT } from '../../config/locale';

const flagGettingDriversMovementHistory = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_DRIVERS_MOVEMENT_HISTORY,
    payload: flag,
  });

const getDriversMovementHistory = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingDriversMovementHistory(true));
    const query = tableFilters;
    const url = `${DRIVER_MOVEMENT_HISTORY_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const drivers = await response.json();
    dispatch({
      type: GET_DRIVERS_MOVEMENT_HISTORY,
      payload: drivers,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingDriversMovementHistory(false));
  }
};

const clearDriversMovementHistory = () => (dispatch) =>
  dispatch({
    type: CLEAR_DRIVERS_MOVEMENT_HISTORY,
  });

const flagDriverMovementHistoryActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_DRIVER_MOVEMENT_HISTORY_ACTIVITY,
    payload: flag,
  });

const getDriverMovementHistory =
  async ({ driverMovementHistoryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagDriverMovementHistoryActivity(true));
      const url = `${DRIVER_MOVEMENT_HISTORY_ENDPOINT}/${driverMovementHistoryId}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response, null, dispatch);
      const driverMovementHistory = await response.json();
      dispatch({
        type: GET_DRIVER_MOVEMENT_HISTORY,
        payload: driverMovementHistory,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDriverMovementHistoryActivity(false));
    }
  };

const postDriverMovementHistory =
  async ({
    startDate,
    endDate,
    driver: { value: driverId },
    driverMovement: { value: driverMovementId },
    note,
    secondaryNote,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagDriverMovementHistoryActivity(true));
      const payload = {
        startDate,
        endDate,
        driverId,
        driverMovementId,
        note,
        secondaryNote,
      };
      const response = await fetch(DRIVER_MOVEMENT_HISTORY_ENDPOINT, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const driverMovementConflicts = await response.json();

      dispatch({
        type: POST_DRIVER_MOVEMENT_HISTORY,
        payload: driverMovementConflicts,
      });

      const driverMovement = driverMovementConflicts.find(
        (item) => item.isConflicted === false,
      );

      if (driverMovement) {
        dispatch(push(`${DRIVER_MOVEMENT_HISTORY_PATH}/${driverMovement.id}`));
        return;
      }

      driverMovementConflicts.forEach((movement) => {
        toastr.warning(
          'Movimiento en conflicto',
          `No se pudo registrar el Movimiento debido que está en: ${
            movement.name
          } desde ${tzNormalizeDate({
            date: movement.startDate,
            format: DATE_TIME_FORMAT,
          })} hasta ${tzNormalizeDate({
            date: movement.endDate,
            format: DATE_TIME_FORMAT,
          })}`,
          {
            removeOnHover: false,
            timeOut: 0,
            showCloseButton: true,
          },
        );
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDriverMovementHistoryActivity(false));
    }
  };

const putDriverMovementHistory =
  async (driverMovementId, { note, secondaryNote, startDate, endDate }) =>
  async (dispatch) => {
    try {
      dispatch(flagDriverMovementHistoryActivity(true));
      const payload = {
        note,
        secondaryNote,
        startDate,
        endDate,
      };
      const url = `${DRIVER_MOVEMENT_HISTORY_ENDPOINT}/${driverMovementId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      const driverMovement = await response.json();

      dispatch({
        type: PUT_DRIVER_MOVEMENT_HISTORY,
        payload: driverMovement,
      });

      dispatch(push(`${DRIVER_MOVEMENT_HISTORY_PATH}/${driverMovement.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagDriverMovementHistoryActivity(false));
    }
  };

const getPrintDriverMovementHistory =
  async (formValues) => async (dispatch) => {
    try {
      dispatch(flagGettingDriversMovementHistory(true));
      const url = `${DRIVER_MOVEMENT_HISTORY_ENDPOINT}/export-file?${QueryString.stringify(
        formValues,
      )}`;
      const response = await fetch(url, {
        ...DEFAULT_GET_CONFIG,
      });
      await isErrorResponse(response, null, dispatch);
      const blob = await response.blob();
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'Tareo.xlsx';
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingDriversMovementHistory(false));
    }
  };

export {
  flagGettingDriversMovementHistory,
  getDriversMovementHistory,
  clearDriversMovementHistory,
  flagDriverMovementHistoryActivity,
  getDriverMovementHistory,
  postDriverMovementHistory,
  getPrintDriverMovementHistory,
  putDriverMovementHistory,
};
