import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { LEGAL_ABANDONMENT_ENPOINT } from '../../config/endpoints';
import { DEFAULT_GET_CONFIG, DEFAULT_PUT_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  CLEAR_UNCLAIMED_CARGO_ITEMS,
  FLAG_GETTING_UNCLAIMED_CARGO_ITEMS,
  FLAG_LEGAL_ABANDONMENT_ACTIVITY,
  GET_UNCLAIMED_CARGO_ITEMS,
} from '../types/cargo/LegalAbandonment';

const flagLegalAbandonmentActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_LEGAL_ABANDONMENT_ACTIVITY,
    payload: flag,
  });

const flagGettingUnclaimedCargoItems = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_UNCLAIMED_CARGO_ITEMS,
    payload: flag,
  });

const clearUnclaimedCargoItems = () => (dispatch) =>
  dispatch({
    type: CLEAR_UNCLAIMED_CARGO_ITEMS,
  });

const putStatusToDepured =
  async (legalAbandonmentDepuratedRequest) => async (dispatch) => {
    try {
      dispatch(flagLegalAbandonmentActivity(true));

      const url = LEGAL_ABANDONMENT_ENPOINT;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(legalAbandonmentDepuratedRequest),
      });
      await isErrorResponse(response, null, dispatch);
      toastr.success('Confirmacion', 'Encomiendas Depuradas Exitosamente');
      return response;
    } catch (error) {
      toastr.error('Error', error.message);
      return null;
    } finally {
      dispatch(flagLegalAbandonmentActivity(false));
    }
  };

const getUnclaimedCargoItems = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingUnclaimedCargoItems(true));
    const query = tableFilters;
    const url = `${LEGAL_ABANDONMENT_ENPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response, null, dispatch);
    const uclaimedCargoItems = await response.json();
    dispatch({
      type: GET_UNCLAIMED_CARGO_ITEMS,
      payload: uclaimedCargoItems,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingUnclaimedCargoItems(false));
  }
};

export {
  flagLegalAbandonmentActivity,
  flagGettingUnclaimedCargoItems,
  clearUnclaimedCargoItems,
  putStatusToDepured,
  getUnclaimedCargoItems,
};
