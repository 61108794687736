export const FLAG_GETTING_DRIVERS_MOVEMENT_HISTORY =
  'FLAG_GETTING_DRIVERS_MOVEMENT_HISTORY';
export const GET_DRIVERS_MOVEMENT_HISTORY = 'GET_DRIVERS_MOVEMENT_HISTORY';
export const CLEAR_DRIVERS_MOVEMENT_HISTORY = 'CLEAR_DRIVERS_MOVEMENT_HISTORY';
export const FLAG_DRIVER_MOVEMENT_HISTORY_ACTIVITY =
  'FLAG_DRIVER_MOVEMENT_HISTORY_ACTIVITY';
export const GET_DRIVER_MOVEMENT_HISTORY = 'GET_DRIVER_MOVEMENT_HISTORY';
export const CLEAR_DRIVER_MOVEMENT_HISTORY = 'CLEAR_DRIVER_MOVEMENT_HISTORY';
export const POST_DRIVER_MOVEMENT_HISTORY = 'POST_DRIVER_MOVEMENT_HISTORY';
export const PUT_DRIVER_MOVEMENT_HISTORY = 'PUT_DRIVER_MOVEMENT_HISTORY';
