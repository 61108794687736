import { number, string } from 'prop-types';

const circuitBasicInformationPropTypes = {
  id: number.isRequired,
  name: string.isRequired,
  description: string.isRequired,
  createDate: number.isRequired,
  lastUpdate: number.isRequired,
};

export default circuitBasicInformationPropTypes;
