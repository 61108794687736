import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Button, Form, FormGroup, Input, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { printExpense } from '../../../../utils/printers/Expense';
import {
  getCheckExpenseItinerary,
  getPreviewTripExpense,
  postTripExpense,
} from '../../../../actions';
import Modal from '../../../common/modal/Modal';
import PreviewTripExpense from './PreviewTripExpense';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { GET } from '../../../../config/permissions';
import { generateGetPreviewTripEndpoint } from '../../../../config/endpoints';

class PrintExpenseButton extends Component {
  constructor(props) {
    super(props);

    const { circuitId, routeId } = props.itinerary.toJS();

    this.state = {
      showModal: false,
      modalBody: null,
      disableConfirmButton: true,
      driverId: 0,
      loading: false,
      isExistExpenseItinerary: false,
      // isTripExpenseByCircuit is validated about
      // https://civa.atlassian.net/browse/FLIT-2879
      isTripExpenseByCircuit:
        (circuitId === 156 && (routeId === 365 || routeId === 366)) || // LIMA - HUARAZ - LIMA
        (circuitId === 318 && (routeId === 778 || routeId === 779)) || // LIMA SUPERCIVA/ECONOCIVA - HUANCAYO - LIMA SUPERCIVA/ECONOCIVA
        (circuitId === 101 && (routeId === 391 || routeId === 381)) || // LIMA - MARCONA - LIMA
        (circuitId === 448 && (routeId === 951 || routeId === 952)) || // LIMA - OXAMPAMPA - LIMA
        (circuitId === 319 && (routeId === 778 || routeId === 780)) || // LIMA EXCLUCIVA - HUANCAYO - LIMA EXCLUCIVA
        (circuitId === 83 && (routeId === 380 || routeId === 374)), // LIMA - AYACUCHO
    };
  }

  onClickPreview = async () => {
    const {
      drivers,
      dispatchGetPreviewTripExpense,
      itinerary,
      dispatchGetCheckExpenseItinerary,
    } = this.props;

    const { isTripExpenseByCircuit } = this.state;

    this.setState({ loading: true });

    let modalBody;

    const driverOptions = drivers.map(({ driverId, driverName }) => (
      <option key={driverId.toString()} value={driverId}>
        {driverName}
      </option>
    ));

    const checkItineraryExpense = await dispatchGetCheckExpenseItinerary({
      itineraryId: itinerary.get('id'),
    });

    const driverArray = drivers.toArray();

    if (checkItineraryExpense) {
      this.setState({
        loading: false,
        isExistExpenseItinerary: true,
        disableConfirmButton: false,
        driverId: driverArray[0].driverId,
      });

      modalBody = (
        <>
          <p className="text-justify">
            El Itinerario seleccionado ya genero un gasto de viaje previamente
          </p>
        </>
      );

      this.setState({ showModal: true, modalBody });
    } else {
      const tripExpenseList = await dispatchGetPreviewTripExpense(
        {
          itineraryId: itinerary.get('id'),
        },
        isTripExpenseByCircuit,
      );

      this.setState({ loading: false });

      if (tripExpenseList && tripExpenseList.length > 0) {
        const itineraryText = isTripExpenseByCircuit
          ? 'estos Itinerarios'
          : 'este Itinerario';
        modalBody = (
          <Row>
            <Col sm={12} md={12} lg={7}>
              <h5>Vista Previa - Gastos de Viaje</h5>
              <PreviewTripExpense expenseList={tripExpenseList} />
            </Col>
            <Col sm={12} md={12} lg={5}>
              <p className="text-justify">
                ¿Está seguro que desea generar Gastos de Viaje para{' '}
                {itineraryText}? Esta acción DESCONTARÁ DINERO DE SU CAJA de
                forma automática por el monto autorizado para Peajes y
                Condiciones de Trabajo.
              </p>
              <p className="text-justify">
                Si está seguro, por favor seleccione al Conductor que cobra para
                generar los Gastos de Viaje.
              </p>
              <Form>
                <FormGroup row>
                  <Col>
                    <Input
                      type="select"
                      name="driver"
                      id="driver"
                      onChange={this.handleChangeDriver}
                    >
                      <option value="">Seleccione</option>
                      {driverOptions}
                    </Input>
                  </Col>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        );

        this.setState({ showModal: true, modalBody });
      }
    }
  };

  onClickGenerateTripExpense = async () => {
    const { itinerary, dispatchPostTripExpense, drivers } = this.props;

    const {
      driverId: driverPayeeId,
      isTripExpenseByCircuit,
      isExistExpenseItinerary,
    } = this.state;

    const otherDrivers = drivers
      .filter(({ driverId }) => Number(driverId) !== Number(driverPayeeId))
      .toJS();

    const secondDriverPayeeId = otherDrivers[0]
      ? otherDrivers[0].driverId
      : null;

    const thirdDriverPayeeId = otherDrivers[1]
      ? otherDrivers[1].driverId
      : null;

    const tripExpenseData = {
      itineraryId: itinerary.get('id'),
      driverPayeeId: Number(driverPayeeId),
      secondDriverPayeeId,
      thirdDriverPayeeId,
    };

    const tripExpenseList = await dispatchPostTripExpense(
      tripExpenseData,
      isTripExpenseByCircuit,
    );

    if (tripExpenseList && tripExpenseList.length > 0)
      printExpense(tripExpenseList, drivers.toJS(), isExistExpenseItinerary);
  };

  handleChangeDriver = (e) => {
    const {
      currentTarget: { value },
    } = e;

    let setState = { disableConfirmButton: true, driverId: 0 };

    if (value !== '') {
      setState = { disableConfirmButton: false, driverId: value };
    }

    this.setState(setState);
  };

  render() {
    const {
      showModal,
      modalBody,
      disableConfirmButton,
      loading,
      isExistExpenseItinerary,
    } = this.state;

    const loadingIcon = loading ? (
      <i className="fa fa-spinner fa-spin fa-fw" />
    ) : (
      <i className="fa fa-print" />
    );

    const modalConfirmButtonText = !isExistExpenseItinerary
      ? 'Generar Gastos de Viaje'
      : 'Reimprimir Gastos de Viaje';

    return (
      <Fragment>
        <Modal
          show={showModal}
          title="Confirmación"
          body={modalBody}
          onClickConfirm={() => this.onClickGenerateTripExpense()}
          onClickCancel={() => this.setState({ showModal: false })}
          onClickClose={() => this.setState({ showModal: false })}
          confirmButtonText={modalConfirmButtonText}
          cancelButtonText="Cancelar"
          size="lg"
          disableConfirmButton={disableConfirmButton}
        />
        <Button color="light" className="border" onClick={this.onClickPreview}>
          {loadingIcon} Gastos de Viaje
        </Button>
      </Fragment>
    );
  }
}

PrintExpenseButton.propTypes = {
  itinerary: PropTypes.instanceOf(Immutable.Map).isRequired,
  drivers: PropTypes.instanceOf(Immutable.Set).isRequired,
  dispatchPostTripExpense: PropTypes.func.isRequired,
  dispatchGetPreviewTripExpense: PropTypes.func.isRequired,
  dispatchGetCheckExpenseItinerary: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ItineraryUnit }) => ({
  itinerary: ItineraryUnit.Itinerary.getIn(['current', 'content']),
});

const mapDispatchToProps = {
  dispatchPostTripExpense: postTripExpense,
  dispatchGetPreviewTripExpense: getPreviewTripExpense,
  dispatchGetCheckExpenseItinerary: getCheckExpenseItinerary,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrintExpenseButton);

export default withEndpointAuthorization({
  url: generateGetPreviewTripEndpoint(0),
  permissionType: GET,
})(connectedComponent);
