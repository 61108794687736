import {
  string,
  shape,
  number,
  arrayOf,
  oneOfType,
  instanceOf,
  bool,
} from 'prop-types';

const companyResourcePropTypes = {
  id: number.isRequired,
  name: string.isRequired,
  companyResourceTypeId: string.isRequired,
  active: bool.isRequired,
  companyResourceType: shape({
    id: number,
    description: string,
  }).isRequired,
  companyResourceGroupId: number,
};

const CompanyResourceBasicInformationPropTypes = {
  id: number.isRequired,
  name: string.isRequired,
  companyResourceList: arrayOf(shape(companyResourcePropTypes)).isRequired,
  createDate: oneOfType([number, instanceOf(Date)]).isRequired,
  lastUpdate: oneOfType([number, instanceOf(Date)]).isRequired,
};

export default CompanyResourceBasicInformationPropTypes;
