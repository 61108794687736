import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import { change, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import { isRequired, validateEmail } from '../../../../utils/validators';
import Select from '../../../common/forms/select/Select';
import { enumToSelectOptions } from '../../../../utils/enum';
import {
  CURRENCY_CODE,
  NIUBIZ_DOCUMENT_TYPE,
} from '../../../../config/constants';
import withEndpointAuthorization from '../../authorization/withEndPointAuthorization';
import { POST } from '../../../../config/permissions';
import { NIUBIZ_LINK_ENDPOINT } from '../../../../config/endpoints';
import { optionPropTypes } from '../../../common/forms/select/SelectPropTypes';
import { postGenerateNiubizLink } from '../../../../actions';
import Modal from '../../../common/modal/Modal';
import NiubizLinkModal from './NiubizLinkModal';

const NiubizFields = ({
  form,
  dispatchChange,
  receiverValues: {
    amount,
    currency,
    userName,
    userLastName,
    userEmail,
    userDocumentNumber,
    userDocumentType,
    userCodeCountry,
    userPhone,
    transactionCode,
  },
  dispatchPostGenerateNiubizLink,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [niubizResponse, setNiubizResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const modalBody = (
    <NiubizLinkModal niubizResponse={niubizResponse} isLoading={isLoading} />
  );

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const validateValues = () => {
    let isValid = false;
    if (!userName) toastr.error('Error', 'Nombre es obligatorio.');
    if (!userLastName) toastr.error('Error', 'Apellido es obligatorio.');
    if (!userEmail) toastr.error('Error', 'Correo es obligatorio.');
    if (!userDocumentNumber)
      toastr.error('Error', 'Nro. de Documento es obligatorio.');
    if (!userCodeCountry)
      toastr.error('Error', 'Código de País es obligatorio.');
    if (!userPhone) toastr.error('Error', 'Celular es obligatorio.');

    if (
      userName &&
      userLastName &&
      userEmail &&
      userDocumentNumber &&
      userCodeCountry &&
      userPhone
    ) {
      isValid = true;
    }

    return isValid;
  };

  const validateNiubizResponse = (response) => {
    if (!response) return false;
    if (!response.link) return false;
    return true;
  };

  const handleGenerateLink = async () => {
    const isValid = validateValues();
    if (!isValid || niubizResponse) return;

    setIsLoading(true);
    setShowModal(true);

    const response = await dispatchPostGenerateNiubizLink({
      amount,
      currency,
      userName,
      userLastName,
      userEmail,
      userDocumentNumber,
      userDocumentType: userDocumentType.value,
      userCodeCountry,
      userPhone,
      transactionCode,
    });

    const isSucces = validateNiubizResponse(response);
    setIsLoading(false);

    if (!isSucces) {
      toastr.error('Error al crear el link');
      setShowModal(false);
      return;
    }

    setNiubizResponse(response);
    dispatchChange(form, 'niubizLink', response.link);
  };

  return (
    <>
      <h2>Niubiz Pago Link</h2>
      <hr />
      <p className="text-muted">
        Los siguientes datos son necesarios para la correcta generación del Link
      </p>
      <FormGroup row>
        <FormItem label="Monto" required>
          <Row>
            <Col lg={3} md={4} sm={6} xs={6}>
              <Field
                name="amount"
                id="amount"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Monto"
                disabled
                validate={[isRequired]}
              />
            </Col>
            <Col lg={3} md={4} sm={6} xs={6}>
              <Field
                name="currency"
                id="currency"
                component={Select}
                showToolTip
                options={enumToSelectOptions(CURRENCY_CODE)}
                placeholder="Moneda"
                isDisabled
                validate={[isRequired]}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Pasajero" required>
          <Row>
            <Col md={6} sm={12} xs={12}>
              <Field
                name="userName"
                id="userName"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Nombre"
                validate={[isRequired]}
              />
            </Col>
            <Col md={6} sm={12} xs={12}>
              <Field
                name="userLastName"
                id="userLastName"
                component={TextInput}
                showToolTip
                type="text"
                validate={[isRequired]}
                placeholder="Apellido"
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Correo" required>
          <Field
            name="userEmail"
            component={TextInput}
            type="text"
            validate={[isRequired, validateEmail]}
            placeholder="Correo"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Doc. de Identidad" required>
          <Row>
            <Col lg={3} md={4} sm={6} xs={6}>
              <Field
                name="userDocumentNumber"
                id="userDocumentNumber"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Nro. de Documento"
                validate={[isRequired]}
              />
            </Col>
            <Col lg={3} md={4} sm={6} xs={6}>
              <Field
                name="userDocumentType"
                id="userDocumentType"
                component={Select}
                isClearable={false}
                showToolTip
                options={enumToSelectOptions(NIUBIZ_DOCUMENT_TYPE)}
                placeholder="Tipo de Documento"
                validate={[isRequired]}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Celular" required>
          <Row>
            <Col lg={2} md={3} sm={4} xs={6}>
              <Field
                name="userCodeCountry"
                id="userCodeCountry"
                component={TextInput}
                showToolTip
                type="text"
                placeholder="Código de País"
                validate={[isRequired]}
              />
            </Col>
            <Col lg={4} md={5} sm={8} xs={6}>
              <Field
                name="userPhone"
                id="userPhone"
                component={TextInput}
                type="text"
                showToolTip
                placeholder="Celular"
                validate={[isRequired]}
              />
              <Field
                name="transactionCode"
                component={TextInput}
                type="hidden"
                placeholder=""
                validate={[isRequired]}
              />
            </Col>
          </Row>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem>
          <Button
            type="button"
            color="secondary"
            onClick={handleGenerateLink}
            disabled={niubizResponse || isLoading}
          >
            <i className="fa fa-link" /> Generar Link
          </Button>{' '}
          {niubizResponse && (
            <Button type="button" color="secondary" onClick={handleOpenModal}>
              <i className="fa fa-window-maximize" /> Mostrar Link
            </Button>
          )}
        </FormItem>
      </FormGroup>
      <Modal
        title="Niubiz Pago Link"
        onClickCancel={handleCloseModal}
        onClickClose={handleCloseModal}
        show={showModal}
        body={modalBody}
      />
    </>
  );
};

NiubizFields.propTypes = {
  receiverValues: PropTypes.shape({
    amount: PropTypes.number,
    currency: optionPropTypes,
    userName: PropTypes.string,
    userLastName: PropTypes.string,
    userEmail: PropTypes.string,
    userDocumentNumber: PropTypes.string,
    userDocumentType: optionPropTypes,
    userCodeCountry: PropTypes.string,
    userPhone: PropTypes.string,
    transactionCode: PropTypes.number,
  }).isRequired,
  dispatchPostGenerateNiubizLink: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { selector }) => ({
  receiverValues: {
    amount: selector(state, 'amount'),
    currency: selector(state, 'currency'),
    userName: selector(state, 'userName'),
    userLastName: selector(state, 'userLastName'),
    userEmail: selector(state, 'userEmail'),
    userDocumentNumber: selector(state, 'userDocumentNumber'),
    userDocumentType: selector(state, 'userDocumentType'),
    userCodeCountry: selector(state, 'userCodeCountry'),
    userPhone: selector(state, 'userPhone'),
    transactionCode: selector(state, 'transactionCode'),
  },
});

const mapDispatchToProps = {
  dispatchPostGenerateNiubizLink: postGenerateNiubizLink,
  dispatchChange: change,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NiubizFields);

export default withEndpointAuthorization({
  url: NIUBIZ_LINK_ENDPOINT,
  permissionType: POST,
})(connectedComponent);
