import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { MANUAL_VOUCHER_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import ManualVoucherForm from './ManualVoucherForm';
import Loader from '../../../common/Loader';
import {
  clearManualVoucher,
  getManualVoucher,
  putManualVoucher,
} from '../../../../actions';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import { currencyToNumber, numberFormatter } from '../../../../utils/number';
import {
  CURRENCY_CODE,
  MANUAL_VOUCHER_PAYMENT_TYPE,
  MANUAL_VOUCHER_SERVICE_TYPE,
} from '../../../../config/constants';
import { padStart } from '../../../../utils/string';
import { tzNormalizeDate } from '../../../../utils/date';

export class EditManualVoucher extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id: manualVoucherId },
      },
      dispatchGetManualVoucher,
    } = this.props;
    dispatchGetManualVoucher({ manualVoucherId });
  }

  componentWillUnmount() {
    this.props.dispatchClearManualVoucher();
  }

  onSubmit = (formValues) => {
    const {
      dispatchPutManualVoucher,
      match: {
        params: { id },
      },
      voucher,
    } = this.props;

    const newFormValues = { ...formValues };

    if (formValues.businessId)
      newFormValues.businessId = formValues.businessId.value;

    if (formValues.customerId)
      newFormValues.customerId = formValues.customerId.value;

    newFormValues.installments = formValues.installments || 1;
    newFormValues.voucherTypeId = formValues.voucherTypeId.value;
    newFormValues.serviceType = formValues.serviceType.value;
    newFormValues.currencyCode = formValues.currencyCode.value;
    newFormValues.paymentType = formValues.paymentType.value;

    newFormValues.items = formValues.items.map((item) => ({
      ...item,
      totalPrice: currencyToNumber(item.totalPrice),
    }));

    newFormValues.totalAmountWithoutTaxes = currencyToNumber(
      formValues.totalAmountWithoutTaxes,
    );
    newFormValues.taxes = currencyToNumber(formValues.taxes);
    newFormValues.totalAmount = currencyToNumber(formValues.totalAmount);
    newFormValues.detractionAmount =
      formValues.detractionAmount &&
      currencyToNumber(formValues.detractionAmount);
    newFormValues.totalAmountPayable =
      formValues.totalAmountPayable &&
      currencyToNumber(formValues.totalAmountPayable);

    const voucherJson = voucher.toJS();
    newFormValues.documentSeries = voucherJson.documentSeries;
    newFormValues.documentCode = voucherJson.documentCode;

    newFormValues.userId = voucher.get('userId');
    newFormValues.createDate = voucher.get('createDate');
    newFormValues.status = voucher.get('status');

    dispatchPutManualVoucher(id, newFormValues);
  };

  generateInitialValues = ({
    voucherType,
    customer,
    business,
    issueDate,
    paymentType,
    affectedByTaxes,
    currencyCode,
    items,
    description,
    detraction,
    detractionAmount,
    serviceType,
    installments,
    dueDate,
    totalAmount,
    totalAmountPayable,
    taxes,
    totalAmountWithoutTaxes,
    documentSeries,
    documentCode,
    previousManualVoucher,
  }) => {
    const currencyCodeObject = CURRENCY_CODE[currencyCode];

    const previousManualVoucherId =
      previousManualVoucher &&
      `${previousManualVoucher.id} - ${
        previousManualVoucher.documentSeries
      }-${padStart(previousManualVoucher.documentCode, 7)} - ${numberFormatter({
        style: 'currency',
        value: previousManualVoucher.totalAmountPayable,
        currency: currencyCode,
      })}`;

    return {
      previousManualVoucherId,
      businessId: business && {
        value: business.id,
        label: `${business.businessTaxId} - ${business.name}`,
        businessTaxId: business.businessTaxId,
        name: business.name,
        email: business.email,
        primaryContact: business.primaryContact,
        phone: business.phone,
        fax: business.fax,
        addressSummary: business.addressSummary,
        addressId: business.addressId,
        address: business.address,
      },
      customerId: customer && {
        value: customer.id,
        label: `${customer.idDocumentNumber} (${customer.idCountryOfOrigin}) - ${customer.fullName}`,
        id: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        fullName: customer.fullName,
        idDocumentNumber: customer.idDocumentNumber,
        gender: customer.gender,
        idCountryOfOrigin: customer.idCountryOfOrigin,
        identificationType: customer.identificationType,
        email: customer.email,
        mobilePhone: customer.mobilePhone,
        dob: customer.dob,
        discountCode: customer.discountCode,
      },
      isPerson: !!customer,
      issueDate: tzNormalizeDate({ date: issueDate }),
      totalAmountPayable: numberFormatter({
        style: 'currency',
        value: totalAmountPayable,
        currency: currencyCodeObject.value,
      }),
      contractItineraryListElements: 'true',
      totalAmount: numberFormatter({
        style: 'currency',
        value: totalAmount,
        currency: currencyCodeObject.value,
      }),
      taxes: numberFormatter({
        style: 'currency',
        value: taxes,
        currency: currencyCodeObject.value,
      }),
      totalAmountWithoutTaxes: numberFormatter({
        style: 'currency',
        value: totalAmountWithoutTaxes,
        currency: currencyCodeObject.value,
      }),
      installments,
      dueDate: tzNormalizeDate({ date: dueDate }),
      description,
      detraction,
      detractionAmount: numberFormatter({
        style: 'currency',
        value: detractionAmount,
        currency: currencyCodeObject.value,
      }),
      affectedByTaxes,
      currencyCode: currencyCodeObject,
      serviceType: MANUAL_VOUCHER_SERVICE_TYPE[serviceType],
      paymentType: MANUAL_VOUCHER_PAYMENT_TYPE[paymentType],
      items: items.map(
        ({
          quantity,
          description: itemDescription,
          unitaryPrice,
          totalPrice,
        }) => ({
          quantity,
          description: itemDescription,
          unitaryPrice,
          totalPrice: numberFormatter({
            style: 'currency',
            value: totalPrice,
            currency: currencyCodeObject.value,
          }),
        }),
      ),
      documentSeries,
      documentCode,
      voucherTypeId: {
        value: voucherType.id,
        label: voucherType.name,
        voucherCode: voucherType.voucherCode,
      },
    };
  };

  render() {
    const { breadcrumbs, voucher, loading } = this.props;

    let content = null;

    if (loading) {
      content = <Loader />;
    } else if (voucher.isEmpty()) {
      content = <NoDataResource returnPage={MANUAL_VOUCHER_PATH} />;
    } else {
      content = (
        <ManualVoucherForm
          onSubmit={this.onSubmit}
          initialValues={this.generateInitialValues(voucher.toJS())}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Editar Comprobante Manual"
        subtitle="Edita este comprobante manual"
        content={content}
      />
    );
  }
}

const mapStateToProps = (
  { AccountingUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Comprobantes Manuales',
      href: MANUAL_VOUCHER_PATH,
    },
    {
      text: 'Ver',
      href: `${MANUAL_VOUCHER_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  voucher: AccountingUnit.ManualVoucher.getIn(['current', 'content']),
  loading: !AccountingUnit.ManualVoucher.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPutManualVoucher: putManualVoucher,
  dispatchGetManualVoucher: getManualVoucher,
  dispatchClearManualVoucher: clearManualVoucher,
};

EditManualVoucher.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutManualVoucher: PropTypes.func.isRequired,
  dispatchGetManualVoucher: PropTypes.func.isRequired,
  dispatchClearManualVoucher: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  voucher: PropTypes.instanceOf(Immutable.Map).isRequired,
};

EditManualVoucher.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditManualVoucher);
