import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import PrintCargoTicketButton from '../../reservation/ticket/ticket-toolbar/PrintCargoTicketButton';
import PrintCargoVoucherButton from '../../reservation/ticket/ticket-toolbar/PrintCargoVoucherButton';
import PayParcelButton from './PayParcelButton';
import {
  PARCEL_FINANCIAL_STATUS,
  PARCEL_PAYMENT_STATUS,
  PARCEL_PAYMENT_STATUS_WAITING_FOR_PAYMENT,
  PARCEL_STATUS,
  PARCEL_STATUS_UNCLAIMED,
  PAYMENT_METHOD_PAGO_EN_DESTINO,
} from '../../../../config/constants';
import DeliverParcelButton from './DeliverParcelButton';
import ChangeSecretCodeButton from './ChangeSecretCodeButton';
import CreateCreditNoteParcelButton from './CreateCreditNoteParcelButton';
import SendEmailButton from './SendEmailButton';
import DeliverParcelQrButton from './DeliverParcelQrButton';
import VoidButton from './VoidButton';
import VoidPaymentButton from './VoidPaymentButton';
import DeliverParcelButtonLegalAbandonment from './DeliverParcelButtonLegalAbandonment';

const ParcelToolbar = ({ currentSalesSession, parcel }) => (
  <ButtonToolbar className="pull-right">
    {parcel.financialStatus === PARCEL_FINANCIAL_STATUS.ACTIVE.value && (
      <div>
        <PrintCargoTicketButton ticket={parcel} />{' '}
        <PrintCargoVoucherButton voucher={parcel} />{' '}
        {!currentSalesSession.isEmpty() &&
          parcel.salesSessionId === currentSalesSession.get('id') && (
            <>
              <VoidButton parcelId={parcel.id} status={parcel.status} />
            </>
          )}{' '}
        {parcel.isPaymentOnArrival &&
          !currentSalesSession.isEmpty() &&
          parcel.paymentStatus === PARCEL_PAYMENT_STATUS.PAID.value && (
            <VoidPaymentButton parcelId={parcel.id} />
          )}{' '}
        {parcel.paymentStatus === PARCEL_PAYMENT_STATUS_WAITING_FOR_PAYMENT &&
          parcel.transactionCode === PAYMENT_METHOD_PAGO_EN_DESTINO && (
            <PayParcelButton parcel={parcel} />
          )}{' '}
        {(parcel.paymentStatus !== PARCEL_PAYMENT_STATUS_WAITING_FOR_PAYMENT ||
          parcel.transactionCode !== PAYMENT_METHOD_PAGO_EN_DESTINO) &&
          parcel.status !== PARCEL_STATUS.DELIVERED.value && (
            <>
              <DeliverParcelButton parcel={parcel} />{' '}
              <DeliverParcelQrButton parcel={parcel} />{' '}
              {parcel.status.includes(PARCEL_STATUS_UNCLAIMED) && (
                <DeliverParcelButtonLegalAbandonment parcel={parcel} />
              )}
            </>
          )}{' '}
        {parcel.status !== PARCEL_STATUS.DELIVERED.value && (
          <ChangeSecretCodeButton parcel={parcel} />
        )}{' '}
        {parcel.status !== PARCEL_STATUS.DELIVERED.value && (
          <CreateCreditNoteParcelButton parcel={parcel} />
        )}{' '}
        <SendEmailButton parcel={parcel} />
      </div>
    )}
  </ButtonToolbar>
);

ParcelToolbar.propTypes = {
  parcel: PropTypes.instanceOf(Object).isRequired,
  currentSalesSession: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default ParcelToolbar;
