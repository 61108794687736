import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import Loader from '../../../common/Loader';
import ReceiverForm from './ReceiverForm';
import './Receiver.css';
import PassengerDetails from './PassengerDetails';
import { customerOrderPropType } from './ReceiverPropTypes';
import { CIP_DOCUMENT_TYPE, CURRENCY_CODE } from '../../../../config/constants';

const generateInitialValues = (customerOrderId, finalPrice) => ({
  currency: CURRENCY_CODE.PEN,
  amount: finalPrice,
  userDocumentType: CIP_DOCUMENT_TYPE.DNI,
  transactionCode: customerOrderId,
  userCodeCountry: '+51',
});

const Receiver = ({ customerOrder, postingPretransaction, finalPrice }) => {
  if (!customerOrder || postingPretransaction) return <Loader />;

  return (
    <Container className="Receiver">
      <h2>Información de reserva</h2>
      <PassengerDetails
        passengers={customerOrder.passengers}
        finalPrice={finalPrice}
      />
      <br />
      <ReceiverForm
        initialValues={generateInitialValues(customerOrder.id, finalPrice)}
        passengers={customerOrder.passengers}
        totalPrice={customerOrder.totalPrice}
      />
    </Container>
  );
};

Receiver.propTypes = {
  customerOrder: customerOrderPropType.isRequired,
  postingPretransaction: PropTypes.bool.isRequired,
  finalPrice: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const customerOrderMap = state.PostpaidBookingUnit.PostpaidBooking.getIn([
    'receiver',
    'customerOrder',
  ]);

  let finalPrice = 0;
  const passengers = customerOrderMap.get('passengers');
  if (passengers && passengers.length > 0)
    finalPrice = passengers.reduce(
      (accumulated, customerOrder) => accumulated + customerOrder.salePrice,
      0,
    );

  return {
    customerOrder: customerOrderMap.isEmpty() ? null : customerOrderMap.toJS(),
    postingPretransaction: state.PostpaidBookingUnit.PostpaidBooking.getIn([
      'receiver',
      'postingPretransaction',
    ]),
    finalPrice,
  };
};

export default connect(mapStateToProps, null)(Receiver);
