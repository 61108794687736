import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup } from 'reactstrap';
import FormItem from '../../../../common/forms/FormItem';
import FormFooter from '../../../../common/forms/FormFooter';
import Select from '../../../../common/forms/select/Select';
import { LEGAL_ABANDONMENT_STATUS_OPTIONS } from '../../../../../config/constants';
import { enumToSelectOptions } from '../../../../../utils/enum';

const UnloadItemLegalAbandonmentModalForm = ({ items, handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    {items.map((item) => (
      <div key={item.correlation}>
        <div className="mb-2">
          <span className="d-block">
            <strong>Correlativo:</strong> {item.correlation}
          </span>
          <span className="d-block">
            <strong>Descripción:</strong> {item.description}
          </span>
          <span className="d-block">
            <strong>Destino Final:</strong>
            {item.destinationCityName}
          </span>
        </div>
        <FormGroup row>
          <FormItem label="Estado de encomienda">
            <Field
              name={`itemStatus-${item.correlation}`}
              component={Select}
              options={enumToSelectOptions(LEGAL_ABANDONMENT_STATUS_OPTIONS)}
            />
          </FormItem>
        </FormGroup>
      </div>
    ))}
    <FormFooter />
  </Form>
);

UnloadItemLegalAbandonmentModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(Immutable.List).isRequired,
};

UnloadItemLegalAbandonmentModalForm.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
  const initialValues = {};

  ownProps.items.forEach((item) => {
    initialValues[`itemStatus-${item.correlation}`] =
      LEGAL_ABANDONMENT_STATUS_OPTIONS.OK;
  });

  return {
    initialValues,
  };
};

const formComponent = reduxForm({
  form: 'UnloadItemLegalAbandonmentModalForm',
  enableReinitialize: true, // Permite reinicializar valores
})(UnloadItemLegalAbandonmentModalForm);

export default connect(mapStateToProps)(formComponent);
