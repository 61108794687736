import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { isRequired } from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import Loader from '../../../common/Loader';
import DynamicForm from '../../../common/forms/DynamicForm';
import LocationSelect from '../../../common/forms/select/LocationSelect';
import {
  DRIVER_CONTRACT_FORM_COLUMNS,
  DRIVER_DOCUMENT_FORM_COLUMNS,
} from '../../../../config/dynamicFormFields';
import CustomerField from '../../../common/forms/fields/CustomerField';
import DriverTypeSelect from '../../../common/forms/select/DriverTypeSelect';
import CrewStatusSelect from '../../../common/forms/select/CrewStatusSelect';
import FormFooter from '../../../common/forms/FormFooter';
import FileDetailResource from '../../../common/resource/FileDetailResource';

const DriverForm = ({ handleSubmit, loading, onSubmit, imageUrl }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    if (imageUrl) {
      setPreviewImage(imageUrl);
    }
  }, [imageUrl]);

  const isValidFileType = (file) => {
    const validExtensions = ['.jpg', '.jpeg', '.png'];
    const fileName = file.name.toLowerCase();
    const maxSize = 5 * 1024 * 1024;
    return (
      validExtensions.some((ext) => fileName.endsWith(ext)) &&
      file.size <= maxSize
    );
  };

  const onChangePicture = (e) => {
    const file = e.target.files[0];
    if (file && isValidFileType(file)) {
      setSelectedFile(file);
    } else {
      toastr.error(
        'Error',
        'Sólo se permiten archivos con extensión apta para imagen y tamaño máximo de 5MB',
      );
      e.target.value = null;
      setSelectedFile(null);
    }
  };

  const submitForm = (formValues) => {
    if (selectedFile && !isValidFileType(selectedFile)) {
      toastr.error(
        'Error',
        'El archivo seleccionado no es un archivo de imagen válido o excede el tamaño máximo permitido',
      );
      return;
    }

    const newFormValues = {
      ...formValues,
      file: selectedFile,
    };

    onSubmit(newFormValues);
  };

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      <FormGroup row>
        <FormItem label="Cliente" required>
          <CustomerField fieldName="customerId" validate={[isRequired]} />
        </FormItem>
      </FormGroup>
      <h3>Documentos</h3>
      <DynamicForm
        name="driverDocumentList"
        columns={DRIVER_DOCUMENT_FORM_COLUMNS}
      />
      <h3>Estado</h3>
      <FormGroup row>
        <FormItem label="Tipo de Conductor" required>
          <Field
            name="driverTypeId"
            component={DriverTypeSelect}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Base" required>
          <Field
            name="homeBaseId"
            component={LocationSelect}
            forHomeBase
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Estado" required>
          <Field
            name="crewStatusId"
            component={CrewStatusSelect}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <h3>Contratos</h3>
      <DynamicForm
        name="driverContractList"
        columns={DRIVER_CONTRACT_FORM_COLUMNS}
      />
      <FormGroup row>
        <Col sm={3} xs={12}>
          <Label>Foto</Label>
        </Col>
        <Col sm={9} xs={12}>
          <Input
            name="voucher"
            type="file"
            accept={['.jpg', '.jpeg', '.png']}
            onChange={onChangePicture}
            className="mb-4"
          />
          {selectedFile && <FileDetailResource file={selectedFile} />}

          {previewImage && !selectedFile && (
            <img
              src={previewImage}
              className="img-fluid"
              aria-hidden="true"
              width={300}
              alt=""
            />
          )}
        </Col>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

DriverForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  imageUrl: PropTypes.string,
};

DriverForm.defaultProps = {
  imageUrl: null,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  loading: !TrafficUnit.Driver.getIn(['current', 'activity']).isEmpty(),
});

const formComponent = reduxForm({
  form: 'DriverForm',
})(DriverForm);

export default connect(mapStateToProps)(formComponent);
