import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import ManualGrrForm from './ManualSenderRemissionGuideForm';
import { INT_DATE_FORMAT } from '../../../../config/locale';
import { tzNormalizeDate } from '../../../../utils/date';
import { postManualSenderRemissionGuide } from '../../../../actions/cargo/ManualSenderRemissionGuide';
import { MANUAL_SENDER_REMISSION_GUIDE_PATH } from '../../../../config/paths';
import {
  GRR_MOTIVE_BUSINESS_TRANSFER,
  GRR_MOTIVE_INTERNAL_TRANSFER,
  GRR_MOTIVE_OTHERS,
  GRR_MOTIVE_SALE,
} from '../../../../config/constants';
import ManualSenderRemissionGuideToolbar from './ManualSenderRemissionGuideToolbar';

const receiverIdByMotiveId = (business, motiveId, formValues) => {
  if (motiveId === GRR_MOTIVE_INTERNAL_TRANSFER) {
    return null;
  } else if (
    motiveId === GRR_MOTIVE_BUSINESS_TRANSFER ||
    motiveId === GRR_MOTIVE_SALE
  ) {
    return formValues.consigneeBusiness.value;
  } else if (motiveId === GRR_MOTIVE_OTHERS && business) {
    return formValues.consigneeBusiness.value;
  }
  return formValues.consigneeCustomer.value;
};

const NewManualSenderRemissionGuide = ({
  breadcrumbs,
  dispatchManualSenderRemissionGuide,
}) => {
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [contingencyRequest, setContingencyRequest] = useState({});

  const onSubmitManualGrr = (
    formValues,
    drivers,
    refDocumentRequired,
    business,
    internalParcelRequired,
    internalParcelId,
  ) => {
    const refDocument = {
      documentSeries: refDocumentRequired ? formValues.documentSeries : null,
      documentCode: refDocumentRequired ? formValues.documentCode : null,
      voucherType: refDocumentRequired ? formValues.voucherType.value : null,
      issuerDocumentType: 'RUC',
      issuerDocumentNumber: refDocumentRequired
        ? formValues.issuerDocumentNumber
        : null,
    };

    const newFormValues = {
      motiveId: formValues.motiveId.value,
      motiveDescription: formValues.motiveDescription,
      comments: formValues.comments,
      sourceLocationId: formValues.sourceLocation.value,
      destinationLocationId: formValues.destinationLocation.value,
      itemList: formValues.cargoItems,
      refDocumentRequired,
      documentReference: refDocumentRequired ? refDocument : null,
      consigneeBusiness: business,
      receiverId: receiverIdByMotiveId(
        business,
        formValues.motiveId.value,
        formValues,
      ),
      busLicensePlate: formValues.registeredBusId
        ? formValues.registeredBusId.licensePlate
        : null,
      driverIdList: drivers
        ? drivers.flatMap((bus) =>
            bus.driverAssignmentDateList.map(
              (driverAssignment) => driverAssignment.driverId,
            ),
          )
        : [],
      itineraryDepartureDate: tzNormalizeDate({
        date: formValues.itineraryDepartureDate,
        format: INT_DATE_FORMAT,
      }),
      internalParcelId,
      internalParcelRequired,
      registeredBusId: formValues.registeredBusId
        ? formValues.registeredBusId.value
        : null,
      externalLicensePlate: formValues.externalLicensePlate
        ? formValues.externalLicensePlate
        : null,
      externalDriverList: formValues.externalDriverList
        ? formValues.externalDriverList.map((driver) => ({
            ...driver,
            documentType: driver.documentType.value,
          }))
        : null,
    };

    setFirstSubmit(true);

    setContingencyRequest(newFormValues);

    dispatchManualSenderRemissionGuide(newFormValues);
  };

  const toolbar = (
    <ManualSenderRemissionGuideToolbar
      isFirstSubmit={firstSubmit}
      contingencyRequest={contingencyRequest}
    />
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Guía de Remisión Remitente"
      subtitle="Nueva Guía de Remisión Remitente"
      toolbar={toolbar}
      content={
        <ManualGrrForm
          onSubmit={onSubmitManualGrr}
          initialValues={{
            itineraryDepartureDate: tzNormalizeDate(),
          }}
        />
      }
    />
  );
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Guias Remision Remitente',
      href: MANUAL_SENDER_REMISSION_GUIDE_PATH,
    },
    {
      text: 'Nueva Guía de Remisión Remitente',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchManualSenderRemissionGuide: postManualSenderRemissionGuide,
};

NewManualSenderRemissionGuide.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchManualSenderRemissionGuide: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewManualSenderRemissionGuide);
