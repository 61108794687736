import React from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm } from 'redux-form';
import { PropTypes } from 'prop-types';
import { Button, FormGroup, Form } from 'reactstrap';
import FormItem from '../../../common/forms/FormItem';
import FormFooter from '../../../common/forms/FormFooter';
import RegisteredBusSelect from '../../../common/forms/select/RegisteredBusSelect';
import MaintenanceBusGroupSelect from '../../../common/forms/select/MaintenanceBusGroupSelect';
import CompanyResourceSelect from '../../../common/forms/select/CompanyResourceSelect';
import CompanyResourceGroupSelect from '../../../common/forms/select/CompanyResourceGroupSelect';

const validateBusOrCompanyResourceOrFuelGroup = (value, allValues) => {
  const {
    registeredBusId,
    companyResourceId,
    maintenanceBusGroupId,
    companyResourceGroupId,
  } = allValues;

  const isNotEmpty = (val) => {
    if (val === null || val === undefined) {
      return false;
    }
    if (Array.isArray(val)) {
      return val.length > 0;
    }
    if (typeof val === 'object') {
      return Object.keys(val).length > 0;
    }
    return val !== '';
  };

  const validFields = [
    registeredBusId,
    companyResourceId,
    maintenanceBusGroupId,
    companyResourceGroupId,
  ].filter(isNotEmpty);

  if (validFields.length === 0) {
    return 'Debe seleccionar uno: Bus, Recurso de la Compañía , Grupo de Buses o Grupo de Recursos de Compañia.';
  }

  if (validFields.length > 1) {
    return 'Solo debe seleccionar Bus, Recurso de la Compañía , Grupo de Buses o Grupo de Recursos de Compañia.';
  }

  return undefined;
};
const AddResourceMaintenancePlanningForm = ({
  handleSubmit,
  handleProcess,
  onCancel,
  isCompanyResource,
}) => (
  <Form onSubmit={handleSubmit(handleProcess)}>
    <p>Seleccione los Recursos que desea agregar</p>
    <hr />
    {!isCompanyResource && (
      <>
        <FormGroup row className="mt-4">
          <FormItem label="Bus">
            <Field
              name="registeredBusId"
              component={RegisteredBusSelect}
              isClearable
              isMulti
              validate={[validateBusOrCompanyResourceOrFuelGroup]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row className="mt-4">
          <FormItem label="Grupo de Buses">
            <Field
              name="maintenanceBusGroupId"
              component={MaintenanceBusGroupSelect}
              isClearable
              isMulti
              validate={[validateBusOrCompanyResourceOrFuelGroup]}
            />
          </FormItem>
        </FormGroup>
      </>
    )}

    {isCompanyResource && (
      <>
        <FormGroup row className="mt-4">
          <FormItem label="Recursos de Compañia">
            <Field
              name="companyResourceId"
              component={CompanyResourceSelect}
              isClearable
              isMulti
              validate={[validateBusOrCompanyResourceOrFuelGroup]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row className="mt-4">
          <FormItem label="Grupo de Recursos de Compañia">
            <Field
              name="companyResourceGroupId"
              component={CompanyResourceGroupSelect}
              isClearable
              isMulti
              validate={[validateBusOrCompanyResourceOrFuelGroup]}
            />
          </FormItem>
        </FormGroup>
      </>
    )}

    <FormFooter
      saveButtonColor="info"
      saveButtonIcon="fa fa-plus-circle"
      saveButtonText="Agregar"
    >
      <Button color="primary" onClick={onCancel}>
        Cancelar
      </Button>
    </FormFooter>
  </Form>
);

AddResourceMaintenancePlanningForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleProcess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isCompanyResource: PropTypes.bool,
};

AddResourceMaintenancePlanningForm.defaultProps = {
  isCompanyResource: false,
};

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'AddResourceMaintenancePlanningForm',
})(AddResourceMaintenancePlanningForm);

export default connect(null, mapDispatchToProps)(formComponent);
