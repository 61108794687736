import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getAgencies,
  clearAgencies,
  getVisibleAgencies,
} from '../../../../actions/index';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { optionsPropTypes } from './SelectPropTypes';

const AgencySelect = ({
  dispatchGetAgencies,
  clearAllAgencies,
  showOptions,
  dispatchClearAgencies,
  dispatchGetVisibleAgencies,
  agencies,
  isLoading,
  isMulti,
  input,
  meta,
  isDisabled,
  isClearable,
  visibilityRestricted,
}) => {
  useLayoutEffect(
    () => () => {
      if (clearAllAgencies) dispatchClearAgencies();
    },
    [],
  );

  const handleInputChange = (inputValue) => {
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      if (visibilityRestricted) {
        dispatchGetVisibleAgencies({ query: inputValue });
      } else {
        dispatchGetAgencies({ query: inputValue });
      }
    }
  };

  const agencyFilterOption = (options) => options;

  return (
    <Select
      options={!showOptions.length ? agencies : showOptions}
      isLoading={isLoading}
      isMulti={isMulti}
      input={input}
      meta={meta}
      isClearable={isClearable}
      isDisabled={isDisabled}
      onInputChange={handleInputChange}
      filterOption={agencyFilterOption}
      placeholder="Ingrese ubicación"
    />
  );
};

AgencySelect.propTypes = {
  dispatchGetAgencies: PropTypes.func.isRequired,
  dispatchClearAgencies: PropTypes.func.isRequired,
  dispatchGetVisibleAgencies: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  agencies: optionsPropTypes,
  meta: metaPropTypes,
  showOptions: PropTypes.instanceOf(Array),
  clearAllAgencies: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  visibilityRestricted: PropTypes.bool,
};

AgencySelect.defaultProps = {
  isLoading: false,
  isDisabled: false,
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isClearable: false,
  clearAllAgencies: true,
  showOptions: [],
  isMulti: false,
  agencies: [],
  visibilityRestricted: false,
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  agencies: HumanResourcesUnit.Agency.getIn(['all', 'content', 'content']).map(
    (agency) => ({ ...agency, value: agency.id, label: agency.name }),
  ),
  isLoading: HumanResourcesUnit.Agency.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetAgencies: getAgencies,
  dispatchGetVisibleAgencies: getVisibleAgencies,
  dispatchClearAgencies: clearAgencies,
};

export default connect(mapStateToProps, mapDispatchToprops)(AgencySelect);
