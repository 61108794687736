import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import { MAINTENANCE_BUS_GROUP_COLUMNS } from '../../../../config/columns';

import {
  COMPANY_RESOURCE_GROUP_PATH,
  NEW_COMPANY_RESOURCE_GROUP_PATH,
} from '../../../../config/paths';
import {
  clearCompanyResourceGroups,
  getCompanyResourceGroups,
} from '../../../../actions';

export const CompanyResourceGroups = ({
  breadcrumbs,
  companyResourcesGroups,
  loading,
  dispatchGetCompanyResourceGroups,
  dispatchClearCompanyResourceGroups,
}) => {
  useLayoutEffect(() => () => dispatchClearCompanyResourceGroups(), []);

  const data = companyResourcesGroups.get('content') || [];
  const pages = companyResourcesGroups.get('totalPages') || null;
  const defaultPageSize =
    companyResourcesGroups.get('size') || DEFAULT_PAGE_SIZE;

  return (
    <ModuleList
      title="Grupos de Recursos de Compañia"
      breadcrumbs={breadcrumbs}
      buttonPath={NEW_COMPANY_RESOURCE_GROUP_PATH}
      tableStructure={{
        columns: MAINTENANCE_BUS_GROUP_COLUMNS,
        data,
        pages,
        defaultPageSize,
        filterable: true,
        fetchData: dispatchGetCompanyResourceGroups,
        modelPath: COMPANY_RESOURCE_GROUP_PATH,
        loading,
        navigateToModelOnRowClick: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  dispatchGetCompanyResourceGroups: getCompanyResourceGroups,
  dispatchClearCompanyResourceGroups: clearCompanyResourceGroups,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Grupos de Recursos de Compañia',
      href: COMPANY_RESOURCE_GROUP_PATH,
    },
  ],
  companyResourcesGroups: MechanicalMaintenanceUnit.CompanyResourceGroup.getIn([
    'all',
    'content',
  ]),
  loading: MechanicalMaintenanceUnit.CompanyResourceGroup.getIn([
    'all',
    'loading',
  ]),
});

CompanyResourceGroups.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  companyResourcesGroups: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetCompanyResourceGroups: PropTypes.func.isRequired,
  dispatchClearCompanyResourceGroups: PropTypes.func.isRequired,
};

CompanyResourceGroups.defaultProps = {
  companyResourcesGroups: null,
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyResourceGroups);
