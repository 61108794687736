import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { getExpense, clearExpense } from '../../../../actions/index';
import Loader from '../../../common/Loader';
import { EXPENSE_PATH } from '../../../../config/paths';
import { TICKET_REFUND } from '../../../../config/constants';
import {
  matchPropTypes,
  breadcrumbsPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import ExpenseToolbar from './ExpenseToolbar';
import ExpenseBasicInformationResource from './resource/ExpenseBasicInformationResource';
import ExpenseItemInformationResource from './resource/ExpenseItemInformationResource';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';
import ExpenseUpdateHistoryInformationResource from './resource/ExpenseUpdateHistoryInformationResource';

const Expense = ({
  match: {
    params: { id: expenseId },
  },
  dispatchGetExpense,
  dispatchClearExpense,
  expense,
  loading,
  breadcrumbs,
}) => {
  useLayoutEffect(() => {
    dispatchGetExpense(expenseId);

    return () => dispatchClearExpense();
  }, []);

  let content;
  let toolbar;

  const transactionNumber = expense.get('transactionList')
    ? expense.get('transactionList').length
    : 0;

  if (loading) {
    content = <Loader />;
  } else if (expense.isEmpty()) {
    content = <NoDataResource returnPage={EXPENSE_PATH} />;
  } else {
    if (
      expense.get('transaction').expenseType.expenseTypeLabel !== TICKET_REFUND
    ) {
      toolbar = (
        <ExpenseToolbar
          expenseId={+expenseId}
          isTripExpense={transactionNumber > 1}
          status={expense.get('expenseStatus')}
        />
      );
    }

    const basicInformationResource = (
      <ExpenseBasicInformationResource expense={expense.toJS()} />
    );

    const itemInformationResource = (
      <ExpenseItemInformationResource expense={expense.toJS()} />
    );

    const updateHistoryInformationResource = (
      <ExpenseUpdateHistoryInformationResource expense={expense.toJS()} />
    );

    const internalInformationResource = (
      <InternalResource {...expense.toJS()} />
    );

    content = (
      <>
        {basicInformationResource}
        {itemInformationResource}
        {expense.get('expenseUpdateHistoryList').length !== 0 &&
          updateHistoryInformationResource}
        {internalInformationResource}
      </>
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Egreso"
      toolbar={toolbar}
      content={content}
    />
  );
};

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    { text: 'Egresos', href: EXPENSE_PATH },
    { text: 'Ver', href: '' },
  ],
  expense: SalesUnit.Expense.getIn(['current', 'content']),
  loading: !SalesUnit.Expense.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetExpense: getExpense,
  dispatchClearExpense: clearExpense,
};

Expense.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  match: matchPropTypes.isRequired,
  expense: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetExpense: PropTypes.func.isRequired,
  dispatchClearExpense: PropTypes.func.isRequired,
};

Expense.defaultProps = {
  breadcrumbs: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(Expense);
