import React, { Fragment } from 'react';
import { locationAttributeInformationPropTypes } from '../proptypes/LocationPropTypes';
import Checkbox from '../../../../common/forms/Checkbox';

const LocationAttributeInformationResource = ({
  isHomeBase,
  isTerminal,
  isPointOfSale,
  isStop,
  isToll,
  receiveCargoPoint,
  pickupCargoPoint,
  legalAbandonmentHomeBase,
}) => (
  <Fragment>
    <h3>Atributos</h3>
    <div>
      <Checkbox checked={isHomeBase} /> Base
    </div>
    <div>
      <Checkbox checked={isTerminal} /> Terrapuerto
    </div>
    <div>
      <Checkbox checked={isPointOfSale} /> Punto de Venta
    </div>
    <div>
      <Checkbox checked={isStop} /> Parada Autorizada
    </div>
    <div>
      <Checkbox checked={isToll} /> Peaje
    </div>
    <div>
      <Checkbox checked={receiveCargoPoint} /> Punto de Recepción de Carga
    </div>
    <div>
      <Checkbox checked={pickupCargoPoint} /> Punto de Recojo de Carga
    </div>
    <div>
      <Checkbox checked={legalAbandonmentHomeBase} /> Base de Abandono Legal
    </div>
  </Fragment>
);

LocationAttributeInformationResource.propTypes =
  locationAttributeInformationPropTypes;

export default LocationAttributeInformationResource;
