import QueryString from 'query-string';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import {
  FLAG_GETTING_CIRCUITS,
  GET_CIRCUITS,
  CLEAR_CIRCUITS,
  FLAG_CIRCUIT_ACTIVITY,
  GET_CIRCUIT,
  CLEAR_CIRCUIT,
} from '../types/route';
import { CIRCUIT_ENDPOINT } from '../../config/endpoints';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { CIRCUIT_PATH } from '../../config/paths';

const flagGettingCircuits = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CIRCUITS,
    payload: flag,
  });

const flagCircuitActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_CIRCUIT_ACTIVITY,
    payload: flag,
  });

const getCircuits = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCircuits(true));
    const query = tableFilters;
    const url = `${CIRCUIT_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const circuits = await response.json();
    dispatch({
      type: GET_CIRCUITS,
      payload: circuits,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingCircuits(false));
  }
};

const getCircuit =
  async ({ circuitId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCircuitActivity(true));
      const url = `${CIRCUIT_ENDPOINT}/${circuitId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const circuitGroup = await response.json();
      dispatch({
        type: GET_CIRCUIT,
        payload: circuitGroup,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCircuitActivity(false));
    }
  };

const deleteCircuit =
  async ({ circuitId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCircuitActivity(true));
      const url = `${CIRCUIT_ENDPOINT}/${circuitId}`;
      const promise = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(CIRCUIT_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCircuitActivity(false));
    }
  };

const postCircuit =
  async ({ name, description, circuitRouteList }) =>
  async (dispatch) => {
    try {
      dispatch(flagCircuitActivity(true));
      const payload = {
        name,
        description,
        circuitRouteList,
      };
      const url = CIRCUIT_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(CIRCUIT_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCircuitActivity(false));
    }
  };

const putCircuit =
  async (circuitId, { name, description, circuitRouteList }) =>
  async (dispatch) => {
    try {
      dispatch(flagCircuitActivity(true));
      const payload = {
        id: circuitId,
        name,
        description,
        circuitRouteList,
      };
      const url = `${CIRCUIT_ENDPOINT}/${circuitId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(CIRCUIT_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCircuitActivity(false));
    }
  };

const clearCircuits = () => (dispatch) =>
  dispatch({
    type: CLEAR_CIRCUITS,
  });

const clearCircuit = () => (dispatch) =>
  dispatch({
    type: CLEAR_CIRCUIT,
  });

export {
  flagGettingCircuits,
  getCircuits,
  clearCircuits,
  getCircuit,
  clearCircuit,
  deleteCircuit,
  postCircuit,
  putCircuit,
};
