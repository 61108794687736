import React from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import Loader from '../../../common/Loader';
import TextInput from '../../../common/forms/input/TextInput';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import DriverSelect from '../../../common/forms/select/DriverSelect';
import FormFooter from '../../../common/forms/FormFooter';
import DriverMovementSelect from '../../../common/forms/select/DriverMovementSelect';
import { MAX_LENGTH_TEXTAREA_FOR_DRIVER_MOVEMENT_HISTORY_NOTE } from '../../../../config/constants';

const DriverMovementHistoryForm = ({
  handleSubmit,
  loading,
  loadingDriversAssignment,
  isEdit,
}) => {
  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Conductor" required>
          <Field
            name="driver"
            component={DriverSelect}
            isLoading={loadingDriversAssignment}
            isDisabled={isEdit}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Motivo" required>
          <Field
            name="driverMovement"
            component={DriverMovementSelect}
            isLoading={loadingDriversAssignment}
            isDisabled={isEdit}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha/Hora Inicio" required>
          <Field
            name="startDate"
            component={TextInput}
            type="datetime-local"
            placeholder={DATE_TIME_FORMAT}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Fecha/Hora Final" required>
          <Field
            name="endDate"
            component={TextInput}
            type="datetime-local"
            placeholder={DATE_TIME_FORMAT}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Observación 1" required>
          <Field
            name="note"
            component={TextInput}
            type="textarea"
            placeholder="Notas"
            maxLength={MAX_LENGTH_TEXTAREA_FOR_DRIVER_MOVEMENT_HISTORY_NOTE}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Observación 2" required>
          <Field
            name="secondaryNote"
            component={TextInput}
            type="textarea"
            placeholder="Notas"
            maxLength={MAX_LENGTH_TEXTAREA_FOR_DRIVER_MOVEMENT_HISTORY_NOTE}
          />
        </FormItem>
      </FormGroup>
      <FormFooter />
    </Form>
  );
};

DriverMovementHistoryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingDriversAssignment: PropTypes.bool,
  isEdit: PropTypes.bool.isRequired,
};

DriverMovementHistoryForm.defaultProps = {
  loadingDriversAssignment: false,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  loading: !TrafficUnit.Driver.getIn(['current', 'activity']).isEmpty(),
  loadingDriversAssignment: TrafficUnit.DriverBusAssignment.getIn([
    'all',
    'loading',
  ]),
});

const formComponent = reduxForm({
  form: 'DriverMovementHistoryForm',
})(DriverMovementHistoryForm);

export default connect(mapStateToProps)(formComponent);
