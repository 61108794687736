import { toastr } from 'react-redux-toastr';
import { EXPENSE_TYPE_ENDPOINT } from '../../../../config/endpoints';
import Fetch from '../../../../utils/Fetch';

const getExpenseType = async ({ id }) => {
  try {
    const url = `${EXPENSE_TYPE_ENDPOINT}/${id}`;
    const expenseType = await Fetch.get(url);
    return expenseType;
  } catch (error) {
    toastr.error('Error', error.message);
    return null;
  }
};

const postExpenseType = async ({
  name,
  expenseCode,
  description,
  expenseTypeLabel,
  internal,
  costCenterId,
  basicService,
}) => {
  try {
    const payload = {
      name,
      expenseCode,
      description,
      expenseTypeLabel,
      internal,
      costCenterId,
      basicService,
    };
    const purchaseOrder = await Fetch.post({
      url: EXPENSE_TYPE_ENDPOINT,
      payload,
    });
    return purchaseOrder;
  } catch (error) {
    toastr.error('Error', error.message);
    return null;
  }
};

const putExpenseType = async ({
  id,
  name,
  expenseCode,
  description,
  expenseTypeLabel,
  internal,
  costCenterId,
  basicService,
}) => {
  try {
    const payload = {
      id,
      name,
      expenseCode,
      description,
      expenseTypeLabel,
      internal,
      costCenterId,
      basicService,
    };
    const purchaseOrder = await Fetch.put({
      url: `${EXPENSE_TYPE_ENDPOINT}/${id}`,
      payload,
    });
    return purchaseOrder;
  } catch (error) {
    toastr.error('Error', error.message);
    return null;
  }
};

const deleteExpenseType = async ({ id }) => {
  try {
    await Fetch.delete({ url: `${EXPENSE_TYPE_ENDPOINT}/${id}` });
    return true;
  } catch (error) {
    toastr.error('Error', error.message);
    return false;
  }
};

export { getExpenseType, postExpenseType, putExpenseType, deleteExpenseType };
