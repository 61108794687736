import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { PRICING_PROFILE_ENDPOINT } from '../../config/endpoints';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import {
  FLAG_GETTING_PRICING_PROFILES,
  GET_PRICING_PROFILES,
  CLEAR_PRICING_PROFILES,
  CLEAR_PRICING_PROFILE,
  FLAG_PRICING_PROFILE_ACTIVITY,
  GET_PRICING_PROFILE,
} from '../types';
import { PRICING_PROFILE_PATH } from '../../config/paths';

const flagGettingPricingProfiles = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_PRICING_PROFILES,
    payload: flag,
  });

const getPricingProfiles =
  async (tableFilters = null) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingPricingProfiles(true));
      const query = tableFilters;
      const url = `${PRICING_PROFILE_ENDPOINT}?${QueryString.stringify(query)}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response, null, dispatch);
      const pricingProfiles = await response.json();
      dispatch({ type: GET_PRICING_PROFILES, payload: pricingProfiles });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagGettingPricingProfiles(false));
    }
  };

const clearPricingProfiles = () => (dispatch) =>
  dispatch({
    type: CLEAR_PRICING_PROFILES,
  });

const clearPricingProfile = () => (dispatch) =>
  dispatch({
    type: CLEAR_PRICING_PROFILE,
  });

const flagPricingProfileActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_PRICING_PROFILE_ACTIVITY,
    payload: flag,
  });

const postPricingProfile =
  async ({
    name,
    version,
    basePrice,
    isSeatMapPricing,
    itineraryLevelPricing,
    lastUpdate,
    seatMapId,
    isMaciva,
    priceDays,
    priceDatesList,
    priceZoneList,
    agencyGroups,
    pricingProfileRouteList,
    serviceTypeId,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagPricingProfileActivity(true));

      const payload = {
        name,
        version,
        basePrice,
        isSeatMapPricing,
        itineraryLevelPricing,
        lastUpdate,
        seatMapId,
        isMaciva,
        priceDays,
        priceDatesList,
        priceZoneList,
        agencyGroups,
        pricingProfileRouteList,
        serviceTypeId,
      };

      const url = PRICING_PROFILE_ENDPOINT;

      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);

      await response.json();

      dispatch(push(PRICING_PROFILE_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagPricingProfileActivity(false));
    }
  };

const getPricingProfile =
  async ({ pricingProfileId }) =>
  async (dispatch) => {
    try {
      dispatch(flagPricingProfileActivity(true));

      const url = `${PRICING_PROFILE_ENDPOINT}/${pricingProfileId}`;

      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });

      await isErrorResponse(response, null, dispatch);

      const pricingProfile = await response.json();

      dispatch({
        type: GET_PRICING_PROFILE,
        payload: pricingProfile,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagPricingProfileActivity(false));
    }
  };

const putPricingProfile =
  async (
    pricingProfileId,
    {
      agencyGroups,
      name,
      version,
      basePrice,
      isSeatMapPricing,
      itineraryLevelPricing,
      lastUpdate,
      seatMapId,
      isMaciva,
      priceDays,
      priceDatesList,
      priceZoneList,
      seatPriceOverrideList,
      pricingProfileRouteList,
      serviceTypeId,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagPricingProfileActivity(true));

      const payload = {
        agencyGroups,
        name,
        version,
        basePrice,
        isSeatMapPricing,
        itineraryLevelPricing,
        lastUpdate,
        seatMapId,
        isMaciva,
        priceDays,
        priceDatesList,
        priceZoneList,
        seatPriceOverrideList,
        id: pricingProfileId,
        pricingProfileRouteList,
        serviceTypeId,
      };

      const url = `${PRICING_PROFILE_ENDPOINT}/${pricingProfileId}`;

      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response, null, dispatch);

      const pricingProfile = await response.json();

      dispatch(push(`${PRICING_PROFILE_PATH}/${pricingProfile.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagPricingProfileActivity(false));
    }
  };

const deletePricingProfile =
  async ({ pricingProfileId }) =>
  async (dispatch) => {
    try {
      dispatch(flagPricingProfileActivity(true));

      const url = `${PRICING_PROFILE_ENDPOINT}/${pricingProfileId}`;

      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });

      await isErrorResponse(response, null, dispatch);

      await response.json();

      dispatch(push(PRICING_PROFILE_PATH));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagPricingProfileActivity(false));
    }
  };

export {
  flagGettingPricingProfiles,
  getPricingProfiles,
  clearPricingProfiles,
  clearPricingProfile,
  postPricingProfile,
  getPricingProfile,
  putPricingProfile,
  deletePricingProfile,
};
