import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import Content from '../../../layout/Content';
import DriverMovementHistoryForm from './DriverMovementHistoryForm';
import { DRIVER_MOVEMENT_HISTORY_PATH } from '../../../../config/paths';
import { breadcrumbPropType } from '../../../common/resource/proptypes/CommonPropTypes';
import { postDriverMovementHistory } from '../../../../actions';
import { tzNormalizeDate } from '../../../../utils/date';

const NewDriverMovementHistory = ({
  breadcrumbs,
  dispatchPostDriverMovementHistory,
}) => {
  const onSubmit = (formValues) => {
    const startDate = tzNormalizeDate({
      date: formValues.startDate,
    });

    const endDate = tzNormalizeDate({
      date: formValues.endDate,
    });

    if (moment(startDate).isAfter(moment(endDate))) {
      toastr.error(
        'Error',
        'La fecha de inicio no puede ser mayor a la fecha de fin',
      );
      return;
    }

    const newFormValues = {
      ...formValues,
      startDate,
      endDate,
    };

    dispatchPostDriverMovementHistory(newFormValues);
  };

  const content = <DriverMovementHistoryForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo movimiento de conductor"
      content={content}
    />
  );
};

NewDriverMovementHistory.propTypes = {
  breadcrumbs: breadcrumbPropType.isRequired,
  dispatchPostDriverMovementHistory: PropTypes.func.isRequired,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Movimientos de conductor',
      href: DRIVER_MOVEMENT_HISTORY_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostDriverMovementHistory: postDriverMovementHistory,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewDriverMovementHistory);
