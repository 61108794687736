import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  ADD_CARGO_ITEM_TO_LOCATION_ENDPOINT,
  CARGO_ITEM_ENDPOINT,
  CARGO_ITEM_ENDPOINT_V2,
  CARGO_ITEM_FOUND_LOCATION_ENDPOINT,
  GET_CARGO_ITEM_DETAIL_ENDPOINT,
  GET_INTERNAL_CARGO_ITEM_DETAIL_ENDPOINT,
  POST_CARGO_ITEM_COMMENT_ENDPOINT,
  generatePutChangeCargoItemStatus,
  generatePutChangeInternalCargoItemStatus,
} from '../../config/endpoints';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_PATCH_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
  EXCEL_GET_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  FLAG_GETTING_CARGO_ITEMS,
  GET_CARGO_ITEMS,
  CLEAR_CARGO_ITEMS,
  FLAG_CARGO_ITEM_ACTIVITY,
  GET_CARGO_ITEM_DETAIL,
  CLEAR_CARGO_ITEM_DETAIL,
} from '../types/cargo';

const flagGettingCargoItems = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CARGO_ITEMS,
    payload: flag,
  });

const getCargoItems = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCargoItems(true));
    const query = tableFilters;

    const url = `${CARGO_ITEM_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const items = await response.json();
    dispatch({
      type: GET_CARGO_ITEMS,
      payload: items,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingCargoItems(false));
  }
};

const clearCargoItems = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_ITEMS,
  });

const flagCargoItemActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_CARGO_ITEM_ACTIVITY,
    payload: flag,
  });

const addItemToAgency =
  ({ cargoItemId, locationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoItemActivity(true));
      const payload = { cargoItemId, locationId };
      const url = ADD_CARGO_ITEM_TO_LOCATION_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      return await response.json();
    } catch (error) {
      toastr.error('Error', error.message);
      return null;
    } finally {
      dispatch(flagCargoItemActivity(false));
    }
  };

const getCargoItem =
  ({ cargoItemId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoItemActivity(true));
      const url = `${CARGO_ITEM_ENDPOINT}/${cargoItemId}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response, null, dispatch);
      return await response.json();
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoItemActivity(false));
    }
    return null;
  };

const fetchCargoItemDetail = async (cargoItemId, dispatch) => {
  const urlGet = `${GET_CARGO_ITEM_DETAIL_ENDPOINT}/${cargoItemId}`;
  const responseGet = await fetch(urlGet, DEFAULT_GET_CONFIG);
  await isErrorResponse(responseGet);
  const item = await responseGet.json();
  dispatch({
    type: GET_CARGO_ITEM_DETAIL,
    payload: item,
  });
};

const fetchInternalCargoItemDetail = async (cargoItemId, dispatch) => {
  const urlGet = `${GET_INTERNAL_CARGO_ITEM_DETAIL_ENDPOINT}/${cargoItemId}`;
  const responseGet = await fetch(urlGet, DEFAULT_GET_CONFIG);
  await isErrorResponse(responseGet);
  const item = await responseGet.json();
  dispatch({
    type: GET_CARGO_ITEM_DETAIL,
    payload: item,
  });
};

const getCargoItemDetail =
  ({ cargoItemId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoItemActivity(true));
      await fetchCargoItemDetail(cargoItemId, dispatch);
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoItemActivity(false));
    }
  };

const getInternalCargoItemDetail =
  ({ cargoItemId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoItemActivity(true));
      await fetchInternalCargoItemDetail(cargoItemId, dispatch);
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoItemActivity(false));
    }
  };

const changeCargoItemStatus =
  ({ cargoItemId, status }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoItemActivity(true));
      const url = generatePutChangeCargoItemStatus(cargoItemId, status);
      const response = await fetch(url, DEFAULT_PUT_CONFIG);
      await isErrorResponse(response, null, dispatch);
      await fetchCargoItemDetail(cargoItemId, dispatch);
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoItemActivity(false));
    }
    return null;
  };

const changeInternalCargoItemStatus =
  ({ cargoItemId, status }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoItemActivity(true));
      const url = generatePutChangeInternalCargoItemStatus(cargoItemId, status);
      const response = await fetch(url, DEFAULT_PUT_CONFIG);
      await isErrorResponse(response, null, dispatch);
      await fetchInternalCargoItemDetail(cargoItemId, dispatch);
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoItemActivity(false));
    }
    return null;
  };

const clearCargoItemsDetail = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_ITEM_DETAIL,
  });

const postCargoItemComment =
  ({ cargoItemId, description }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoItemActivity(true));
      const payload = {
        cargoItemId,
        description,
      };
      const url = POST_CARGO_ITEM_COMMENT_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      await fetchCargoItemDetail(cargoItemId, dispatch);
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoItemActivity(false));
    }
    return null;
  };

const postInternalCargoItemComment =
  ({ internalCargoItemId, description }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoItemActivity(true));
      const payload = {
        internalCargoItemId,
        description,
      };
      const url = POST_CARGO_ITEM_COMMENT_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response, null, dispatch);
      await fetchInternalCargoItemDetail(internalCargoItemId, dispatch);
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoItemActivity(false));
    }
    return null;
  };

const getCargoItemsV2 = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCargoItems(true));
    const query = tableFilters;
    const url = `${CARGO_ITEM_ENDPOINT_V2}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);
    const items = await response.json();
    dispatch({
      type: GET_CARGO_ITEMS,
      payload: items,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingCargoItems(false));
  }
};

const patchItemFound =
  async ({ internal, id, locationId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCargoItemActivity(true));
      const payload = {
        internal,
        id,
        locationId,
      };
      const url = CARGO_ITEM_FOUND_LOCATION_ENDPOINT;
      await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
        body: JSON.stringify(payload),
      });
      if (!internal) {
        dispatch(getCargoItemDetail({ cargoItemId: id }));
      } else {
        dispatch(getInternalCargoItemDetail({ cargoItemId: id }));
      }
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagCargoItemActivity(false));
    }
  };

const getCargoItemsExcel = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingCargoItems(true));
    const query = tableFilters;
    const url = `${CARGO_ITEM_ENDPOINT_V2}?${QueryString.stringify(
      query,
    )}&download=true`;
    const response = await fetch(url, EXCEL_GET_CONFIG);
    await isErrorResponse(response, null, dispatch);

    const blob = await response.blob();

    const downloadUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = downloadUrl;

    const contentDisposition = response.headers.get('Content-Disposition');
    let fileName = 'cargo_items.xlsx'; // Nombre por defecto

    if (contentDisposition) {
      const match = contentDisposition.match(/filename="?(.+)"?/);
      if (match) {
        [, fileName] = match;
      }
    }

    link.setAttribute('download', fileName);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);

    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingCargoItems(false));
  }
};

export {
  flagGettingCargoItems,
  getCargoItems,
  clearCargoItems,
  addItemToAgency,
  getCargoItemDetail,
  getInternalCargoItemDetail,
  clearCargoItemsDetail,
  getCargoItem,
  changeCargoItemStatus,
  changeInternalCargoItemStatus,
  postCargoItemComment,
  postInternalCargoItemComment,
  getCargoItemsV2,
  patchItemFound,
  getCargoItemsExcel,
};
