import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RouteHome from './RouteHome';
import {
  FUEL_STATION_PATH,
  NEW_FUEL_STATION_PATH,
  AGENCY_TO_FUEL_STATION_PATH,
  ITINERARY_GROUP_PATH,
  NEW_ITINERARY_GROUP_PATH,
  SEGMENT_PATH,
  NEW_SEGMENT_PATH,
  ROUTE_UNIT_PATH,
  CIRCUIT_PATH,
  NEW_CIRCUIT_PATH,
} from '../../../config/paths';
import AgencyToFuelStation from './fuel-station/AgencyToFuelStation';
import FuelStations from './fuel-station/FuelStations';
import FuelStation from './fuel-station/FuelStation';
import NewFuelStation from './fuel-station/NewFuelStation';
import EditFuelStation from './fuel-station/EditFuelStation';
import ItineraryGroups from './itinerary-group/ItineraryGroups';
import NewItineraryGroup from './itinerary-group/NewItineraryGroup';
import ItineraryGroup from './itinerary-group/ItineraryGroup';
import EditItineraryGroup from './itinerary-group/EditItineraryGroup';
import Segments from './segment/Segments';
import NewSegment from './segment/NewSegment';
import Segment from './segment/Segment';
import EditSegment from './segment/EditSegment';
import Circuit from './circuit/Circuit';
import Circuits from './circuit/Circuits';
import NewCircuit from './circuit/NewCircuit';
import EditCircuit from './circuit/EditCircuit';

const RouteUnit = () => (
  <Switch>
    <Route exact path={ROUTE_UNIT_PATH} component={RouteHome} />
    <Route exact path={SEGMENT_PATH} component={Segments} />
    <Route exact path={NEW_SEGMENT_PATH} component={NewSegment} />
    <Route exact path={`${SEGMENT_PATH}/:id`} component={Segment} />
    <Route exact path={`${SEGMENT_PATH}/:id/edit`} component={EditSegment} />
    <Route exact path={FUEL_STATION_PATH} component={FuelStations} />
    <Route exact path={NEW_FUEL_STATION_PATH} component={NewFuelStation} />
    <Route exact path={`${FUEL_STATION_PATH}/:id`} component={FuelStation} />
    <Route
      exact
      path={`${FUEL_STATION_PATH}/:id/edit`}
      component={EditFuelStation}
    />
    <Route
      exact
      path={AGENCY_TO_FUEL_STATION_PATH}
      component={AgencyToFuelStation}
    />
    <Route exact path={ITINERARY_GROUP_PATH} component={ItineraryGroups} />
    <Route
      exact
      path={NEW_ITINERARY_GROUP_PATH}
      component={NewItineraryGroup}
    />
    <Route
      exact
      path={`${ITINERARY_GROUP_PATH}/:id`}
      component={ItineraryGroup}
    />
    <Route
      exact
      path={`${ITINERARY_GROUP_PATH}/:id/edit`}
      component={EditItineraryGroup}
    />
    <Route exact path={CIRCUIT_PATH} component={Circuits} />
    <Route exact path={NEW_CIRCUIT_PATH} component={NewCircuit} />
    <Route exact path={`${CIRCUIT_PATH}/:id`} component={Circuit} />
    <Route exact path={`${CIRCUIT_PATH}/:id/edit`} component={EditCircuit} />
  </Switch>
);

export default RouteUnit;
