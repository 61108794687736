import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Content from '../../../layout/Content';
import { postManualVoucher } from '../../../../actions';
import { MANUAL_VOUCHER_PATH } from '../../../../config/paths';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import CreditNoteManualVoucherForm from './CreditNoteManualVoucherForm';
import { tzNormalizeDate } from '../../../../utils/date';
import {
  CURRENCY_CODE,
  MANUAL_VOUCHER_PAYMENT_TYPE,
  MANUAL_VOUCHER_SERVICE_TYPE,
} from '../../../../config/constants';

export const NewCreditNoteManualVoucher = ({
  breadcrumbs,
  dispatchPostManualVoucher,
}) => {
  const onSubmit = (formValues) => {
    dispatchPostManualVoucher(formValues);
  };

  const today = tzNormalizeDate();

  const initialValues = {
    issueDate: today,
    dueDate: today,
    voucherTypeId: { value: 83, label: 'Nota de credito' },
    currencyCode: CURRENCY_CODE.PEN,
    serviceType: {
      value: MANUAL_VOUCHER_SERVICE_TYPE.TICKET_SERVICE.value,
      label: MANUAL_VOUCHER_SERVICE_TYPE.TICKET_SERVICE.label,
    },
    paymentType: MANUAL_VOUCHER_PAYMENT_TYPE.CASH,
  };

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Nota de Crédito"
      subtitle="Crear un nuevo comprobante manual: Nota de crédito"
      content={
        <CreditNoteManualVoucherForm
          onSubmit={onSubmit}
          initialValues={initialValues}
        />
      }
    />
  );
};

const mapStateToProps = ({ AccountingUnit }) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Comprobantes Manuales',
      href: MANUAL_VOUCHER_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

const mapDispatchToProps = {
  dispatchPostManualVoucher: postManualVoucher,
};

NewCreditNoteManualVoucher.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostManualVoucher: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCreditNoteManualVoucher);
