import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectFilter from '../../filters/SelectFilter';
import {
  inputDefaultProps,
  inputPropTypes,
  metaDefaultProps,
  metaPropTypes,
} from '../../forms/input/InputPropTypes';
import { clearRoutes, getCompanyRoutes, getRoutes } from '../../../../actions';
import Select from './Select';
import { isValidNumber } from '../../../../utils/validators';

class RouteSelect extends Component {
  componentWillUnmount() {
    const { dispatchClearRoutes } = this.props;
    dispatchClearRoutes();
  }

  handleRouteChange = (inputValue) => {
    const { dispatchGetRoutes, dispatchGetCompanyRoutes, isForMine } =
      this.props;

    const value = inputValue.trim();

    if (isForMine) {
      if (value.length >= 4) dispatchGetCompanyRoutes({ query: value });
    } else if (value.length) {
      let query = [];

      if (isValidNumber(value)) {
        query = [`id:${value}`];
      } else if (value.length >= 4) {
        query = [`name:${value}`];
      }

      dispatchGetRoutes({ query });
    }
  };

  render() {
    const {
      options,
      loading,
      isMulti,
      input,
      meta,
      useForFilter,
      onChangeFilter,
      manualOptions,
      givenOptions,
      ...rest
    } = this.props;

    const component = !useForFilter ? (
      <Select
        options={!manualOptions ? options : givenOptions}
        isLoading={loading}
        isMulti={isMulti}
        // Select expects onChange and value inside input
        input={input}
        // Select expects touched, error, warning inside meta
        meta={meta}
        onInputChange={!manualOptions ? this.handleRouteChange : undefined}
        {...rest}
      />
    ) : (
      <SelectFilter
        isMulti
        onChangeFilter={onChangeFilter}
        onInputChange={this.handleRouteChange}
        options={options}
        {...rest}
      />
    );

    return component;
  }
}

RouteSelect.propTypes = {
  dispatchGetRoutes: PropTypes.func.isRequired,
  dispatchClearRoutes: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    }),
  ),
  meta: metaPropTypes,
  loading: PropTypes.bool,
  useForFilter: PropTypes.bool,
  onChangeFilter: PropTypes.func,
  placeholder: PropTypes.string,
  isForMine: PropTypes.bool,
  dispatchGetCompanyRoutes: PropTypes.func.isRequired,
  givenOptions: PropTypes.instanceOf(Array),
  manualOptions: PropTypes.bool,
};

RouteSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  loading: false,
  isMulti: false,
  options: [],
  useForFilter: false,
  onChangeFilter: () => {},
  placeholder: 'Busque por ID o nombre de Ruta',
  isForMine: false,
  givenOptions: [],
  manualOptions: false,
};

const mapStateToProps = ({ RouteUnit, authentication }) => ({
  options: RouteUnit.Route.getIn(['all', 'content', 'content']).map(
    ({ id, name, routeSegmentList, sourceLocation, destinationLocation }) => ({
      value: id,
      label: `${id} - ${name}`,
      routeSegmentList,
      sourceLocation,
      destinationLocation,
    }),
  ),
  loading: RouteUnit.Route.getIn(['all', 'loading']),
  isForMine: !!authentication.get('user').salesSessionUserId,
});

const mapDispatchToprops = {
  dispatchGetRoutes: getRoutes,
  dispatchClearRoutes: clearRoutes,
  dispatchGetCompanyRoutes: getCompanyRoutes,
};

export default connect(mapStateToProps, mapDispatchToprops)(RouteSelect);
