import React from 'react';
import { expenseItemInformationPropTypes } from '../proptypes/ExpensePropTypes';
import Table from '../../../../common/Table';
import { EXPENSE_UPDATE_HISTORY_COLUMNS } from '../../../../../config/columns';

const ExpenseUpdateHistoryInformationResource = ({ expense }) => (
  <div className="mb-3">
    <h3>Historial</h3>
    <Table
      columns={EXPENSE_UPDATE_HISTORY_COLUMNS}
      data={expense.expenseUpdateHistoryList}
      defaultPageSize={
        expense.expenseUpdateHistoryList
          ? expense.expenseUpdateHistoryList.length
          : 0
      }
      showPagination={false}
    />
  </div>
);

ExpenseUpdateHistoryInformationResource.propTypes = {
  expense: expenseItemInformationPropTypes.isRequired,
};

export default ExpenseUpdateHistoryInformationResource;
