import {
  MAINTENANCE_ACTIVITY_TYPE,
  MAINTENANCE_WARNING_PRIORITY,
  MAINTENANCE_WARNING_TYPE,
} from '../../config/constants';
import { DATE_TIME_FORMAT } from '../../config/locale';
import { tzNormalizeDate } from '../date';

const registeredBusGenerator = ({
  id,
  companyBusId,
  busChassis,
  seatMap,
  homeBaseLocation,
  licensePlate,
  busChassisId,
  chassisSerialNumber,
  busBodyworkId,
  busBodywork,
  bodyworkSerialNumber,
  homeBaseLocationId,
  seatMapId,
  year,
  actualVirtualOdometer,
}) => ({
  id,
  value: id,
  label: companyBusId,
  companyBusId,
  busChassis,
  seatMap: {
    id: seatMap.id,
    displayName: seatMap.displayName,
    serviceType: seatMap.serviceType,
    name: seatMap.name,
    seats: seatMap.seats,
  },
  homeBaseLocation: homeBaseLocation && {
    id: homeBaseLocation.id,
    name: homeBaseLocation.name,
  },
  homeBaseLocationId,
  licensePlate,
  busChassisId,
  chassisSerialNumber,
  busBodyworkId,
  busBodywork,
  bodyworkSerialNumber,
  year,
  seatMapId,
  actualVirtualOdometer,
});

const registeredBusesGenerator = (array) =>
  array.map((element) => registeredBusGenerator(element));

const addressGenerator = ({
  id,
  address,
  district,
  city: { name: cityName },
  cityId,
  phone,
  postalCode,
  address2,
}) => ({
  id,
  value: id,
  label: `${address}, ${district}, ${cityName}`,
  cityId,
  district,
  phone,
  postalCode,
  address,
  address2,
});

const addressesGenerator = (array) =>
  array.map((element) => addressGenerator(element));

const maintenanceWarningGenerator = ({
  id,
  categoryId,
  subCategoryId,
  category,
  subCategory,
  issueDate,
  registeredBusId,
  registeredBus,
  companyResource,
  maintenanceWarningType,
  priority,
  maintenanceFailMode,
  activityBusId,
  recurrence,
  creationType,
  maintenancePlanningActivityResourceId,
  maintenancePlanningActivityResource,
}) => {
  let busText = '';
  if (registeredBus.companyBusId !== null) {
    busText = ` - ${registeredBus.companyBusId} `;
  } else {
    busText = ` - ${companyResource.name} `;
  }
  const typeText = maintenanceWarningType
    ? ` - ${MAINTENANCE_WARNING_TYPE[maintenanceWarningType].label} `
    : '';

  let failModeText =
    maintenanceFailMode && maintenanceFailMode.description
      ? ` - ${maintenanceFailMode.description} `
      : '';
  if (
    creationType === MAINTENANCE_ACTIVITY_TYPE.PREVENTIVE.value &&
    maintenancePlanningActivityResource
  )
    failModeText = ` - ${maintenancePlanningActivityResource.maintenancePlanningActivityOrder.activity.companyActivityId} - ${maintenancePlanningActivityResource.maintenancePlanningActivityOrder.activity.name} `;

  const recurrenceText = recurrence ? ` Reincidencia ${recurrence}` : '';

  const label = `${id} - ${tzNormalizeDate({
    date: issueDate,
    format: DATE_TIME_FORMAT,
  })}${busText}${typeText}${failModeText}(${
    MAINTENANCE_WARNING_PRIORITY[priority].label
  })${recurrenceText} (${MAINTENANCE_ACTIVITY_TYPE[creationType].label})`;

  return {
    value: id,
    label,
    categoryId,
    subCategoryId,
    category: category ? { name: category.name } : '',
    subCategory: subCategory ? { name: subCategory.name } : '',
    issueDate,
    registeredBusId,
    registeredBus: registeredBus
      ? {
          companyBusId: registeredBus.companyBusId,
        }
      : null,
    companyResource: companyResource
      ? {
          name: companyResource.name,
        }
      : null,
    maintenanceWarningType,
    priority,
    activityBusId,
    maintenancePlanningActivityResourceId,
  };
};

const maintenanceWarningsGenerator = (array) =>
  array.map((element) => maintenanceWarningGenerator(element));

const customerGenerator = ({
  id,
  firstName,
  lastName,
  fullName,
  idDocumentNumber,
  idCountryOfOrigin,
  gender,
  identificationType,
  email,
  mobilePhone,
  dob,
  discountCode,
}) => {
  const identificationTypeText = identificationType
    ? ` | ${identificationType.name}: `
    : '';
  const idDocumentNumberText = idDocumentNumber || '';
  const idCountryOfOriginText = idCountryOfOrigin
    ? ` (${idCountryOfOrigin}) `
    : '';
  return {
    value: id,
    label: `${fullName}${identificationTypeText}${idDocumentNumberText}${idCountryOfOriginText}`,
    id,
    firstName,
    lastName,
    fullName,
    idDocumentNumber,
    gender,
    idCountryOfOrigin,
    identificationType,
    email,
    mobilePhone,
    dob,
    discountCode,
  };
};

const customersGenerator = (array) =>
  array.map((element) => customerGenerator(element));

export {
  registeredBusGenerator,
  registeredBusesGenerator,
  addressGenerator,
  addressesGenerator,
  maintenanceWarningGenerator,
  maintenanceWarningsGenerator,
  customerGenerator,
  customersGenerator,
};
