import React, { useLayoutEffect, useState } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Button, ButtonToolbar, Form } from 'reactstrap';
import SelectionableTable from '../../../../common/forms/table/SelectionableTable';
import { MANIFEST_CARGO_ITEM_TO_ITINERARY_COLUMNS } from '../../../../../config/columns';
import {
  CARGO_OPERATIONS,
  DEFAULT_PAGE_SIZE,
} from '../../../../../config/constants';
import Loader from '../../../../common/Loader';
import { NO_MANIFEST_CARGO_ITEM_IN_ITINERARY } from '../../../../../config/messages';
import {
  clearManifestItems,
  deleteManifestItemFromItinerary,
  putRemoveItemFromLegalAbandonmentItinerary,
} from '../../../../../actions';
import Alert from '../../../../common/informative/Alert';
import UnloadItemLegalAbandonmentModalForm from './UnloadItemLegalAbandonmentModalForm';
import Modal from '../../../../common/modal/Modal';
import { getUniqueLocations } from '../../unloading-cargo-from-itinerary/UnloadingCargoForm';
import CargoOperationByQrButton from '../../manifest/cargo-operation-qr/CargoOperationByQrButton';

const UnloadCargoLegalAbandonmentFromItineraryForm = ({
  items,
  loading,
  itineraryId,
  handleSubmit,
  locationId,
  dispatchClearManifestItem,
  dispatchPutRemoveItemFromLegalAbandonmentItinerary,
  disabledButton,
  dispatchDeleteManifestItemFromItinerary,
  itineraryCounter,
}) => {
  const [show, setShow] = useState(false);
  const [modalBody, setModalBody] = useState(null);

  useLayoutEffect(
    () => () => {
      dispatchClearManifestItem();
    },
    [],
  );

  const handleSubmitModal = async (values) => {
    const updatedItems = items
      .map((item) => {
        const itemStatusField = values[`itemStatus-${item.correlation}`];

        if (itemStatusField) {
          return {
            cargoItemId: item.id,
            legalAbandonmentItemStatus: itemStatusField.value,
            arrived: true,
          };
        }

        return null;
      })
      .filter((item) => item !== null);
    if (!updatedItems.isEmpty()) {
      const removed = await dispatchPutRemoveItemFromLegalAbandonmentItinerary({
        legalAbandonmentItemRequestList: updatedItems,
        itineraryId,
        locationId,
      });
      if (removed && removed.length) {
        dispatchDeleteManifestItemFromItinerary(removed);
      }
    }
    setShow(false);
  };

  const onClickUnloadCargoLegalAbandonmentFromItinerary = async ({
    itemsToUnloadCorrelations,
  }) => {
    if (!itemsToUnloadCorrelations || !itemsToUnloadCorrelations.length) {
      return;
    }

    const filteredItems = items.filter((item) =>
      itemsToUnloadCorrelations.includes(item.correlation),
    );

    const uniqueLocations = getUniqueLocations(itineraryCounter, true, true);

    const matchedLocation = uniqueLocations.find(
      (location) => location.id === locationId,
    );

    if (matchedLocation.legalAbandonmentHomeBase) {
      setModalBody(
        <UnloadItemLegalAbandonmentModalForm
          items={filteredItems}
          onSubmit={handleSubmitModal}
        />,
      );
      setShow(true);
    } else {
      const updatedItems = items
        .map((item) => ({
          cargoItemId: item.id,
          legalAbandonmentItemStatus: 'DEFAULT',
          arrived: itemsToUnloadCorrelations.includes(item.correlation),
        }))
        .filter((item) => item !== null);
      if (!updatedItems.isEmpty()) {
        const removed =
          await dispatchPutRemoveItemFromLegalAbandonmentItinerary({
            legalAbandonmentItemRequestList: updatedItems,
            itineraryId,
            locationId,
          });
        if (removed && removed.length) {
          dispatchDeleteManifestItemFromItinerary(removed);
        }
      }
    }
  };

  const toolbar = (
    <ButtonToolbar className="pull-right">
      <div>
        <Button type="submit" color="success" disabled={disabledButton}>
          Bajar
        </Button>{' '}
        <CargoOperationByQrButton
          onClickAddItemToItinerary={
            onClickUnloadCargoLegalAbandonmentFromItinerary
          }
          cargoOperation={CARGO_OPERATIONS.UNLOAD_ITEM}
        />
      </div>
    </ButtonToolbar>
  );
  const data = items.toJS();
  const defaultPageSize =
    items.size > DEFAULT_PAGE_SIZE ? DEFAULT_PAGE_SIZE : items.size;

  if (items.size === 0) {
    if (loading) {
      return <Loader />;
    }
    return (
      <Alert type="warning" message={NO_MANIFEST_CARGO_ITEM_IN_ITINERARY} />
    );
  }

  return (
    <>
      <Form
        onSubmit={handleSubmit(onClickUnloadCargoLegalAbandonmentFromItinerary)}
      >
        <div className="mt-3">
          {toolbar}
          <div className="flex">
            <h4>Selecione las encomiendas a bajar</h4>
          </div>
          <SelectionableTable
            columns={MANIFEST_CARGO_ITEM_TO_ITINERARY_COLUMNS}
            data={data}
            name="itemsToUnloadCorrelations"
            defaultPageSize={defaultPageSize}
            returnOnlySelectedItems
            keyField="correlation"
            loading={loading}
            form="UnloadCargoLegalAbandonmentFromItineraryForm"
          />
        </div>
      </Form>
      <Modal
        show={show}
        title="Verificar estado de las encomiendas"
        body={modalBody}
        onClickClose={() => setShow(false)}
        size="lg"
      />
    </>
  );
};

UnloadCargoLegalAbandonmentFromItineraryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(Immutable.List).isRequired,
  loading: PropTypes.bool.isRequired,
  itineraryId: PropTypes.number,
  locationId: PropTypes.number.isRequired,
  dispatchClearManifestItem: PropTypes.func.isRequired,
  dispatchPutRemoveItemFromLegalAbandonmentItinerary: PropTypes.func.isRequired,
  dispatchDeleteManifestItemFromItinerary: PropTypes.func.isRequired,
  disabledButton: PropTypes.bool,
  itineraryCounter: PropTypes.instanceOf(Immutable.Map).isRequired,
};

UnloadCargoLegalAbandonmentFromItineraryForm.defaultProps = {
  itineraryId: null,
  disabledButton: false,
};

const mapStateToProps = ({ CargoUnit, ItineraryUnit }) => ({
  items: CargoUnit.ManifestCargo.getIn(['all', 'content']),
  loading: CargoUnit.ManifestCargo.getIn(['all', 'loading']),
  disabledButton: CargoUnit.ManifestCargo.getIn(['disabledActions']),
  itineraryCounter: ItineraryUnit.Itinerary.getIn(['current', 'content']),
});

const mapDispatchToProps = {
  dispatchPutRemoveItemFromLegalAbandonmentItinerary:
    putRemoveItemFromLegalAbandonmentItinerary,
  dispatchClearManifestItem: clearManifestItems,
  dispatchDeleteManifestItemFromItinerary: deleteManifestItemFromItinerary,
};

const formComponent = reduxForm({
  form: 'UnloadCargoLegalAbandonmentFromItineraryForm',
})(UnloadCargoLegalAbandonmentFromItineraryForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
