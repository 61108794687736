import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, Form, change, formValueSelector } from 'redux-form';
import { FormGroup, Label, InputGroupText } from 'reactstrap';
import FormItem from '../../../../common/forms/FormItem';
import { isRequired, validateNumber } from '../../../../../utils/validators';
import TextInput from '../../../../common/forms/input/TextInput';
import Loader from '../../../../common/Loader';
import {
  DATE_TIME_FORMAT,
  INT_DATE_FORMAT,
  INT_DATE_TIME_FORMAT,
  TIME_FORMAT,
} from '../../../../../config/locale';
import DatePicker from '../../../../common/forms/input/DatePicker';
import RouteSelect from '../../../../common/forms/select/RouteSelect';
import ServiceTypeSelect from '../../../../common/forms/select/ServiceTypeSelect';
import CircuitSelect from '../../../../common/forms/select/CircuitSelect';
import PricingProfileSelect from '../../../../common/forms/select/PricingProfileSelect';
import SeatMapSelect from '../../../../common/forms/select/SeatMapSelect';
import DriverCommissionSelect from '../../../../common/forms/select/DriverCommissionSelect';
import CabinCrewCommissionSelect from '../../../../common/forms/select/CabinCrewCommissionSelect';
import Select from '../../../../common/forms/select/Select';
import {
  ITINERARY_REPEAT_TYPE_OPTIONS,
  ITINERARY_REPEAT_TYPE,
  ITINERARY_TYPE_OPTIONS,
  COMPANY_STAFF_TYPE,
} from '../../../../../config/constants';
import TimetableTemplateEditable from '../TimetableTemplateEditable';
import Alert from '../../../../common/informative/Alert';
import { timeFormat, tzNormalizeDate } from '../../../../../utils/date';
import CompanySelect from '../../../../common/forms/select/CompanySelect';
import TimeInput from '../../../../common/forms/input/TimeInput';
import FormFooter from '../../../../common/forms/FormFooter';
import { enumToSelectOptions } from '../../../../../utils/enum';
import ShiftSelect from '../../../../common/forms/select/ShiftSelect';
import { getRoutesForItinerarySchedule } from '../../../../../actions';

const selector = formValueSelector('ItineraryScheduleForm');

const ItineraryScheduleForm = ({
  loading,
  handleSubmit,
  onSubmit,
  initialValues: {
    itineraryCode,
    departureTime: departureTimeProp,
    startDate: startDateProp,
    repeatType: repeatTypeProp,
    routeSegmentList: routeSegmentListProp,
    isMaciva: isMacivaProp,
    durationAdjustmentList: durationAdjustmentListProp,
    layoverList: layoverListProp,
  },
  dispatchChange,
  isForMine,
  serviceType,
  pricingProfiles,
  circuit,
  dispatchGetRoutesForItinerarySchedule,
}) => {
  const [frecuency, setFrecuency] = useState('Ninguno');
  const [repeatTypeState, setRepeatTypeState] = useState(
    ITINERARY_REPEAT_TYPE.NO_REPEAT.value,
  );
  const [routeSegmentListState, setrouteSegmentListState] = useState(null);
  const [durationAdjustmentListState, setDurationAdjustmentListState] =
    useState([]);
  const [layoverListState, setLayoverListState] = useState([]);
  const [departureTimeState, setDepartureTimeState] = useState(null);
  const [startDateState, setStartDateState] = useState(null);
  const [newRouteSegmentList, setNewRouteSegmentList] = useState([]);
  const [isMacivaState, setIsMacivaState] = useState(false);
  const [routeOptions, setRouteOptions] = useState([]);

  const generateTimetableData = (
    routeSegmentList,
    departureTime,
    startDate,
    durationAdjustmentList,
    layoverList,
  ) => {
    const timetableData = [];

    const formattedDepartureTime = departureTime
      ? tzNormalizeDate({ date: departureTime, format: TIME_FORMAT })
      : null;
    const formattedStartDate = startDate
      ? tzNormalizeDate({ date: startDate, format: INT_DATE_FORMAT })
      : null;

    let lastArrivalTimeWithoutFormat = null;
    let lastDurationAdjusment = 0;
    let lastLayover = 0;

    if (routeSegmentList) {
      routeSegmentList.forEach(
        (
          {
            segment: {
              id: segmentId,
              sourceLocation,
              destinationLocation,
              duration,
            },
          },
          index,
          array,
        ) => {
          const timetable = {
            segmentId,
            sourceLocationName: sourceLocation.name,
            destinationLocationName: destinationLocation.name,
            duration: timeFormat(duration),
            startOffset: '-',
            departureTime: '-',
            arrivalTime: '-',
          };

          if (startDate && departureTime) {
            if (index === 0) {
              const date = formattedStartDate;

              const time = formattedDepartureTime;

              timetable.startOffset = 0;

              timetable.departureTimeWithoutFormat = `${date} ${time}`;

              timetable.departureTime = tzNormalizeDate({
                date: timetable.departureTimeWithoutFormat,
              });

              timetable.departureTimeFormatted = tzNormalizeDate({
                date: timetable.departureTimeWithoutFormat,
                format: DATE_TIME_FORMAT,
              });
            } else {
              const { segment } = array[index - 1];

              const { startOffset: lastStartOffset } = timetableData[index - 1];

              timetable.startOffset =
                segment.duration +
                lastDurationAdjusment +
                lastLayover +
                lastStartOffset;

              timetable.departureTimeWithoutFormat =
                lastArrivalTimeWithoutFormat;

              timetable.departureTime = tzNormalizeDate({
                date: timetable.departureTimeWithoutFormat,
                addTime: { amount: lastLayover, unit: 'minutes' },
              });

              timetable.departureTimeFormatted = tzNormalizeDate({
                date: timetable.departureTimeWithoutFormat,
                addTime: { amount: lastLayover, unit: 'minutes' },
                format: DATE_TIME_FORMAT,
              });
            }

            lastDurationAdjusment = durationAdjustmentList[segmentId]
              ? Number(durationAdjustmentList[segmentId])
              : 0;

            timetable.segmentAdjustment = lastDurationAdjusment;

            timetable.arrivalTimeWithoutFormat = tzNormalizeDate({
              date: timetable.departureTimeWithoutFormat,
              addTime: {
                amount: duration + timetable.segmentAdjustment + lastLayover,
                unit: 'minutes',
              },
              format: INT_DATE_TIME_FORMAT,
            });

            timetable.arrivalTime = tzNormalizeDate({
              date: timetable.arrivalTimeWithoutFormat,
            });

            lastArrivalTimeWithoutFormat = timetable.arrivalTimeWithoutFormat;

            timetable.arrivalTimeFormatted = tzNormalizeDate({
              date: timetable.departureTimeWithoutFormat,
              addTime: {
                amount: duration + timetable.segmentAdjustment + lastLayover,
                unit: 'minutes',
              },
              format: DATE_TIME_FORMAT,
            });

            lastLayover = layoverList[segmentId]
              ? Number(layoverList[segmentId])
              : 0;

            timetable.layover = lastLayover;
          }

          timetableData.push(timetable);
        },
      );
    }

    setNewRouteSegmentList(timetableData);
  };

  useLayoutEffect(() => {
    if (repeatTypeProp) {
      setRepeatTypeState(repeatTypeProp.value);
      setFrecuency(repeatTypeProp.unit);
    }

    if (isForMine) {
      setRepeatTypeState(ITINERARY_REPEAT_TYPE.WEEKLY_REPEAT.value);
      setFrecuency(ITINERARY_REPEAT_TYPE.WEEKLY_REPEAT.unit);
    }

    setDepartureTimeState(departureTimeProp);
    setStartDateState(startDateProp);
    setrouteSegmentListState(routeSegmentListProp);

    if (durationAdjustmentListProp)
      setDurationAdjustmentListState(durationAdjustmentListProp);

    if (layoverListProp) setLayoverListState(layoverListProp);

    if (!isMacivaProp) {
      generateTimetableData(
        routeSegmentListProp,
        departureTimeProp,
        startDateProp,
        durationAdjustmentListProp,
        layoverListProp,
      );
    }

    dispatchChange('ItineraryScheduleForm', 'quantity', 1);
  }, []);

  useEffect(async () => {
    const { value: circuitValue } = circuit;
    const pricingProfileValues = pricingProfiles.map(
      (profile) => profile.value,
    );
    if (circuitValue) {
      const routes = await dispatchGetRoutesForItinerarySchedule(
        pricingProfileValues,
        circuitValue,
      );
      setRouteOptions(
        routes.map((route) => ({
          value: route.id,
          label: `${route.id} - ${route.name}`,
          routeSegmentList: route.routeSegmentList,
        })),
      );
    }
  }, [pricingProfiles, circuit]);

  const onHandleSubmit = (formValues) => {
    const newFormValues = { ...formValues };

    newFormValues.itineraryScheduleSegmentList = newRouteSegmentList;

    newFormValues.shift = formValues.shift
      ? formValues.shift.map((shift) => shift.value).join(',')
      : null;

    onSubmit(newFormValues);
  };

  const onValueChangeDurationAdjustment = ({ id, value }) => {
    durationAdjustmentListState[id] = value;

    setDurationAdjustmentListState(durationAdjustmentListState);

    generateTimetableData(
      routeSegmentListState,
      departureTimeState,
      startDateState,
      durationAdjustmentListState,
      layoverListState,
    );
  };

  const onValueChangeLayover = ({ id, value }) => {
    layoverListState[id] = value;

    setLayoverListState(layoverListState);

    generateTimetableData(
      routeSegmentListState,
      departureTimeState,
      startDateState,
      durationAdjustmentListState,
      layoverListState,
    );
  };

  const onChangeRepeatType = (type) => {
    setRepeatTypeState(type.value);
    setFrecuency(type.unit);
  };

  const onChangeRoute = (route) => {
    const routeSegmentList = route ? route.routeSegmentList : null;

    setrouteSegmentListState(routeSegmentList);

    if (routeSegmentList) {
      generateTimetableData(
        routeSegmentList,
        departureTimeState,
        startDateState,
        durationAdjustmentListState,
        layoverListState,
      );
    }
  };

  const onChangeDepartureTime = (departureTime) => {
    dispatchChange('ItineraryScheduleForm', 'departureTime', departureTime);

    setDepartureTimeState(departureTime);

    generateTimetableData(
      routeSegmentListState,
      departureTime,
      startDateState,
      durationAdjustmentListState,
      layoverListState,
    );
  };

  const onChangeMaciva = ({ target: { checked } }) => {
    setIsMacivaState(checked);
  };

  const onChangeStartDate = (startDate) => {
    dispatchChange('ItineraryScheduleForm', 'startDate', startDate);

    setStartDateState(startDate);

    generateTimetableData(
      routeSegmentListState,
      departureTimeState,
      startDate,
      durationAdjustmentListState,
      layoverListState,
    );
  };

  if (loading) return <Loader />;

  const inputGroupAppend = <InputGroupText>{frecuency}</InputGroupText>;

  const repeatEveryField = (
    <FormGroup row>
      <FormItem label="Repetir cada" required>
        <Field
          name="repeatEvery"
          component={TextInput}
          type="text"
          placeholder="Frecuencia"
          append={inputGroupAppend}
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
  );

  const endDateField = (
    <FormGroup row>
      <FormItem label="Fecha de Fin" required>
        <Field name="endDate" component={DatePicker} validate={[isRequired]} />
      </FormItem>
    </FormGroup>
  );

  let frecuencyFields = null;

  if (repeatTypeState === ITINERARY_REPEAT_TYPE.WEEKLY_REPEAT.value) {
    frecuencyFields = (
      <>
        {endDateField}
        <FormGroup row>
          <FormItem label="Días">
            <Label>
              <Field name="monday" component="input" type="checkbox" /> Lunes
            </Label>{' '}
            <br />
            <Label>
              <Field name="tuesday" component="input" type="checkbox" /> Martes
            </Label>
            <br />
            <Label>
              <Field name="wednesday" component="input" type="checkbox" />{' '}
              Miércoles
            </Label>
            <br />
            <Label>
              <Field name="thursday" component="input" type="checkbox" /> Jueves
            </Label>
            <br />
            <Label>
              <Field name="friday" component="input" type="checkbox" /> Viernes
            </Label>
            <br />
            <Label>
              <Field name="saturday" component="input" type="checkbox" /> Sábado
            </Label>
            <br />
            <Label>
              <Field name="sunday" component="input" type="checkbox" /> Domingo
            </Label>
          </FormItem>
        </FormGroup>
        {repeatEveryField}
      </>
    );
  }

  if (repeatTypeState === ITINERARY_REPEAT_TYPE.MONTHLY_REPEAT.value) {
    frecuencyFields = (
      <>
        {endDateField}
        <FormGroup row>
          <FormItem>
            <Label>
              <Field
                name="repeatByDayOfMonth"
                component="input"
                type="checkbox"
              />{' '}
              Repetir por día del mes
            </Label>
          </FormItem>
        </FormGroup>
        {repeatEveryField}
      </>
    );
  }

  if (
    repeatTypeState === ITINERARY_REPEAT_TYPE.DAILY_REPEAT.value ||
    repeatTypeState === ITINERARY_REPEAT_TYPE.YEARLY_REPEAT.value
  ) {
    frecuencyFields = (
      <>
        {endDateField}
        {repeatEveryField}
      </>
    );
  }

  let timetableResource = (
    <Alert message="Seleccione una ruta y fecha de inicio" />
  );

  if (routeSegmentListState) {
    timetableResource = (
      <TimetableTemplateEditable
        routeSegmentList={newRouteSegmentList}
        onValueChangeLayover={onValueChangeLayover}
        onValueChangeDurationAdjustment={onValueChangeDurationAdjustment}
        setInitialValues={!!itineraryCode}
      />
    );
  }

  let pricingProfileField = null;

  let frecuencySection = null;

  const startDateField = (
    <FormGroup row>
      <FormItem label="Fecha de Inicio" required>
        <Field
          name="startDate"
          component={DatePicker}
          input={{
            onChange: onChangeStartDate,
            value: startDateState,
          }}
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
  );

  const departureTimeField = (
    <FormGroup row>
      <FormItem label="Hora de Salida" required>
        <Field
          name="departureTime"
          component={TimeInput}
          input={{
            onChange: onChangeDepartureTime,
            value: departureTimeState,
          }}
          validate={[isRequired]}
        />
      </FormItem>
    </FormGroup>
  );

  if (!isMacivaState) {
    pricingProfileField = serviceType.value && (
      <FormGroup row>
        <FormItem label="Perfil de Precio">
          <Field
            name="pricingProfileId"
            component={PricingProfileSelect}
            isMulti
            isClearable
            query={[`serviceTypeId:${serviceType.value}`]}
          />
        </FormItem>
      </FormGroup>
    );

    frecuencySection = (
      <>
        <h3>Frecuencia</h3>
        <FormGroup row>
          <FormItem label="Tipo de Repetición" required>
            <Field
              name="repeatType"
              component={Select}
              isClearable={false}
              options={ITINERARY_REPEAT_TYPE_OPTIONS}
              onChange={onChangeRepeatType}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        {departureTimeField}
        {startDateField}
        {frecuencyFields}
        <h3>Horarios</h3>
        {timetableResource}
      </>
    );
  }

  let itineraryScheduleVersionField = null;

  if (itineraryCode) {
    itineraryScheduleVersionField = (
      <FormGroup row>
        <FormItem label="Versión de Plantilla de Itinerario" required>
          <Field
            name="itineraryScheduleVersion"
            component={TextInput}
            disabled
            type="text"
            placeholder="Versión de Plantilla de Itinerario"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
    );
  }

  const routeField = (
    <FormGroup row>
      <FormItem label="Ruta" required>
        <Field
          name="routeId"
          component={RouteSelect}
          validate={[isRequired]}
          onChange={onChangeRoute}
          manualOptions
          givenOptions={routeOptions}
        />
      </FormItem>
    </FormGroup>
  );

  // Render fields according prop isForMine
  let itineraryFields = (
    <>
      <FormGroup row>
        <FormItem label="Código de Itinerario" required>
          <Field
            name="itineraryCode"
            component={TextInput}
            disabled={!!itineraryCode}
            type="text"
            placeholder="Código de Itinerario"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      {itineraryScheduleVersionField}
      <FormGroup row>
        <FormItem label="Nombre" required>
          <Field
            name="name"
            component={TextInput}
            type="text"
            placeholder="Nombre"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Descripción">
          <Field
            name="description"
            component={TextInput}
            type="text"
            placeholder="Descripción"
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem>
          <Label>
            <Field
              name="isMaciva"
              onChange={onChangeMaciva}
              component="input"
              type="checkbox"
            />{' '}
            MACIVA
          </Label>
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Circuito" required>
          <Field
            name="circuitId"
            component={CircuitSelect}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipo de Servicio" required>
          <Field
            name="serviceTypeId"
            component={ServiceTypeSelect}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      {pricingProfileField}
      {serviceType.value && (
        <FormGroup row>
          <FormItem label="Mapa de Asientos" required>
            <Field
              name="seatMapId"
              component={SeatMapSelect}
              validate={[isRequired]}
              query={[`serviceTypeId:${serviceType.value}`]}
            />
          </FormItem>
        </FormGroup>
      )}
      {routeField}
      <FormGroup row>
        <FormItem label="Comisión del Conductor" required>
          <Field
            name="driverCommissionId"
            component={DriverCommissionSelect}
            validate={[isRequired]}
            isClearable
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Tipo de Itinerario" required>
          <Field
            name="type"
            component={Select}
            options={ITINERARY_TYPE_OPTIONS}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Comisión del Tripulante">
          <Field
            name="cabinCrewCommissionId"
            component={CabinCrewCommissionSelect}
            isClearable
          />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Compañia">
          <Field name="companyId" component={CompanySelect} isClearable />
        </FormItem>
      </FormGroup>
      <FormGroup row>
        <FormItem label="Estado">
          <Label>
            <Field name="active" component="input" type="checkbox" /> Activo
          </Label>
        </FormItem>
      </FormGroup>
      {frecuencySection}
    </>
  );

  if (isForMine)
    itineraryFields = (
      <>
        <FormGroup row>
          <FormItem label="Nombre" required>
            <Field
              name="name"
              component={TextInput}
              type="text"
              placeholder="Nombre"
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Régimen" required>
            <Field
              name="shift"
              component={ShiftSelect}
              disabled={!!itineraryCode}
              placeholder="Régimen"
              isMulti
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        {routeField}
        <FormGroup row>
          <FormItem label="Tipo" required>
            <Field
              name="staffType"
              value={COMPANY_STAFF_TYPE.HOLDERS.value}
              component={Select}
              options={enumToSelectOptions(COMPANY_STAFF_TYPE)}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Cantidad" required>
            <Field
              name="quantity"
              component={TextInput}
              type="text"
              placeholder="Cantidad"
              validate={[isRequired, validateNumber]}
            />
          </FormItem>
        </FormGroup>
        {departureTimeField}
        {startDateField}
        {frecuencyFields}
        <h3>Horarios</h3>
        {timetableResource}
      </>
    );

  return (
    <Form onSubmit={handleSubmit(onHandleSubmit)}>
      <h3>Itinerario</h3>
      {itineraryFields}
      <FormFooter />
    </Form>
  );
};

ItineraryScheduleForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    departureTime: PropTypes.instanceOf(Date),
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    routeSegmentList: PropTypes.arrayOf({}),
    layoverList: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
    durationAdjustmentList: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
    isMaciva: PropTypes.bool,
    repeatType: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      unit: PropTypes.string,
    }),
    itineraryCode: PropTypes.string,
  }),
  dispatchChange: PropTypes.func.isRequired,
  dispatchGetRoutesForItinerarySchedule: PropTypes.func.isRequired,
  isForMine: PropTypes.bool,
  serviceType: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  pricingProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  circuit: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

ItineraryScheduleForm.defaultProps = {
  initialValues: {},
  isForMine: false,
  serviceType: {},
  pricingProfiles: [],
  circuit: {},
};

const mapStateToProps = (state) => ({
  loading: !state.ItineraryUnit.ItinerarySchedule.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  isForMine: !!state.authentication.get('user').salesSessionUserId,
  serviceType: selector(state, 'serviceTypeId'),
  pricingProfiles: selector(state, 'pricingProfileId'),
  circuit: selector(state, 'circuitId'),
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchGetRoutesForItinerarySchedule: getRoutesForItinerarySchedule,
};

const formComponent = reduxForm({
  form: 'ItineraryScheduleForm',
})(ItineraryScheduleForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
