import React, { useLayoutEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { EXPENSE_AUTHORIZER_USER_PATH } from '../../../../config/paths';
import Loader from '../../../common/Loader';
import Content from '../../../layout/Content';
import NoDataResource from '../../../common/resource/NoDataResource';
import ExpenseAuthorizerUserToolbar from './ExpenseAuthorizerUserToolbar';
import ExpenseAuthorizerUserBasicInformationResource from './resource/ExpenseAuthorizerUserBasicInformationResource';
import {
  clearExpenseAuthorizerUser,
  getExpenseAuthorizerUser,
} from '../../../../actions/accounting/ExpenseAuthorizerUser';

export const ExpenseAuthorizerUser = ({
  breadcrumbs,
  match: {
    params: { id: expenseAuthorizerUserId },
  },
  expenseAuthorizerUser,
  loading,
  dispatchGetExpenseAuthorizerUser,
  dispatchClearExpenseAuthorizerUser,
}) => {
  useLayoutEffect(() => {
    dispatchGetExpenseAuthorizerUser({ expenseAuthorizerUserId });

    return () => dispatchClearExpenseAuthorizerUser();
  }, []);

  let content = null;
  let toolbar = null;
  if (loading) content = <Loader />;
  else if (expenseAuthorizerUser.isEmpty()) {
    content = <NoDataResource returnPage={EXPENSE_AUTHORIZER_USER_PATH} />;
  } else {
    const expenseAuthorizerUserJSON = expenseAuthorizerUser.toJS();
    content = (
      <ExpenseAuthorizerUserBasicInformationResource
        {...expenseAuthorizerUserJSON}
      />
    );
    toolbar = (
      <ExpenseAuthorizerUserToolbar
        expenseAuthorizerUserId={expenseAuthorizerUserJSON.id}
      />
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Usuario Autorizador de Egreso"
      toolbar={toolbar}
      content={content}
    />
  );
};

ExpenseAuthorizerUser.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  expenseAuthorizerUser: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  dispatchGetExpenseAuthorizerUser: PropTypes.func.isRequired,
  dispatchClearExpenseAuthorizerUser: PropTypes.func.isRequired,
};

ExpenseAuthorizerUser.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ AccountingUnit }) => ({
  breadcrumbs: [
    ...AccountingUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Usuarios Autorizadores de Egresos',
      href: EXPENSE_AUTHORIZER_USER_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  expenseAuthorizerUser: AccountingUnit.ExpenseAuthorizerUser.getIn([
    'current',
    'content',
  ]),
  loading: !AccountingUnit.ExpenseAuthorizerUser.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetExpenseAuthorizerUser: getExpenseAuthorizerUser,
  dispatchClearExpenseAuthorizerUser: clearExpenseAuthorizerUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpenseAuthorizerUser);
