import QueryString from 'query-string';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import {
  COMPANY_RESOURCE_ENDPOINT,
  COMPANY_RESOURCE_TYPE_ENDPOINT,
} from '../../config/endpoints';

import {
  CLEAR_COMPANY_RESOURCE,
  CLEAR_COMPANY_RESOURCE_TYPES,
  CLEAR_COMPANY_RESOURCES,
  FLAG_COMPANY_RESOURCE_ACTIVITY,
  FLAG_GETTING_COMPANY_RESOURCE_TYPES,
  FLAG_GETTING_COMPANY_RESOURCES,
  GET_COMPANY_RESOURCE,
  GET_COMPANY_RESOURCE_TYPES,
  GET_COMPANY_RESOURCES,
} from '../types';
import { COMPANY_RESOURCE_PATH } from '../../config/paths';

const flagCompanyResources = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_COMPANY_RESOURCES,
    payload: flag,
  });

const getCompanyResources = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagCompanyResources(true));
    const query = tableFilters;
    const url = `${COMPANY_RESOURCE_ENDPOINT}?${QueryString.stringify(query)}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise);
    const response = await promise.json();
    dispatch({
      type: GET_COMPANY_RESOURCES,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagCompanyResources(false));
  }
};

const clearCompanyResources = () => (dispatch) =>
  dispatch({
    type: CLEAR_COMPANY_RESOURCES,
  });

const flagCompanyResourceActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_COMPANY_RESOURCE_ACTIVITY,
    payload: flag,
  });

const getCompanyResource =
  async ({ companyResourceId }) =>
  async (dispatch) => {
    try {
      dispatch(flagCompanyResourceActivity(true));
      const url = `${COMPANY_RESOURCE_ENDPOINT}/${companyResourceId}`;
      const promise = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(promise);
      const response = await promise.json();
      dispatch({
        type: GET_COMPANY_RESOURCE,
        payload: response,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCompanyResourceActivity(false));
    }
  };

const clearCompanyResource = () => (dispatch) =>
  dispatch({
    type: CLEAR_COMPANY_RESOURCE,
  });

const postCompanyResource =
  async ({ name, companyResourceTypeId, active, description }) =>
  async (dispatch) => {
    try {
      dispatch(flagCompanyResourceActivity(true));
      const payload = {
        name,
        companyResourceTypeId,
        active,
        description,
      };
      const url = COMPANY_RESOURCE_ENDPOINT;
      const promise = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise);
      const resource = await promise.json();
      if (resource) {
        dispatch(push(`${COMPANY_RESOURCE_PATH}/${resource.id}`));
      }
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCompanyResourceActivity(false));
    }
  };

const putCompanyResource =
  async (
    companyResourceId,
    { name, companyResourceTypeId, active, description },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagCompanyResourceActivity(true));
      const payload = {
        id: companyResourceId,
        name,
        companyResourceTypeId,
        active,
        description,
      };
      const url = `${COMPANY_RESOURCE_ENDPOINT}/${companyResourceId}`;
      const promise = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(promise, null, dispatch);
      await promise.json();
      dispatch(push(`${COMPANY_RESOURCE_PATH}/${companyResourceId}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagCompanyResourceActivity(false));
    }
  };

const flagCompanyResourceTypes = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_COMPANY_RESOURCE_TYPES,
    payload: flag,
  });

const getCompanyResourceTypes = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagCompanyResourceTypes(true));
    const query = tableFilters;
    const url = `${COMPANY_RESOURCE_TYPE_ENDPOINT}?${QueryString.stringify(
      query,
    )}`;
    const promise = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(promise);
    const response = await promise.json();
    dispatch({
      type: GET_COMPANY_RESOURCE_TYPES,
      payload: response,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagCompanyResourceTypes(false));
  }
};

const clearCompanyResourceTypes = () => (dispatch) =>
  dispatch({
    type: CLEAR_COMPANY_RESOURCE_TYPES,
  });

export {
  getCompanyResources,
  clearCompanyResources,
  getCompanyResource,
  clearCompanyResource,
  postCompanyResource,
  putCompanyResource,
  getCompanyResourceTypes,
  clearCompanyResourceTypes,
};
