import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import AddCargoToItineraryByParcelButton from './AddCargoToItineraryByParcelButton';
import { CARGO_OPERATIONS } from '../../../../../config/constants';
import CargoOperationByQrButton from '../cargo-operation-qr/CargoOperationByQrButton';

const AddCargoToItineraryToolbar = ({
  onClickAddItemToItinerary,
  disabled,
  loadingItemToItinerary,
}) => (
  <ButtonToolbar className="pull-right">
    <div>
      <Button color="success" type="submit" disabled={loadingItemToItinerary}>
        Subir
      </Button>{' '}
      <CargoOperationByQrButton
        onClickAddItemToItinerary={onClickAddItemToItinerary}
        cargoOperation={CARGO_OPERATIONS.ADD_ITEM}
        disabled={disabled}
      />{' '}
      <AddCargoToItineraryByParcelButton
        onClickAddItemToItinerary={onClickAddItemToItinerary}
        disabled={disabled}
      />{' '}
    </div>
  </ButtonToolbar>
);

AddCargoToItineraryToolbar.propTypes = {
  onClickAddItemToItinerary: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loadingItemToItinerary: PropTypes.bool,
};

AddCargoToItineraryToolbar.defaultProps = {
  disabled: false,
  loadingItemToItinerary: false,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loadingItemToItinerary: !CargoUnit.ItemToItinerary.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

export default connect(mapStateToProps, null)(AddCargoToItineraryToolbar);
