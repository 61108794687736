import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { postMaintenanceServiceOrder } from '../../../../actions/mechanical-maintenance';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import { MAINTENANCE_SERVICE_ORDER_PATH } from '../../../../config/paths';
import { MAINTENANCE_SERVICE_ORDER_STATUS } from '../../../../config/constants';
import { tzNormalizeDate } from '../../../../utils/date';
import MaintenanceServiceOrderForm from './MaintenanceServiceOrderForm';
import { DATE_TIME_LOCAL } from '../../../../config/locale';

const NewMaintenanceServiceOrder = ({
  breadcrumbs,
  dispatchPostMaintenanceServiceOrder,
}) => {
  const onSubmit = (formValues, selectedPlans) => {
    const invalidPlans = selectedPlans.filter(
      (plan) => plan.selectedActivityOrder === null,
    );

    if (invalidPlans.length > 0) {
      toastr.error(
        'Error',
        'Por favor selecciona una actividad para todos los planes.',
      );
      return;
    }

    let maintenanceWarningList = [];

    if (
      Array.isArray(formValues.preventiveMaintenanceWarningList) ||
      Array.isArray(formValues.correctiveMaintenanceWarningList)
    ) {
      maintenanceWarningList = [
        ...(formValues.preventiveMaintenanceWarningList || []),
        ...(formValues.correctiveMaintenanceWarningList || []),
      ];
    }

    const materialList =
      formValues.materialList &&
      formValues.materialList.map(({ materialId, quantity }) => ({
        materialId: materialId.value,
        quantity: quantity || 1,
      }));

    dispatchPostMaintenanceServiceOrder({
      maintenanceWarningList: maintenanceWarningList.map((warning) => ({
        id: warning.maintenanceWarningId.value,
        maintenancePlanningActivityResourceId:
          warning.maintenanceWarningId.maintenancePlanningActivityResourceId,
      })),
      registeredBusId: formValues.registeredBusId
        ? formValues.registeredBusId.value
        : null,
      companyResourceId: formValues.companyResourceId
        ? formValues.companyResourceId.value
        : null,
      deadline: tzNormalizeDate({ date: formValues.deadline }),
      priority: formValues.priority.value,
      description: formValues.description,
      status: MAINTENANCE_SERVICE_ORDER_STATUS.OPEN.value,
      selectedMaintenancePlanningList: selectedPlans,
      materialAssigmentList: materialList,
    });
  };

  const content = (
    <MaintenanceServiceOrderForm
      onSubmit={onSubmit}
      initialValues={{
        status: MAINTENANCE_SERVICE_ORDER_STATUS.OPEN,
        deadline: tzNormalizeDate({ format: DATE_TIME_LOCAL }),
      }}
    />
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nueva Orden de Servicio"
      subtitle="Crear nueva orden de servicio"
      content={content}
    />
  );
};

NewMaintenanceServiceOrder.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostMaintenanceServiceOrder: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostMaintenanceServiceOrder: postMaintenanceServiceOrder,
};

const mapStateToProps = ({ MechanicalMaintenanceUnit }) => ({
  breadcrumbs: [
    ...MechanicalMaintenanceUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Ordenes de Servicio',
      href: MAINTENANCE_SERVICE_ORDER_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewMaintenanceServiceOrder);
