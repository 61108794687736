import Immutable from 'immutable';
import { COLLECTION_SHAPE } from '../../../config/shapes';
import {
  FLAG_GETTING_MANUAL_VOUCHERS,
  GET_MANUAL_VOUCHERS,
  CLEAR_MANUAL_VOUCHERS,
  FLAG_MANUAL_VOUCHER_ACTIVITY,
  GET_MANUAL_VOUCHER,
  CLEAR_MANUAL_VOUCHER,
  FLAG_ORIGINAL_VOUCHER_ACTIVITY,
  GET_ORIGINAL_VOUCHER,
  CLEAR_ORIGINAL_VOUCHER,
} from '../../../actions/types/accounting';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  current: Immutable.Map({
    content: Immutable.Map(),
    activity: Immutable.List(),
    original: Immutable.Map(),
    originalActivity: Immutable.List(),
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_MANUAL_VOUCHERS:
      return state.setIn(['all', 'loading'], payload);
    case GET_MANUAL_VOUCHERS:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_MANUAL_VOUCHERS:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case FLAG_MANUAL_VOUCHER_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_MANUAL_VOUCHER:
      return state.setIn(['current', 'content'], Immutable.Map(payload));
    case CLEAR_MANUAL_VOUCHER:
      return state.setIn(['current'], INITIAL_STATE.getIn(['current']));
    case FLAG_ORIGINAL_VOUCHER_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'originalActivity'],
          state.getIn(['current', 'originalActivity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'originalActivity'],
        state.getIn(['current', 'originalActivity']).pop(),
      );
    case GET_ORIGINAL_VOUCHER:
      return state.setIn(['current', 'original'], Immutable.Map(payload));
    case CLEAR_ORIGINAL_VOUCHER:
      return state.setIn(
        ['current', 'original'],
        INITIAL_STATE.getIn(['current', 'original']),
      );
    default:
      return state;
  }
};
