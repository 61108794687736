import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { patchApproveManualVoucher } from '../../../../actions';
import withEndpointAuthorization from '../../../units/authorization/withEndPointAuthorization';
import { POST } from '../../../../config/permissions';
import { generateApproveManualVoucherEndpoint } from '../../../../config/endpoints';

const ApproveManualVoucherCell = ({
  id,
  dispatchPatchApproveManualVoucher,
  loading,
}) => {
  const onApprove = () => {
    if (loading) return;
    dispatchPatchApproveManualVoucher(id);
  };
  return (
    <>
      <i
        tabIndex="0"
        role="button"
        className="fa fa-check-circle text-success"
        onKeyPress={onApprove}
        onClick={onApprove}
      />
    </>
  );
};
ApproveManualVoucherCell.propTypes = {
  id: PropTypes.number.isRequired,
  dispatchPatchApproveManualVoucher: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ AccountingUnit }) => ({
  loading: !AccountingUnit.ManualVoucher.getIn([
    'current',
    'activity',
  ]).isEmpty(),
});

const mapDispatchToprops = {
  dispatchPatchApproveManualVoucher: patchApproveManualVoucher,
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToprops,
)(ApproveManualVoucherCell);

export default withEndpointAuthorization({
  url: generateApproveManualVoucherEndpoint(),
  mapUrlParamsToProps: { id: 'id' },
  permissionType: POST,
})(connectedComponent);
