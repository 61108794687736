import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLocations, clearLocations } from '../../../../actions/index';
import { optionsPropTypes } from './SelectPropTypes';
import Select from './Select';
import { DEFAULT_SELECT_PLACEHOLDER } from '../../../../config/constants';

export const LocationSelect = ({
  dispatchClearLocations,
  dispatchGetLocations,
  forSales,
  forCargo,
  forPickupCargo,
  forHomeBase,
  loading,
  options,
  isGridVariant,
  value,
  isMulti,
  isClearable,
  onValueChange,
  placeholder,
  forLegalAbandonmentHomeBase,
  ...rest
}) => {
  useLayoutEffect(() => () => dispatchClearLocations(), []);

  const handleInputChange = (inputValue) => {
    if (inputValue.trim().length && inputValue.trim().length >= 3) {
      let query = [`name:${inputValue}`];

      if (forSales) query = [...query, 'isTerminal:true', 'isStop:true'];

      if (forHomeBase) query = [...query, 'isHomeBase:true'];

      if (forPickupCargo) query = [...query, 'pickupCargoPoint:true'];
      if (forLegalAbandonmentHomeBase)
        query = [...query, 'legalAbandonmentHomeBase:true'];

      dispatchGetLocations({ query });
    }
  };

  let filterOption = null;

  if (forCargo) filterOption = (locations) => locations;

  return (
    <Select
      isLoading={loading}
      options={options}
      isMulti={isMulti}
      input={{ value }}
      onValueChange={onValueChange}
      isClearable={isClearable}
      isGridVariant={isGridVariant}
      onInputChange={handleInputChange}
      placeholder={placeholder}
      filterOption={filterOption}
      {...rest}
    />
  );
};

LocationSelect.propTypes = {
  isGridVariant: PropTypes.bool,
  options: optionsPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.func,
  dispatchGetLocations: PropTypes.func.isRequired,
  dispatchClearLocations: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  forSales: PropTypes.bool,
  forCargo: PropTypes.bool,
  forPickupCargo: PropTypes.bool,
  forHomeBase: PropTypes.bool,
  forLegalAbandonmentHomeBase: PropTypes.bool,
};

LocationSelect.defaultProps = {
  noOptionsMessage: undefined,
  isDisabled: false,
  isGridVariant: false,
  onValueChange: undefined,
  isClearable: false,
  value: {},
  isMulti: false,
  placeholder: DEFAULT_SELECT_PLACEHOLDER,
  forSales: false,
  forCargo: false,
  forPickupCargo: false,
  forHomeBase: false,
  forLegalAbandonmentHomeBase: false,
};

const mapStateToProps = ({ LocationUnit }) => ({
  options: LocationUnit.Location.getIn(['all', 'content', 'content']).map(
    (location) => ({
      value: location.id,
      label: location.name,
      city: location.address.city,
    }),
  ),
  loading: LocationUnit.Location.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetLocations: getLocations,
  dispatchClearLocations: clearLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelect);
