import Immutable from 'immutable';
import {
  ACTIVITY_PATH,
  BUS_ACTIVITY_ASSIGNMENT_PATH,
  PREVENTIVE_EXECUTION_REGISTER_PATH,
  HOME_PATH,
  MECHANICAL_MAINTENANCE_UNIT_PATH,
  MECHANIC_PATH,
  ORGANIZATION_CHART_PATH,
  PREVENTIVE_MAINTENANCE_REPORT_PATH,
  BUS_ACTIVITY_HISTORY_PATH,
  MATERIAL_PATH,
  ACTIVITY_PROJECTION_PATH,
  ADVANCED_EXECUTION_REGISTER_PATH,
  ODOMETER_BY_BUS_PATH,
  MAINTENANCE_WARNING_PATH,
  MAINTENANCE_SERVICE_ORDER_PATH,
  MY_MAINTENANCE_SERVICE_ORDER_PATH,
  MAINTENANCE_TASK_PATH,
  MAINTENANCE_PLANNING_PATH,
  MAINTENANCE_BUS_GROUP_PATH,
  COMPANY_RESOURCE_PATH,
  COMPANY_RESOURCE_GROUP_PATH,
} from '../../../config/paths';

const INITIAL_STATE = Immutable.Map({
  cards: [
    {
      id: 'organization-chart',
      title: 'Organigrama',
      path: ORGANIZATION_CHART_PATH,
      icon: 'sitemap',
    },
    {
      id: 'mechanic',
      title: 'Personal',
      path: MECHANIC_PATH,
      icon: 'male',
    },
    {
      id: 'material',
      title: 'Material',
      path: MATERIAL_PATH,
      icon: 'archive',
    },
    {
      id: 'activity',
      title: 'Actividad',
      path: ACTIVITY_PATH,
      icon: 'tasks',
    },
    {
      id: 'bus-activity-assigment',
      title: 'Asignar Actividades a Buses',
      path: BUS_ACTIVITY_ASSIGNMENT_PATH,
      icon: 'bus',
    },
    {
      id: 'maintenance-occurrence-report',
      title: 'Reporte de Ocurrencia',
      path: MAINTENANCE_WARNING_PATH,
      icon: 'exclamation-triangle',
    },
    {
      id: 'maintenance-service-order',
      title: 'Orden de Servicio',
      path: MAINTENANCE_SERVICE_ORDER_PATH,
      icon: 'file-text-o',
    },
    {
      id: 'my-service-orders',
      title: 'Mis Ordenes de Servicio',
      path: MY_MAINTENANCE_SERVICE_ORDER_PATH,
      icon: 'columns',
    },
    {
      id: 'preventive-maintenance-report',
      title: 'Reporte de Mantenimiento Preventivo',
      path: PREVENTIVE_MAINTENANCE_REPORT_PATH,
      icon: 'file-pdf-o',
    },
    {
      id: 'preventive-execution-register',
      title: 'Registro de Ejecución Preventivo',
      path: PREVENTIVE_EXECUTION_REGISTER_PATH,
      icon: 'edit',
    },
    {
      id: 'advanced-execution-register',
      title: 'Registro de Ejecución Adelantado',
      path: ADVANCED_EXECUTION_REGISTER_PATH,
      icon: 'pencil',
    },
    {
      id: 'bus-activity-history',
      title: 'Historial de Actividades',
      path: BUS_ACTIVITY_HISTORY_PATH,
      icon: 'trello',
    },
    {
      id: 'activity-projection',
      title: 'Proyección de Actividades',
      path: ACTIVITY_PROJECTION_PATH,
      icon: 'line-chart',
    },
    {
      id: 'odometer-by-bus',
      title: 'Odómetro por Bus',
      path: ODOMETER_BY_BUS_PATH,
      icon: 'dashboard',
    },
    {
      id: 'maintenance-task',
      title: 'Tareas',
      path: MAINTENANCE_TASK_PATH,
      icon: 'tasks',
    },
    {
      id: 'maintenance-planning',
      title: 'Plan de Mantenimiento',
      path: MAINTENANCE_PLANNING_PATH,
      icon: 'list-ol',
    },
    {
      id: 'maintenance-bus-group',
      title: 'Grupo de Buses',
      path: MAINTENANCE_BUS_GROUP_PATH,
      icon: 'bus',
    },
    {
      id: 'company-resource',
      title: 'Recursos de la Compañia',
      path: COMPANY_RESOURCE_PATH,
      icon: 'building',
    },
    {
      id: 'company-resource-group',
      title: 'Grupo de Recursos de la Compañia',
      path: COMPANY_RESOURCE_GROUP_PATH,
      icon: 'object-group',
    },
  ],
  breadcrumbs: [
    {
      text: 'Inicio',
      tag: 'a',
      href: HOME_PATH,
    },
    {
      text: 'Gestión de Mantenimiento Mecánico',
      tag: 'span',
      href: MECHANICAL_MAINTENANCE_UNIT_PATH,
    },
  ],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
