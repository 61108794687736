import React from 'react';
import { Button, Col, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {
  clearUnclaimedCargoItems,
  getUnclaimedCargoItems,
} from '../../../../actions/cargo/LegalAbandonment';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import LegalAbandonmentResult from './LegalAbandonmentResult';
import LocationSelect from '../../../common/forms/select/LocationSelect';

const LegalAbandonmentForm = ({
  handleSubmit,
  dispatchGetUnclaimedCargoItems,
}) => {
  const onHandleSubmit = ({ sourceLocation }) => {
    dispatchGetUnclaimedCargoItems({
      locationId: sourceLocation.value,
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        <FormGroup row>
          <FormItem label="Ubicación Actual" required>
            <Field
              name="sourceLocation"
              component={LocationSelect}
              forLegalAbandonmentHomeBase
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>

        <FormGroup row>
          <Col className="flex row-reverse">
            <Button type="submit" color="primary">
              Buscar
            </Button>
          </Col>
        </FormGroup>
      </Form>
      <h5>Resultados de la Búsqueda</h5>
      <LegalAbandonmentResult />
    </>
  );
};

LegalAbandonmentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatchGetUnclaimedCargoItems: PropTypes.func.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  loading: CargoUnit.LegalAbandonment.getIn(['all', 'loading']),
  data: CargoUnit.LegalAbandonment.getIn(['all', 'content']),
});

const mapDispatchToProps = {
  dispatchGetUnclaimedCargoItems: getUnclaimedCargoItems,
  dispatchClearUnclaimedCargoItems: clearUnclaimedCargoItems,
};

const formComponent = reduxForm({
  form: 'LegalAbandonmentForm',
})(LegalAbandonmentForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
