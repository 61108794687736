import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import {
  DRIVER_MOVEMENT_HISTORY_PATH,
  NEW_DRIVER_MOVEMENT_HISTORY_PATH,
} from '../../../../config/paths';
import {
  clearDriversMovementHistory,
  getDriversMovementHistory,
  getPrintDriverMovementHistory,
} from '../../../../actions';
import { DRIVERS_MOVEMENT_HISTORY_COLUMNS } from '../../../../config/columns';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import Table from '../../../common/Table';
import PrintDriverMovementHistoryForm from './PrintDriverMovementHistoryForm';
import TableToolbar from '../../../common/TableToolbar';

export const DriversMovementHistory = ({
  dispatchGetDriversMovementHistory,
  dispatchClearDriversMovementHistory,
  loading,
  driversMovementHistory,
  breadcrumbs,
  dispatchGetPrintDriverMovementHistory,
}) => {
  useLayoutEffect(() => () => dispatchClearDriversMovementHistory(), []);

  const onSubmit = (formValues) => {
    const newFormValues = {
      ...formValues,
      month: formValues.month.value,
    };
    dispatchGetPrintDriverMovementHistory(newFormValues);
  };

  const data = driversMovementHistory.get('content') || [];
  const pages = driversMovementHistory.get('totalPages') || null;
  const defaultPageSize =
    driversMovementHistory.get('size') || DEFAULT_PAGE_SIZE;

  const toolbar = (
    <TableToolbar
      buttonPath={NEW_DRIVER_MOVEMENT_HISTORY_PATH}
      buttonText="Crear"
      buttonClassName="btn btn-success"
    />
  );

  const content = (
    <>
      <PrintDriverMovementHistoryForm onSubmit={onSubmit} />
      <hr />
      <Table
        columns={DRIVERS_MOVEMENT_HISTORY_COLUMNS}
        data={data}
        pages={pages}
        defaultPageSize={defaultPageSize}
        filterable
        fetchData={dispatchGetDriversMovementHistory}
        modelPath={DRIVER_MOVEMENT_HISTORY_PATH}
        loading={loading}
        navigateToModelOnRowClick
      />
    </>
  );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Movimientos de Conductores"
      toolbar={toolbar}
      content={content}
    />
  );
};

DriversMovementHistory.propTypes = {
  dispatchGetDriversMovementHistory: PropTypes.func.isRequired,
  dispatchClearDriversMovementHistory: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  driversMovementHistory: PropTypes.instanceOf(Immutable.Map),
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetPrintDriverMovementHistory: PropTypes.func.isRequired,
};

DriversMovementHistory.defaultProps = {
  loading: false,
  driversMovementHistory: null,
};

const mapStateToProps = ({ TrafficUnit }) => ({
  breadcrumbs: [
    ...TrafficUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Movimientos de Conductores',
      href: DRIVER_MOVEMENT_HISTORY_PATH,
    },
  ],
  driversMovementHistory: TrafficUnit.DriverMovementHistory.getIn([
    'all',
    'content',
  ]),
  loading: TrafficUnit.DriverMovementHistory.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetDriversMovementHistory: getDriversMovementHistory,
  dispatchClearDriversMovementHistory: clearDriversMovementHistory,
  dispatchGetPrintDriverMovementHistory: getPrintDriverMovementHistory,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DriversMovementHistory);
