import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { expenseAuthorizerUserBasicInformationPropTypes } from '../proptypes/ExpenseAuhorizerUserBasicInformationPropTypes';

const ExpenseAuthorizerUserBasicInformationResource = ({
  id,
  user,
  costCenter,
}) => (
  <>
    <ResourceProperty label="ID:">{id}</ResourceProperty>
    <ResourceProperty label="Usuario Autorizador:">
      {user.customer.fullName}
    </ResourceProperty>
    <ResourceProperty label="Centro de Costos:">
      {costCenter ? costCenter.name : ''}
    </ResourceProperty>
  </>
);

ExpenseAuthorizerUserBasicInformationResource.propTypes =
  expenseAuthorizerUserBasicInformationPropTypes;

export default ExpenseAuthorizerUserBasicInformationResource;
