import React from 'react';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import InternalResource from '../../../../common/resource/InternalResource';
import CompanyResourceBasicInformationPropTypes from './CompanyResourceGroupBasicInformationPropTypes';

const CompanyResourceGroupBasicInformationResource = ({
  id,
  name,
  description,
  companyResourceList,
  createDate,
  lastUpdate,
}) => (
  <>
    <ResourceProperty label="Nombre:">{name}</ResourceProperty>
    <ResourceProperty label="Descripción:">{description}</ResourceProperty>
    <h3>Recursos de Compañia</h3>
    {companyResourceList.map((companyResource) => (
      <ResourceProperty
        key={companyResource.id}
        label={`${companyResource.name} :`}
      >
        {companyResource.companyResourceType.description}
      </ResourceProperty>
    ))}
    <InternalResource id={id} createDate={createDate} lastUpdate={lastUpdate} />
  </>
);

CompanyResourceGroupBasicInformationResource.propTypes =
  CompanyResourceBasicInformationPropTypes;

export default CompanyResourceGroupBasicInformationResource;
