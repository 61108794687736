import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, FormGroup, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import Select from '../../../common/forms/select/Select';
import { EXPENSE_STATUS } from '../../../../config/constants';
import { enumToSelectOptions } from '../../../../utils/enum';

const ExpenseStatusSearchForm = ({ handleSubmit }) => {
  const expenseStatusOptions = enumToSelectOptions(EXPENSE_STATUS);

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormItem label="Buscar por Estado de Egreso" required>
          <Field
            name="expenseStatus"
            component={Select}
            options={expenseStatusOptions.splice(0, 3)}
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
      <Row>
        <Col className="flex row-reverse">
          <Button type="submit" color="secondary" outline>
            <i className="fa fa-search" /> Buscar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

ExpenseStatusSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'ExpenseStatusSearchForm',
})(ExpenseStatusSearchForm);
