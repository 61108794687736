import { string, number, bool, arrayOf, shape } from 'prop-types';

const manualVoucherDataPropTypes = {
  id: number,
  voucherTypeId: number,
  documentSeries: string,
  documentCode: number,
  serviceType: string,
  issueDate: number,
  description: string,
  status: string,
};

const manualVoucherDataDefaultProps = {
  id: null,
  voucherTypeId: null,
  documentSeries: null,
  documentCode: null,
  serviceType: null,
  issueDate: null,
  description: null,
  status: null,
};

const manualVoucherPaymentPropTypes = {
  affectedByTaxes: bool,
  currencyCode: string,
  paymentType: string,
  installments: number,
  dueDate: number,
};

const manualVoucherPaymentDefaultProps = {
  affectedByTaxes: null,
  currencyCode: null,
  paymentType: null,
  installments: null,
  dueDate: null,
};

const manualVoucherItemsDataPropTypes = {
  items: arrayOf(
    shape({
      quantity: number,
      description: string,
      unitaryPrice: number,
      totalPrice: number,
    }),
  ),
  totalAmountWithoutTaxes: number,
  taxes: number,
  totalAmount: number,
  detraction: number,
  detractionAmount: number,
  totalAmountPayable: number,
  totalAmountPayableText: string,
};

const manualVoucherItemsDataDefaultProps = {
  items: [],
  totalAmountWithoutTaxes: null,
  taxes: null,
  totalAmount: null,
  detraction: null,
  detractionAmount: null,
  totalAmountPayable: null,
  totalAmountPayableText: null,
};

export {
  manualVoucherDataPropTypes,
  manualVoucherDataDefaultProps,
  manualVoucherPaymentDefaultProps,
  manualVoucherPaymentPropTypes,
  manualVoucherItemsDataDefaultProps,
  manualVoucherItemsDataPropTypes,
};
