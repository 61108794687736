import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import { CIRCUIT_PATH } from '../../../../config/paths';
import { postCircuit } from '../../../../actions';
import CircuitForm from './CircuitForm';

const NewCircuit = ({ breadcrumbs, dispatchPostCircuit }) => {
  const onSubmit = (formValues) => {
    const circuitRouteList = formValues.circuitRouteList.map((route) => ({
      routeId: route.value,
    }));
    dispatchPostCircuit({ ...formValues, circuitRouteList });
  };

  const content = <CircuitForm onSubmit={onSubmit} />;

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Nuevo Circuito"
      subtitle="Crear nuevo Circuito"
      content={content}
    />
  );
};

NewCircuit.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostCircuit: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchPostCircuit: postCircuit,
};

const mapStateToProps = ({ RouteUnit }) => ({
  breadcrumbs: [
    ...RouteUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Lista de Circuitos',
      href: CIRCUIT_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCircuit);
